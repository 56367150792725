import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { GetBusinessDayUsecase } from '@src/core/usecases/transfer/beneficiaries/get-businessDay-usecases';
import { GetBoletoUsecases } from '@src/core/usecases/payment/get-boleto.usecase';
import { GetStatusHourPaymentUsecases } from '@src/core/usecases/payment/get-status-hour-payment.usecase';
import { PostBoletoUsecases } from '@src/core/usecases/payment/post-boleto.usecase';
import { SaveBoletosUsecases } from '@src/core/usecases/payment/save-boleto.usecase';
import { PaymentBoletoRequest } from '@src/core/domain/payment/payment.model';
import { AccountDataShare } from '@src/data/repository/data-share-repository';
import { BeneficiariesModel } from '@src/core/domain/transfer/transfer.model';
import { PaymentDataShare } from '@src/core/usecases/data-share/payment-data-share.service';
import { CompanyDataShare } from '@src/core/usecases/data-share/company-data-share.service';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import { boletoMask, formatHour, removeMask } from '@src/shared/util-common';
import { TransferApprovalDataRepository } from '@src/data/repository/approval-repository/approval.repository';
import { ApprovalandRefuseRequest } from '@src/core/domain/transfer-approval/approval.model';
import { GetReceiptPdfUsecase } from '@src/core/usecases/receipt/get-receipt-pdf-usecases';
import { ReceiptPdfRequest } from '@src/core/domain/receipt/receipt.model';
import * as $ from 'jquery';
import { toTitleCase } from 'codelyzer/util/utils';
import { LimitsDisclaimerUsecase } from '@src/core/usecases/limits-disclaimer/limits-disclaimer.usecase';

@Component({
  selector: 'fibra-page-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  public loading: boolean;
  paymentStep = 1;
  char = 100;
  paymentInput = 'digitavel';
  paymentBarcode: string;
  paymentDescription: string;
  paymentBarcodeOptical: string;
  public valuesBoleto = false;
  public errorBarCodeDigit = false;
  public errorBarCodeOtico = false;
  public paymentDate: NgbDate;
  public date;
  public paymentContaCod: string;
  public paymentContaAg: string;
  public paymentContaDesc: string;
  public paymentContaExibicao: string;
  public paymentContaSemChequeEmpresa: string;
  public paymentContaComChequeEmpresa: string;
  public limiteChequeEmpresa: number;
  public paymentCodeAuth = 'MBB123457866FLMJO123'; // TODO: Pegar do retorno do serviço
  public paymentNumberAppointment = '123465789'; // TODO: Pegar do retorno do serviço
  public paymentValue: number;
  public paymentMinValue: number;
  public paymentCnpjCpf: string;
  public paymentBank: string;
  public paymentJuros: number;
  public paymentMora: number;
  public paymentData: string;
  public paymentMultas: number;
  public paymentCodBank: string;
  public valorBoleto: number;
  public showModalValidation = false;
  public paymentNomeBeneficiario: string;
  public paymentDataVencimento: string = moment().format('DD/MM/YYYY');
  public paymentDateFormat: string = moment().format('DD/MM/YYYY');
  public selectedDate: any;
  public businessDayPayment: any;
  public businessDayDue: any;
  public paymentHour = moment().format('k:mm');
  public paymentMultiple: any;
  public operationNumberArr: any;
  public multipleParams: any;
  public navigateToMultiple: boolean;
  public toasterTitle: string;
  public toasterMessage: string;
  public paymentDesconto: number;
  public paymentAbatimento: number;
  public idTransacao: number;
  public idPagamento: number;
  public codBarrasRepetido: boolean = false;

  // tslint:disable-next-line:variable-name
  boleto_sucesso: boolean;
  // tslint:disable-next-line:variable-name
  num_compromisso: any;
  // tslint:disable-next-line:variable-name
  desc_etapa_pagamento: any;
  isPayment: boolean;
  // tslint:disable-next-line:variable-name
  dt_transacao: string;
  // tslint:disable-next-line:variable-name
  dt_hora: string;
  companyName: any;
  companyCnpj: any;
  companyList: any = [];
  public paymentMethod: string;
  public channel: string;
  paymentCheckBalance: any;
  showBusinessDayMsg: string;
  showTaxTooltip: boolean;
  showDiaUtil: boolean;
  showDiaUtilTax: boolean;
  flagPostBoleto: boolean;
  public inputKey: string;
  public inputReader: string;
  // tslint:disable-next-line:variable-name
  public mem_calculo: any = {};

  public validatedBarcode: any;
  public statusPaymentHour:string;
  public showStatusHourPaymentClosed:boolean=false;

  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';
  public typeColorToast = this.REDCOLOR;

  public params: any;
  public apiCallback: number;
  
  public leitor: boolean = true;
  public toTitleCase = toTitleCase;

  limits = [];
  showLimit = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private calendar: NgbCalendar,
    private getBoleto: GetBoletoUsecases,
    private shareAccount: AccountDataShare,
    private postBoleto: PostBoletoUsecases,
    private saveBoletos: SaveBoletosUsecases,
    private paymentDataShare: PaymentDataShare,
    private getBusinessDay: GetBusinessDayUsecase,
    private companyDataShare: CompanyDataShare,
    private transactionApproval: TransferApprovalDataRepository,
    private getStatusHourPayment : GetStatusHourPaymentUsecases,
    private getPdf: GetReceiptPdfUsecase,
    private limitDisclaimer: LimitsDisclaimerUsecase
  ) {
    this.paymentMultiple = [];
    this.operationNumberArr = [];
    this.toasterTitle = 'Ocorreu um erro no envio!';
    this.toasterMessage = 'Tente novamente.';
    this.paymentMethod = 'Débito em conta';
    this.channel = 'Internet Banking';
    this.validatedBarcode = {
      firstBlock: false,
      secondBlock: false,
      thirdBlock: false,
      fourthBlock: false,
      barcode: '',
    };
  }
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;
  ngOnInit() {

     const req = { }
      this.getStatusHourPayment.execute(this.paymentValue).subscribe((x) =>{
            this.statusPaymentHour = x.data.status;
            this.showStatusHourPaymentClosed = this.statusPaymentHour=='FECHADO' ? true : false;
            //console.log('horario', this.statusPaymentHour)
            //console.log('showStatusHourPaymentClosed', this.showStatusHourPaymentClosed)
          })

        this.statusPaymentHour = 'FECHADO'
        //this.showStatusHourPaymentClosed = true

    this.getDetails();
    this.date = this.calendar.getToday();
    const day = Number(moment().format('DD'));
    const month = Number(moment().format('MM'));
    const year = Number(moment().format('YYYY'));

    const date: NgbDate = new NgbDate(year, month, day);
    this.paymentDate = date;

    this.route.queryParams.subscribe((parametros) => {
      if(parametros.option && parametros.option == "false"){
        this.leitor = false;
      }else{
        this.leitor = true;
      }
      if (parametros.boleto) {
        this.paymentBarcode = parametros.boleto;
        this.inputKey = parametros.boleto;
        
        this.removeSelector();
        $('#ItemTransferencia').addClass('menu-active');
        $('.transactions-white').attr('src', '/assets/svg/transactions_white.svg');
        this.validateBarcodeBlock(this.paymentBarcode);
      }
    });
  }

  ngAfterViewInit(){
    var radioDigitavel = document.getElementById("radio-digitavel");
    var radioOtico = document.getElementById("radio-otico");
    if(this.leitor){
      radioDigitavel['checked'] = true;
      radioOtico['checked'] = false;
    }else{
      radioDigitavel['checked'] = false;
      radioOtico['checked'] = true;
    }
  }
  public goToView = (route) => this.router.navigateByUrl(route);

  changePaymentInput(newPayment) {
    this.clearAll();
    this.clearInputs();
    this.paymentInput = newPayment;
    this.validateBarcodeBlock();
  }

  changeDescription(value) {
    this.char = 100 - value.length;
  }

  closeModal(event?) {
    this.showModalValidation = event;
  }

  securityResponse(value) {
    if (this.apiCallback === 1) {
      if (value.status === 'success') {
        this.postBoletoSuccess(value);
      } else {
        this.postBoletosError(value); }
    } else if (this.apiCallback === 2) {

      if (value.status === 'success') {
        this.saveBoletosSuccess(value);
      } else {
        this.saveBoletosError(value);
      }
    }
    this.clearInputs();
  }

  public securityValidation = () => {
    if (this.paymentMultiple.length > 0) {
      this.navigateToMultiple = true;
      this.addMultiplePayment();
    } else {
      this.pay();
      this.showModalValidation = !this.showModalValidation;
    }
  }

  getBoletoSuccess = (res) => {
    if (res && res.status && res.status !== '') {
      this.valuesBoleto = res.status === 'success';
      if (res && res.data) {
        this.getDayBusiness();

        const customer = JSON.parse(localStorage.getItem('batch-company-name'));
        const titularity = removeMask(res.data.num_cpf_cnpj);
    
        let tipo;

        if(res.data.tipo_pessoa === 'PJ' && customer.cpfCnpj !== titularity){
          tipo = 'OUTRA_TITULARIDADE_PJ';
        }

        if(res.data.tipo_pessoa === 'PF' && customer.cpfCnpj !== titularity){
          tipo = 'OUTRA_TITULARIDADE_PF';
        }

        if((res.data.tipo_pessoa === 'PJ' || res.data.tipo_pessoa === 'PF') && customer.cpfCnpj === titularity){
          tipo = 'MESMA_TITULARIDADE';
        }

        // Pegar limites
        const payload = {
          NumeroConta: this.paymentContaCod,
          NumeroEmpresa: "",
          IdEmpresa: 0,
          Pix: false
        }

        this.limitDisclaimer.execute(payload).subscribe(l => {
          const limitReady: any = l.data.filter((e: any) => e.tipoTransacao === 'BOLETO' && e.tipoTitularidade === tipo);
          this.limits = limitReady;
          this.showLimit = true;
        })

        //console.log({pessoa: res.data.tipo_pessoa, cpfCnpj: removeMask(res.data.num_cpf_cnpj)})

         const req = { }
          this.getStatusHourPayment.execute(res.data.mem_calculo.vlr_total).subscribe((x) =>{
            this.statusPaymentHour = x.data.status;
            this.showStatusHourPaymentClosed = this.statusPaymentHour=='FECHADO' ? true : false;
            if(this.showStatusHourPaymentClosed){ 
              this.getDayBusiness(true);
            }
          
          })

       
       
        this.paymentValue = res.data.mem_calculo.vlr_total;
        this.valorBoleto = res.data.vlr_titulo;
        this.paymentDataVencimento = this.formatBrDate(res.data.dt_vencimento);
        this.paymentCnpjCpf = res.data.num_cpf_cnpj.toString().replace(/[^0-9]/g, '');
        this.paymentJuros = res.data.mem_calculo.vlr_juros;
        this.paymentMultas = res.data.mem_calculo.vlr_multa;
        this.paymentNomeBeneficiario = res.data.nome_beneficiario;
        this.paymentHour = formatHour(res.data.dt_vencimento);
        this.paymentBank = res.data.desc_banco;
        this.paymentCodBank = res.data.num_banco;
        this.paymentMora = res.data.mem_calculo.vlr_mora;
        this.mem_calculo.vlr_minimo = res.data.mem_calculo.vlr_minimo;
        this.mem_calculo.vlr_maximo = res.data.mem_calculo.vlr_maximo;
        this.mem_calculo.tipo_calculo = res.data.mem_calculo.tipo_calculo;
        this.mem_calculo.vlr_total = res.data.mem_calculo.vlr_total;
        this.paymentDesconto = res.data.mem_calculo.vlr_desconto;
        this.paymentAbatimento = res.data.mem_calculo.vlr_abatimento;
        const dtVencimento = this.paymentDataVencimento;
        let dtBoleto: any = dtVencimento.split('/');
        dtBoleto = moment(
          dtBoleto[2] + '-' + dtBoleto[1] + '-' + dtBoleto[0] + 'T00:00:00'
        );
        const selectedDate = moment(moment().format('YYYY-MM-DDT00:00:00'));
        const diff = dtBoleto.diff(selectedDate);
        this.showDiaUtilTax = diff < 0;
        this.getBusinessDay
          .execute({ diautil: dtBoleto.format('YYYY-MM-DD') })
          // tslint:disable-next-line:no-shadowed-variable
          .subscribe((res) => {
            this.businessDayDue = res.data.diautil;
            this.loading = false;
            this.flagPostBoleto = false;
          });
      }
    } else {
      this.valuesBoleto = false;
    }
  }

  getBoletoError = (err) => {
    this.typeColorToast = this.REDCOLOR;
    this.showToast(err);
    this.loading = false;
    this.flagPostBoleto = false;
  }

  getBoletoComplete = () => {
    this.loading = false;
    this.flagPostBoleto = false;
  }

  clearAll() {
    this.paymentValue = 0;
    this.flagPostBoleto = false;
    this.errorBarCodeOtico = false;
    this.errorBarCodeDigit = false;
    this.valuesBoleto = false;
    this.paymentDataVencimento = moment().format('DD/MM/YYYY');
    this.paymentCnpjCpf = '';
    this.paymentJuros = 0;
    this.paymentMultas = 0;
    this.paymentNomeBeneficiario = '';
    this.paymentHour = moment().format('HH:mm:ss');
    this.paymentDescription = '';
  }

  clearBarcode() {
    this.paymentBarcode = '';
    this.paymentBarcodeOptical = '';
  }

  validateForm() {
    if (this.valuesBoleto) {
      return this.paymentValue > 0;
    } else {
      return false;
    }
  }

  nextStep() {
      this.paymentStep = 2;
      this.getCompanyDetails();
  }

  getDetails() {
    this.shareAccount.dataShared.subscribe((res) => {
      if (res !== undefined) {
        const data = res.filter((e) => e.selecionado)[0];
        this.paymentContaComChequeEmpresa = data.saldo.vlr_saldo_cheque;
        this.paymentContaSemChequeEmpresa = data.saldo.vlr_saldo;
        this.limiteChequeEmpresa = data.saldo.vlr_limite;
        this.paymentContaCod = data.num_conta;
        this.paymentContaAg = data.cod_agencia;
        this.paymentContaDesc = data.desc_conta;
        this.paymentContaExibicao = data.exibicao;
      }
    });
  }

  getCompanyDetails() {
    this.companyDataShare.dataShared.subscribe((res) => {
      if (res !== undefined) {
        res.companyList.map((e) => {
          if (e.selected) {
            this.companyName = e.name;
            this.companyCnpj = e.modality_account;
          }
        });
      }
    });
  }

  formatDate(date: string) {
    const newDate = date.split('/');
    return newDate[2] + '-' + newDate[1] + '-' + newDate[0];
  }

  pay() {
    // this.loading = true;
    this.apiCallback = 1;
    let myBarcode = '';
    if (this.paymentInput === 'digitavel') {
      myBarcode = this.paymentBarcode;
    } else {
      myBarcode = this.paymentBarcodeOptical;
    }
    const numCodBarras = this.formatBarCode(myBarcode);
    const vlrPagamento =
      this.mem_calculo.tipo_calculo.toUpperCase() === 'QUALQUERVALOR' ||
      this.mem_calculo.tipo_calculo.toUpperCase() === 'ENTREMINIMOEMAXIMO'
        ? this.paymentMinValue === 0 ||
          this.paymentMinValue === undefined ||
          this.paymentMinValue === null
          ? this.mem_calculo.vlr_total
          : this.paymentMinValue
        : this.mem_calculo.tipo_calculo.toUpperCase() === 'SOMENTEVALORMINIMO'
        ? this.mem_calculo.vlr_minimo
        : this.mem_calculo.vlr_total;

    this.params = {
      num_cod_barras: numCodBarras,
      desc_pagamento: this.paymentDescription || '',
      vlr_pagamento: vlrPagamento,
      dt_pagamento: this.formatDate(this.paymentDateFormat),
      num_conta_debito: this.paymentContaCod,
      num_canal_pagamento: 3, // 3 - internet banking //// Mobile - 9
      num_forma_pagamento: 1, // 1 - conta corrente  / 2 - cartao credito
      //desc_pagamento : this.paymentDescription
    };
  }

  postBoletoSuccess = (res) => {
    if (res && res.data && (res.data.sucesso || res.data.desc_etapa_pagamento === 'EnviadoAprovacaoSemSaldo' || res.data.desc_etapa_pagamento === 'EnviadoAprovacaoSemAlcada')) {
      this.desc_etapa_pagamento = res.data.desc_etapa_pagamento === 'AgendadamentoPrevio' ? 'AprovadoSucesso' : res.data.desc_etapa_pagamento;
      this.num_compromisso = res.data.num_compromisso;
      this.boleto_sucesso = res.data.desc_etapa_pagamento === 'AgendadamentoPrevio' ? true : res.data.sucesso;

      if (!this.boleto_sucesso) {
        this.paymentStep = 4;
      } else {
        this.idTransacao = res.data.num_compromisso;
        this.idPagamento = res.data.num_operacao;
        this.paymentStep = 3;
      }

      let actualDay: any = moment().format('YYYY-MM-DD');
      actualDay = moment(actualDay);
      // tslint:disable-next-line:variable-name
      const dtPagamento = moment(this.formatDate(this.paymentDateFormat));

      const diffDay = dtPagamento.diff(actualDay);

      this.isPayment = diffDay === 0;

      this.dt_transacao =  res.data.data_que_foi_agendado;//moment().format('DD/MM/YYYY');
      //this.dt_hora = moment().format('HH:mm');
      
      const approvalReq:ApprovalandRefuseRequest = {
        lista_transacoes:[ res.data.num_compromisso]
      }
      
      this.transactionApproval.postApproval(approvalReq).subscribe((apro) =>{
       
      })

    }

    if (res.data.desc_etapa_pagamento === 'AgendadamentoPrevio') {
      this.num_compromisso = res.data.num_compromisso;
      this.boleto_sucesso = res.data.desc_etapa_pagamento === 'AgendadamentoPrevio' ? true : res.data.sucesso;

      if (!this.boleto_sucesso) {
        this.paymentStep = 4;
      } else {
        this.idTransacao = res.data.num_compromisso;
        this.idPagamento = res.data.num_operacao;
        this.paymentStep = 3;
      }
      this.paymentDateFormat = res.data.data_agendamento
      this.dt_transacao = res.data.data_que_foi_agendado;
      this.isPayment = false;
      // const approvalReq:ApprovalandRefuseRequest = {
      //   lista_transacoes:[ res.data.num_compromisso]
      // }
      
      // this.transactionApproval.postApproval(approvalReq).subscribe((apro) =>{       
      // })
    } else if (res.data.desc_etapa_pagamento === 'ErroPagamento') {
      sessionStorage.setItem('erroAprovacao', 'Transação em processamento. Entre em contato com a nossa área de atendimento.');
      this.router.navigate(['transaction-approval']);
    }
  }

  postBoletoPending() {
    this.shareAccount.dataShared.subscribe((res) => {
      const data = res.filter((e) => e.selecionado)[0];
      this.paymentCheckBalance = data.saldo.vlr_saldo;

      if (this.paymentCheckBalance < 0) {
        this.paymentStep = 4;
      } else {
        this.paymentStep = 3;
      }
    });
  }

  postBoletoError = (err) => {
    this.loading = false;
    this.showToast(err);
  }

  postBoletoComplete = () => {
    this.loading = false;
    this.clearInputs();
  }

  back() {
    this.paymentStep = 1;
  }
  newPayment() {
    this.clearAll();
    this.clearBarcode();
    this.date = this.calendar.getToday();
    const day = Number(moment().format('DD'));
    const month = Number(moment().format('MM'));
    const year = Number(moment().format('YYYY'));

    const date: NgbDate = new NgbDate(year, month, day);
    this.paymentDate = date;
    this.back();
  }
  navigateBack() {
    this.clearInputs();
    this.clearAll();
    this.clearBarcode();
    this.back();
    // this.router.navigateByUrl('/dashboard');
  }
  changeDate(value) {
    this.paymentDate = value;
    this.getDayBusiness();
  }

  getDayBusiness(limite?) {
    if(limite){
      this.paymentDate.day = this.paymentDate.day + 1
    }
    const date: NgbDate = this.paymentDate;
    const params = {
      diautil: date.year + '-' + date.month + '-' + date.day,
    };
    this.getBusinessDay
      .execute(params)
      .subscribe(this.daySuccessRequest, this.addError);

    this.selectedDate = {
      year: date.year,
      month: date.month,
      day: date.day,
    };
    this.paymentDateFormat =
      (date.day < 10 ? '0' + date.day : date.day) +
      '/' +
      (date.month < 10 ? '0' + date.month : date.month) +
      '/' +
      date.year;

    const getDate = moment(
      date.year +
        '-' +
        (date.month < 10 ? '0' + date.month : date.month) +
        '-' +
        (date.day < 10 ? '0' + date.day : date.day) +
        'T00:00:00'
    );

    // tslint:disable-next-line:variable-name
    const dtVencimento = this.paymentDataVencimento;
    let dtBoleto: any = dtVencimento.split('/');
    dtBoleto = moment(
      dtBoleto[2] + '-' + dtBoleto[1] + '-' + dtBoleto[0] + 'T00:00:00'
    );

    const diff = getDate.diff(dtBoleto);

    if (diff > 0) {
      this.showDiaUtilTax = true;
    }
  }

  daySuccessRequest = (value: BeneficiariesModel) => {
    const aux: string = value.data.diautil.split('T');
    const date = aux[0].split('-');
    this.date = {
      year: parseInt(date[0]),
      month: parseInt(date[1]),
      day: parseInt(date[2]),
    };
    this.businessDayPayment =
      this.date.year +
      '-' +
      (this.date.month < 10 ? '0' + this.date.month : this.date.month) +
      '-' +
      (this.date.day < 10 ? '0' + this.date.day : this.date.day) +
      'T00:00:00';

    this.paymentDateFormat =
      (this.date.day < 10 ? '0' + this.date.day : this.date.day) +
      '/' +
      (this.date.month < 10 ? '0' + this.date.month : this.date.month) +
      '/' +
      this.date.year;

    this.showDiaUtil = !(
      this.selectedDate.year === this.date.year &&
      this.selectedDate.month === this.date.month &&
      this.selectedDate.day === this.date.day
    );

    if (
      moment(moment().format('YYYY-MM-DDT00:00:00')) !==
      moment(value.data.diautil)
    ) {
      this.getBoletoEncargos(value.data.diautil);
    }
  }

  private getBoletoEncargos = (date) => {
    this.loading = true;
    let myBarcode = '';

    if (this.paymentInput === 'digitavel') {
      myBarcode = this.paymentBarcode;
    } else {
      myBarcode = this.paymentBarcodeOptical;
    }

    if (myBarcode) {
      const codigoBoleto = this.formatBarCode(myBarcode);
      const params: PaymentBoletoRequest = {
        codigoBoleto,
        dt_pagamento: date.split('T')[0],
      };
      this.getBoleto
        .execute(params)
        .subscribe(
          this.getBoletoEncargosSuccess,
          this.getBoletoEncargosError,
          this.getBoletoEncargosComplete
        );
    }
  }

  private getBoletoEncargosSuccess = (res) => {
    if (res && res.status && res.status !== '') {
      this.valuesBoleto = res.status === 'success';

      if (res && res.data) {
        this.paymentValue = res.data.mem_calculo.vlr_total;
        this.valorBoleto = res.data.vlr_titulo;
        this.paymentDataVencimento = this.formatBrDate(res.data.dt_vencimento);
        this.paymentCnpjCpf = res.data.num_cpf_cnpj.toString().replace(/[^0-9]/g, '');
        this.paymentJuros = res.data.mem_calculo.vlr_juros;
        this.paymentMultas = res.data.mem_calculo.vlr_multa;
        this.paymentNomeBeneficiario = res.data.nome_beneficiario;
        this.paymentHour = formatHour(res.data.dt_vencimento);
        this.paymentBank = res.data.desc_banco;
        this.paymentCodBank = res.data.num_banco;
        this.paymentMora = res.data.mem_calculo.vlr_mora;
        this.mem_calculo.vlr_minimo = res.data.mem_calculo.vlr_minimo;
        this.mem_calculo.vlr_maximo = res.data.mem_calculo.vlr_maximo;
        this.mem_calculo.tipo_calculo = res.data.mem_calculo.tipo_calculo;
        this.mem_calculo.vlr_total = res.data.mem_calculo.vlr_total;
        this.paymentDesconto = res.data.mem_calculo.vlr_desconto;
        this.paymentAbatimento = res.data.mem_calculo.vlr_abatimento;
        const dtVencimento = this.paymentDataVencimento;
        let dtBoleto: any = dtVencimento.split('/');
        dtBoleto = moment(
          dtBoleto[2] + '-' + dtBoleto[1] + '-' + dtBoleto[0] + 'T00:00:00'
        );
        const diff = dtBoleto.diff(moment(res.data.mem_calculo.dt_validade));
        this.showDiaUtilTax = diff < 0;
        this.getBusinessDay
          .execute({ diautil: dtBoleto.format('YYYY-MM-DD') })
          // tslint:disable-next-line:no-shadowed-variable
          .subscribe((res) => {
            this.businessDayDue = res.data.diautil;
          });
      }
    } else {
      this.valuesBoleto = false;
    }
  }

  private getBoletoEncargosError = (err) => {
    this.loading = false;
    this.showToast(err);
    this.flagPostBoleto = false;
  }

  private getBoletoEncargosComplete = () => {
    this.loading = false;
    this.flagPostBoleto = false;
  }

  private addError = (err) => {
    // console.log(err);
  }

  public generatePDF() {
    this.getPdf
    .execute(this.idTransacao)
    .subscribe(this.successPdf, this.errorPdf);
    
  }

  private successPdf = (value) => {
    fetch(value.data.url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "ComprovantePagamento.pdf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    })
  };

  private errorPdf = (err) => {
    this.toast.callModalMessage(null, "Falha no download do comprovante tente novamente");
  };

  onClose() {
    this.showDiaUtil = false;
    this.showDiaUtilTax = false;
    this.showStatusHourPaymentClosed=false
  }

  clearInputs = () => {
    this.inputKey = '';
    this.paymentDescription = '';
  }

  addMultiplePaymentOld() {
    this.clearInputs();

    this.loading = true;
    // tslint:disable-next-line:variable-name
    let dtPagamento: any = this.paymentDateFormat.split('/');
    dtPagamento = dtPagamento[2] + '-' + dtPagamento[1] + '-' + dtPagamento[0];

    let myBarcode: string;

    if (this.paymentInput === 'digitavel') {
      myBarcode = this.paymentBarcode;
    } else {
      myBarcode = this.paymentBarcodeOptical;
    }
    const numCodBarras = myBarcode.replace(/[^0-9]/g, '');

    const vlrPagamento =
      this.mem_calculo.tipo_calculo.toUpperCase() === 'QUALQUERVALOR' ||
      this.mem_calculo.tipo_calculo.toUpperCase() === 'ENTREMINIMOEMAXIMO'
        ? this.paymentMinValue === 0 ||
          this.paymentMinValue === undefined ||
          this.paymentMinValue === null
          ? this.mem_calculo.vlr_total
          : this.paymentMinValue
        : this.mem_calculo.tipo_calculo.toUpperCase() === 'SOMENTEVALORMINIMO'
        ? this.mem_calculo.vlr_minimo
        : this.mem_calculo.vlr_total;

    const params = {
      num_cod_barras: numCodBarras,
      desc_pagamento: this.paymentDescription || '',
      vlr_pagamento: vlrPagamento,
      dt_pagamento: dtPagamento,
      num_conta_debito: this.paymentContaCod,
      num_canal_pagamento: 3, // 3 - internet banking //// Mobile - 9
      num_forma_pagamento: 1, // 1 - conta corrente  / 2 - cartao credito
    };
    this.multipleParams = params;
    this.saveBoletos
      .execute(params)
      .subscribe(
        this.saveBoletosSuccess,
        this.saveBoletosError,
        this.saveBoletosComplete
      );
  }

  addMultiplePayment() {
    let myBarcode: string;
    //this.codBarrasRepetido = false;

    if (this.paymentInput === 'digitavel') {
      myBarcode = this.paymentBarcode;
    } else {
      myBarcode = this.paymentBarcodeOptical;
    }

    const numCodBarras = myBarcode.replace(/[^0-9]/g, '');

      this.loading = true;
      let descricaoPagamento = this.paymentDescription;
      this.clearInputs();
  
      this.apiCallback = 2;
  
      this.loading = true;
      // tslint:disable-next-line:variable-name
      let dtPagamento: any = this.paymentDateFormat.split('/');
      dtPagamento = dtPagamento[2] + '-' + dtPagamento[1] + '-' + dtPagamento[0];
  
  
      const vlrPagamento =
        this.mem_calculo.tipo_calculo.toUpperCase() === 'QUALQUERVALOR' ||
        this.mem_calculo.tipo_calculo.toUpperCase() === 'ENTREMINIMOEMAXIMO'
          ? this.paymentMinValue === 0 ||
            this.paymentMinValue === undefined ||
            this.paymentMinValue === null
            ? this.mem_calculo.vlr_total
            : this.paymentMinValue
          : this.mem_calculo.tipo_calculo.toUpperCase() === 'SOMENTEVALORMINIMO'
          ? this.mem_calculo.vlr_minimo
          : this.mem_calculo.vlr_total;
  
      this.params = {
        num_cod_barras: numCodBarras,
        desc_pagamento: descricaoPagamento || '',
        vlr_pagamento: vlrPagamento,
        dt_pagamento: dtPagamento,
        num_conta_debito: this.paymentContaCod,
        num_canal_pagamento: 3, // 3 - internet banking //// Mobile - 9
        num_forma_pagamento: 1, // 1 - conta corrente  / 2 - cartao credito
      };
      this.multipleParams = this.params;
  
      this.saveBoletos
        .execute(this.multipleParams)
        .subscribe(
          this.saveBoletosSuccess,
          this.saveBoletosError,
          this.saveBoletosComplete
          
        );
  }

  saveBoletosSuccess = (res) => {
    let actualDay: any = moment().format('YYYY-MM-DD');
    actualDay = moment(actualDay);
    const dtPagamento = moment(this.formatDate(this.paymentDateFormat));
    this.isPayment = dtPagamento.diff(actualDay) === 0;
    if (res.data && res.data.sucesso) {

      
      this.multipleParams.num_operacao = res.data.num_operacao;
      this.multipleParams.nome_beneficiario = this.paymentNomeBeneficiario;
      this.multipleParams.dt_vencimento = this.paymentDataVencimento;
      this.multipleParams.num_cpf_cnpj = this.paymentCnpjCpf;
      this.multipleParams.vlr_titulo = this.paymentValue;
      this.multipleParams.desc_transacao = this.paymentDescription || '';
      this.multipleParams.vlr_juros = this.paymentJuros;
      this.multipleParams.vlr_multa = this.paymentMultas;
      this.multipleParams.vlr_mora = this.paymentMora;
      this.multipleParams.desc_banco = this.paymentBank;
      this.multipleParams.num_banco = this.paymentCodBank;
      this.multipleParams.payment_method = this.paymentMethod;
      this.multipleParams.channel = this.channel;
      this.multipleParams.dt_agendada = this.paymentDateFormat;
      this.multipleParams.dt_transacao = this.dt_transacao; // call after
      this.multipleParams.hr_transacao = this.dt_hora; // call after
      this.multipleParams.debitCompany = this.companyName;
      this.multipleParams.debitDocument = this.companyCnpj;
      this.multipleParams.debitAccountName = this.paymentContaExibicao;
      this.multipleParams.debitAccountCod = this.paymentContaCod;
      this.multipleParams.debitAgency = this.paymentContaAg;
      this.multipleParams.compromiseNumber = this.num_compromisso; // call after
      this.multipleParams.paymentBarcode =
        this.paymentBarcode !== ''
          ? this.paymentBarcode
          : this.paymentBarcodeOptical;
      this.multipleParams.isPayment = this.isPayment;
      this.multipleParams.showTooltip = false;
      this.multipleParams.mem_calculo = this.mem_calculo;
      // tslint:disable-next-line:max-line-length
      this.multipleParams.vlr_pagamento =
        this.mem_calculo.tipo_calculo.toUpperCase() === 'QUALQUERVALOR' ||
        this.mem_calculo.tipo_calculo.toUpperCase() === 'ENTREMINIMOEMAXIMO'
          ? this.paymentMinValue === 0 ||
            this.paymentMinValue === undefined ||
            this.paymentMinValue === null
            ? this.mem_calculo.vlr_total
            : this.paymentMinValue
          : this.mem_calculo.tipo_calculo.toUpperCase() === 'SOMENTEVALORMINIMO'
          ? this.mem_calculo.vlr_minimo
          : this.mem_calculo.vlr_total;
          this.multipleParams.vlr_desconto = this.paymentDesconto;
          this.multipleParams.vlr_abatimento = this.paymentAbatimento;

      this.paymentMultiple.push(this.multipleParams);
      this.paymentDataShare.setValue({
        paymentMultiple: this.paymentMultiple,
      });
      this.clearAll();
      if (this.navigateToMultiple) {
        this.navigateToMultiple = false;
        this.router.navigate(['/payment-multiple']);
      } else {
        this.date = this.calendar.getToday();
        const day = Number(moment().format('DD'));
        const month = Number(moment().format('MM'));
        const year = Number(moment().format('YYYY'));

        const date: NgbDate = new NgbDate(year, month, day);
        this.paymentDate = date;
        this.paymentStep = 1;
      }
      
    }
    this.paymentDateFormat='';
    this.loading = false;
  }

  postBoletosError = (err) => {
    this.loading = false;

    if (err.status == 500 || err.status == 504) {
      const msg = err.status == 500 ? 'Serviço indisponível. Atenção! Antes de realizar novamente o pagamento, confira se ele não foi concluído.' : 'Transação em processamento. Entre em contato com a nossa área de atendimento.';
      sessionStorage.setItem('erroAprovacao', msg);
      this.router.navigate(['transaction-approval']);
    } else {
      this.showToast(err);
    }
  }

  saveBoletosError = (err) => {
    this.loading = false;
    this.showToast(err);
  }

  saveBoletosComplete = () => {
    this.loading = false;
  }

  formatBrDate = (date) => {
    let newDate = date.split('T');
    newDate = newDate[0].split('-');
    return newDate[2] + '/' + newDate[1] + '/' + newDate[0];
  }

  private showToast = (err) => {
    if (err && err.message && err.message.length > 0) {
      let message: string;
      switch (err.message[0]) {
        case 'Você já possui um Pagamento Realizado para esse boleto.':
          message =
            'Título já liquidado/pago. Não é possível realizar o pagamento.';
          break;
        case 'Documento já cadastrado para pagamento.':
          message = err.message[0];
          break;
        case 'Pagamento já efetuado.':
          message = err.message[0];
          break;
        case 'Boleto Baixado':
          message = 'Título baixado. Não é possível realizar o pagamento.';
          break;
        case 'Boleto Cancelado': // TODO: validate message for cancelled
          message = 'Título cancelado. Não é possível realizar o pagamento.';
          break;
        case 'Pagamento de boleto indisponível. Tente novamente mais tarde.': // TODO: validate message for cancelled
          message = 'Pagamento de boleto indisponível. Tente novamente mais tarde.';
          break;  
        default:
          err.message[0] === 'H' ? message = err.error.message[0] : message = err.message[0];
        break;
      }
      this.toast.callModalMessage(null, message);
    } else {
      if(this.codBarrasRepetido){
        this.toast.callModalMessage(null, 'Documento já cadastrado para pagamento.');
      }else{
        this.toast.callModalMessage(null, this.toasterTitle, this.toasterMessage);
      }
    }
  }

  public boletoMask(rawValue?: any) {
    return boletoMask(rawValue);
  }

  private formatBarCode = (code) => {
    return code.replace(/[^0-9]/g, '');
  }

  showEncargosError = () => {
    const payment = moment(this.businessDayPayment);
    const due = moment(this.businessDayDue);

    return payment.diff(due) !== 0;
  }

  validateBarcodeBlock = (value?: string) => {
    /**
     * em caso de pagamentos multiplos 
     * verifica se o boleto já foi inserido
    */
    if(this.checkIfRegistred(value)){
      this.toast.callModalMessage(null, 'Documento já cadastrado para pagamento.');
      return
    };
    
    let myBarcode: string;
    this.codBarrasRepetido = false;
    this.clearAll();
    if (value !== null && value !== undefined && value !== '') {
      const barcode = this.formatBarCode(value);

      const code = String(barcode);
      if (code.slice(0, 1) === '8') {
        // TODO: Error para arrecadação
        this.clearAll();

        if (this.paymentInput === 'digitavel') {
          this.errorBarCodeDigit = true;
        } else {
          this.errorBarCodeOtico = true;
        }
      } else if (code.length === 44) {
        const validateBarCode = this.formatLineToBarcode(code);
        if (!validateBarCode.isValid) {
          this.validateAll(this.addZeros(code));
          //this.errorBarCodeOtico = true;
        } else {
          this.clearAll();
          this.validaCodBarrasRepetido(code);
          if(this.codBarrasRepetido){
            this.showToast('');
          }else{
            this.callGetBoleto(code);
          }
        }
      }
      else if(code.length == 47){
        this.validateAll(code);
      }
      else if (code.length >= 33 && code.length <= 47) {
        //this.validateAll(this.addZeros(code));
        this.errorBarCodeOtico = true;
      } else {
        this.clearAll();

        if (this.paymentInput === 'digitavel') {
          this.errorBarCodeDigit = true;
        } else {
          this.errorBarCodeOtico = true;
        }
      }
    }

    if (this.paymentInput === 'digitavel') {
      myBarcode = this.paymentBarcode;
    } else {
      myBarcode = this.paymentBarcodeOptical;
    }


  }

  validaCodBarrasRepetido(codBarras){
    this.codBarrasRepetido = false;
    if(this.paymentMultiple && this.paymentMultiple.length > 0){
      this.paymentMultiple.forEach(element => {
        if(element.paymentBarcode == codBarras.barcode){
          this.codBarrasRepetido = true;
        }
      });
    }
  }

  checkIfRegistred(value): boolean{
    let formatCode = value.replace(/[^0-9]/g, '');
    let isEqualBarcode: boolean = false;

    if(this.paymentMultiple.length > 0){
      isEqualBarcode = this.paymentMultiple.some(e => e.paymentBarcode === formatCode);
    }
    return isEqualBarcode;
  }

  addZeros = (code) => {
    let barcode = code;
    for (let i = code.length + 1; i <= 47; i++) {
      barcode += '0';
    }
    return barcode;
  }

  validateAll = (code) => {
    const barCodeFormat = this.formatLineToBarcode(code);
    this.validatedBarcode.firstBlock =
      Number(code.substr(9, 1)) === this.calcModule10(code.substr(0, 9));
    this.validatedBarcode.secondBlock =
      Number(code.substr(20, 1)) === this.calcModule10(code.substr(10, 10));
    this.validatedBarcode.thirdBlock =
      Number(code.substr(31, 1)) === this.calcModule10(code.substr(21, 10));
    this.validatedBarcode.fourthBlock = barCodeFormat.isValid;
    this.validatedBarcode.barcode = barCodeFormat.barcode;
    this.validaCodBarrasRepetido(barCodeFormat);
    if(this.codBarrasRepetido){
      this.showToast('');
    }else{
      if (
        this.validatedBarcode.firstBlock &&
        this.validatedBarcode.secondBlock &&
        this.validatedBarcode.thirdBlock &&
        this.validatedBarcode.fourthBlock
      ) {
        this.clearAll();
        this.callGetBoleto(this.validatedBarcode.barcode);
      } else {
        this.clearAll();
  
        if (this.paymentInput === 'digitavel') {
          this.errorBarCodeDigit = true;
        } else {
          this.errorBarCodeOtico = true;
        }
      }
    }
  }

  callGetBoleto = (codigoBoleto) => {
    if (!this.flagPostBoleto) {
      if (this.paymentInput === 'digitavel') {
        this.paymentBarcode = codigoBoleto;
      } else {
        this.paymentBarcodeOptical = codigoBoleto;
      }
      this.loading = true;
      this.flagPostBoleto = true;
      const params = {
        codigoBoleto,
        dt_pagamento: moment().format('YYYY-MM-DD'),
      };
      this.getBoleto
        .execute(params)
        .subscribe(this.getBoletoSuccess, this.getBoletoError);
    }
  }

  calcModule10 = (value) => {
    let mod10 = 0;
    let resto = 0;
    let j = 0;
    for (let i = value.length - 1; i >= 0; i = i - 1) {
      if (j % 2 === 0) {
        if (Number(value[i]) * 2 > 9) {
          mod10 +=
            Number(Number(value[i] * 2).toString()[0]) +
            Number(Number(value[i] * 2).toString()[1]);
        } else {
          mod10 += Number(value[i]) * 2;
        }
      } else {
        mod10 += Number(value[i]);
      }
      j++;
    }
    resto = mod10 % 10;
    return resto === 0 ? 0 : 10 - resto;
  }

  calcModule11 = (value) => {
    let mod11 = 0;
    let resto = 0;
    let multiplicador = 2;
    for (let i = value.length - 1; i >= 0; i = i - 1) {
      if (multiplicador === 10) {
        mod11 += Number(value[i]) * 2;
        multiplicador = 2 + 1;
      } else {
        mod11 += Number(value[i]) * multiplicador;
        multiplicador = multiplicador + 1;
      }
    }
    resto = mod11 % 11;
    return resto === 0 || resto === 1 ? 0 : resto === 10 ? 1 : 11 - resto;
  }

  calcModule11Barcode = (value) => {
    let mod11 = 0;
    let resto = 0;
    let multiplicador = 2;
    for (let i = value.length - 1; i >= 0; i = i - 1) {
      if (multiplicador === 10) {
        mod11 += Number(value[i]) * 2;
        multiplicador = 2 + 1;
      } else {
        mod11 += Number(value[i]) * multiplicador;
        multiplicador = multiplicador + 1;
      }
    }
    resto = mod11 % 11;
    return String(resto === 0 || resto === 10 || resto === 1 ? 1 : 11 - resto);
  }

  formatLineToBarcode = (line) => {
    const calc = -1;
    let barcode = '';
    let barcodeCalc = '';
    let dv: any;
    let lineDv: any;

    if (line.length === 47) {
      barcode += line.substr(1 + calc, 4);
      barcode += line.substr(33 + calc, 1);
      barcode += line.substr(34 + calc, 14);
      barcode += line.substr(5 + calc, 5);
      barcode += line.substr(11 + calc, 10);
      barcode += line.substr(22 + calc, 10);

      barcodeCalc += line.substr(1 + calc, 4);
      barcodeCalc += line.substr(34 + calc, 14);
      barcodeCalc += line.substr(5 + calc, 5);
      barcodeCalc += line.substr(11 + calc, 10);
      barcodeCalc += line.substr(22 + calc, 10);

      lineDv = line.substr(33 + calc, 1);
    } else {
      barcode = line;
      barcodeCalc = line.substr(1 + calc, 4) + line.substr(6 + calc, 40);
      lineDv = line.substr(5 + calc, 1);
    }

    dv = this.calcModule11Barcode(barcodeCalc);
    return {
      barcode,
      isValid: dv === lineDv,
    };
  }
  removeSelector(): void {
    $('#ItemHome').removeClass('menu-active');
    $('#ItemExtrato').removeClass('menu-active');
    $('#ItemCobranca').removeClass('menu-active');
    $('#ItemTransferencia').removeClass('menu-active');
    $('#ItemProducts').removeClass('menu-active');
    $('#ItemMais').removeClass('menu-active');
    $('#ItemInvestimento').removeClass('menu-active');
    $('#ItemLoans').removeClass('menu-active');
  }

  formatHour(hr){
    let combineHour = hr;
    combineHour = `${combineHour.split(':')[0]}:${combineHour.split(':')[1]}`;
    return combineHour;
  }
}
