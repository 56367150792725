import { TabListDataShare } from '@src/core/usecases/data-share/tab-list-data-share.service';
import { Component, EventEmitter, OnInit, OnDestroy, Output, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { PostExportExcelUsecase } from '@src/core/usecases/transfer-approval/post-export-approval-usecase';
import { FilterComponent } from '@src/presentation/web/components/filter/filter.component';
import { TransactionDetailRequest } from '@src/core/domain/transaction/transaction.model';
import { GetTransactionDetailUsecase } from '@src/core/usecases/transaction/get-transaction-detail-usecases';
import { GetReceiptPdfUsecase } from '@src/core/usecases/receipt/get-receipt-pdf-usecases';
import { ApprovalandRefuseRequest, ApprovalList, ApprovalRequest, TransferApprovalModel } from '@src/core/domain/transfer-approval/approval.model';
import * as Util from '@src/shared/util-common';
import { GetApprovalListUsecase } from '@src/core/usecases/transfer-approval/get-approval-list-usecases';
import { TransactionsDataShare } from '@src/core/usecases/data-share/transactions-data-share.service';
import { TabsDataShare } from '@src/data/repository/data-share-repository';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { isArray } from 'rxjs/internal-compatibility';
import * as $ from 'jquery';

@Component({
  selector: 'fibra-transaction-approval',
  templateUrl: './transaction-approval.component.html',
  styleUrls: ['./transaction-approval.component.scss'],
})
export class TransactionApprovalComponent implements OnInit, OnDestroy {
  constructor(
    private getApprovalList: GetApprovalListUsecase,
    private getDetail: GetTransactionDetailUsecase,
    private transactionsDataShare: TransactionsDataShare,
    private tabsDataShared: TabsDataShare,
    private postExportExcel: PostExportExcelUsecase,
    private getPdf: GetReceiptPdfUsecase,
    private _router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private setTab: TabListDataShare
  ) { }
  public data;
  public filter = new FormControl('');
  public loading: boolean;
  public hide: boolean;
  public shown = [];
  public shownAll: boolean;
  public dataAll: ApprovalList[];
  public actionConfirmed: boolean;
  public showConfirmation: boolean;
  public showConfirmationRadio: boolean;
  public checkedArrayIsNotEmpty: boolean;
  public checkedArrayIsExpiredTicket: boolean;
  public oneOrMorePendingChecked: boolean;
  public oneOrMorePendingExpiredChecked: boolean;
  public isPoolPenddingselected: boolean;
  public isExpiredTicketSelected: boolean;
  public showRefusedOrApproveAction: boolean;
  public showIndividualConfirmation: boolean;
  public showIndividualRecusa: boolean;
  public approvalList = [];
  public checkedList = [];
  public action: string;
  public total;
  public params;
  public type;
  public count = 0;
  public dataDetail;
  public loadingDetail: boolean;
  public util = Util;
  public selectedTab: string;
  public status: string;
  public options = [];
  public actionAproveOrRefuse = false;
  public channelEnum;
  public objRequest: ApprovalandRefuseRequest;
  public isSchedules;
  public typToastColor;
  public indexList: boolean;
  public isPendent: boolean;
  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';
  public AMBARCOLOR = '#D69D10';
  public paramsExport;
  public showCheckbox: boolean;
  public selected = [];
  public initAllFlag = true;
  public varSubscriptTabs: Subscription;
  public varSubscriptTransaction: Subscription;
  public vencidoJuros;
  public vencidoMultas;
  public vencidoMora;
  public totalEncargos;
  public dataDetailEncargos;
  public transacaoSelecionada;
  public loadingEncargos: boolean = true;
  private _subRequestSearch: Subscription;
  public approvalTransaction = true
  verifySchedule = [];
  public localStorageButtonTransacao: any;
  public dateInitialWhenPixSchedulling: string;
  public dateFinalWhenPixSchedulling: any;
  public verifyIsSchedule = false;

  @Output() emitTransferData: EventEmitter<any> = new EventEmitter();

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;
  @ViewChild(FilterComponent, null) filterComp: FilterComponent;

  public generatePDF = (value?) => Util.generatePDF(value);
  public removeMask = (value?) => Util.removeMask(value);
  public userEmail;

  ngOnInit(): void {
    this.init();
    this.selectedTab = 'Tudo';
    this.userEmail = localStorage.getItem('useremail');
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    sessionStorage.removeItem('tab');
    localStorage.removeItem('labelButtonAcompanharAgendamento');
    
    if (this.varSubscriptTabs) {
      this.varSubscriptTabs.unsubscribe();
    }
    
    if (this.varSubscriptTransaction) {
      this.varSubscriptTransaction.unsubscribe();
    }
    
    if (this._subRequestSearch) {
      this._subRequestSearch.unsubscribe();
    }    
  }

  private init = () => {
    this.typToastColor = this.REDCOLOR;
    this.options = [
      { idOption: 'Tudo', option: 'Tudo' },
      { idOption: 'PENDENTE', option: 'Pendentes' },
      { idOption: 'LIQUIDADO', option: 'Liquidados' },
      { idOption: 'RECUSADO', option: 'Recusados' },
      { idOption: 'AGENDADO', option: 'Agendados' },
      { idOption: 'VENCIDO', option: 'Vencidos' },
    ];

    var erroAprovacao = sessionStorage.getItem('erroAprovacao');

    if (erroAprovacao) {
      this.toast.callModalMessage(null, erroAprovacao, '', null, true);
      sessionStorage.removeItem('erroAprovacao');
    }
  };

  ngAfterViewInit() {
    this.selectedTabSchedule();
      this.varSubscriptTabs = this.tabsDataShared.dataShared.subscribe((res) => {
        if (res && res.typeList) {
          this.resetList();
            this.verifyIsSchedule ? this.selectedTab = 'AGENDADO' : this.selectedTab = (res && res.typeList);
            this.setFilterBehavior();
        }
      });
    this.setTab.setValue(undefined);
    }

    public setFilterBehavior() {
        if (this.selectedTab === 'AGENDADO' || this.selectedTab === 'PENDENTE') {
            this.isSchedules = true;
            this.filterComp.initSchedules();
            this.approvalTransaction = true;
            return;
        }
        if (this.selectedTab === 'RECUSADO' || this.selectedTab === 'Tudo') {
            this.isSchedules = false;
            this.filterComp.initAll();
            this.approvalTransaction = true;
        }
        if (this.selectedTab !== 'AGENDADO' &&
            this.selectedTab !== 'PENDENTE' &&
            this.selectedTab !== 'RECUSADO') {
            this.isSchedules = false;
            this.filterComp.initAll();
            this.approvalTransaction = false;
        }
    }

  private requestDetail(id) {
    this.loadingDetail = true;
    const params: TransactionDetailRequest = {
      idTransacao: id,
    };
    this.getDetail
      .execute(params)
      .subscribe(this.successDetail, this.errorDetail);
  }

  private successDetail = (value) => {
    this.dataDetail = value.data;
    this.loadingDetail = false;
    localStorage.setItem('approval-receipt', JSON.stringify(this.dataDetail));
  };

  private errorDetail = (err) => {
    this.loadingDetail = false;
  };

  private requestDetailEncargos(id) {
    this.loadingEncargos = true;
    const params: TransactionDetailRequest = {
      idTransacao: id,
    };
    this.getDetail
      .execute(params)
      .subscribe(this.successDetailEncargos, this.errorDetailEncargos);
  }

  private successDetailEncargos = (value) => {
    this.loadingEncargos = false;
    this.dataDetailEncargos = value.data;
    if(this.dataDetailEncargos && this.dataDetailEncargos.info_encargo){
      this.vencidoJuros = +this.dataDetailEncargos.info_encargo.vlr_juros;
      this.vencidoMora = +this.dataDetailEncargos.info_encargo.vlr_mora;
      this.vencidoMultas = +this.dataDetailEncargos.info_encargo.vlr_multa;
      this.totalEncargos = +this.dataDetailEncargos.vlr_pago_transacao
  
    }
  };

  private errorDetailEncargos = (err) => {
    this.loadingEncargos = false;
  };
  private resolveStatus(status?) {
    switch (status) {
      case 'AGENDADO':
        return 1;
      case 'PENDENTE':
        return 2;
      case 'LIQUIDADO':
        return 5;
      case 'RECUSADO':
        return 6;
      case 'VENCIDO':
        return 7;

      default:
        return '';
    }
  }

  public requestSearch(filter?, type?, tabs?) {
    startWith('');
    this.loading = true;
    this.resetList();
    this.resetAction();
    this.hide = false;
    const params: ApprovalRequest = {
      dt_inicial: this.params.dt_Inicio,
      dt_final: this.params.dt_Final,
      num_conta: filter.account === 'Todas' ? '' : filter.account,
      status: tabs === 'Tudo' ? '' : tabs,
      tipo_transacao: type === 'Todas' ? '' : type,
      num_cpf_cnpj: '',
      cod_empresa: 0,
      funcionalidade: 'APROV_TRANS'
    };
    this.paramsExport = params;

    if (this._subRequestSearch) {
      this._subRequestSearch.unsubscribe();
    }

    this._subRequestSearch =  this.getApprovalList
      .execute(params)
      .subscribe(
        this.requestSuccess,
        this.requestError,
        () => {
          this.loading = false;
          this.dateInitialWhenPixSchedulling = '';
          this.dateFinalWhenPixSchedulling = '';
        }
      );
  }

  private verifySuccess(value) {
    const data = value.data;
    if (value.data === '') {
      this.data = [];
    } else {
      this.data = Util.verifyArray(data);

      //this.data.sort((a, b) =>
      //  a.dt_transacao > b.dt_transacao
      //    ? -1
      //   : b.dt_transacao > a.dt_transacao
      //      ? 1
      //      : 0
      //);
    }
  }

  private requestSuccess = (value: TransferApprovalModel) => {
    this.verifySuccess(value);
    this.dataAll = this.data;
    localStorage.setItem(
      'approval-export-data',
      JSON.stringify({
        data: this.data,
        type: this.type,
        date: this.params,
        tabs: this.selectedTab,
      })
    );
    localStorage.removeItem('labelButtonAcompanharAgendamento');
    this.resetIsSchedules();
    this.hide = Util.isEmpty(this.data);
    this.checkStatus(this.data);
    this.loading = false;
  };

  private requestError = (err) => {
    localStorage.setItem(
      'approval-export-data',
      JSON.stringify({
        data: this.data,
        type: this.type,
        date: this.params,
        tabs: this.selectedTab,
      })
    );
    
    this.loading = false;
    this.data = [];
    this.hide = Util.isEmpty(this.data);
  };
 
  private verifyChecked(data, checked) {
    let aux = [];
    if (this.approvalList.some((e) => e.desc_status)) {
      aux = this.approvalList.filter((e) => e.desc_status);
      for (const element of aux) {
        if (element.desc_status === 'VENCIDO') {
          if (aux.length > 1) {
            this.oneOrMorePendingExpiredChecked = true;
            this.isExpiredTicketSelected = false;
            this.showRefusedOrApproveAction = false;
            this.showConfirmationRadio = true;
          } else {
            this.isExpiredTicketSelected = true;
            this.showRefusedOrApproveAction = true;
            this.isPoolPenddingselected = false;
            this.showConfirmationRadio = true;
            this.requestDetailEncargos(element.cod_transacao);
            return;
          }
        }
      }
    }

    if (data.desc_status === 'PENDENTE' && checked) {
      this.isPoolPenddingselected = true;
      this.showConfirmationRadio = true;
      this.oneOrMorePendingExpiredChecked = this.approvalList.map(x => x.desc_status == 'VENCIDO').includes(true);
    }

    if (data.desc_status === 'AGENDADO' && checked) {
      this.isPoolPenddingselected = true;
      this.showConfirmationRadio = true;
      this.oneOrMorePendingExpiredChecked = this.approvalList.map(x => x.desc_status == 'VENCIDO').includes(true);
    } else {
      this.isExpiredTicketSelected = false;
      this.isPoolPenddingselected = true;

      this.showConfirmationRadio = this.showRefusedOrApproveAction ? false : true;
      this.oneOrMorePendingExpiredChecked = this.approvalList.map(x => x.desc_status == 'VENCIDO').includes(true);
    }
  }

  private totalValue() {
    this.total = 0;

    for (const item of this.approvalList) {

      const element = item.vlr_transacao;
      this.total += element;

    }
  }

  public resetAction() {
    this.checkedArrayIsNotEmpty = false;
    this.checkedArrayIsExpiredTicket = false;
    this.oneOrMorePendingChecked = false;
    this.isPoolPenddingselected = false;
    this.showConfirmationRadio = false;
    this.isExpiredTicketSelected = false;
    this.showRefusedOrApproveAction = false;
    this.oneOrMorePendingExpiredChecked = false;
    this.showConfirmation = false;
    this.showIndividualConfirmation = false;
    this.showIndividualRecusa = false;
    this.shown = [];
  }

  private resetList() {
    this.data = [];
    this.approvalList = [];
    this.checkedList = [];
    this.selected = [];
  }

  public resetCheckBox() {
    this.checkedList = [];
    this.approvalList = [];
    this.total = 0;
    this.shownAll = true;
    this.checked('all');
  }

  private navigateToResource(router?: string): void {
    window.open(router);
  }

  public async tabsSelect(tabs) {
    this.isSchedules = tabs === 'AGENDADO' || tabs === 'PENDENTE';
    this.selectedTab = tabs;
  }

  public isPagamentoEmLote(item) {
    return item === 'Pagamento em Lote'
  }

  public checkStatus = (data) => {
    const numArrayToAllow = [];
    if (data !== undefined) {
      if (isArray(data)) {
        data.forEach((e) => {
          if (e.desc_status === 'LIQUIDADO' || e.desc_status === 'RECUSADO' || e.desc_status === 'CANCELADO BANCO FIBRA') {
            numArrayToAllow.push(e);
          }
        });
      }
      this.showCheckbox = data.length !== numArrayToAllow.length;
      return;
    }
    this.showCheckbox = true;
  };
  private addChecListAll(item, idx, index) {
    this.checkedList.push(item.cod_transacao);
    this.approvalList.push(item);
    this.selected.push(index);
    this.verifyChecked(item, this.shown[idx]);
  }

  public checked(idx, data?: ApprovalList) {
    this.showIndividualConfirmation = false;
    this.showIndividualRecusa = false;
    if (this.selected.find(x => x == idx) != null) {
      let index = this.selected.findIndex(x => x == idx);
      this.selected.splice(index, 1);
    } else {
      this.selected.push(idx);
    }

    this.showRefusedOrApproveAction = false;
    if (idx !== 'all') {
      this.shown[idx] = !this.shown[idx];
      if (this.shown[idx] !== false) {
        if (!this.checkedList.some(i => i == data.cod_transacao)) {
          this.checkedList.push(data.cod_transacao);
          this.approvalList.push(data);
        }

        this.verifyChecked(data, this.shown[idx]);
      } else {
        this.approvalList.splice(this.approvalList.indexOf(data), 1);
        this.checkedList.splice(
          this.checkedList.indexOf(data.cod_transacao),
          1
        );
        if (this.checkedList.length === 0) {
          this.checkedArrayIsNotEmpty = false;
          this.checkedArrayIsExpiredTicket = false;
          this.oneOrMorePendingChecked = false;
          this.isPoolPenddingselected = false;
          this.showConfirmationRadio = false;
          this.isExpiredTicketSelected = false;
          this.showRefusedOrApproveAction = false;
        } else {
          this.verifyChecked(data, this.shown[idx]);
        }

      }
    } else {
      this.shownAll = !this.shownAll;
      this.approvalList = [];
      this.checkedList = [];
      this.selected = [];
      this.data.map((item, index) => {
        const elem = document.getElementById(
          index.toString()
        ) as HTMLInputElement;
        elem.checked = this.shownAll;
        this.shown[index] = this.shownAll;
        if (
          this.shownAll !== false && !item.flag_robot_aprovacao
          && this.isMaster(item.aprovadores) 
          && (item.desc_status == 'PENDENTE' ||
          item.desc_status == 'AGENDADO')) {
          this.addChecListAll(item, idx, index);
        } else if (this.shownAll !== false && !item.flag_robot_aprovacao && item.desc_status == 'VENCIDO' && item.tipo_transacao == 'PAGAMENTO') {
          this.addChecListAll(item, idx, index);
        }
        else {
          this.totalValue();
        }
      });
    }
    this.objRequest = {
      lista_transacoes: this.checkedList,
    };
    if (this.objRequest && this.objRequest.lista_transacoes.length == 0) {
      this.resetAction();
    }
    this.totalValue();
  }

  public closeModal = () => {
    this.actionConfirmed = false;
    this.cancelConfirmation();
  };

  public changeTypeApprove(value) {
    this.actionAproveOrRefuse = value;
    this.channelEnum = value;
    if(value === 9){
      this.action = 'aprovar';
    }
    else if(value === 10){
      this.action = 'recusar';
    }
  }

  public approveOrRefuse(action, index, item?) {
    // Transações agendadas quando ainda se gerada o boleto eletrônico no momento da criação não podem ser cancelados no IB
    if (item.desc_status === "AGENDADO" && item.id_boleto_eletronico > 0) {
      this.toast.callModalMessage(null, "Por favor entrar em contato com o Banco Fibra.", '',  false, true);
      return false;
    }

    this.showConfirmationRadio = false;
    this.cancelConfirmation();
    this.action = action;
    if (action === 'aprovar') {
      this.channelEnum = 9;
      this.showIndividualConfirmation = true;
      this.showIndividualRecusa = false;
      this.requestDetailEncargos(item.cod_transacao);
    }
    if (action === 'recusar') {
      this.channelEnum = 10;
      this.showIndividualConfirmation = false;
      this.showIndividualRecusa = true;
    }
    if (this.showRefusedOrApproveAction || this.showConfirmationRadio) {
      return this.cancelConfirmation();
    }
    if (item.desc_status === 'PENDENTE') {
      this.showConfirmation = true;
      this.checkedList.push(item.cod_transacao);
      this.objRequest = {
        lista_transacoes: this.checkedList,
      };
      this.showRefusedOrApproveAction = true;
      return;
    }
    if (item.desc_status === 'VENCIDO') {
      this.isExpiredTicketSelected = true;
      this.showRefusedOrApproveAction = true;
      this.checkedList.push(item.cod_transacao);
      this.objRequest = {
        lista_transacoes: this.checkedList,
      };
      this.showRefusedOrApproveAction = true;
      return;
    }

    if (item.desc_status === 'AGENDADO') {
      this.showConfirmation = true;
      this.checkedList.push(item.cod_transacao);
      this.objRequest = {
        lista_transacoes: this.checkedList,
      };
      this.showRefusedOrApproveAction = true;
      return;
    }

    this.requestSearch(
      this.params,
      this.type,
      this.resolveStatus(this.selectedTab)
    );
  }

  public cancelConfirmation() {
    this.showRefusedOrApproveAction = false;
    this.actionAproveOrRefuse = false;
    this.resetAction();
    this.resetCheckBox();
  }

  public confirmAction() {
    // Transações agendadas quando ainda se gerada o boleto eletrônico no momento da criação não podem ser cancelados no IB
    let trava = this.verifySchedule.find(e => e.desc_status === 'AGENDADO' && e.id_boleto_eletronico > 0);
    if (trava) {
      this.toast.callModalMessage(null, "Por favor entrar em contato com o Banco Fibra.", '',  false, true);
      return false;
    }
    this.verifySchedule = [];
    this.actionConfirmed = true;
    this.resetAction();
  }

  public aprovarAction() {
    this.actionConfirmed = true;
    this.channelEnum = 9;
    this.resetAction();
  }

  public recusarAction() {
    this.actionConfirmed = true;
    this.channelEnum = 10;
    this.resetAction();
  }
  public sendReceipt(item) {
    this.getPdf
      .execute(item.cod_transacao)
      .subscribe(this.successPdf, this.errorPdf);
    // this.requestDetail(item.cod_transacao);
    // setTimeout(() => {
    //   this.navigateToResource('/approval-receipt');
    // }, 300);
  }

  private successPdf = (value) => {
    fetch(value.data.url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "Comprovante_transação.pdf";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      })
  };

  private errorPdf = (err) => {
    this.toast.callModalMessage(null, "Falha no download do comprovante tente novamente");
  };

  public setFilter(event?) {
    if(this.localStorageButtonTransacao != null) {
      event.dt_Inicio = this.dateInitialWhenPixSchedulling;
      event.dt_Final = this.dateFinalWhenPixSchedulling;
      this.localStorageButtonTransacao = null;
    }
    this.count = 0;
    if (this.varSubscriptTransaction) {
      this.varSubscriptTransaction.unsubscribe();
    }
    this.varSubscriptTransaction = this.transactionsDataShare.dataShared.subscribe((res) => {
      startWith('');
      this.type = res.type;
      this.params = event;
      this.requestSearch(
        this.params,
        this.type,
        this.resolveStatus(this.selectedTab)
      );
    });
  }

  public toogleDetail = (index, id) => {
    this.requestDetail(id);
    if (this.indexList !== index) {
      this.indexList = index;
      $('.collapse').removeClass('show');
      // $(`.item-icon img`).removeClass('img-arrow');
    }
  };

  public exportScreen(value) {
    if (value === 1) {
      this.navigateToResource('/approval-export');
      return;
    }

    this.postExportExcel.execute(this.paramsExport).subscribe((res) => {
      //this.successDownload(res);
      var currentdate = new Date();
      Util.handleFile(res, 'aprovacao_de_transacoes_' + currentdate.getDate().toString() + '/' + (currentdate.getMonth() + 1).toString() + '/' + currentdate.getFullYear().toString());
    }, error => {
      var currentdate = new Date();
      Util.handleFile(error.error.text, 'aprovacao_de_transacoes_' + currentdate.getDate().toString() + '/' + (currentdate.getMonth() + 1).toString() + '/' + currentdate.getFullYear().toString());
    });
  }
  private successDownload = (value) => {
    if (value.status === 'success') {
      // const blob = new Blob([value.data], { type: 'application/octet-stream' });
      Util.handleFile(value.data, 'arquivo');
    }
  };

  public verifyCalendar(event) {
    if (event === 'isGreaterThan730Days') {
      this.typToastColor = this.REDCOLOR;
      this.toast.callModalMessage(
        null,
        'Período fora de alcance',
        'A lista é disponível para operações dentro do intervalo de 730 dias.',
        null,
        null
      );
      return;
    }

    if (event === 'outOfReach') {
      this.typToastColor = this.REDCOLOR;
      this.toast.callModalMessage(
        null,
        'Período fora de alcance',
        'A lista é disponível para operações dentro do intervalo de 180 dias.',
        null,
        null
      );
      return;
    }
  }

  public callToast(title) {
    this.toast.callModalMessage(null, title, '', null, true);
  }

  public securityResponse = (value?) => {
    this.approvalList = [];
    this.checkedList = [];
    this.resetAction();

    const success = (res: any) => {

      this.requestSearch(
        this.params,
        this.type,
        this.resolveStatus(this.selectedTab)
      );
      this.resetCheckBox();
      this.actionConfirmed = false;
      let returnMessage: string;
      if (this.action === 'aprovar') {
        this.typToastColor = this.GREENCOLOR;
        returnMessage = 'Transação aprovada';
        this.toast.callModalMessage(null, `${returnMessage}`, '', null, true);
        return;
      }
      if (this.action === 'recusar') {
        this.typToastColor = this.REDCOLOR;
        returnMessage = 'Transação reprovada';
        this.toast.callModalMessage(null, `${returnMessage}`, '', null, true);
        return;
      }
      if (this.isPendent) {
        this.typToastColor = this.AMBARCOLOR;
        this.toast.callModalMessage(
          null,
          'Transações pendentes',
          'Necessário aprovação dos demais masters da sua empresa.',
          null,
          true
        );
        return;
      }
      let message = returnMessage;

      if (res.data[0].desc_mensagem != null && res.data[0].desc_mensagem != undefined) {
        message = res.data[0].desc_mensagem
        if(message === 'Será realizada na data agendada, mediante disponibilidade de saldo em conta.'){
          message = 'Transferência agendada. Será realizada na data agendada, mediante disponibilidade de saldo em conta.'
        }
      }
      this.toast.callModalMessage(null, `${message}`, '', null, true);
    };
    
    const error = (err) => {
      console.log(err);
      this.checkedList = [];
      this.approvalList = [];
      this.actionConfirmed = false;
      this.cancelConfirmation();

      if (err.error && err.error.message && err.error.message instanceof Array && err.error.message.length > 0) {
        if (err.error.code == 500) {
          sessionStorage.setItem('erroAprovacao', 'Serviço indisponível. Atenção! Antes de realizar novamente a aprovação das transações, confira a situação delas.');
          this._router.navigate(['extract']);        
        } else if (err.error.code == 504) {
          this.typToastColor = this.REDCOLOR;
          this.callToast('Transação em processamento. Entre em contato com a nossa área de atendimento.');

          // Atualiza valores mostrados na tela
          this.requestSearch(
            this.params,
            this.type,
            this.resolveStatus(this.selectedTab)
          );
          this.resetCheckBox();
        } else if (err.error.code === 400 && (<string>err.error.message[0]).endsWith('em estado inconsistente devido a um erro no momento da liquidação.')) {
          this.typToastColor = this.REDCOLOR;
          this.callToast('Transação em processamento. Entre em contato com a nossa área de atendimento.');

          // Atualiza valores mostrados na tela
          this.requestSearch(
            this.params,
            this.type,
            this.resolveStatus(this.selectedTab)
          );
          this.resetCheckBox();
        // OBS: Verificar prq essa menssagem volta no erro 400
        } else if (err.error.code === 400 && err.error.message[0] === 'Transação aprovada com sucesso, aguardando demais aprovações para executar a transação.'){
          // Atualiza valores mostrados na tela
          this.requestSearch(
            this.params,
            this.type,
            this.resolveStatus(this.selectedTab)
          );
          this.resetCheckBox();
          this.typToastColor = this.AMBARCOLOR;
          this.toast.callModalMessage(null, `${err.error.message[0]}`, '', null, true);
        } else {
          this.requestSearch(
            this.params,
            this.type,
            this.resolveStatus(this.selectedTab)
          );
          this.resetCheckBox();
          this.typToastColor = this.REDCOLOR;
          this.toast.callModalMessage(null, `${err.error.message[0]}`, '', null, true);
 
        } 
          if (err.error.message[0].startsWith('Solicitação já aprovada')) {
            // Atualiza valores mostrados na tela
            this.requestSearch(
              this.params,
              this.type,
              this.resolveStatus(this.selectedTab)
            );

            this.resetCheckBox();
          }
                 
      } else if (err.error.code === 404 && err.error.status === 'success' && err.error.data.length == 0) {
        this.requestSearch(
          this.params,
          this.type,
          this.resolveStatus(this.selectedTab)
        );
        this.resetCheckBox();
        this.typToastColor = this.AMBARCOLOR;
        this.toast.callModalMessage(null, 'Transações em processamento', '', null, true);
      } else if (err.error && err.error.message && err.error.message.length > 0){
        this.typToastColor = this.REDCOLOR;
        this.callToast('Transação em processamento. Entre em contato com a nossa área de atendimento.');

        // Atualiza valores mostrados na tela
        this.requestSearch(
          this.params,
          this.type,
          this.resolveStatus(this.selectedTab)
        );
        this.resetCheckBox();
      } else {
        this.toast.callModalMessage(null, 'Ocorreu um erro ao aprovar transação. Tente novamente mais tarde.');
      }
    };

    if (value.code === 200) {
      success(value);
      return;
    }

    error(value);
    this.resetAction();
  };

  public securityRequest = () => {
    return this.objRequest;
  };

  public showApprovalMaster(obj: any) {
    this.isPendent = obj.every((e) => e.tipo_acesso === 'Master').length > 1;
    return obj.some((e) => e.tipo_acesso === 'Master');
  }

  public showApprovalOperator(obj: any) {
    return obj.some((e) => e.tipo_acesso === 'Operador' && e.desc_status_aprovador.indexOf('Aguardando Aprova') !== -1);
  }
  public checkOperator(obj: any) {
    return obj.find((x: { tipo_acesso: string; }) => x.tipo_acesso === 'Operador') != null;
  }
  verificaSelected(i: any) {
    return this.selected.find(x => x == i) != null;
  }

  isPedding(approveds): boolean {
    let isOtherMaster = false;
    approveds.map(data => {
      if (data.desc_status_aprovador.indexOf('Aguardando Aprova') !== -1 && this.userEmail === data.desc_email_aprovador && data.pode_aprovar) {
        isOtherMaster = true
      }
    })
    return isOtherMaster
  }

  isMaster(approveds): boolean {
    let isOtherMaster = false;
    approveds.map(data => {
      if (this.userEmail === data.desc_email_aprovador) {
        isOtherMaster = true
      }
    })
    return isOtherMaster
  }

  isTheCreator(item): boolean {
    return item.email_operador == this.userEmail;
  }

  handleTypeConfirm() {
    let justRefuse = false
    this.approvalList.map(data => {
      for (const item of data.aprovadores ) {
        if (!item.pode_aprovar && this.userEmail === item.desc_email_aprovador) {
          justRefuse = true;
        }
      }
    })
    return justRefuse;
  }

  insertDatesWhenRedirectPixScheduling() {
    let dateInit = new Date().toJSON().split('T');
    this.dateInitialWhenPixSchedulling = dateInit[0];
    let dateFinal = new Date();
    dateFinal.setDate(dateFinal.getDate() + 7);
    this.dateFinalWhenPixSchedulling = dateFinal.toJSON().split("T");
    this.dateFinalWhenPixSchedulling = this.dateFinalWhenPixSchedulling[0];
  }

  selectedTabSchedule() {
    this.setTab.dataShared.subscribe((result)=> {
      if(result === 'AGENDADO') {
        this.verifyIsSchedule = true;
        this.insertDatesWhenRedirectPixScheduling();
      }
    });
  }

  resetIsSchedules() {
    if(this.verifyIsSchedule) {
      if(this.selectedTab == 'Tudo' || this.selectedTab == 'LIQUIDADO' || this.selectedTab == 'RECUSADO' || this.selectedTab == 'VENCIDO') {
        this.isSchedules = undefined;
      }
    } 
  }

  exibeMensagemRestricaoCredito(item) {
    this.toast.callModalMessage(null, `Não é possível realizar ${(item.tipo_transacao == 'PAGAMENTO' ? 'o pagamento' : item.tipo_transacao == 'PIX' ? 'a transferência PIX' : 'a transferência')}. Entre em contato com o seu gerente de relacionamento`, '', false, true);
  }
}
