import { Injectable } from "@angular/core";
import { UseCase } from "@src/core/base/use-case";
import { NotificationModel } from '@src/core/domain/notification/notification.model';
import { NotificationRepository } from "@src/data/repository/notification/notification.repository";
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class DeleteNotificationUseCases implements UseCase<any, NotificationModel> {
    constructor(private notificationRepository: NotificationRepository) {}

    execute(id: number): Observable<NotificationModel> {
        return this.notificationRepository.deleteNotification(id);
    }
}