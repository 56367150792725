import {
  Component,
  OnInit,
  Output,
  Input,
  forwardRef,
  EventEmitter,
  ViewChild,
  Injectable
} from '@angular/core';
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { dateMask } from '@src/shared/util-common';
import * as moment from 'moment';
import { MomentDateFormatter } from '@src/shared/formatDate';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

const I18N_VALUES = {
  // tslint:disable-next-line
  'pt': {
    weekdays: ['S', 'T', 'Q', 'Q', 'S', 'S', 'D'],
    months: ['Janeiro / ', 'Fevereiro / ', 'Março / ', 'Abril / ', 'Maio / ', 'Junho / ',
    'Julho / ', 'Agosto / ', 'Setembro / ', 'Outubro / ', 'Novembro / ', 'Dezembro / '],
  }
};

@Injectable()
export class I18n {
  /*eslint quote-props: ["error", "always"]*/
  /*eslint-env es6*/
  language = 'pt';
}

@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
 // tslint:disable-next-line
  constructor(private _i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return I18N_VALUES[this._i18n.language].months[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}
@Component({
  selector: 'fibra-modal-period-datepicker',
  templateUrl: './modal-period-datepicker.component.html',
  styleUrls: ['./modal-period-datepicker.component.scss'],
  providers: [ I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    { provide: NgbDateParserFormatter, useValue: new MomentDateFormatter() },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ModalPeriodDatepickerComponent),
      multi: true,
    },
  ],
})
export class ModalPeriodDatepickerComponent implements OnInit {
  @Output() closeEvent: EventEmitter<any> = new EventEmitter();
  @Output() change: EventEmitter<{ from: NgbDate; to: NgbDate }>;
  @ViewChild('content', { static: true }) content: HTMLElement;
  public dateMask = dateMask;
  readonly DT_FORMAT = 'DD/MM/YYYY';
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  dateFrom: NgbDate | null = null;
  dateTo: NgbDate | null = null;
  public dataI;
  public dataF;
  @Output() dateSelected: EventEmitter<NgbDate>;
  @Output() dateInvalid: EventEmitter<string>;
  @Input() dateNgb: NgbDate;
  @Input() minDate: NgbDate;
  @Input() maxDate: NgbDate;
  @Input() maxRange: number;
  @Input() abaPosicaoCarteira = '';
  @Input() abaSelected:string;
  @Input() isSchedules?;
  public today: NgbDate;
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  constructor(
    private modalService: NgbModal,
    calendar: NgbCalendar
  ) {
    this.change = new EventEmitter();
    this.today = calendar.getToday();
  }

  ngOnInit(): void {
    this.openModal(this.content);
    this.fromDate = null;
    this.toDate = null;
    this.dateFrom = null;
    this.dateTo = null;
    if(this.abaPosicaoCarteira === 'posicao-carteira-list-expiring') {
      this.minDate = this.today;
    } else if(this.abaPosicaoCarteira !== '') {
      this.maxDate = this.today;
    }
  }

  public addMask = (value: any) => {
    return {
      mask: value,
      placeholderChar: ' ',
      guide: true,
      modelClean: true,
    };
  }

  public openModal(content): void {
    this.modalService.open(content, {
      beforeDismiss: () => {
      this.closeEvent.emit({close: true})
       return true;
     }, 
     centered: true,
      size: 'lg',
      windowClass: 'modal-xxl'
   });
  }
  private adjustDate(date) {
    if (Number(date) < 10) {
      return `0${date}`;
    } else {
      return date;
    }
  }

  public closeModal(value: any): void {
    if(value === 'limpar') {
      this.fromDate = null;
      this.toDate = null;
      this.dataF = '';
      this.dataI = '';
      return;
    }

    if (this.fromDate && this.toDate) {
      var dataInicio = `${this.fromDate.year}-${this.adjustDate(
        this.fromDate.month
      )}-${this.adjustDate(this.fromDate.day)}`;

      var dataFim = `${this.toDate.year}-${this.adjustDate(
        this.toDate.month
      )}-${this.adjustDate(this.toDate.day)}`;
      const dateTo = moment(dataFim);
      const dateFrom = moment(dataInicio);
      const diffDays = dateTo.diff(dateFrom, 'days');
      if(this.fromDate.after(this.toDate)) {
        this.toast.callModalMessage(
          null,
          'Período fora de alcance',
          'A data inicial não pode ser posterior a data final.',
          null,
          null
        );
        return;
      } else if (this.maxRange) {
        if (this.maxRange && diffDays > this.maxRange) {
          this.toast.callModalMessage(null, 'Período fora de alcance', `A lista é disponível para operações dentro do intervalo de ${this.maxRange} dias.`, null, null);
          return;
        }
      } else if(diffDays > 90 && this.abaPosicaoCarteira === '') {
        this.toast.callModalMessage(
          null,
          'Período fora de alcance',
          'A lista é disponível para operações dentro do intervalo de 90 dias.',
          null,
          null
        );
        return;
      } else if(diffDays > 365 && this.abaPosicaoCarteira !== '') {
        this.toast.callModalMessage(
          null,
          'Período fora de alcance',
          'A lista é disponível para operações dentro do intervalo de 365 dias.',
          null,
          null
        );
        return;
      }

      if(value === 'buscar') {
        this.closeEvent.emit(value);
        if (this.fromDate && this.toDate) {   
          this.change.emit({ from: this.fromDate, to: this.toDate });
        }
        this.modalService.dismissAll();
        this.closeEvent.emit({close: true})
      } else {
        this.modalService.dismissAll();
        this.closeEvent.emit({close: true})
      }
    } else {
      if(this.dateFrom == null && this.dateTo == null) {
        this.toast.callModalMessage(
          null,
          'Período fora de alcance',
          'Nenhum período selecionado/digitado.',
          null,
          null
        );
      } else if(!this.fromDate) {
        if(this.dateFrom.after(this.maxDate)) {
          this.toast.callModalMessage(
            null,
            'Período fora de alcance',
            'O período de consulta não pode ser posterior a data atual.',
            null,
            null
          );
        } else {
          this.toast.callModalMessage(
            null,
            'Período fora de alcance',
            'O período de consulta não pode ser anterior a data atual.',
            null,
            null
          );
        }
      } else if(!this.toDate) {
        if(this.dateTo.after(this.maxDate)) {
          this.toast.callModalMessage(
            null,
            'Período fora de alcance',
            'O período de consulta não pode ser posterior a data atual.',
            null,
            null
          );
        } else {
          this.toast.callModalMessage(
            null,
            'Período fora de alcance',
            'O período de consulta não pode ser anterior a data atual.',
            null,
            null
          );
        }
      }
      
      return;
    }
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate || this.fromDate.day == null) {
      this.fromDate = date;
    } else if ((this.fromDate && !this.toDate && date.after(this.fromDate)) || date == this.fromDate) {
      this.toDate = date;
      const ajustDateFrom = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day)
      const ajustDateTo = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day)
      this.dataI = moment(ajustDateFrom).format('DD/MM/YYYY');
      this.dataF =moment(ajustDateTo).format('DD/MM/YYYY');
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    
  }

  isHovered(date: NgbDate) {
    return this.fromDate && this.fromDate.day != null && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  dataChanged(value: Event) {
    var isDate = value.target['value'].replaceAll(" ", "");
    if (isDate && isDate.length == 10) {
      var teste = value.target['value']
      const mdt = moment(teste, this.DT_FORMAT, true);
      if (!mdt.isValid()) {
        this.dateInvalid.emit("invalid");
      }
      else {
        let date = value.target['value'].split("/");
        var data = {
          day: parseInt(date[0], 10),
          month: parseInt(date[1], 10),
          year: parseInt(date[2], 10),
        };
        this.dateNgb = new NgbDate(data.year, data.month, data.day)
        if(this.dateNgb.before(this.maxDate) && this.dateNgb.after(this.minDate)) {
          this.dateSelected.emit(this.dateNgb);
        }
      }
    }
  }

  todate(value) {
    let date = value.split("/");
    var data = {
      day: parseInt(date[0], 10),
      month: parseInt(date[1], 10),
      year: parseInt(date[2], 10),
    };

    var dateNgb =  new NgbDate(data.year, data.month, data.day);
    if(this.dateNgb.before(this.maxDate) && this.dateNgb.after(this.minDate)) {
      this.dateSelected.emit(this.dateNgb);
    }
  }

  onBlur(value) {
    let date = value.split("/");
    var data = {
      day: parseInt(date[0], 10),
      month: parseInt(date[1], 10),
      year: parseInt(date[2], 10),
    };
    this.dateFrom = null;
    this.fromDate = null;
    var from = new NgbDate(data.year, data.month, data.day);
    if(from.before(this.minDate) || from.after(this.maxDate)) {
      this.dateFrom = from;
      this.fromDate = null;
    } else {
      this.fromDate =  new NgbDate(data.year, data.month, data.day);
    }
  }

  onBlurDataF(value) {
    let date = value.split("/");
    var data = {
      day: parseInt(date[0], 10),
      month: parseInt(date[1], 10),
      year: parseInt(date[2], 10),
    };
    var to = new NgbDate(data.year, data.month, data.day);
    this.dateTo = null;
    this.toDate = null;
    if(to.before(this.minDate) || to.after(this.maxDate)) {
      this.toDate = null;
      this.dateTo = to;
    } else {
      this.toDate =  new NgbDate(data.year, data.month, data.day);
    }

  }

  isDateInit(date: NgbDate) {
    return (
      date.equals(this.fromDate)
    );
  }

  isDateEnd(date: NgbDate) {
    return (
      date.equals(this.toDate)
    );
  }

  isPastDatePosicaoCarteira(date) {

    if (this.abaSelected) {
      if (this.maxDate != undefined) {

      if (this.maxDate.before(date)) {
        return true;
      }
    }
    if (this.minDate != undefined) {
    if (this.minDate.after(date)) {
      return true;
    }
  }
    } 
  }

  isPastDate(date) {
    if (this.isSchedules) {
      if (this.today.after(date)) {
        return true;
      }
    } else {
      if (this.today.before(date)) {
        return true;
      }
    }
  }
}
