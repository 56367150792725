<div class="new-rule" *ngIf="!loading">
  <div class="new-rule-content">
    <!-- Rule / Count text -->
    <div class="row">
      <div class="col-6">
        <label class="new-rule-title">Nome da regra</label>
      </div>
      <div class="col-6">
        <label
          class="new-rule-count"
          [ngClass]="{ 'txt-alert': countTxtRegra < 11 }"
          >{{ countTxtRegra }} caracteres</label
        >
      </div>
    </div>

    <!-- Rule -->
    <div class="row row-line">
      <div class="col-12">
        <input
          class="input-new-rule"
          type="text"
          name="fibra-new-rule-{{ randHack }}"
          id="fibra-new-rule-{{ randHack }}"
          [ngModel]="nomeRegra"
          (keyup)="changeNomeRegra($event.target.value)"
          maxlength="30"
          placeholder="Regra 1"
          autocomplete="fibra-new-rule-{{ randHack }}"
        />
      </div>
    </div>

    <!-- Valor -->
    <div class="row row-top">
      <div class="col-12">
        <label class="new-rule-title">Valor até R$</label>
      </div>
    </div>

    <!-- Valores -->
    <div class="row row-line">
      <div class="col-12 col-inline">
        <input
          type="text"
          [(ngModel)]="vlrPermitido"
          name="vlr_permitido-{{ randHack }}"
          id="vlr_permitido-{{ randHack }}"
          placeholder="R$ 00.000.000,00"
          currencyMask
          maxlength="24"
          [options]="{
            prefix: 'R$ ',
            thousands: '.',
            decimal: ',',
            align: 'left'
          }"
        />

        <button
          type="button"
          class="btn btn-rule"
          [ngClass]="{ 'btn-rule-active': vlrPermitido === '100.000,00' }"
          (click)="onClick($event, 'R$ 100.000,00')"
        >
          100.000,00
        </button>

        <button
          type="button"
          class="btn btn-rule"
          [ngClass]="{ 'btn-rule-active': vlrPermitido === '500.000,00' }"
          (click)="onClick($event, 'R$ 500.000,00')"
        >
          500.000,00
        </button>

        <button
          type="button"
          class="btn btn-rule"
          [ngClass]="{ 'btn-rule-active': vlrPermitido === '1.000.000,00' }"
          (click)="onClick($event, 'R$ 1.000.000,00')"
        >
          1.000.000,00
        </button>
      </div>
    </div>

    <!-- Operadores -->
    <div class="row row-top">
      <div class="col-12">
        <label class="new-rule-title">Operadores</label>
      </div>
    </div>

    <!-- Operadores Combo -->
    <div class="row row-mini-top">
      <div class="col-9" data-hj-suppress>
        <input
          class="input-operador"
          [resultFormatter]="formatter"
          [inputFormatter]="formatter"
          [ngbTypeahead]="search"
          [(ngModel)]="model"
          name="operator-{{ randHack }}"
          id="operator-{{ randHack }}"
          type="text"
          autocomplete="operador-{{ randHack }}"
          placeholder="Digite o nome ou e-mail do operador"
        />
      </div>
      <div class="col-3">
        <button
          [disabled]="errorOperator"
          (click)="addOperatorRule()"
          class="button-add"
          type="reset"
        >
          Adicionar
        </button>
      </div>
    </div>
    <div
      class="deleteAllOperator"
      (click)="deleteMessageOperator()"
      *ngIf="checkOperators.length > 0"
    >
      <span>{{
        checkDeleteOperators()
          ? 'Excluir usuários selecionados'
          : 'Excluir usuário selecionado'
      }}</span>
    </div>
    <!-- Operadores List -->
    <div class="row row-line">
      <div class="col-12">
        <div
          class="box-itens"
          *ngFor="
            let operator of addOperators | sortBy: 'asc':'nome_pessoa';
            let i = index
          "
        >
          <div class="row box-item">
            <div class="col-lg-1 col-sm-1 d-flex justify-content-start">
              <input
                id="operatorCheck{{ i }}"
                type="checkbox"
                class="checkbox"
                (click)="checkOperator(operator)"
              />
            </div>
            <div class="col-lg-5 col-sm-5 d-flex justify-content-start">
              <label class="box-title" data-hj-suppress>{{ operator.nome_pessoa }}</label>
            </div>
            <div class="col-lg-5 col-sm-5 d-flex justify-content-start">
              <label class="box-title">{{ operator.email }}</label>
            </div>
            <div class="col-lg-1 col-sm-1 d-flex justify-content-start">
              <a
                *ngIf="checkLengthOperators()"
                (click)="removeOperator(operator)"
              >
                <img class="actBtns" src="/assets/png/icon_delete_rule.png"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Masters -->
    <div class="row row-top">
      <div class="col-6">
        <label class="new-rule-title">Masters</label>
      </div>

      <div class="col-6 masters-content">
        <input
          type="checkbox"
          class="masters-check"
          (click)="addMaster($event)"
        />
        <label class="masters-txt">Os masters deverão aprovar as transações</label>
      </div>
    </div>

    <!-- Masters / List -->
    <div class="row row-line">
      <div class="col-12">
        <!-- Masters list -->
        <ul
          *ngFor="
            let master of masters | sortBy: 'asc':'nome_pessoa';
            let i = index
          "
          class="masters-list"
        >
          <li *ngIf="master.ativo === true && !master.master_backup" class="masters-item">
            <label class="masters-name" data-hj-suppress>{{ master.nome_pessoa }}</label>
          </li>
        </ul>
      </div>
    </div>

    <!-- Contas -->
    <div class="row row-top">
      <div class="col-12">
        <label class="new-rule-title">Contas</label>
      </div>
    </div>

    <!-- Contas Combo -->
    <div class="row row-mini-top">
      <div class="col-9">
        <fibra-form-control class="combo-conta">
          <!-- Combo Conta -->
          <fibra-form-control [style.width.%]="100">
            <fibra-dropdown
              removeShadown="true"
              customClass="select-option"
              [options]="countOptionsRule"
              label=""
              placeholder="Selecionar conta"
              (emitChange)="changeCounter($event)"
              id="optionsCounter"
            ></fibra-dropdown>
          </fibra-form-control>
        </fibra-form-control>
      </div>
      <div class="col-3">
        <button
          class="button-add negativeTop"
          type="button"
          (click)="addAccount()"
        >
          Adicionar
        </button>
      </div>
    </div>
    <div
      class="deleteAllUsers"
      (click)="deleteMessageAccount()"
      *ngIf="checkAccounts.length > 0"
    >
      <span>{{
        checkAccounts.length > 1
          ? 'Excluir contas selecionadas'
          : 'Excluir conta selecionada'
      }}</span>
    </div>
    <div class="row row-line">
      <div class="col-12">
        <div class="box-itens" *ngFor="let account of optionsCountSelected">
          <div class="row box-item" *ngIf="account?.length > 0">
            <div class="col-lg-11 col-sm-11 d-flex justify-content-start">
              <input
                type="checkbox"
                class="checkbox"
                (click)="checkAccount(account)"
              /><label class="box-title">{{ account }}</label>
            </div>
            <div class="col-lg-1 col-sm-1 d-flex justify-content-start">
              <a
                *ngIf="checkAccounts.length === 0"
                (click)="deleteAccount(account)"
                ><img class="actBtns" src="/assets/png/icon_delete_rule.png"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div class="row row-top">
      <div class="col-12 align-right">
        <button
          [disabled]="
            this.nomeRegra === '' ||
            optionsCountSelected.length === 0 ||
            addOperators.length === 0
          "
          (click)="cancelAll()"
          type="button"
          class="btn button-cancel"
        >
          Cancelar
        </button>
        <button
          type="button"
          [disabled]="
            this.nomeRegra === '' ||
            vlrPermitido === '' ||
            optionsCountSelected.length === 0 ||
            addOperators.length === 0
          "
          class="btn space-left btn-primary button-default"
          (click)="addNewRule()"
        >
          Cadastrar regra
        </button>
      </div>
    </div>
  </div>
</div>
<div class="loading-new-rule" *ngIf="loading">
  <div class="d-flex justify-content-center position">
    <div class="spinner-border text-primary" role="status"></div>
  </div>
</div>
<div class="box-options" *ngIf="showAction">
  <div class="info"></div>
  <div class="actions">
    <div class="text">{{ textAlertRule }}</div>
    <div class="buttons">
      <div class="cancel" (click)="cancelDeleteRule(); showAction = false">
        Cancelar
      </div>
      <div class="approval" (click)="bottomFunctions(selectedBottomFunction)">
        Sim
      </div>
    </div>
  </div>
</div>
<fibra-toast-error-message [color]="typeColorToast"></fibra-toast-error-message>
<fibra-modal-validation
    *ngIf="securityAcction"
    [apiCallback]="apiCallback"
    (closeEvent)="closeModal()"
    [apiPayload]="securityRequest()"
    (responseValidation)="securityResponse($event)"
>
</fibra-modal-validation>
