export const empresasCapitalGiro = ['3831403000170',
'28269178000151',
'62635669000107',
'26589893000146',
'7155032000105',
'26295633000168',
'4008658000109',
'6958449000658',
'6958449000658',
'6958449000496',
'6958449000496',
'6958449000577',
'6958449000143',
'6958449000143',
'7909323000141',
'2423640000130',
'31112243000307',
'31112243000307',
'31112243000226',
'31112243000226',
'78813045000172',
'86160074000100',
'88610191000154',
'43127570000150',
'27895350000110',
'14626101000187',
'5969344000127',
'19108652000172',
'16920223000107',
'30742555000170',
'61155529000179',
'19943196000186',
'1628604000140',
'80959513000163',
'4691623004600',
'4691623002314',
'4691623004520',
'4691623001938',
'4691623004953',
'4691623001695',
'4691623001180',
'4691623000613',
'4691623000532',
'4691623000109',
'15488871000173',
'8891190000196',
'19059914000156',
'19869582000175',
'7987748000179',
'13373017000306',
'9608144000280',
'13373017000489',
'7911318000173',
'28612598000199',
'27547941000104',
'27547941000368',
'29869709000100',
'17586530000166',
'28913260000177',
'144257000117',
'66557588000197',
'66617747000100',
'33960282000128',
'40995964000131',
'28347519000160',
'92660893000110',
'33309685000101',
'33309685000101',
'9506556000200',
'61142089000115',
'21628516000155',
'9641540000129',
'17908958000188',
'44463156000184',
'60860681000190',
'60860681000432',
'46686465000149',
'46686465000149',
'46686465000220',
'73586976000161',
'58451386000138',
'58451386000138',
'45361425000164',
'32274158000146',
'45127545000100',
'51482776000126',
'43544121000107',
'7671092000180',
'5435483000170',
'61144150000678',
'2999999000150',
'2999999000150',
'7609993000142',
'52717659000167',
'19041463000120',
'81804676000130',
'81804676000130',
'5861238000125',
'6144818000164',
'73337305000167',
'73337305000167',
'6696624000171',
'26592564000154',
'9170450000105',
'18816410000170',
'4117143000139',
'11190870000197',
'7229006000184',
'4654727000143',
'78186228000105',
'26608960000122',
'43119650000163',
'8915713000197',
'17659781000123',
'3928279000166',
'8617578000101',
'1455222000161',
'4827795000167',
'46677860000165',
'3582243000173',
'49810369000159',
'60446929000170',
'19572597000177',
'9258268000100',
'20382746000114',
'21393000000179',
'3456069000112',
'74461005000158',
'10981488000139',
'49034275000135',
'44242287000131',
'7544518000135',
'61150447000131',
'5955701000106',
'5810004000159',
'61322558000188',
'4298106000174',
'61727285000151',
'53924379000192',
'53924379000192'];

export const empresasChequeEmpresa = ['71923304000179',
'06940439000180',
'11310764000108',
'47333539000126',
'07801105000199',
'06072452000165'];

export const empresasFibraFacil = ['19041463000120',
'04757459000195',
'51236974000100',
'04746729000162',
'21656241000163',
'22811775000180',
'04021679000156',
'03347431000117',
'11620561000100',
'24415230000180',
'83056804000130',
'81293961000133',
'03558055000100'];