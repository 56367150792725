import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AuthGuard } from '@src/data/repository/auth-guard/auth-guard.repository';
import { PfGuard } from '@src/shared/guards/pf.guard';
import { PjGuard } from '@src/shared/guards/pj.guard';

// pages
import { DetalsRetornoComponent } from './pages/detals-retorno/detals-retorno.component';
import { LoginComponent } from '@src/presentation/web/pages/login/login.component';
import { DashboardComponent } from '@src/presentation/web/pages/dashboard/dashboard.component';
import { DemoComponent } from '@src/presentation/web/pages/demo/demo.component';
import { ExtractComponent } from '@src/presentation/web/pages/extract/extract.component';
import { ChargeComponent } from '@src/presentation/web/pages/charge/charge.component';
import { ChargeArchiveComponent } from '@src/presentation/web/pages/charge-archive/charge-archive.component';
import { SummaryComponent } from '@src/presentation/web/pages/summary/summary.component';
import { ChargeEditComponent } from '@src/presentation/web/pages/charge-edit/charge-edit.component';
import { DuplicateBillComponent } from '@src/presentation/web/pages//duplicate-bill/duplicate-bill.component';
import { QueryComponent } from '@src/presentation/web/pages/query/query.component';
import { PaymentComponent } from '@src/presentation/web/pages/payment/payment.component';
import { PaymentRevisionComponent } from '@src/presentation/web/pages/payment-revision/payment-revision.component';
import { TransferComponent } from '@src/presentation/web/pages/transfer/transfer.component';
import { TransferConfirmComponent } from '@src/presentation/web/pages/transfer-confirm/transfer-confirm.component';
import { TransferReceiptComponent } from '@src/presentation/web/pages/transfer-receipt/transfer-receipt.component';
import { BankComponent } from '@src/presentation/web/pages/bank/bank.component';
import { SameOwnershipComponent } from '@src/presentation/web/pages/same-ownership/same-ownership.component';
import { OwnershipPageComponent } from '@src/presentation/web/pages/ownership/ownership.component';
import { ApprovalMatrixComponent } from '@src/presentation/web/pages/approval-matrix/approval-matrix.component';
import { TransactionApprovalComponent } from '@src/presentation/web/pages/transaction-approval/transaction-approval.component';
import { TransactionApprovalBatchComponent } from '@src/presentation/web/pages/transaction-approval-batch/transaction-approval-batch.component';
import { NewOwnershipPageComponent } from '@src/presentation/web/pages/new-ownership/new-ownership.component';
import { PaymentMultipleComponent } from '@src/presentation/web/pages/payment-multiple/payment-multiple.component';
import { PaymentPendingComponent } from '@src/presentation/web/pages/payment-pending/payment-pending.component';
import { TransferPendingComponent } from '@src/presentation/web/pages/transfer-pending/transfer-pending.component';
import { AdminUserPageComponent } from '@src/presentation/web/pages/admin-user-page/admin-user-page.component';
import { PixComponent } from '@src/presentation/web/pages/pix/pix.component';
import { ForeignTradeComponent } from '@src/presentation/web/pages/foreign-trade/foreign-trade.component';
import { IncomeReportPageComponent } from '@src/presentation/web/pages/income-report/income-report.component';
import { DerivativePageComponent } from '@src/presentation/web/pages/derivative/derivative.component';
import { RegisterGroupAdminPageComponent } from '@src/presentation/web/pages/register-group-admin/register-group-admin.component';
import { ApprovalReceiptComponent } from '@src/presentation/web/components/approval-receipt/approval-receipt.component';
import { DownloadApprovalListComponent } from '@src/presentation/web/components/download-approval-list/download-approval-list.component';
import { DownloadDerivativeListComponent } from '@src/presentation/web/components/download-derivative-list/download-derivative-list.component';
import { RegisterAdminPageComponent } from '@src/presentation/web/pages/register-admin/register-admin.component';



// const isResponsive = () => {
//   let width = window.innerWidth <= 768;

//   if (width) {
//     return { path: '', component: DownloadAppComponent };
//   }
//   return { path: 'login', component: LoginComponent, canActivate: [MsalGuard] };
// };

import { CapitalAgilComponent } from './pages/capital-agil/capital-agil.component';
import { RegistratoComponent } from './pages/registrato/registrato.component';
import { LoansComponent } from './pages/loans/loans.component';
import { DownloadContractsComponent } from './components/download-contracts/download-contracts.component';
import { DownloadDailyPositionComponent } from './components/download-daily-position/download-daily-position.component';
import { DownloadInstallmentsComponent } from './components/download-installments/download-installments.component';
import { DownloadCheckCompanyComponent } from './components/download-check-company/download-check-company.component';
import { DownloadContractInfoComponent } from './components/download-contract-info/download-contract-info.component';
import { AccessDeniedPageComponent } from './pages/access-denied-page/access-denied-page.component';
import { RoleGuard, PermissionValidationType } from '@src/shared/guards/role.guard';
import { InvestmentsComponent } from './pages/investments/investments.component';
import { ImpersonateComponent } from './pages/impersonate/impersonate.component';
import { ImpersonateGuard } from '@src/shared/guards/impersonate.guard';
import { PaymentBatchComponent } from './pages/payment-batch/payment-batch.component';
import { ReceiptComponent } from './pages/receipt/receipt.component';
import { DownloadReceiptComponent } from '@src/presentation/web/components/download-receipt/download-receipt.component';
import { DetailsLiquidadoComponent } from './components/details-liquidado/details-liquidado.component';
import { TermUserPageComponent } from './pages/terms-user/terms-user.component';
import { PaymentBatchCnab240ReviewComponent } from './payment-batch-cnab240-review/payment-batch-cnab240-review.component';
import { OpenbankingPaymentRequestComponent } from './pages/openbanking-paymentrequest/openbanking-paymentrequest.component';
import { OpenbankingPaymentRequestCanDeactivate } from './pages/openbanking-paymentrequest/openbanking-paymentrequest.candeactivate';
import { OpenbankingPaymentRequestCanActivate } from './pages/openbanking-paymentrequest/openbanking-paymentrequest.canactivate';
import { OpenbankingComponent } from './pages/openbanking/openbanking.component';
import { DownloadAppComponent } from './pages/download-app/download-app.component';
import { NoPermissionModule } from './pages/no-permission/no-permission.module';
import { ChangeTransactionalLimitComponent } from './pages/change-transactional-limit/change-transactional-limit.component';
import { ResolveService } from '@src/shared/services/resolve.service';
import { AccessDeniedNoAccountPageComponent } from '@src/presentation/web/pages/access-denied-no-account-page/access-denied-no-account-page.component';
import { DdaAdesaoComponent } from "./pages/dda/adesao/adesao.component";
import { DdaTransacionalComponent } from "./pages/dda/transacional/transacional.component";
import { TransacionalResolver } from "./pages/dda/resolvers/transacional.resolver";
import { DdaMainComponent } from "./pages/dda/dda-main/dda-main.component";
import { OpportunityComponent } from "./pages/opportunity/opportunity.component";
import { OpportunityCapitalGiroComponent } from "./pages/opportunity-capital-giro/opportunity-capital-giro.component";
import { OpportunityCambioComponent } from "./pages/opportunity-cambio/opportunity-cambio.component";
import { OpportunitySegurosComponent } from './pages/opportunity-seguros/opportunity-seguros.component';
import { OpportunityFiancaBancariaComponent } from './pages/opportunity-fianca-bancaria/opportunity-fianca-bancaria.component';


// TODO: Ajuste a ser adicionado para mostrar download do app
const routes: Routes = [
  // { path: '', pathMatch: 'full', redirectTo: window.innerWidth <= 768 ? 'mobile-download' : 'login'},
  { path: 'login', component: LoginComponent, canActivate: [MsalGuard] },
  { 
    path: 'no-permission', 
    loadChildren: () => import('./pages/no-permission/no-permission.module').then(m => m.NoPermissionModule)
  },
  { path: 'impersonate', component: ImpersonateComponent, canActivate: [ImpersonateGuard] },
  { path: 'mobile-download', component: DownloadAppComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },{
    path: "opportunity",
    component: OpportunityComponent,
    canActivate: [AuthGuard],
  },{
    path: "capital-giro",
    component: OpportunityCapitalGiroComponent,
    canActivate: [AuthGuard],
  },{
    path: "cambio",
    component: OpportunityCambioComponent,
    canActivate: [AuthGuard],
  },{
    path: "seguros",
    component: OpportunitySegurosComponent,
    canActivate: [AuthGuard],
  },{
    path: "fianca-bancaria",
    component: OpportunityFiancaBancariaComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'extract', component: ExtractComponent, canActivate: [AuthGuard,RoleGuard], data: {
      permissions: [
        '!SEM_CONTA',
        '!PROBLEMA_CREDITO',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'QUALQUER_CONTA.EXT.CONS_EXT_SAL',
        'EXT.CONS_EXT_SAL'
      ]
    }
  },
  { path: 'charge', component: ChargeComponent, canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: [
        '!SEM_CONTA',
        'COB.CAD_TIT',
        'QUALQUER_CONTA.COB.CAD_TIT'
      ]
    } 
  },   
  {
    path: 'duplicate-bill',
    component: DuplicateBillComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: [
        '!SEM_CONTA',
        'COB.GER_SEG_BOL',
        'QUALQUER_CONTA.COB.GER_SEG_BOL']
    } 
  },

  { path:'detalsRetorno/:id/:nomeArquivo',component: DetalsRetornoComponent,canActivate: [AuthGuard, RoleGuard], data: {
    permissions: [
      '!SEM_CONTA',
      '!REST_CRED.100',
      '!REST_CRED.101',
      'PAG_LOTE.TRANS_ARQ',
      'PAG_LOTE.CONS_ARQ_REM',
      'PAG_LOTE.CONS_ARQ_RET',
      'PAG_LOTE.CONS_AR_LIQ'
    ]
  } 

},

  { path:'detailsLiquidado/:id/:nomeArquivo',component: DetailsLiquidadoComponent,canActivate: [AuthGuard, RoleGuard], data: {
    permissions: [
      '!SEM_CONTA',
      '!REST_CRED.100',
      '!REST_CRED.101',
      'PAG_LOTE.TRANS_ARQ',
      'PAG_LOTE.CONS_ARQ_REM',
      'PAG_LOTE.CONS_ARQ_RET',
      'PAG_LOTE.CONS_AR_LIQ'
    ]
  } 

},

  {
    path: 'charge-edit',
    component: ChargeEditComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: [
        '!SEM_CONTA',
        'COB.CAD_TIT',
        'QUALQUER_CONTA.COB.CAD_TIT'
      ]
    } 
  },
  {
    path: 'charge-archive',
    component: ChargeArchiveComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: [
        '!SEM_CONTA',
        'COB.TRANS_ARQ', 
        'COB.CONS_ARQ_REM',
        'COB.CONS_ARQ_RET',
        'QUALQUER_CONTA.COB.TRANS_ARQ',
        'QUALQUER_CONTA.COB.CONS_ARQ_REM',
        'QUALQUER_CONTA.COB.CONS_ARQ_RET']
    } 
  },
  { path: 'summary', component: SummaryComponent, canActivate: [AuthGuard] },
  { path: 'query', component: QueryComponent, canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: 
      [
        '!SEM_CONTA',
        'COB.CONS_POS_CART',
        'COB.CONS_ARQ_TRANS',
        'COB.CONS_INST_ADIC',
        'COB.CONS_FRANC',
        'QUALQUER_CONTA.COB.CONS_INST_ADIC',
        'QUALQUER_CONTA.COB.CONS_POS_CART',
        'QUALQUER_CONTA.COB.CONS_ARQ_TRANS',
        'QUALQUER_CONTA.COB.CONS_FRANC',]
    } 
  },
  { path: 'demo', component: DemoComponent },
  {
    path: 'payment', component: PaymentComponent, canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        '!SEM_CONTA',
        '!PROBLEMA_CREDITO',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'PAG_BOL.REALIZAR_PAG_BOL'
      ]
    }
  },
  {
    path: 'payment-batch', component: PaymentBatchComponent, canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        '!SEM_CONTA',
        '!PROBLEMA_CREDITO',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'PAG_LOTE.TRANS_ARQ',
        'PAG_LOTE.CONS_ARQ_REM',
        'PAG_LOTE.CONS_ARQ_RET',
        'PAG_LOTE.CONS_AR_LIQ'
      ]
    } 
  },
  {
    path: 'payment-batch/cnab240-review/:filename', component: PaymentBatchCnab240ReviewComponent, canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        '!SEM_CONTA',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'PAG_LOTE.TRANS_ARQ',
        'PAG_LOTE.CONS_ARQ_REM',
        'PAG_LOTE.CONS_ARQ_RET',
        'PAG_LOTE.CONS_AR_LIQ'
      ]
    } 
  },
  {
    path: 'payment-revision',
    component: PaymentRevisionComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: ['!SEM_CONTA', 'PAG_BOL.REALIZAR_PAG_BOL']
    } 
  },
  {
    path: 'dda',
    component: DdaMainComponent,
    canActivate: [AuthGuard, RoleGuard],
    resolve: {termos: TransacionalResolver},
    data: {
      permissions: [
        '!SEM_CONTA',
        '!PROBLEMA_CREDITO',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'DDA.DDA_CONS_BOL',
        'DDA.DDA_REALIZA_PAG_BOL',
        'DDA.DDA_CONS_HIST'
      ],
    },
  },
  {
    path: 'dda-aceite',
    component: DdaAdesaoComponent,
    canActivate: [AuthGuard, RoleGuard],
    resolve: {termos: TransacionalResolver},
    data: {
      permissions: [
        '!SEM_CONTA',
        '!PROBLEMA_CREDITO',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'DDA.DDA_CONS_BOL',
        'DDA.DDA_REALIZA_PAG_BOL',
        'DDA.DDA_CONS_HIST'        
      ],
    },
  },
  {
    path: 'dda-transacional',
    component: DdaTransacionalComponent,
    canActivate: [AuthGuard, RoleGuard],
    resolve: {termos: TransacionalResolver},
    data: {
      permissions: [
        '!SEM_CONTA',
        '!PROBLEMA_CREDITO',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'DDA.DDA_CONS_BOL',
        'DDA.DDA_REALIZA_PAG_BOL',
        'DDA.DDA_GER_ARQ_CNAB',
        'DDA.DDA_CONS_HIST'
      ],
    },
  },
  { path: 'transfer', component: TransferComponent, canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: [
      '!SEM_CONTA',
      '!PROBLEMA_CREDITO',
      '!REST_CRED.100',
      '!REST_CRED.101',
      'TRANSF.TRANSF_MSM_TITU',
      'TRANSF.TRANSF_OTR_TITU',
      'TRANSF.EXC_BEN',
      'TRANSF.TRANSF_MSM_TIT_OTR_BC',
      'TRANSF.TRANSF_OTR_TIT_BC']
    } 
  },
  {
    path: 'transfer', component: TransferComponent, canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        '!SEM_CONTA',
        '!PROBLEMA_CREDITO',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'TRANSF.TRANSF_MSM_TITU',
        'TRANSF.TRANSF_OTR_TITU',
        'TRANSF.TRANSF_MSM_TIT_OTR_BC',
        'TRANSF.TRANSF_OTR_TIT_BC',
        'TRANSF.EXC_BEN'
      ]
    }
  },
  {
    path: 'transfer-confirm',
    component: TransferConfirmComponent,
    canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        '!SEM_CONTA',
        '!PROBLEMA_CREDITO',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'TRANSF.TRANSF_MSM_TITU',
        'TRANSF.TRANSF_OTR_TITU',
        'TRANSF.TRANSF_MSM_TIT_OTR_BC',
        'TRANSF.TRANSF_OTR_TIT_BC'
      ]
    }
  },
  {
    path: 'transfer-receipt',
    component: TransferReceiptComponent,
    canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        '!SEM_CONTA',
        '!PROBLEMA_CREDITO',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'TRANSF.TRANSF_MSM_TITU',
        'TRANSF.TRANSF_OTR_TITU',
        'TRANSF.TRANSF_MSM_TIT_OTR_BC',
        'TRANSF.TRANSF_OTR_TIT_BC'
      ]
    }
  },
  {
    path: 'same-ownership',
    component: SameOwnershipComponent,
    canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        '!SEM_CONTA',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'TRANSF.TRANSF_MSM_TITU',
        'TRANSF.TRANSF_OTR_TITU',
        'TRANSF.TRANSF_MSM_TIT_OTR_BC',
        'TRANSF.TRANSF_OTR_TIT_BC'
      ]
    }
  },
  {
    path: 'approval-matrix',
    component: ApprovalMatrixComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: [
        '!SEM_CONTA',
        '!PROBLEMA_CREDITO',
        'MTZ_APROV.CAD_REGRAS',
        'MTZ_APROV.VISU_REGRAS',
        'MTZ_APROV.EDIT_EXCL_REG',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'PJ'
      ]
    }
  },
  { path: 'bank', component: BankComponent, canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: [
        '!SEM_CONTA',
        'TRANSF.TRANSF_OTR_TITU',
        'TRANSF.TRANSF_MSM_TITU',
        'TRANSF.TRANSF_MSM_TIT_OTR_BC',
        'TRANSF.TRANSF_OTR_TIT_BC'
      ]
    } 
  },
  {
    path: 'ownership',
    component: OwnershipPageComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: [
        '!SEM_CONTA',
        'TRANSF.TRANSF_MSM_TITU',
        'TRANSF.TRANSF_OTR_TITU',
        'TRANSF.TRANSF_MSM_TIT_OTR_BC',
        'TRANSF.TRANSF_OTR_TIT_BC'
      ]
    } 
  },
  {
    path: 'transaction-approval',
    component: TransactionApprovalComponent,
    canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        '!SEM_CONTA',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'APROV_TRANS.CONS_TRANS',
        'APROV_TRANS.APROV_REPROV_TRANS'
      ]
    }
  },
  {
    path: 'transaction-approval/batch/:id',
    component: TransactionApprovalBatchComponent,
    canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        '!SEM_CONTA',
        '!PROBLEMA_CREDITO',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'APROV_TRANS.CONS_TRANS',
        'APROV_TRANS.APROV_REPROV_TRANS'
      ]
    }
  },
  {
    path: 'new-ownership',
    component: NewOwnershipPageComponent,
    canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        '!SEM_CONTA',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'TRANSF.TRANSF_MSM_TITU',
        'TRANSF.TRANSF_OTR_TITU',
        'TRANSF.TRANSF_MSM_TIT_OTR_BC',
        'TRANSF.TRANSF_OTR_TIT_BC'
      ]
    }
  },
  {
    path: 'payment-multiple',
    component: PaymentMultipleComponent,
    canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        '!SEM_CONTA',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'PAG_BOL.REALIZAR_PAG_BOL'
      ]
    }
  },
  {
    path: 'payment-pending',
    component: PaymentPendingComponent,
    canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        '!SEM_CONTA',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'PAG_BOL.REALIZAR_PAG_BOL'
      ]
    }
  },
  {
    path: 'transfer-pending',
    component: TransferPendingComponent,
    canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        '!SEM_CONTA',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'TRANSF.TRANSF_MSM_TITU',
        'TRANSF.TRANSF_OTR_TITU',
        'TRANSF.TRANSF_MSM_TIT_OTR_BC',
        'TRANSF.TRANSF_OTR_TIT_BC'
      ]
    }
  },
  {
    path: 'admin-user',
    component: AdminUserPageComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      advancedPermissions: [
        {
          type: PermissionValidationType.hasAll,
          permissions: ['MASTER', 'PJ']
        }
      ]
    }
  },
  {
    path: 'foreign-trade',
    component: ForeignTradeComponent,
    canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        'COMERCIO_EXTERIOR.CONSULTAR_POSICAO_CARTEIRA',
        'COM_EXT.CONS_CAMB_PR',
        'COM_EXT.CONS_IMP',
        'COM_EXT.CONS_EXP',
        'COM_EXT.CONS_PAG_REC',
        'COM_EXT.CONS_GAR'
      ]
    }
  },
  {
    path: 'capital-agil',
    component: CapitalAgilComponent,
    canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        'CAP_AGIL.SIM_SOL_CONTR',
        'CAP_AGIL.ACOMP_SOL'
      ]
    }
  },
  {
    path: 'pix', component: PixComponent,
    resolve: {
      resolve: ResolveService
    },
    canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        '!SEM_CONTA',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'PIX.CONS_CHAVE',
        'PIX.CAD_CHAVE',
        'PIX.EXC_CHAVE',
        'PIX.TRANSF_PIX_CHAVE',
        'PIX.TRANSF_PIX_MSM_TIT',
        'PIX.TRANS_PIX_OTR_TIT',
        'PIX.TRANSF_PIX_MSM_TIT_BC',
        'PIX.TRANSF_PIX_OTR_TIT_BC',
        'PIX.CONS_SOLIC'
      ]
    }
  },
  {
    path: 'openbanking', 
    component: OpenbankingComponent
  },
  {
    path: 'openbanking-paymentrequest', 
    component: OpenbankingPaymentRequestComponent, 
    canActivate: [ OpenbankingPaymentRequestCanActivate ],
    canDeactivate: [ OpenbankingPaymentRequestCanDeactivate ]
  },
  {
    path: 'income-report',
    component: IncomeReportPageComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: ['INF_REND.CONS_INF_REND']
    }
  },
  {
    path: 'derivative',
    component: DerivativePageComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: ['DER.CONS_DER']
    }
  },
  {
    path: 'loans',
    component: LoansComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: ['OP_CRED.CONS_OP_CRED']
    }
  },
  {
    path: 'register-admin-user',
    component: RegisterAdminPageComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: ['MASTER','PJ']
    }
  },
  {
    path: 'register-group-admin-user',
    component: RegisterGroupAdminPageComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: ['MASTER','PJ']
    }
  },
  {
    path: 'approval-receipt',
    component: ApprovalReceiptComponent,
    canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        '!SEM_CONTA',
        '!REST_CRED.100',
        '!REST_CRED.101'
      ]
    }
  },
  {
    path: 'approval-export',
    component: DownloadApprovalListComponent,
    canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        '!SEM_CONTA',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'APROV_TRANS.CONS_TRANS',
        'APROV_TRANS.APROV_REPROV_TRANS'
      ]
    }
  },
  {
    path: 'receipt-export',
    component: DownloadReceiptComponent,
    canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        '!SEM_CONTA',
        '!REST_CRED.100',
        '!REST_CRED.101',
        'APROV_TRANS.CONS_TRANS',
        'APROV_TRANS.APROV_REPROV_TRANS'
      ]
    }
  },
  {
    path: 'investments',
    component: InvestmentsComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: ['INVEST.CONS_INV']
    }
  },
  {
    path: 'download-contracts',
    component: DownloadContractsComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: ['OP_CRED.CONS_OP_CRED']
    }
  },
  {
    path: 'download-daily-position',
    component: DownloadDailyPositionComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: ['OP_CRED.CONS_OP_CRED']
    }
  },
  {
    path: 'download-check-company',
    component: DownloadCheckCompanyComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: ['OP_CRED.CONS_OP_CRED']
    }
  },
  {
    path: 'download-installments',
    component: DownloadInstallmentsComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: ['OP_CRED.CONS_OP_CRED']
    }
  },
  {
    path: 'download-contract-info',
    component: DownloadContractInfoComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: ['OP_CRED.CONS_OP_CRED']
    }
  },
  {
    path: 'derivative-export',
    component: DownloadDerivativeListComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      permissions: ['DER.CONS_DER']
    }
  },
  {
    path: 'registrato',
    component: RegistratoComponent,
    canActivate: [AuthGuard, PfGuard],
  },
  {
    path: 'access-denied',
    component: AccessDeniedPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'access-denied-no-account',
    component: AccessDeniedNoAccountPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'receipt',
    component: ReceiptComponent,
    canActivate: [AuthGuard, RoleGuard], data: {
      permissions: [
        '!REST_CRED.100',
        '!REST_CRED.101',
        'COMP.CONS_COMP'
      ]
    }
  },
  {
    path: 'open-finance-terms-of-service',
    component: TermUserPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'change-transactional-limits',
    component: ChangeTransactionalLimitComponent,
    resolve: {
      resolve: ResolveService
    },
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
