import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, SimpleChanges, OnChanges, ElementRef, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ListQueryModel, posicaoCarteiraListQueryTypeEnum } from '@src/core/domain/query/query.model';
import { GetPosicaoCarteiraUsecase } from '@src/core/usecases/query/posicao-carteira-list/get-all-query.usecase';
import { FilterQueryDataShare, TabsDataShare, optionPosicaoCarteira, DetailsListShare, InstructionDataShare, TabsOnChange } from '@src/data/repository/data-share-repository';
import * as Util from '@src/shared/util-common';
import * as moment from 'moment'
import { Subscription, Subject } from 'rxjs';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { QueryTransitRefusedListComponent } from '../lists/query-transit-details/query-transit-details.component';

@Component({
  selector: 'fibra-query-posicao-carteira',
  templateUrl: './query-posicao-carteira.component.html',
  styleUrls: ['./query-posicao-carteira.component.scss']
})
export class QueryPosicaoCarteiraComponent implements OnInit, OnDestroy {  

  private _campo_ordenacao: string;
  private _sentido_ordenacao: string;
  transit: Array<any>; // transit.pagador[index] e transit.vlr_titulo[index]. pegar o index do checked(idx)?  
  filter = new FormControl('');
  getOrderedDate;
  getOrderedPayer;
  getOrderedValue;
  @Input() params;
  @Input() valueChoiceQuery;
  @Input() isCarteira: boolean = false;
  public cleanInput: boolean = false;
  arrShown;
  public dataDeHoje = new Date();
  dataVencimento = new Date();
  today;
  isVencido: boolean;
  public _isBatchChoose: boolean;
  public _isBatchAbatimentoChoose: boolean;
  public _isBatchAltVencimentoChoose: boolean;
  public _readonlyInput: boolean;

  @Output() TotalValuesPeriod = new Subject<{ rowcount: number, titulospagos: number, titulosvencidos: number, titulosbaixados: number, titulosavencer: number, titulosemcartorio:number }>();
  @Output() valueChecked = new EventEmitter();
  @Output() valueChoiceOut = new EventEmitter();
  @Output() isDatePosicaoCarteiraValid = new EventEmitter();
  @Output() isExpirationDateInvalid = new EventEmitter();
  @Output() qtdChecked = new EventEmitter();
  @Output() checkedAll = new Subject<boolean>();
  @Output() eof = new Subject<boolean>();
  @Output() loadingRemainingRows = new Subject<boolean>();
  @Output() loadedRemainingRows = new Subject<Array<posicaoCarteiraListQueryTypeEnum>>();

  private _limparLista: boolean;

  get limparLista(): boolean {
    return this._limparLista;
  }

  private _showCheckboxInstruction: boolean;

  get showCheckboxInstruction(): boolean {
    return this._showCheckboxInstruction;
  }

  @Input() set showCheckboxInstruction(value: boolean) {
    this._showCheckboxInstruction = value;

    if (!value)
      this.ClearDataForDetails()
  };

  get isBatchChoose(): boolean {
    return this._isBatchChoose;
  }

  @Input() set isBatchChoose(value: boolean) {
    this._isBatchChoose = value;
  }

  // verificar lote Abatimento
  get isBatchAbatimentoChoose(): boolean {
    return this._isBatchAbatimentoChoose;
  }

  @Input() set isBatchAbatimentoChoose(value: boolean) {
    this._isBatchAbatimentoChoose = value;
  }

  // verificar lote Alteracao Vencimento
  get isBatchAltVencimentoChoose(): boolean {
    return this._isBatchAltVencimentoChoose;
  }

  @Input() set isBatchAltVencimentoChoose(value: boolean) {
    this._isBatchAltVencimentoChoose = value;
  }

  // verificar se foi Adicionado, assim deixar input como readonly
  get readonlyInput(): boolean {
    return this._readonlyInput;
  }

  @Input() set readonlyInput(value: boolean) {
    this._readonlyInput = value;
  }

  @Output() instructionEvent: EventEmitter<any>;
  showCheckbox = false;
  valueChoice;
  shown = [];
  public loading: boolean;
  public loadingMore: boolean = false;
  private _loadingRemainingRows: boolean = false;
  private _eof: boolean = false;
  public show: boolean = false;
  shownAll = false;
  date = new Date();
  @Output() emitItem: EventEmitter<any>;
  private subscriptions: Subscription[] = [];
  private _subTabs: Subscription;
  typeList: string;
  
  @Input() set limparLista(value: boolean) {
    this._limparLista = value;

    if (value === true) {
      let indexValue = this.shown.indexOf(true) !== -1;
      if (this.shownAll === true || indexValue) {
        this.shownAll = false;
        this.shown = [];
        this.checked('all', null)
        this.checked('all', null)
        this.cleanInput = true;
      } 
  }
  };


  dataForDetails = {
    numberItem: null, // Item a ser enviado para a Api de Instruções (sem detalhes)?
    originalValue: null, // Item a ser enviado para a Api de Instruções?
    labelService: '',
    inputServiceMoney: null,
    inputServiceNumber: null,
    calendarService: null,
    postInput: '',
    item: null
  };

  paramsDefault = {
    num_conta: null,
    dt_inicial: null,
    dt_final: null,
    codigo_especie: null,
    param_carteira: [],
    nossonumero: null,
    seunumero: null,
    pagador: null,
    valor: null,
    paginar: false,
    offset: null,
    tipo_data_posicao: null,
    campo_ordenacao: null,
    sentido_ordenacao: null
  };
  paramsAux = {};
  tabprevious = '';

  dataSetDetailsBar = {
    instruction: '', // Item a ser enviado para a Api de Instruções?
    optionInBatch: null,
    screen: '',
    inputServiceMoney: false,
    inputServiceNumber: false,
    calendarService: false

  };
  hide;

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;
  @ViewChild(QueryTransitRefusedListComponent, null) details: QueryTransitRefusedListComponent;

  private _tableBody: ElementRef = null;
  private _getAllQueryListSub: Subscription;

  @ViewChild('tablebody', null) set tableBody(value: ElementRef) {
    if (value) {
      this._tableBody = value;

      this._tableBody.nativeElement.addEventListener("scroll", () => {
        if (!this.loadingMore && !this._eof && (this._tableBody.nativeElement.scrollTop + this._tableBody.nativeElement.clientHeight + 20 >= this._tableBody.nativeElement.scrollHeight)) {
          this.loadMore();
        }
      });
    }
  }

  private ShowToast(value: any): void {
    const isMessageServer: boolean = !!(value && value.code && value.message && value.message.length > 0);
    isMessageServer && this.toast && this.toast.callModalMessage(null, value.message[0], null, null, null);
  }

  constructor(
    private getAllQueryList: GetPosicaoCarteiraUsecase,
    private sharedFilter: FilterQueryDataShare,
    private shareTab: TabsDataShare,
    private shareValueChoice: DetailsListShare,
    private shareInstruction: InstructionDataShare,
    private _tabsOnChange: TabsOnChange
  ) {
    this.emitItem = new EventEmitter();
    this.instructionEvent = new EventEmitter();
    this.transit = [];
    this.dataForDetails.numberItem = [];
    this.dataForDetails.originalValue = [];
    this.dataForDetails.item = [];
    this.sendDataToDetails('init');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
    this._subTabs.unsubscribe();
    this._readonlyInput = false;
  }

  ngOnInit() {
    this.subscribeFilter();
    this.subscribeTab();

    this.dataDeHoje.setDate(this.dataDeHoje.getDate());
    this.today = this.dataDeHoje.toLocaleDateString();

    this.shareInstruction.dataShared.subscribe((res) =>{
      this.dataForDetails.inputServiceMoney =  res && res.dataQueryComponent.inputServiceMoney;
      this.dataForDetails.inputServiceNumber =  res && res.dataQueryComponent.inputServiceNumber;
      this.dataForDetails.calendarService = res && res.dataQueryComponent.calendarService;
    });

    this._subTabs = this._tabsOnChange.onTabChange.subscribe(t => {
      this.subscriptions.forEach((sb) => sb.unsubscribe());
  });
  }  
  
  private loadMore() {
    this.loadingMore = true;

    const params = { ...this.paramsDefault };

    params.offset = this.transit[this.transit.length - 1];
    params.campo_ordenacao = this._campo_ordenacao;
    params.sentido_ordenacao = this._sentido_ordenacao;

    this.switchList(this.typeList ? this.typeList : 'posicao-carteira-list-all' , params);
  }

  public loadRemainingRows() {
    this.loadingRemainingRows.next(this._loadingRemainingRows = true);

    const params = { ...this.paramsDefault };

    params.paginar = false;
    params.offset = this.transit[this.transit.length - 1];

    this.switchList(this.typeList ? this.typeList : 'posicao-carteira-list-all', params);
  }

  public limparListaSelecionado(value: boolean) {
    this._limparLista = value;

    if (value === true) {
      this.dataForDetails.item = [];
      this.dataForDetails.numberItem = [];
      this.dataForDetails.originalValue = [];

      let indexValue = this.shown.indexOf(true) !== -1;
      if (this.shownAll === true || indexValue) {
        this.shownAll = false;
        this.shown = [];
        this.cleanInput = true;
      } 
    }
  }

  subscribeFilter(){
    this.loading = true;    
    this.subscriptions.push(
      this.sharedFilter.dataShared.subscribe(
        (res: { account: string; dt_Final: string; dt_Inicio: string; codigo_especie: string; param_carteira: any; yourNumber: string; ourNumber: string; draweeName: string; valorTitulo: number; tipo_data_posicao: string }) => {

          if(res && res.dt_Inicio) {
            this.paramsDefault = {
              num_conta: res == undefined ? "" : res.account,
              dt_inicial: res.dt_Inicio,
              dt_final: res.dt_Final,
              codigo_especie: res.codigo_especie,
              param_carteira: res.param_carteira,
              nossonumero: res.ourNumber,
              seunumero: res.yourNumber,
              pagador: res.draweeName,
              valor: res.valorTitulo,
              paginar: true,
              offset: null,
              tipo_data_posicao: res.tipo_data_posicao || 'emissao',
              campo_ordenacao: null,
              sentido_ordenacao: null
            };
            this.hide = false;
            this.eof.next(this._eof = false);
            this.loading = true;
            this.transit = [];
            this._campo_ordenacao = null;
            setTimeout(() => {
              this.switchList(this.typeList ? this.typeList : 'posicao-carteira-list-all' , this.paramsDefault);
            }, 300);
          }
        }
      )
    );
  }

  subscribeTab(){
    this.subscriptions.push(this.shareTab.dataShared.subscribe((res) => {
      if (res && res.typeList && res.typeList.startsWith('posicao-carteira-list')) {
        let type = res.typeList;
        this.loading = true;
        this.switchList(type, this.paramsDefault);
        this.typeList = type;
      }
    }));
  }

  private switchList(list, param) {
    if (this.isCarteira && list && list !== 'query-list-all' && list !== 'francesinha-list-all' && param.dt_inicial && param.dt_final && (this.tabprevious !== list || this.paramsAux !== param)) {      
      const codigo_situacao = optionPosicaoCarteira
        .filter(situacao => situacao['idOption'] == list)
        .map(situacao => situacao['codigo_situacao'])
        .find(_ => true);

      this.requestQueryListAll({ ...param, codigo_situacao });
      this.paramsAux = param;
      this.tabprevious = list;
    }

    if (!this.loadedRemainingRows) {
      this._readonlyInput = false;
    }    
  }

  checked(idx, item) {
    if (idx !== 'all') {
      this.shown[idx] = !this.shown[idx];

      if (this.shown[idx]) this.PushDataForDetails(item);//(this.transit[idx]);
      else this.RemoveItemForInstruction(item); // this.RemoveDataForDetails(idx);
        let indexValue = this.shown.indexOf(true) !== -1;
        if (indexValue) {
        this.valueChecked.emit(true); 
        } else {
         this.valueChecked.emit(false); 
        }

        var valueShown = this.shown 
      
        var valueShownFilter = valueShown.filter(w => w === true).length;
        this.qtdChecked.emit(valueShownFilter);     

    } else {
      this.shownAll = !this.shownAll;

      if (this.shownAll === true) {
        this.valueChecked.emit(true); 
      } else {
        this.valueChecked.emit(false); 
      }

      let testeArray = [];
      this.transit.map((item, index) => {

        if (this.shownAll) this.PushDataForDetails(item);
        else this.ClearDataForDetails();

        const elem = document.getElementById(index.toString()) as HTMLInputElement;

        if (elem) {
          elem.checked = this.shownAll;
          this.shown[index] = this.shownAll;
          testeArray.push(elem.id)
        }
          
      });

      this.qtdChecked.emit(testeArray.length); 
      this.checkedAll.next(this.shownAll);
    }

    this.shareValueChoice.setValue({
      numberItem: this.dataForDetails.numberItem,
      originalValue: this.dataForDetails.originalValue,
      item: this.dataForDetails.item
    });
  }

  private PushDataForDetails(item: any): void {
    this.dataForDetails.numberItem.push(item.seu_numero);
    this.dataForDetails.originalValue.push(item.vlr_titulo);
    this.dataForDetails.item.push(item);
  }

  private RemoveDataForDetails(index: number): void {
    this.dataForDetails.numberItem = this.dataForDetails.numberItem.filter((_, i) => i != index);
    this.dataForDetails.originalValue = this.dataForDetails.originalValue.filter((_, i) => i != index);
    this.dataForDetails.item = this.dataForDetails.item.filter((_, i) => i != index);
  }

  private RemoveItemForInstruction(item): void {
    const itemNumber = this.dataForDetails.numberItem.indexOf(item.seu_numero); 
    const removedItemNumber = this.dataForDetails.numberItem[itemNumber];
    this.dataForDetails.numberItem = this.dataForDetails.numberItem.filter((seuNumero, index) => seuNumero !== removedItemNumber);

    const itemValorTitulo = this.dataForDetails.originalValue.indexOf(item.vlr_titulo); 
    const removedItemValue = this.dataForDetails.originalValue[itemValorTitulo];
    this.dataForDetails.originalValue = this.dataForDetails.originalValue.filter((valorTitulo, index) => valorTitulo !== removedItemValue);

    const itemInstruction = this.dataForDetails.item.indexOf(item); 
    const removedItemInstruction = this.dataForDetails.item[itemInstruction];
    this.dataForDetails.item = this.dataForDetails.item.filter((itensSelecionados, index) => itensSelecionados !== removedItemInstruction); 
  }

  private ClearDataForDetails(): void {
    this.dataForDetails.numberItem = [];
    this.dataForDetails.originalValue = [];
    this.dataForDetails.item = [];
  }

  sort(field: string) {
    const params = { ...this.paramsDefault };

    if (this._campo_ordenacao === field) {
      this._sentido_ordenacao = this._sentido_ordenacao === 'asc' ? 'desc' : 'asc';
    } else {
      this._campo_ordenacao = field;
      this._sentido_ordenacao = 'asc';
    }
    params.campo_ordenacao = this._campo_ordenacao;
    params.sentido_ordenacao = this._sentido_ordenacao;

    this.hide = false;
    this.eof.next(this._eof = false);
    this.loading = true;
    this.transit = [];

    setTimeout(() => {
      this.switchList(this.typeList ? this.typeList : 'posicao-carteira-list-all', params);
    }, 300);
  }

  resetData() {
    (this.dataForDetails.labelService = ''),
      (this.dataForDetails.inputServiceMoney = false),
      (this.dataForDetails.inputServiceNumber = false),
      (this.dataForDetails.calendarService = false),
      (this.dataForDetails.postInput = '');
  }

  sendDataToDetails(event) {
    this.resetData();
    const items = ['abatimento', 'alteracao-vencimento', 'protesto'];
    const includes = items.includes(this.dataSetDetailsBar.instruction);

    if (this.dataSetDetailsBar.optionInBatch === false && includes) {
      this.showCheckbox = true;
    }
    if (
      this.dataSetDetailsBar.optionInBatch ||
      this.dataSetDetailsBar.optionInBatch === null ||
      !includes
    ) {
      this.showCheckbox = true;
    }
    if (!this.dataSetDetailsBar.optionInBatch) {
      if (this.dataSetDetailsBar.instruction === 'abatimento') {
        if (this.dataSetDetailsBar.screen == '0') {
          (this.dataForDetails.labelService = 'Conceder abatimento de'),
            (this.dataForDetails.inputServiceMoney = true);
        }
        if (this.dataSetDetailsBar.screen == '1') {
          this.dataForDetails.labelService = 'Conceder abatimento de';
        }
        if (this.dataSetDetailsBar.screen == '2') {
          this.dataForDetails.labelService = 'Abatimento concedido de';
        }
      }

      if (this.dataSetDetailsBar.instruction === 'alteracao-vencimento') {
        if (this.dataSetDetailsBar.screen == '0') {
          (this.dataForDetails.labelService = 'Alteração de vencimento'),
            (this.dataForDetails.calendarService = true);
        }
        if (this.dataSetDetailsBar.screen == '1') {
          this.dataForDetails.labelService = 'Alteração de vencimento';
        }
        if (this.dataSetDetailsBar.screen == '2') {
          this.dataForDetails.labelService = 'Concedido novo vencimento';
        }
      }

      if (this.dataSetDetailsBar.instruction === 'protesto') {
        if (this.dataSetDetailsBar.screen == '0') {
          (this.dataForDetails.labelService = 'Conceder protesto de'),
            (this.dataForDetails.inputServiceNumber = true),
            (this.dataForDetails.postInput = 'dias');
        }
        if (this.dataSetDetailsBar.screen == '1') {
          (this.dataForDetails.labelService = 'Conceder protesto de'),
            (this.dataForDetails.postInput = 'dias');
        }
        if (this.dataSetDetailsBar.screen == '2') {
          (this.dataForDetails.labelService = 'Concedido protesto de'),
            (this.dataForDetails.postInput = 'dias');
        }
      }
    }
  }

  receiveValueChoice(valueChoice) {
    this.valueChoice = valueChoice;
    this.valueChoiceOut.emit(valueChoice)
  }

  private requestQueryListAll(param?) {
    if (this.isCarteira && param) {
      if (this._getAllQueryListSub) {
        this._getAllQueryListSub.unsubscribe();
      }

      this.subscriptions.push(this._getAllQueryListSub = this.getAllQueryList
        .execute(param)
        .subscribe(
          this.requestSuccess,
          this.requestError,
          this.requestComplete
        ));
    }
  }

  private requestSuccess = (value: ListQueryModel) => {
    const result = Util.verifyArray(value.data.rows);

    if (this._loadingRemainingRows) {
      this.loadedRemainingRows.next(value.data.rows);
      this.loadingRemainingRows.next(this._loadingRemainingRows = false);
    } else {
      this.eof.next(this._eof = result.length < 30);

      if (!this.loadingMore) {
        this.transit = result;
        this.TotalValuesPeriod.next({ rowcount: value.data.rowcount, titulospagos: value.data.titulospagos, titulosvencidos: value.data.titulosvencidos, titulosbaixados: value.data.titulosbaixados, titulosavencer: value.data.titulosavencer, titulosemcartorio: value.data.titulosemcartorio });
      } else {
        const currentLength = this.transit.length;

        this.transit = this.transit.concat(result);
  
        if (this.shownAll) {
          this.shown = this.shown.concat(result.map((t, i) => i + currentLength));
        }
      }
      
      this.hide = Util.isEmpty(this.transit);    
      this.loading = this.loadingMore = false;
    }
  };

  private requestError = (err) => {
    this.ShowToast(err);
    this.transit = [];
    this.TotalValuesPeriod.next(null)
    this.hide = true;
    this.loading = this.loadingMore = false;
  };

  private requestComplete = () => {
    this.hide = Util.isEmpty(this.transit);
    this.loading = this.loadingMore = false;
  };

  isNull = (value) => {
    return Util.isNull(value);
  };

  sendItemList(item) {
    this.emitItem.emit(item);
    this.show = true;
  }

  isStatus(status: string, statuses: string[]): boolean {
    return statuses.includes(status);
  }

  getStatusFormatedCarteira(status, dt_vencimento) {
    let newStatus = '';
    //  let dt_hoje = new Date(moment(new Date()).format("MM/DD/YYYY"));
    var dataVencimento = (moment(dt_vencimento).format("DD/MM/YYYY"))
    var parts = dataVencimento.split('/');
    var dataTitulosVencimento = Number(parts[2] + parts[1] + parts[0]);
    parts = this.today.split('/');
    var dt_hoje = Number(parts[2] + parts[1] + parts[0]);
    if (['EM ABERTO'].includes(status))
      if(dataTitulosVencimento < dt_hoje){
        newStatus = 'Vencido';
        this.isVencido = true;
      } else {
        newStatus = 'À vencer';
        this.isVencido = false;
      }  
    else if (['BAIXADO'].includes(status))
      newStatus = 'Baixado';
    else if (['PAGO'].includes(status))
      newStatus = 'Pago';
    else if (['PROTESTADO', 'VENCIDO'].includes(status))
      newStatus = 'Vencido';
    else if (['EM CARTÓRIO'].includes(status))
      newStatus = 'Em Cartório';

    return newStatus;
  }

  verifyDatePosicaoCarteira(value) {
   if (value === false) {
     //data invalida
     this.isDatePosicaoCarteiraValid.emit(false);
   } else {
    this.isDatePosicaoCarteiraValid.emit(true);
   }
  }

  verifyExpirationDate(value) {
    if (value === true) {
      this.isExpirationDateInvalid.emit(true);
    }
    else {
      this.isExpirationDateInvalid.emit(false);
    }
  }
}