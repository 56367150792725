<div class="row-details">
  <div class="details-number">
    <!--<div class="icon">
      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.5">
          <path
            d="M12.9999 12.3967H14.1987M5.79932 3.99609H10.5993H5.79932ZM5.79932 9.99609H10.5993H5.79932ZM5.79932 6.39549H9.39932H5.79932ZM5.79932 12.3967H9.39932H5.79932ZM12.9999 3.99609H14.1987H12.9999ZM12.9999 9.99609H14.1987H12.9999ZM12.9999 6.39549H14.1987H12.9999Z"
            stroke="#082A4D"
            stroke-width="0.8"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 1H19M2.8081 1V17.7928L4.6018 16.597L6.421 17.7964L8.2141 16.597L10.0141 17.7928L11.8075 16.597L13.6069 17.7928L15.4012 16.597L17.2 17.7928V1H2.8081ZM13.6069 17.7964V17.7928V17.7964ZM10.0141 17.7964V17.7928V17.7964Z"
            stroke="#082A4D"
            stroke-width="0.8"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </svg>
    </div>-->
    <!--<div>
      <div class="sub-title">Seu número</div>
      <div class="value text-seunumero">{{item?.seu_numero}}</div>
      <div></div>
    </div>-->
  </div>
  <!--<div>
    <div class="sub-title">Valor Original</div>
    <div class="value">{{(item?.vlr_titulo || item?.vlr_original)  | currency: 'BRL'}}</div>
    <div></div>
  </div>-->
  <div class="discount">
    <div class="label">
      {{data.labelService}}
    </div>
    <span class= "mr-2" *ngIf="data.inputServiceMoney">Conceder abatimento de </span>
    <span class= "mr-2" *ngIf="data.inputServiceNumber"></span>
    <span class= "mr-2" *ngIf="data.calendarService">Alteração de vencimento </span>
    <div>
      <input
        *ngIf="data.inputServiceMoney"
        type="text"
        (keyup)="setValueChoice($event)"
        currencyMask
        maxlength="18"
        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false }"
        [readonly]="_readonlyInput"
      >
      <input
        *ngIf="data.inputServiceNumber"
        type="text"
        (keyup)="setValueChoice($event)"
        onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        maxlength="5"
        [readonly]="_readonlyInput"
      />
          
      <div [formGroup]="myGroup" [style.width.px]="145" >
        <fibra-datepicker 
          *ngIf="data.calendarService"
          formControlName="expirationChange"
          [date]="expirationChange"
          [minDate]="createDefaultDateVencimento"
          (dateSelected)="selectDate($event)"
          (input)="dataChanged($event)"
          (change)='todate($event.target.value)'
          placement="bottom"
          placeholder="00/00/0000"
          [readonlyInput]="_readonlyInput == true"
        ></fibra-datepicker>
        <ng-container *ngIf="dataInvalida === true">
          <small class="text-danger">
            {{ (dataInvalida === true && checkExpirationDate === false) ? invalidDateMessage : invalidExpirationDateMessage }}
          </small>
        </ng-container>
      </div>
    </div>
    <span class= "mr-2" *ngIf="data.inputServiceNumber"> dia(s) após o vencimento </span>

    <div class= "mr-2">
        <span *ngIf="_isBatchChoose"></span>
       <span *ngIf="_isBatchChoose && !data.inputServiceNumber && !data.inputServiceMoney && !data.calendarService" class="valueChoice">
      {{choice ? choice : (valueChoiceQuery ? valueChoiceQuery : 0) }}
       </span> 
       <span *ngIf="_isBatchAbatimentoChoose">Conceder abatimento de </span>
       <span *ngIf="_isBatchAbatimentoChoose && !data.inputServiceNumber && !data.inputServiceMoney && !data.calendarService" class="valueChoice">
        {{choice ? choice : (valueChoiceQuery ? valueChoiceQuery : 'R$ 0,00') }}
       </span> 
       <span *ngIf="_isBatchAltVencimentoChoose">Alteração de vencimento </span>
       <span *ngIf="_isBatchAltVencimentoChoose && !data.inputServiceNumber && !data.inputServiceMoney && !data.calendarService" class="valueChoice">
         {{choice ? choice : (valueChoiceQuery ? valueChoiceQuery : '00/00/0000') }}
       </span> 
       <span *ngIf="!_isBatchChoose && !_isBatchAbatimentoChoose && !_isBatchAltVencimentoChoose && !data.inputServiceNumber && !data.inputServiceMoney && !data.calendarService" class="valueChoice">
          {{choice || valueChoiceQuery }}
           </span>
       <span *ngIf="_isBatchChoose">dia(s) após o vencimento </span>
     </div>

    <div *ngIf="data.postInput" class="valueChoice postInput">
      {{data.postInput}}
    </div>
  </div>
</div>
