import { Component, OnInit,OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { TabsDataShare } from '@src/core/usecases/data-share/tabs-data-share.service';
import { BalancesModel } from '@src/core/domain/balance/balances.model';
import { ExtractRequest } from '@src/core/domain/extract/extract.model';
import * as moment from 'moment';

@Component({
  selector: 'fibra-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit,OnDestroy {
  @Input() typeList;
  loading: boolean;
  @Input() balance?: BalancesModel;
  @Output() paramsDate: EventEmitter<ExtractRequest>;
  @Input() params;
  date: Date;
  dateInitial: string;
  periodDate: number;
  periodDays: number;
  periodCalendar: any = {};
  dateEnd: string;
  period: number;
  dataInicio: string;
  dataFinal: string;
  public periodShow = false;
  typeListLast="extract-list-all"
  @Output() emitDetail: EventEmitter<any>;

  constructor(public tab: TabsDataShare) {
    this.emitDetail = new EventEmitter();
    this.paramsDate = new EventEmitter();
    this.date = new Date();
    this.period = null;
  }

  ngOnDestroy()
  {
    sessionStorage.removeItem('extrato-data')
  }

  ngOnInit() {
    this.paramsDate.emit(this.params);

    this.loading = false;
    if (!this.typeList) {
      this.tab.dataShared.subscribe(
        (res: {
          typeList: string;
          periodDays: number;
          periodCalendar: any;
        }) => {
          if (
            (res.typeList && res.periodDays && res.periodCalendar) ||
            (res.typeList && res.periodDays) ||
            res.periodCalendar
          ) {
            
            
            if(this.typeListLast !== res.typeList)
            {
              this.periodShow = false
              this.typeListLast = res.typeList
            }
            else{
              this.periodShow = res.periodCalendar ? true : false;
            }
            this.typeList = null;
            this.periodDays = res.periodDays;
            this.loading = true;
            setTimeout(() => {
              this.periodCalendar = res.periodCalendar || this.formatDate();
              

              let today = moment().format('YYYY-MM-DD');
              let endDay = moment(today).add(this.periodDays, 'days').format('YYYY-MM-DD');

              if (res.typeList === 'extract-list-scheduling') {

                let item = JSON.parse(sessionStorage.getItem('extrato-data'))
                if(item !== null)
                {
                  this.periodCalendar = { dataInicio: item.dataInicio, dataFinal: item.dataFinal }
                }
                else
                {
                  this.periodCalendar = { dataInicio: today, dataFinal: endDay }
                }
              }

              this.dataInicio = moment(this.periodCalendar.dataInicio)
                .locale('pt-br')
                .format('DD MMM/yyyy');
              this.dataFinal = moment(this.periodCalendar.dataFinal)
                .locale('pt-br')
                .format('DD MMM/yyyy');
              this.typeList = res.typeList;
              this.resolveDate(this.periodCalendar);
              this.loading = false;
            }, 600);
          }
        }
      );
    }
  }

  public formatDate = () => {
    this.period = this.periodDays;
    this.dateEnd = this.date.toISOString().slice(0, 10);
    const auxDate = new Date(this.date);
    this.periodDate = auxDate.setDate(this.date.getDate() - this.period);
    const endDate = new Date(this.periodDate);
    this.dateInitial = endDate.toISOString().slice(0, 10);
    return {
      dataInicio: this.dateInitial,
      dataFinal: this.dateEnd,
    };
  };

  resolveDate(value) {
    let paramswithPage = {
      ...value,
      numeroPagina: 0, 
      quantidadeRegistrosPagina: 30
    }
    this.params = JSON.stringify(paramswithPage);
  }

  showDetail(event) {
    this.emitDetail.emit(event);
  }
}
