import { Injectable } from '@angular/core';
import { PaymentRepository } from '@src/core/repositories/payment/payment.repository';
import {
  PaymentModel,
  PaymentBoletoRequest,
  PaymentRequest,
  PaymentDeleteRequest,
  PaymentMultipleRequest,
  PaymentGetResponse,
  PaymentGetBoletoResponse,
  PaymentResponse,
  PaymentDetalhesBoletoRequest,
  PaymentGetBoletoDetalhadoResponse,
  PaymentAprovadorResponse
} from '@src/core/domain/payment/payment.model';
import { Observable, of, pipe, throwError } from 'rxjs';
import { PaymentRepositoryMapper } from '@src/data/repository/payment/payment-repository.mapper';
import { HttpClient } from '@angular/common/http';
import { PaymentEntity } from '@src/data/repository/payment/payment.entity';
import { concatMap, delay, flatMap, map, retryWhen } from 'rxjs/operators';


// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';
import { DdaBoletoModel, DdaBoletoStatusEnum } from "@src/core/domain/dda/dda-boleto.model";
import { DdaBoletoConsultaModel } from "@src/core/domain/dda/dda-consulta-boleto.model";
import { DdaBoletosStatusErroResponse } from "../../../core/domain/dda/dda-boletos-status-erro-response.js";
import { DdaBoletosStatusErroModel } from "../../../core/domain/dda/dda-boletos-status-erro.js";

const URLBase = environment.URLBase;

@Injectable({
  providedIn: 'root'
})


export class PaymentDataRepository extends PaymentRepository { 
  
  mapper = new PaymentRepositoryMapper();

  constructor(
    private http: HttpClient
  ) {
    super();
  }

  getBoleto(request: PaymentBoletoRequest): Observable<PaymentModel<PaymentGetBoletoResponse>> {

    const httpOptions = {
      headers: getHeader(),
    };

    return this.http
      .get<PaymentEntity>(`${environment.URLBase}v1/pagamentos/consultas/boletos/${request.codigoBoleto}/${request.dt_pagamento}`, httpOptions)
      .pipe(retryWhen(errors => errors.pipe(concatMap((error, count) => { if (count < 3 && error.status === 504) { return of(error); } return throwError(error.error); }), delay(500))))
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }

  getBoletoDetalhado(request: PaymentBoletoRequest): Observable<PaymentModel<PaymentGetBoletoDetalhadoResponse>> {
    const httpOptions = { headers: getHeader() };

    return this.http
    .get<PaymentEntity>(`${environment.URLBase}v1/pagamentos/Consultas/BoletoDetalhado/${request.codigoBoleto}/${request.dt_pagamento}/${request.isDda}`, httpOptions)
      .pipe(retryWhen(errors => errors.pipe(concatMap((error, count) => { if (count < 3 && error.status === 504) { return of(error); } return throwError(error.error); }), delay(500))));
  }

  getStatusHourPayment(x: number): Observable<PaymentModel<{ status: "ABERTO" | "FECHADO" }>> {
    const httpOptions = { headers: getHeader() };

    return this.http
      .post<PaymentModel<{ status: "ABERTO" | "FECHADO" }>>(`${URLBase}v1/transacoes/ObtemStatusLimiteHorarioPagamento`, x, httpOptions)
  }

  postBoleto(params: PaymentRequest): Observable<PaymentModel<PaymentResponse>> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .post<PaymentEntity>(
        `${URLBase}v1/pagamentos/efetivar`, params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]));
  }

  saveBoletos(params: PaymentRequest): Observable<PaymentModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .post<PaymentEntity>(
        `${URLBase}v1/pagamentos/salvar`, params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }

  deleteBoletos(params: PaymentDeleteRequest): Observable<PaymentModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .delete<PaymentEntity>(
        `${URLBase}v1/pagamentos/delete/${params.num_operacao}`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }

  postMultipleBoleto(params: PaymentMultipleRequest): Observable<PaymentModel> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .post<PaymentEntity>(
        `${URLBase}v1/pagamentos/efetivarmultiplo`, params,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom));
  }

  getDetalhes(id: number): Observable<PaymentModel<PaymentGetResponse>> {
    const httpOptions = {
      headers: getHeader(),
    };
    return this.http
      .get<PaymentEntity>(
        `${URLBase}v1/pagamentos/${id}`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]));
  }

  listarBoletosDda(request: DdaBoletoConsultaModel): Observable<PaymentModel<DdaBoletoModel[]>> {

    const httpOptions = { headers: getHeader() };

    return this.http.post<PaymentModel<DdaBoletoModel[]>>(`${URLBase}v1/Pagamentos/Listar/BoletosDda`, request, httpOptions);
  }  

  listarAprovadores(pagamentoId: number): Observable<PaymentModel<PaymentAprovadorResponse[]>> {
    const httpOptions = { headers: getHeader() };

    return this.http.get<PaymentModel<PaymentAprovadorResponse[]>>(`${URLBase}v1/Pagamentos/Listar/Aprovadores/${pagamentoId}`, httpOptions);

  }

  getBoletosStatusErro(param: DdaBoletosStatusErroModel): Observable<PaymentModel<DdaBoletosStatusErroResponse>> {
    const httpOptions = { headers: getHeader() };

    return this.http.get<PaymentModel<DdaBoletosStatusErroResponse>>(`${URLBase}v1/Dda/Boletos/Status-Erros/${param.data_inicial}/${param.data_final}`, httpOptions);
  }
}
