import { PostTitleInformationUsecase } from "@src/core/usecases/charge/post-titleInformation.usecase";
import { Router } from "@angular/router";
import { Component, OnInit, ViewChild } from "@angular/core";
import { TitleInformationEntity } from "@src/data/repository/charge/title-information/title-information.entity";

// model
import { StepperModel } from "@src/core/domain/business-models";
import { TitleInformationRequest } from "@src/core/domain/charge/titleinformation.model";
import { SelectorDataShare } from "@src/data/repository/data-share-repository";
import { ToastErrorMessageComponent } from "../../components/toast-error-message/toast-error-message.component";
interface PayerModel {
  tipo_pessoa: string;
  num_cpf_cnpj: string;
  nome_sacado: string;
  desc_logradouro: string;
  cep: string;
  desc_numero: string;
  desc_complemento: string;
  desc_bairro: string;
  desc_cidade: string;
  uf: string;
  ativo: number;
}

@Component({
  selector: "fibra-page-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.scss"],
})
export class SummaryComponent implements OnInit {
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;

  step: StepperModel[] = [
    {
      step: 1,
      name: "Dados do pagador",
    },
    {
      step: 2,
      name: "Dados do Título",
    },
  ];

  step1: any;
  step2: any;

  drawer: any = {
    tipo_pessoa: "",
    num_cpf_cnpj: "",
    cep: "",
    desc_logradouro: "",
    desc_complemento: "",
    desc_numero: "",
    desc_bairro: "",
    desc_cidade: "",
    nome_sacado: "",
    ativo: 1,
    uf: "",
  };

  limitDiscountDate: any;
  dueDate: any;
  createDate: any;
  especie: string;

  especies: any[] = [];

  showModalValidation: boolean;
  requestDataApi: any;
  apiCallback: number;

  showModalConclusao: boolean;
  showDownload: boolean;
  base64Pdf: string;
  typeColorToast = "#ED6C6C";
  message: any;
  constructor(
    private route: Router,
    private postTitleInformation: PostTitleInformationUsecase,
    private shareSelector: SelectorDataShare
  ) {
    this.especies = JSON.parse(localStorage.getItem("especies"));
    console.log("Especies", this.especies);
  }

  ngOnInit() {
    this.setStepsData();
    this.setDrawerData();
    this.setDepedencyData();
  }

  setStepsData(): void {
    this.step1 = JSON.parse(localStorage.getItem("first_step"));
    this.step2 = JSON.parse(localStorage.getItem("second_step"));
  }

  setDrawerData(): void {
    if (localStorage.getItem("drawer_step") !== null) {
      this.drawer = JSON.parse(localStorage.getItem("drawer_step"));
    }
  }

  setDepedencyData(): void {
    if (this.step2) {
      this.limitDiscountDate = this.resolveDate(
        this.step2.titleInformation.limitDiscountDate
      );
      this.dueDate = this.resolveDate(this.step2.titleInformation.dueDate);
      this.createDate = this.resolveDate(this.step2.titleInformation.createAt);

      this.message = this.step2.titleInformation.message1 + this.step2.titleInformation.message2 +
      this.step2.titleInformation.message3 + this.step2.titleInformation.message4 + this.step2.titleInformation.message5;

      if (this.step2.titleInformation && this.step2.titleInformation.especie) {
        this.setEspecie(this.step2.titleInformation.especie);
      }
    }
    this.shareSelector.setValue("summary");
  }

  setEspecie(id: string): void {
    this.especie = this.especies.find((x) => x.value === id).name;
  }

  ShowToast(err: any): void {
    const isMessageServer: boolean = !!(
      err &&
      err.error &&
      err.error.message &&
      err.error.message.length > 0
    );
    isMessageServer &&
      this.toast &&
      this.toast.callModalMessage(null, err.error.message[0], null, null, null);
  }

  resolveDate(date: any) {
    if (date) {
      let dateAux: any;

      if (date.year) {
        dateAux = new Date(date.year, date.month - 1, date.day);
      } else {
        const splitDate = date.split("/");
        dateAux = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
      }

      return new Intl.DateTimeFormat("pt-BR").format(dateAux);
    }
  }

  sendInfo() {
    const payer: PayerModel = {
      tipo_pessoa: this.step1.personal.personType,
      num_cpf_cnpj: this.resolveMask(this.step1.personal.cpfCnpj),
      cep: this.resolveMask(this.step1.address.cep),
      desc_logradouro: this.step1.address.street,
      desc_complemento: this.step1.address.complement,
      desc_numero: this.step1.address.number,
      desc_bairro: this.step1.address.neighborhood,
      desc_cidade: this.step1.address.city,
      nome_sacado: this.step1.personal.draweeName,
      ativo: 1,
      uf: this.step1.address.state,
    };

    let drawer: PayerModel;

    if (this.drawer !== null) {
      // segundo if feito porque, antes ele n verificava se this.drawer.drawer estava
      // preenchido e assim não deixando avançar para fazer a chamada do back
      if (this.drawer.drawer !== undefined) {
        drawer = {
          ativo: 1,
          cep: this.resolveMask(this.drawer.drawerAddress.cep),
          desc_bairro: this.drawer.drawerAddress.neighborhood,
          desc_cidade: this.drawer.drawerAddress.city,
          desc_complemento: this.drawer.drawerAddress.complement,
          desc_logradouro: this.drawer.drawerAddress.street,
          desc_numero: this.drawer.drawerAddress.number,
          nome_sacado: this.drawer.drawer.draweeName,
          tipo_pessoa: this.drawer.drawer.personType,
          uf: this.drawer.drawerAddress.state,
          num_cpf_cnpj: this.resolveMask(this.drawer.drawer.cpfCnpj),
        };
      } else {
        drawer = null;
      }
    } else {
      drawer = null;
    }

    const params: TitleInformationRequest = {
      pagador: payer,
      sacador_avalista: this.drawer === null ? null : drawer,
      num_conta: this.step2.titleInformation.conta,
      num_conta_header: this.step2.titleInformation.conta,
      banco_correspondente: this.step2.titleInformation.banco,
      desc_carteira: this.step2.titleInformation.carteira,
      dt_emissao: this.resolveSendDate(this.step2.titleInformation.createAt),
      dt_vencimento: this.resolveSendDate(this.step2.titleInformation.dueDate),
      vlr_desconto: this.step2.titleInformation.discount,
      chave_nota_fiscal: this.step2.titleInformation.fiscalKey.replace(
        / /g,
        ""
      ),
      cod_moeda: this.step2.titleInformation.coin,
      cod_especie: this.step2.titleInformation.especie,
      meu_numero: this.step2.titleInformation.yourNumber,
      dt_limite: this.resolveSendDate(
        this.step2.titleInformation.limitDiscountDate
      ),
      // tslint:disable-next-line: radix
      qtd_dias_protesto: parseInt(
        this.step2.titleInformation.valueDays === ""
          ? 0
          : this.step2.titleInformation.valueDays
      ),
      desc_mensagem1: this.step2.titleInformation.message1,
      desc_mensagem2: this.step2.titleInformation.message2,
      desc_mensagem3: this.step2.titleInformation.message3,
      desc_mensagem4: this.step2.titleInformation.message4,
      desc_mensagem5: this.step2.titleInformation.message5,
      //protestar: 1,
      protestar: this.step2.titleInformation.protest,
      vlr_mora: this.step2.titleInformation.mora,
      dt_inicio_protesto: this.resolveStrDate(
        this.step2.titleInformation.protestDate
      ),
      vlr_titulo: this.step2.titleInformation.titleValue,
      vlr_multa: this.resolveMoney(this.step2.titleInformation.tax),
      ativo: 1,
      tipo_multa: this.step2.titleInformation.typeTax,
    };

    this.requestSendTitleInformation(params);
  }

  closeModal(): void {
    this.showModalValidation = false;
  }

  closeModalConclusao(): void {
    this.showModalConclusao = false;
  }

  editData() {
    localStorage.setItem("first_step_edit", JSON.stringify(this.step1));
    localStorage.setItem("second_step_edit", JSON.stringify(this.step2));
    localStorage.setItem("drawer_step_edit", JSON.stringify(this.drawer));

    localStorage.removeItem("first_step");
    localStorage.removeItem("second_step");
    localStorage.removeItem("drawer_step");

    this.route.navigate(["/charge-edit"]);
  }

  resolveSendDate(ngbDate) {
    if (ngbDate) {
      let jsDate: any;
      if (ngbDate.year) {
        jsDate = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
      } else {
        const splitDate = ngbDate.split("/");
        jsDate = new Date(splitDate[2], splitDate[1] - 1, splitDate[0]);
      }

      return jsDate.toISOString().slice(0, 19);
    }
  }

  resolveStrDate(date: string) {
    if (date == "") return null;
    const auxDate = date.split("/");
    return new Date(
      Number(auxDate[2]),
      Number(auxDate[1]) - 1,
      Number(auxDate[0])
    )
      .toISOString()
      .slice(0, 19);
  }

  resolveMask(str) {
    return str.replace(/[^0-9]+/g, "");
  }

  resolveMoney(str) {
    let valor: number;
    if (str) {
      let index = str.indexOf("R$") !== -1;
      if (index) {
        let compativelComParseFloat = str.replace("R$ ", "");
        compativelComParseFloat = compativelComParseFloat.replace(",", ".");
        valor = parseFloat(compativelComParseFloat);
      } else {
        let compativelComParseFloat = str.replace("$ ", "");
        compativelComParseFloat = compativelComParseFloat.replace(",", ".");
        valor = parseFloat(compativelComParseFloat);
      }
      return valor;
    }
  }

  success = (value: TitleInformationEntity) => {
    localStorage.removeItem("first_step");
    localStorage.removeItem("second_step");
    localStorage.removeItem("drawer_step");
    localStorage.removeItem("especies");
    this.showDownload = value.data.carteira.includes("CBONLINE");
    this.base64Pdf = value.data.base64;
    this.showModalConclusao = true;
  };

  error = (err) => {
    this.ShowToast(err);
    return console.log(err);
  };

  showMsgErrorDownload(): void {
    this.toast.callModalMessage(
      null,
      "Ocorreu um erro ao tentar baixar o PDF",
      null,
      null,
      null
    );
  }

  requestSendTitleInformation = (params: TitleInformationRequest) => {
    this.requestDataApi = params;
    this.apiCallback = 61;
    this.securityValidation();
  };

  securityValidation = () => {
    this.showModalValidation = true;
  };

  responseApi(value: any) {
    if (value.status === "success") {
      this.success(value);
    } else {
      this.error(value);
    }
  }
}
