<fibra-toast-error-message></fibra-toast-error-message>

<div  #tablebody class="bodyTable" [class.notempty]="!(hide && !loading)" *ngIf="releases != undefined && !loading">
    <ul *ngFor="let release of releases" class="row-list">
        <li class="date-sale row">
            <span class="date col-lg-5">{{ formatDate(release.data) }}</span>
            <span class="sale col-lg-7">
                Saldo do dia:
                <strong>
                    R$ {{isNull(release.vlr_saldo_dia) ? "0,0" : (release.vlr_saldo_dia | number: "1.2-2")}}
                </strong>
            </span>
        </li>
        <li class="list-group-item" *ngFor="let item of release.lancamentos">
            <p class="date-list">

                <img [src]="iconPath(item.cod_deb_cred, item.desc_tipo_transacao)" alt="" />
                <span class="details">
                    <span style="font-weight: 500;">{{ adjustDescriptionTransaction(item.desc_historico) | titlecase }}</span>
                    <span *ngIf="removeItem(item.desc_historico) && temDescricao(item) && HasRemetInfo(item.compl_det.desc_finalidade, item.compl_det.desc_nome, item.num_cpf_cnpj, item.compl_det.dados_conta.cod_agencia, item.compl_det.dados_conta.num_conta)" class="text-desc data-hj-suppress">{{ item.compl_det.desc_nome }}</span>
                    <span class="text-desc" *ngIf="ehPagTit(item)" data-hj-suppress>{{ item.compl_det.desc_finalidade }}</span>
                    <span class="text-desc" *ngIf="removeItem(item.desc_historico) && HasRemetInfo(item.compl_det.desc_finalidade, item.compl_det.desc_nome, item.num_cpf_cnpj, item.compl_det.dados_conta.cod_agencia, item.compl_det.dados_conta.num_conta)">CPF/CNPJ: {{ item.desc_historico.indexOf('Pix') > -1 || item.desc_historico.indexOf('PIX') > -1 ? item.num_cpf_cnpj : formatCpfCnpj(item.num_cpf_cnpj) }}</span>
                    <span *ngIf="temInstituicao(item) && HasRemetInfo(item.compl_det.desc_finalidade, item.compl_det.desc_nome, item.num_cpf_cnpj, item.compl_det.dados_conta.cod_agencia, item.compl_det.dados_conta.num_conta)" class="text-desc">Instituição: {{ item.compl_det.dados_conta.nome_banco }}</span>
                    <span class="text-desc" *ngIf="removeItem(item.desc_historico) && HasRemetInfo(item.compl_det.desc_finalidade, item.compl_det.desc_nome, item.num_cpf_cnpj, item.compl_det.dados_conta.cod_agencia, item.compl_det.dados_conta.num_conta)">CC: {{ formatAccountNumber(item.compl_det.dados_conta.num_conta) }} | AG: {{
                        item.compl_det.dados_conta.cod_agencia?.padStart(4, '0') }}</span>
                    <span *ngIf="temChave(item)" class="text-desc">Chave: {{ item.compl_det.dados_conta.chave }}</span>
                </span>

                <span class="value" [ngClass]="{ green: item.cod_deb_cred === 'C', red: item.cod_deb_cred === 'D' }">R$ <span
                        *ngIf="item.cod_deb_cred === 'D'" style="margin-left: 5px;">-</span>{{ item.vlr_lancamento | money }}
                    <!-- <img *ngIf="temComprovante(item)" src="assets/svg/comprovante.svg" alt="Comprovante" style="cursor: pointer;" (click)="sendReceipt(item)" /> -->
                </span>
            </p>

            <!-- Insere os dados do remetente da TEF/TED -->
            <!-- <div *ngIf="!isPix(item) && HasRemetInfo(item.compl_det.desc_finalidade, item.compl_det.desc_nome, item.num_cpf_cnpj, item.compl_det.dados_conta.cod_agencia, item.compl_det.dados_conta.num_conta)" style="font-size:13px;margin-top: 5px;margin-left:2.5%;color: #8F969D;">
                {{ nomeRemet }} <br /> {{ cpfCnpj }} <br /> {{ agencia }} <br /> {{ conta }}
            </div> -->
        </li>
    </ul>

    <div class="d-flex justify-content-center" *ngIf="loadingMore">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Carregando...</span>
        </div>
    </div>
</div>

<!-- Loading -->
<div class="d-flex justify-content-center position loading-container" *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div class="empty-info" *ngIf="hide && !loading">
    <fibra-generic-info type="warn" title="Nenhum resultado encontrado" message="Tente uma nova pesquisa inserindo novos parâmetros.">
    </fibra-generic-info>
</div>