import { PostAcceptTermUsecase } from '@src/core/usecases/accept-term/accept-term.usecase';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StepperModel } from '@src/core/domain/business-models';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { RolesService } from '@src/shared/services/roles.service';
import { ModalShareDataUsecase } from '@src/core/usecases/modal-share-data/modal-share-data.usecase';

@Component({
  selector: 'fibra-modal-share-data',
  templateUrl: './modal-share-data.component.html',
  styleUrls: ['./modal-share-data.component.scss']
})
export class ModalShareDataComponent implements OnInit {

  @ViewChild('content', {static: false}) content: ElementRef;
  @Input() noAgreed: boolean;

  public checkAgreed;
  public showModalValidation = false;
  public varSubscriptTerm: Subscription;
 
  constructor(
    private modalService: NgbModal,
    private modalShareDataUsecase: ModalShareDataUsecase,
    public modal: NgbActiveModal
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.openModal(this.content);
    this.controlerHeightModal();
    
  }

  public openModal(content): void {
    this.showModalValidation = false;
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      backdrop: 'static'
    });
  }

  public closeModalTerm(): void {
    this.postAgreed();
    this.modalService.dismissAll();
  }

  public controlerHeightModal() {
    const modalContent = Array.from(document.getElementsByClassName("modal-content") as HTMLCollectionOf<HTMLElement>);
    modalContent.forEach(res => {
      res.style.setProperty('height', '600px');
    })
  }
  
  unsubscribeVariable(variable) {
    if(variable) {
      variable.unsubscribe();
    }
    return;
  }

  postAgreed() {
    const body = {
      valorAceite: this.noAgreed
    }
    sessionStorage.setItem('agreedModalShareData','true');
    this.unsubscribeVariable(this.varSubscriptTerm);

    this.varSubscriptTerm = this.modalShareDataUsecase.execute(body)
      .subscribe(
          this.sucessTerm,
          this.errorTerm
      );
  }

  sucessTerm = (value) => {
    console.log('success');
  }

  errorTerm = (err) => {
    console.log(err);
  }
}
