import { LimitsDisclaimerUsecase } from './../../../../core/usecases/limits-disclaimer/limits-disclaimer.usecase';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { finalize, debounceTime } from 'rxjs/operators';
import { PixTransferDataShare } from '@src/core/usecases/data-share/pix-transfer-data-share.service';
import { PixAccountEntity } from '@src/data/repository/pix/accounts/pix-accounts-entity';
import { PixGetAccountsDataRepository } from '@src/data/repository/pix/accounts/pix-get-accounts-repository';
import { PixGetKeysDataRepository } from '@src/data/repository/pix/keys/pix-get-keys-repository';
import { KeysEntity } from '@src/data/repository/pix/keys/pix-key-entity';
import { PixGetBeneficiariesDataRepository } from '@src/data/repository/pix/beneficiaries/pix-get-beneficiaries-repository';
import { PixBeneficiarioFavoritoDataShare } from '@src/core/usecases/data-share/pix-beneficiario-favorito-data-share.service';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { AccountPixDataShare } from '@src/core/usecases/data-share/account-pix-data-share.service';
import { RolesService } from '@src/shared/services/roles.service';
import { PixGetLimitesDataRepository } from '@src/data/repository/pix/limites/pix-get-limites-repository';
import * as Util from '@src/shared/util-common';
import * as moment from 'moment';

@Component({
  selector: 'fibra-pix-new-transfer',
  templateUrl: './pix-new-transfer.component.html',
  styleUrls: ['./pix-new-transfer.component.scss']
})
export class PixNewTransferComponent implements OnInit, OnDestroy {
  
  private _sub: Subscription;

  public array = [1, 2, 3]
  public cadastro = true;
  public selected = false;
  public loading = false;
  public showMiniLoad = false;
  public showNoKeyMsg = false;
  public keyToSearch;
  public key: KeysEntity;
  public contaToDebit;
  public cpfCnpjMask;
  public keyType = '';
  public keyValue = ''
  public validCel = false;
  public keyFound = false;
  public loadingKey = false;
  public validEmail = false;
  public validDocument = false;
  public validAleatorio = false;
  public transferValue;
  public celMask = ['+', '55', '', '(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public accountData: PixAccountEntity[];
  public textArea: string;
  public errorSearchKey = false;
  public bodyTransfer;
  public dataSelected: any;
  public dataRepetirSelected: any;
  public saveFavorite: boolean = false;
  public verificaChave: boolean = false;
  public tipoChave: string;
  public bancoInfo;
  public favorito: boolean = false;
  public novaChave: boolean = false;
  public chequeEmpresaPix: boolean = false;
  public saldoDisponivel: boolean = true;
  public valorChequeEmpresa;
  public char = 40;
  public redBorder;
  public beneficiarioFavorito;
  public contaSelecionada: any;
  public valorLimiteConta = 0;
  public msgCharging = false;
  public permissoes;
  public vlLimAccount = 0;

  public checkRepeat;
  public radioWeek: boolean = false;
  public checkWeekDay: string;
  public resultWeekOrMonth: string;
  public resultDayofWeek: string;
  public agendamento;
  public showCheckRepeatTransaction: boolean = false;
  public stringDay: string = 'segunda-feira';
  public valueRadioWeek: string[] = [];
  public startDate: any;
  public labelDateRepeat;
  public validthirtyDays;
  public validChequeEmpresaDateIsAfterToday: boolean = false;
  public typePerson;
  private _isKeyValidated = false;
  public mesmaTitularidadeOutraTitularidade: string = '';
  public accountCPForCNPJ: string;
  public controllViewLimits: boolean;
  public showInfoDisclaimer = [];
  public validDisclaimer: boolean = false;
  public ocultDisclaimerInit: boolean = true;

  public formGroup: FormGroup;

  private get emailKey(): string {
    return <string>this.formGroup.get('emailKey').value;
  }

  private set emailKey(value: string) {
    this.formGroup.get('emailKey').setValue(value);
  }

  private get celKey(): string {
    return <string>this.formGroup.get('celKey').value;
  }

  private set celKey(value: string) {
    this.formGroup.get('celKey').setValue(value);
  }

  private get documentKey(): string {
    return <string>this.formGroup.get('documentKey').value;
  }

  private set documentKey(value: string) {
    this.formGroup.get('documentKey').setValue(value);
  }

  private get aleatorioKey(): string {
    return <string>this.formGroup.get('aleatorioKey').value;
  }

  private set aleatorioKey(value: string) {
    this.formGroup.get('aleatorioKey').setValue(value);
  }

  public maxDate: any;
  public minDate: any;

  errDates: boolean = false;
  errDateType: string;
  errDatesRepeat: boolean = false;
  errDateTypeRepeat: string;

  constructor(
    public getAccService: PixGetAccountsDataRepository,
    public transferShare: PixTransferDataShare,
    public verifyKeyService: PixGetKeysDataRepository,
    public beneficiarioShare: PixBeneficiarioFavoritoDataShare,
    public getBeneficiariesDataRepository: PixGetBeneficiariesDataRepository,
    private sharePixAccount: AccountPixDataShare,
    private roles: RolesService,
    private fb: FormBuilder,
    private limitesService: PixGetLimitesDataRepository,
    private calendar: NgbCalendar,
    private limitDisclaimer: LimitsDisclaimerUsecase
  ) {
    
  }

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  ngOnInit() {
    this.formGroup = this.fb.group({
      emailKey: [''],
      celKey: [''],
      documentKey: [''],
      aleatorioKey: [''],
    });

    const onKeyChange = (value: string) => {
      this.errorSearchKey = this.showMiniLoad = this.keyFound = this._isKeyValidated = false;

      if (value && value.trim() != '') {
        this.inputValidator();
        this.verifyInputKey(value);
      }
    };

    this.formGroup.get('emailKey').valueChanges.pipe(debounceTime(100)).subscribe(onKeyChange);
    this.formGroup.get('celKey').valueChanges.pipe(debounceTime(100)).subscribe(onKeyChange);
    this.formGroup.get('documentKey').valueChanges.pipe(debounceTime(100)).subscribe(onKeyChange);
    this.formGroup.get('aleatorioKey').valueChanges.pipe(debounceTime(100)).subscribe(onKeyChange);

    this.maxDate = { year: new Date().getFullYear() + 1, month: new Date().getMonth() + 1, day: new Date().getDate() }
    this.minDate = this.calendar.getToday();
    this.permissoes = this.roles.get();
    this.sharePixAccount.dataShared.subscribe((res) => (this.accountData = res));
    var beneficiario = this.beneficiarioShare.getValue();

    if (this.dataSelected === undefined) {
      const data = new Date();
      this.dataSelected = data.toJSON();
    }

    this.removeAccountsWithoutTems();

    if (beneficiario && beneficiario.favorito) {
      this.favorito = true;

      if (beneficiario.chaveInfo.tipo_chave == 'CNPJ' || beneficiario.chaveInfo.tipo_chave == 'CPF') {
        this.selectKeyType('document');
        this.documentKey = beneficiario.chaveInfo.chave
        this.inputValidator();
        this.verifyInputKey(beneficiario.chaveInfo.chave);
      } else {
        if (beneficiario.chaveInfo.tipo_chave == 'EMAIL') {
          this.emailKey = beneficiario.chaveInfo.chave
        } else if (beneficiario.chaveInfo.tipo_chave == 'PHONE') {
          this.celKey = beneficiario.chaveInfo.chave
        } else {
          this.aleatorioKey = beneficiario.chaveInfo.chave
        }

        this.selectKeyType(beneficiario.chaveInfo.tipo_chave)
        this.inputValidator();
        this.verifyInputKey(beneficiario.chaveInfo.chave);
      }
    } else {
      if (beneficiario && beneficiario.novaChave) {
        this.beneficiarioFavorito = beneficiario;
        this.novaChave = true;
      } else {
        this.novaChave = false;
      }

      this.favorito = false;
    }
    this.beneficiarioShare.deleteValue();
  }

  ngOnDestroy(): void {
    if (this._sub) {
      this._sub.unsubscribe();
    }
  }

  public selectKeyType(key: string) {
    this.validCel = this.validDocument = this.validEmail = this.validAleatorio = this._isKeyValidated = false;

    if (this.permissoes.includes('PIX.TRANSF_PIX_CHAVE')) {
      if (key === 'EMAIL') {
        this.keyType = 'EMAIL'
        this.selected = true;
        this.showMiniLoad = false;
      } else if (key === 'document') {
        this.keyType = 'CNPJ/CPF';
        this.selected = true;
        this.showMiniLoad = false;
      } else if (key === 'PHONE') {
        this.keyType = 'TELEFONE'
        this.selected = true;
        this.showMiniLoad = false;
      } else {
        this.keyType = 'ALEATORIO'
        this.selected = true;
        this.showMiniLoad = false;
      }
    } else {
      this.toast.callModalMessage(null, "Acesso negado. Você não possui acesso a essa funcionalidade. Solicite ao(s) usuário(s) master(s) de sua empresa.");
    }
  }

  public getAccounts() {
    this.getAccService.getAccounts().subscribe((accs) => {
      this.accountData = accs.data;
      this.loading = true;
    })
  }

  removeAccountsWithoutTems() {
    this.getAccService.getAccountsWithNoTerms().subscribe(rs => {
      if(rs && rs.data.length > 0) {
        for (const dataWithoutPix of rs.data) {
          const accountWhithoutTerm = this.accountData.findIndex((key: any) => key.num_conta === dataWithoutPix.num_conta);
          if (accountWhithoutTerm !== -1) {
            this.accountData.splice(accountWhithoutTerm, 1);
          }
        }
      }
    })
  }

  public setAccountDebit(event: any) {
    this.getLimitDiscalimer(event.num_conta);
    if (event && this.key && event.num_conta === this.key.informacao_adicional_conta.conta) {
      this.toast.callModalMessage(null, 'A conta de débito deve ser diferente da conta selecionada para realizar o crédito.', null);
      this.contaSelecionada = (Math.random() * 1000) * -1;
    } else {
      this.contaToDebit = event;
    }
  }

  public setTransferInfos() {
    if (this.keyType === 'EMAIL') {
      this.setBody(this.emailKey);
      this.tipoChave = "EMAIL"
    } else if (this.keyType === 'CNPJ/CPF') {
      this.setBody(this.documentKey);
      if (this.documentKey.length > 11) {
        this.tipoChave = "CNPJ"
      } else {
        this.tipoChave = "CPF"
      }
    } else if (this.keyType === 'TELEFONE') {
      this.setBody(this.celKey);
      this.tipoChave = "PHONE"
    } else if (this.keyType === 'ALEATORIO') {
      this.setBody(this.aleatorioKey);
      this.tipoChave = "EVP"
    }
    if (this.saveFavorite) {
      const params = {
        nome_beneficiario: this.bodyTransfer.beneficiario.nome,
        tipo_chave: this.tipoChave,
        chave: this.keyToSearch,
        agencia: this.bodyTransfer.beneficiario.agencia,
        cod_banco: this.bodyTransfer.beneficiario.cod_banco,
        num_conta: this.bodyTransfer.beneficiario.conta,
        nome_banco: this.bodyTransfer.beneficiario.banco,
        tipo_conta: this.bodyTransfer.beneficiario.tipo_conta,
        conta_mascara: this.bodyTransfer.beneficiario.conta_mascara,
        agencia_mascara: this.bodyTransfer.beneficiario.agencia_mascara
      }
      this.getBeneficiariesDataRepository.postPixBeneficiariosNovo(params).subscribe((res) => {
      }, (err) => {
      })
    }
    this.transferShare.setValue(this.bodyTransfer);
    if (this.bodyTransfer.contaDebito.nome == 'CHEQUE EMPRESA') {
      this.chequeEmpresaPix = this.UsaChequeEmpresa()
      this.bodyTransfer.confirme_linha_credito = this.chequeEmpresaPix;
    }
    if (!this.chequeEmpresaPix && this.saldoDisponivel) {
      localStorage.setItem('view-selecionada', 'transferir-confirmTransfer');
      window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
    }

  }

  public changePeriodDate(evento) {
    let dateToday = new Date();
    this.checkPastDate(evento)
    if (evento.toString().indexOf('/') > -1) { 
      let dateCorrection = evento.split("/");
      evento = new NgbDate(parseInt(dateCorrection[2], 10), parseInt(dateCorrection[1], 10), parseInt(dateCorrection[0], 10));
    }
    
    const data = `${evento.year}-${evento.month}-${evento.day}`;
    this.dataSelected = data;

    if(moment(this.dataSelected).isAfter(dateToday.toJSON())) {
      this.validChequeEmpresaDateIsAfterToday = true;
    }

    if(!moment(this.dataSelected).isAfter(dateToday.toJSON())) {
      this.validChequeEmpresaDateIsAfterToday = false;
    }
    
    if(this.dataRepetirSelected) {
      let isDay = moment(this.dataRepetirSelected).diff(moment(this.dataSelected).format(), 'days');
      if (isDay <= 0) {
        this.errDatesRepeat = true;
        this.errDateTypeRepeat = 'Data inválida. Informe uma data maior que a data de transferência.';
        return;
      }

      if(this.resultWeekOrMonth == 'mensal') {
        this.dataRepetirSelected = moment(this.dataSelected).add(30,'days');
        this.validthirtyDays = this.dataRepetirSelected;
        this.startDate = {year: new Date(this.dataRepetirSelected).getFullYear(), month: new Date(this.dataRepetirSelected).getMonth() + 1, day: new Date(this.dataRepetirSelected).getDate()};
        this.getDateLabel(this.dataRepetirSelected);
        this.errDatesRepeat = false;
        return;
      }
      
      if(this.resultWeekOrMonth == 'semanal') {
        this.dataRepetirSelected = moment(this.dataSelected).add(7,'days');
        this.validthirtyDays = this.dataRepetirSelected;
        this.startDate = {year: new Date(this.dataRepetirSelected).getFullYear(), month: new Date(this.dataRepetirSelected).getMonth() + 1, day: new Date(this.dataRepetirSelected).getDate()};
        this.getDateLabel(this.dataRepetirSelected);
        return;
      }

      this.errDatesRepeat = false;
    }
  }

  public changePeriodDateRepeat(evento) {
    this.checkPastDateRepeat(evento)
    if (evento.toString().indexOf('/') > -1) { 
      let dateCorrection = evento.split("/");
      evento = new NgbDate(parseInt(dateCorrection[2], 10), parseInt(dateCorrection[1], 10), parseInt(dateCorrection[0], 10))
    }
    const data = new Date(`${evento.year}-${evento.month}-${evento.day}`);
    this.dataRepetirSelected = data.toJSON();
    this.getDateLabel(this.dataRepetirSelected);
  }

  public verifyInputKey(key: string) {
    if (!this._isKeyValidated) {

      if (this._sub) {
        this._sub.unsubscribe();
      }

      if (this.validCel || this.validDocument || this.validEmail || this.validAleatorio) {

        this.keyToSearch = key;

        if (this.keyType === 'TELEFONE') {
          this.keyToSearch = `+${key.replace(/[^0-9\/]/g, '')}`;
        } else if (this.keyType === 'CNPJ/CPF') {
          this.keyToSearch = this.documentKey.replace(/[^0-9\/]/g, '').replace('/', '');
        }

        this.keyFound = false;
        this.showMiniLoad = true;
        this.loadingKey = true;

        this._sub = this.verifyKeyService.verifyKey(this.keyToSearch)
          .pipe(finalize(() => this._isKeyValidated = true))
          .subscribe(
            res => {
              this.key = res.data;
              this.keyFound = true;
              this.verificaChave = true;

              this.getBeneficiariesDataRepository.getPixBancoChave(res.data.identificacao_psp)
                .pipe(finalize(() => this.loadingKey = false))
                .subscribe((resBancoChave) => {
                  this.bancoInfo = resBancoChave.data[0];
                });

                if(this.controllViewLimits && this.key) {
                  this.validationMesmaTitulariadeOrOutratitularidade();
                }
            },
            () => {
              this.loadingKey = false;
              this.errorSearchKey = true;
            });
      }
    }
  }

  public setBody(_key: string) {
    moment().locale('pt-br');
    let checkIfSchedulle = moment(moment()).isBefore(this.dataSelected, 'day');
    if(this.showCheckRepeatTransaction) {
      checkIfSchedulle = true;
    }

    this.bodyTransfer = {
      beneficiario: {
        nome: this.key.titular_conta.nome,
        cpf_cnpj_mascara: this.key.titular_conta.cpf_cnpj_mascara,
        cod_banco: this.bancoInfo.cod_ispb ? this.bancoInfo.cod_ispb : "",
        banco: this.bancoInfo.nome_banco ? this.bancoInfo.nome_banco : "",
        agencia: this.key.informacao_adicional_conta.agencia,
        conta: this.key.informacao_adicional_conta.conta,
        tipo_conta: this.key.informacao_adicional_conta.tipo_conta,
        conta_mascara: this.key.informacao_adicional_conta.conta_mascara,
        agencia_mascara: this.key.informacao_adicional_conta.agencia_mascara
      },
      repetir: {
        semanaOuMes: '',
        dias: '',
        repetir:'',
      },
      flg_agendamento: checkIfSchedulle,
      stringDay: this.stringDay,
      chave: this.keyToSearch,
      valor: this.transferValue,
      data: this.dataSelected,
      descricao: this.textArea,
      favorito: false,
      email_operador: localStorage.getItem('useremail'),
      nome_operador: localStorage.getItem('username'),
      contaDebito: {
        cod_banco: 224,
        nome: this.contaToDebit.desc_conta,
        conta: this.contaToDebit.num_conta,
        agencia: this.contaToDebit.cod_agencia,
        cpf_cnpj: JSON.parse(localStorage.getItem('batch-company-name')).cpfCnpj,
        exibicao: this.contaToDebit.exibicao
      },
      callback: checkIfSchedulle ? 123 : 25,
      pagRetorno: 'transferir-newtransfer',
      confirme_linha_credito: this.chequeEmpresaPix
    }

    if(this.resultWeekOrMonth) {
      this.bodyTransfer.repetir = {
        semanaOuMes: this.resultWeekOrMonth,
        dias: this.resultDayofWeek,
        repetir: this.dataRepetirSelected
      };
      checkIfSchedulle = true;
    }
    this.transferShare.setValue(this.bodyTransfer);
  }

  public inputValidator() {
    if (this.keyType === 'EMAIL') {
      this.validEmail = this.emailKey && this.validacaoEmail();
    } else if (this.keyType === 'CNPJ/CPF') {
      this.validDocument = this.documentKey && (this.documentKey.length === 11 || this.documentKey.length === 14 || this.documentKey.length === 18);
    } else if (this.keyType === 'TELEFONE') {
      this.validCel = this.celKey && !(this.celKey.length < 15 && !this.favorito);
    } else if (this.keyType === 'ALEATORIO') {
      this.validAleatorio = this.aleatorioKey && this.aleatorioKey.length == 36;
    }
  }

  validacaoEmail() {
    const usuario = this.emailKey.substring(0, this.emailKey.indexOf("@"));
    const dominio = this.emailKey.substring(this.emailKey.indexOf("@") + 1, this.emailKey.length);
    if ((usuario.length >= 1) &&
      (dominio.length >= 3) &&
      (usuario.search("@") == -1) &&
      (dominio.search("@") == -1) &&
      (usuario.search(" ") == -1) &&
      (dominio.search(" ") == -1) &&
      (dominio.search(".") != -1) &&
      (dominio.indexOf(".") >= 1) &&
      (dominio.lastIndexOf(".") < dominio.length - 1)) {
      return true;
    }
    else {
      return false;
    }
  }

  public goBack() {
    this.resetValores()
    if (!this.selected) {
      localStorage.setItem('view-selecionada', 'transferir-beneficiario');
      window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
    } else {
      this.selected = false;
    }
  }

  public resetValores() {
    this.verificaChave = false;
    this.showInfoDisclaimer = [];
    this.transferValue = null;
    this.contaToDebit = null;
    this.key = null;
    this.emailKey = null;
    this.aleatorioKey = null;
    this.celKey = null;
    this.documentKey = null;
    this.textArea = null;
    this.mesmaTitularidadeOutraTitularidade = '';
    this.accountCPForCNPJ = '';
    this.controllViewLimits = false;
    this.validChequeEmpresaDateIsAfterToday = false;
    this.validDisclaimer = false;
    this.ocultDisclaimerInit = true;
  }

  public formatCpf() {
    if (this.documentKey.length < 14) {
      this.cpfCnpjMask = [
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ];
    } else if (this.documentKey.length === 14) {
      this.cpfCnpjMask = [
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ];
    }
  }

  selectedAgenciaConta() {
    if (this.permissoes.includes('PIX.TRANSF_PIX_MSM_TIT') && this.permissoes.includes('PIX.TRANS_PIX_OTR_TIT') && this.permissoes.includes('PIX.TRANSF_PIX_MSM_TIT_BC') && this.permissoes.includes('PIX.TRANSF_PIX_OTR_TIT_BC')) {
      localStorage.setItem('view-selecionada', 'transferir-listBancos');
      window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
    } else {
      this.toast.callModalMessage(null, "Acesso negado. Você não possui acesso a essa funcionalidade. Solicite ao(s) usuário(s) master(s) de sua empresa.");
    }
  }

  setFavorite() {
    var save = document.getElementById('checkbox1')
    if (save['checked'] == true) {
      this.saveFavorite = true;
    } else {
      this.saveFavorite = false;
    }
  }

  validationInfo() {
    return !(this.verificaChave &&
      this.transferValue > 0 &&
      this.contaToDebit) || (this.errDates || this.errDatesRepeat);
  }

  public closeModalPix(value) {
    if (value == 'sim') {
      localStorage.setItem('view-selecionada', 'transferir-confirmTransfer');
      window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
    } else if (value == 'cancelar') {
      this.chequeEmpresaPix = false;
    }
  }

  public UsaChequeEmpresa() {
    this.saldoDisponivel = true;
    let vlr_cheque_disponivel = this.contaToDebit.saldo.vlr_cheque_disponivel;

    if (this.contaToDebit.saldo.vlr_limite == 0) {
      vlr_cheque_disponivel = 0;
    }
    
    if (this.bodyTransfer.valor > (this.contaToDebit.saldo.vlr_saldo_calculado - this.contaToDebit.saldo.vlr_bloqueado) && !this.validChequeEmpresaDateIsAfterToday) {
      var valorMenosSaldo = 0;
      
      if ((this.contaToDebit.saldo.vlr_saldo_calculado - this.contaToDebit.saldo.vlr_bloqueado) < 0) {
        valorMenosSaldo = this.bodyTransfer.valor
      } else {
        valorMenosSaldo = this.bodyTransfer.valor - (this.contaToDebit.saldo.vlr_saldo_calculado - this.contaToDebit.saldo.vlr_bloqueado);
      }
      
      if (valorMenosSaldo > 0 && vlr_cheque_disponivel < valorMenosSaldo) {
        this.toast.callModalMessage(null, 'Saldo Indisponível!', null);
        this.saldoDisponivel = false;
        return false;
      } else if (valorMenosSaldo == 0) {
        this.saldoDisponivel = true;
        return false;
      } else {
        this.valorChequeEmpresa = valorMenosSaldo;
        this.saldoDisponivel = true;
        return true;
      }
    } else {
      return false;
    }
  }

  limitTextarea(valor) {
    const quant = 40;
    const total = valor.length;
    this.redBorder = valor.length;

    if (total <= quant) {
      this.char = quant - total;
    } else {
      this.char = valor.substr(0, quant);
    }
  }

  initialLetters(name) {
    name = name.replace(/\s(de|da|dos|das)\s/g, ' ');
    const initials = name.match(/\b(\w)/gi);
    return (
      initials[0] + (initials[1] !== undefined ? initials[1] : initials[0])
    ).toUpperCase();
  }

  changeAmmount() {
    if (this.transferValue <= 0) {
      this.toast.callModalMessage(null, null, 'Valor inválido.');
    }
  }

  erroLimite(value){ 
  this.validationMesmaTitulariadeOrOutratitularidade();
  this.msgCharging = true;

  if(value && value.code == 200 && value.data.vlr_permitido !== 0){
   this.valorLimiteConta = value.data.vlr_permitido;
   this.msgCharging = false;
  }else if(value && value.code == 200 && value.data.vlr_permitido == 0){
    this.getLimitesData();
  }else{
    this.toast.callModalMessage(null, 'Houve um erro no retorno do valor do seu limite. tente novamente');
    this.msgCharging = false;
  }
}

  checkPastDate(ev) {
    moment.locale('pt-br');
    var valueDate: any;
    if (typeof ev === 'object') {
      valueDate = ev.day + '/' + ev.month + '/' + ev.year;
    } else {
      valueDate = ev;
    }

    let dateFormat = moment(valueDate, 'D/M/YYYY', true).format('DD/MM/YYYY');
    let validDate = moment(dateFormat, 'DD/MM/YYYY', true).isValid();
    let conv = moment(dateFormat, 'DD/MM/YYYY').format('YYYY-MM-DD');
    let bfDay = moment(conv).isBefore(moment(), 'day');
    let isBigger = moment(conv).diff(moment(), 'year');
    
    if (!validDate || bfDay) {
      this.errDates = true;
      this.errDateType = 'Data inválida. Informe uma data igual ou maior que a data atual.';
      return;
    }

    if (validDate && isBigger > 0) {
      this.errDates = true;
      this.errDateType = 'Data inválida.';
      return;
    }
    this.errDates = false;
  }

  checkPastDateRepeat(ev) {
    moment.locale('pt-br');
    var valueDate: any;
    if (typeof ev === 'object') {
      valueDate = ev.day + '/' + ev.month + '/' + ev.year;
    } else {
      valueDate = ev;
    }
    let dateFormat = moment(valueDate, 'D/M/YYYY', true).format('DD/MM/YYYY');
    let validDate = moment(dateFormat, 'DD/MM/YYYY', true).isValid();
    let conv = moment(dateFormat, 'DD/MM/YYYY').format('YYYY-MM-DD');
    let bfDay = moment(conv).isBefore(this.dataSelected, 'day');
    let isBigger = moment(conv).diff(moment(), 'year');
    let isDay = moment(conv).diff(moment(this.dataSelected).format('YYYY-MM-DD'), 'days');
    let thirtyDay = moment(conv).diff(moment(this.validthirtyDays).format('YYYY-MM-DD'), 'days');
    
    if (!validDate || bfDay) {
      this.errDatesRepeat = true;
      this.errDateTypeRepeat = 'Data inválida. Informe uma data maior que a data de transferência.';
      return;
    }

    if (isDay <= 0) {
      this.errDatesRepeat = true;
      this.errDateTypeRepeat = 'Data inválida. Informe uma data maior que a data de transferência.';
      return;
    }

    if (!validDate || isBigger > 0) {
       this.errDatesRepeat = true;
      this.errDateTypeRepeat = 'Data inválida.';
      return;
    }

    if (thirtyDay < 0 && this.resultWeekOrMonth == 'mensal') {
      this.errDatesRepeat = true;
     this.errDateTypeRepeat = 'Data inválida. Informe uma data maior que 30 dias da data de transferência';
     return;
   }
    this.errDatesRepeat = false;

  }

repeatTransaction() {
  moment().locale('pt-br');
  if(!this.checkRepeat){
    this.radioWeek = false;
    this.resultWeekOrMonth = 'mensal';
    this.dataRepetirSelected = moment(this.dataSelected).add(30,'days');
    this.showCheckRepeatTransaction = true;
    this.startDate ={year: new Date(this.dataRepetirSelected).getFullYear(), month: new Date(this.dataRepetirSelected).getMonth() + 1, day: new Date(this.dataRepetirSelected).getDate()};
    this.dataRepetirSelected = this.dataRepetirSelected.toJSON();
    this.validthirtyDays = this.dataRepetirSelected;
    this.getDateLabel(this.dataRepetirSelected);
  }else {    
    this.resultWeekOrMonth = '';
    this.resultDayofWeek = '';
    this.dataRepetirSelected = '';
    this.errDatesRepeat = false;
    this.showCheckRepeatTransaction = false;
    this.labelDateRepeat = '';
    this.validthirtyDays = '';
  }
}

radioWeekOrMonth(value) {
  this.radioWeek = false;
  if(value == 'semanal') {
    this.radioWeek = true;
    this.resultWeekOrMonth = value;
    this.resultDayofWeek = 'segunda';
    this.errDatesRepeat = false;
    this.dataRepetirSelected = moment(this.dataSelected).add(7,'days');
    this.startDate ={year: new Date(this.dataRepetirSelected).getFullYear(), month: new Date(this.dataRepetirSelected).getMonth() + 1, day: new Date(this.dataRepetirSelected).getDate()};
    this.dataRepetirSelected = this.dataRepetirSelected.toJSON();
    this.validthirtyDays = this.dataRepetirSelected;
    this.getDateLabel(this.dataRepetirSelected);
  }else {
    this.radioWeek = false;
    this.resultWeekOrMonth = value;
    this.errDatesRepeat = false;
    this.dataRepetirSelected = moment(this.dataSelected).add(30,'days');
    this.startDate ={year: new Date(this.dataRepetirSelected).getFullYear(), month: new Date(this.dataRepetirSelected).getMonth() + 1, day: new Date(this.dataRepetirSelected).getDate()};
    this.dataRepetirSelected = this.dataRepetirSelected.toJSON();
    this.validthirtyDays = this.dataRepetirSelected;
    this.getDateLabel(this.dataRepetirSelected);
  }
 }

 radioWeekSelected(value) {
  this.resultDayofWeek = value;
  this.returnStringDays(value);
 }

 returnStringDays(day) {
   switch(day) {
    case 'segunda':
      this.stringDay = 'segunda-feira';
      break;
    case 'terca':
      this.stringDay = 'terça-feira';
      break;
    case 'quarta':
      this.stringDay = 'quarta-feira';
      break;
    case 'quinta':
      this.stringDay = 'quinta-feira';
      break;
    case 'sexta':
      this.stringDay = 'sexta-feira';
      break;
    case 'sabado':
      this.stringDay = 'sábado';
      break;
    case 'domingo':
      this.stringDay = 'domingo';
      break;
   }
 }

 getDateLabel(date) {
  if (typeof date === 'object') {
    date = date.toJSON();
  }
  let dateLabel = date.split("T");
    dateLabel = dateLabel[0].split("-");
    this.labelDateRepeat = this.labelDateRepeat = `${dateLabel[2]}/${dateLabel[1]}/${dateLabel[0]}`;
 }


 public getLimitesData() {
  this.limitesService.getPixLimites().subscribe((terms: any) => {
    terms.data.find((p) => {
      p.conta.numero === this.contaToDebit;
      this.vlLimAccount = p.conta.vl_permitido;
      this.valorLimiteConta = this.vlLimAccount;
      this.msgCharging = false;
      return this.valorLimiteConta;
    });
  });
}

public getPersonForPJ() {
  this.typePerson = JSON.parse(sessionStorage.getItem('defaultCompany')); 
  return this.typePerson[0];
}

public validationMesmaTitulariadeOrOutratitularidade() {
  this.controllViewLimits = true;
  this.accountCPForCNPJ = this.key.titular_conta.tipo_titular == 'NATURAL_PERSON' ? 'CPF' : 'CNPJ';
  this.mesmaTitularidadeOutraTitularidade = this.key.mesma_titularidade ? 'MESMA TITULARIDADE' : 'OUTRA TITULARIDADE';
}

public getLimitDiscalimer(account) {
  const numberAccount = {
    NumeroConta: account,
    NumeroEmpresa: "",
    IdEmpresa: 0,
    Pix: true
  }
  this.limitDisclaimer.execute(numberAccount).subscribe((result: any) => {
      this.showDisclaimer(result.data);
  }, error => {
    this.erroDisclaimer();
  });
}

  public showDisclaimer(data) {
    this.showInfoDisclaimer = data;
    this.validDisclaimer = true;
  }

  public erroDisclaimer() {
    this.showInfoDisclaimer = [];
    this.validDisclaimer = false;
    this.ocultDisclaimerInit = false;
    this.toast.callModalMessage(null, 'Não foi possível exibir os limites. Tente novamente!', '',null, true);
  }
}
