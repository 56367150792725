import { Component, OnInit,OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ComexReportDataShare } from '@src/core/usecases/data-share/comex-report-data-share.service';
import { ChangeEntity, ReadyChangeEntity, TotaisEntity } from '@src/data/repository/ready-change-repository/ready-change.entity';
import { ReadyChangeDataRepository } from '@src/data/repository/ready-change-repository/ready-change.repository';
import { ImportDataRepository } from '@src/data/repository/import-repository/import.repository';
import * as $ from 'jquery';

@Component({
  selector: 'fibra-ready-change',
  templateUrl: './ready-change.component.html',
  styleUrls: ['./ready-change.component.scss'],
})
export class ReadyChangeComponent implements OnInit,OnDestroy {

  public periods = ['seven', 'fifteen', 'thirthy', 'ninethy', 'calendar'];
  public maxDate: NgbDate;
  public dataInicio: string;
  public dataFim: string;
  public dataInicioFilter: string;
  public dataFimFilter: string;
  public isFilter = false;
  public changeData: ReadyChangeEntity[];
  public filterByPeriod = false;
  public filterByContract = false;
  public noData = false;
  public contractNum: string = '';
  public changeForm: FormGroup;
  public changeArray: ChangeEntity[];
  public changeTotais: TotaisEntity[];
  public periodoSelected = '';
  public calendar = false;
  public loading = false;
  public hasError = false;
  public fieldRequired = false;
  public total: [{
    moeda: string,
    total: number
  }]
   public showModalPeriodDatepicker = false;

  constructor(private calendario: NgbCalendar,
    private changeService: ReadyChangeDataRepository,
    private formBuilder: FormBuilder,
    public comexReport: ComexReportDataShare,
    private importService: ImportDataRepository) { }

  ngOnInit() {
    this.showModalPeriodDatepicker = false;
    this.maxDate = this.calendario.getToday();
    $('#seven-div').removeClass('period-item-unselected');
    $('#seven-div').addClass('period-item-selected');
    $('#seven-font').removeClass('period-font-unselected');
    $('#seven-font').addClass('period-font-selected');
    this.filterByPeriod = true;
    this.getFilterData('7');
    this.sendPeriodData('period');
    this.changeForm = this.formBuilder.group({
      filter: ['period'],
      contractNum: ['']

    })
  }

  ngOnDestroy()
  {
    sessionStorage.removeItem('ready-change-total')
    sessionStorage.removeItem('excel_info')
  }

  public getPDFNotes(contrato: string, tipo: string) {
    this.loading = true; 
    this.importService.getPDF(contrato, tipo).subscribe((data) => {
      var i = 1;
      data.data.forEach(element => {
        fetch(element.url)
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "Comércio exterior - Ordem de Pagamento.pdf";
          i++;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        })
        this.loading = false;
      });
     
    })
  }

  public changePeriodDate(evento) {
    this.isFilter=true;
    this.dataInicioFilter = `${evento.from.day}/${evento.from.month}/${evento.from.year}`;
    this.dataFimFilter = `${evento.to.day}/${evento.to.month}/${evento.to.year}`;
    this.loading = true;
    this.dataInicio = `${evento.from.year}-${evento.from.month}-${evento.from.day}`;
    this.dataFim = `${evento.to.year}-${evento.to.month}-${evento.to.day}`;
    this.sendPeriodData('period');
    $('#calendar').attr('src', '/assets/svg/icon_calendar.svg');
    this.periodoSelected = 'calendar';

  }

  public getFilterData(period: string) {
    const data = new Date();

    localStorage.setItem('view-atual', 'cambio-pronto');
    sessionStorage.setItem('excel_info', '{"selectedFilter":"cambio-pronto","dt_ini":"' + this.dataInicio + '","dt_fim":"' + this.dataFim + '"  }')

    if (this.filterByPeriod) {
      this.isFilter=false
      this.loading = true;
      if (period === '7') {
        this.periodoSelected = period;
        const timeBetween = data.getTime() - 86400000 * 7;
        const dataMenosSete = new Date(timeBetween);
        this.dataInicio =
          `${dataMenosSete.toLocaleString().slice(6, 10)}-${dataMenosSete.toLocaleString().slice(3, 5)}-${dataMenosSete.toLocaleString().slice(0, 2)}`;

        this.dataFim =
          `${data.toLocaleString().slice(6, 10)}-${data.toLocaleString().slice(3, 5)}-${data.toLocaleString().slice(0, 2)}`;

        this.sendPeriodData('period');
      }
      else if (period === '15') {
        this.periodoSelected = period;
        const timeBetween = data.getTime() - 86400000 * 15;
        const dataMenosSete = new Date(timeBetween);
        this.dataInicio =
          `${dataMenosSete.toLocaleString().slice(6, 10)}-${dataMenosSete.toLocaleString().slice(3, 5)}-${dataMenosSete.toLocaleString().slice(0, 2)}`;

        this.dataFim =
          `${data.toLocaleString().slice(6, 10)}-${data.toLocaleString().slice(3, 5)}-${data.toLocaleString().slice(0, 2)}`;

        this.sendPeriodData('period');
      }
      else if (period === '30') {
        this.periodoSelected = period;
        const timeBetween = data.getTime() - 86400000 * 30;
        const dataMenosSete = new Date(timeBetween);
        this.dataInicio =
          `${dataMenosSete.toLocaleString().slice(6, 10)}-${dataMenosSete.toLocaleString().slice(3, 5)}-${dataMenosSete.toLocaleString().slice(0, 2)}`;

        this.dataFim =
          `${data.toLocaleString().slice(6, 10)}-${data.toLocaleString().slice(3, 5)}-${data.toLocaleString().slice(0, 2)}`;

        this.sendPeriodData('period');
      }
      else if (period === '90') {
        this.periodoSelected = period;
        const timeBetween = data.getTime() - 86400000 * 90;
        const dataMenosSete = new Date(timeBetween);
        this.dataInicio =
          `${dataMenosSete.toLocaleString().slice(6, 10)}-${dataMenosSete.toLocaleString().slice(3, 5)}-${dataMenosSete.toLocaleString().slice(0, 2)}`;

        this.dataFim =
          `${data.toLocaleString().slice(6, 10)}-${data.toLocaleString().slice(3, 5)}-${data.toLocaleString().slice(0, 2)}`;

        this.sendPeriodData('period');
      }
    }

  }

  public formatDate(data: string) {
    const date = `${data.slice(8, 10)}/${data.slice(5, 7)}/${data.slice(0, 4)}`;
    return date;
  }

  public formatUsd(value: number) {
    const val = value.toLocaleString('en-US', { minimumFractionDigits: 2 });
    return val;
  }

  public formatBrl(value: number) {
    const val = value.toLocaleString('pt-br', { minimumFractionDigits: 2 });
    return val;
  }

  public changeFilterType(type: string) {
    this.fieldRequired = false;
    if (type === 'period') {
      this.filterByPeriod = true;
      this.filterByContract = false;
      this.contractNum = '';
      $('#seven-div').removeClass('period-item-unselected');
      $('#seven-div').addClass('period-item-selected');
      $('#seven-font').removeClass('period-font-unselected');
      $('#seven-font').addClass('period-font-selected');
    } else if (type === 'contract') {
      this.filterByPeriod = false;
      this.filterByContract = true;
      this.periods.forEach((elem) => {
        $(`#${elem}-div`).removeClass('period-item-selected');
        $(`#${elem}-div`).addClass('period-item-unselected');
        $(`#${elem}-font`).removeClass('period-font-selected');
        $(`#${elem}-font`).addClass('period-font-unselected');
      })
      this.calendar = false;
      $('#calendar').attr('src', '/assets/svg/icon_calendar_gray.svg');
    }
  }

  public changePeriodFilter(period: string) {
    this.fieldRequired = false;
    if (this.filterByPeriod) {
      if (period === '7') {
        $('#seven-div').removeClass('period-item-unselected');
        $('#seven-div').addClass('period-item-selected');
        $('#seven-font').removeClass('period-font-unselected');
        $('#seven-font').addClass('period-font-selected');
        this.calendar = false;
        $('#calendar').attr('src', '/assets/svg/icon_calendar_gray.svg')
        this.periods.forEach((elem) => {
          if (elem !== 'seven') {
            $(`#${elem}-div`).removeClass('period-item-selected');
            $(`#${elem}-div`).addClass('period-item-unselected');
            $(`#${elem}-font`).removeClass('period-font-selected');
            $(`#${elem}-font`).addClass('period-font-unselected');
          }
        })
      }
      if (period === '15') {
        $('#fifteen-div').removeClass('period-item-unselected');
        $('#fifteen-div').addClass('period-item-selected');
        $('#fifteen-font').removeClass('period-font-unselected');
        $('#fifteen-font').addClass('period-font-selected');
        this.calendar = false;
        $('#calendar').attr('src', '/assets/svg/icon_calendar_gray.svg')
        this.periods.forEach((elem) => {
          if (elem !== 'fifteen') {
            $(`#${elem}-div`).removeClass('period-item-selected');
            $(`#${elem}-div`).addClass('period-item-unselected');
            $(`#${elem}-font`).removeClass('period-font-selected');
            $(`#${elem}-font`).addClass('period-font-unselected');
          }
        })

      }
      if (period === '30') {
        $('#thirthy-div').removeClass('period-item-unselected');
        $('#thirthy-div').addClass('period-item-selected');
        $('#thirthy-font').removeClass('period-font-unselected');
        $('#thirthy-font').addClass('period-font-selected');
        this.calendar = false;
        $('#calendar').attr('src', '/assets/svg/icon_calendar_gray.svg')
        this.periods.forEach((elem) => {
          if (elem !== 'thirthy') {
            $(`#${elem}-div`).removeClass('period-item-selected');
            $(`#${elem}-div`).addClass('period-item-unselected');
            $(`#${elem}-font`).removeClass('period-font-selected');
            $(`#${elem}-font`).addClass('period-font-unselected');
          }
        })
      }
      if (period === '90') {
        $('#ninethy-div').removeClass('period-item-unselected');
        $('#ninethy-div').addClass('period-item-selected');
        $('#ninethy-font').removeClass('period-font-unselected');
        $('#ninethy-font').addClass('period-font-selected');
        this.calendar = false;
        $('#calendar').attr('src', '/assets/svg/icon_calendar_gray.svg')
        this.periods.forEach((elem) => {
          if (elem !== 'ninethy') {
            $(`#${elem}-div`).removeClass('period-item-selected');
            $(`#${elem}-div`).addClass('period-item-unselected');
            $(`#${elem}-font`).removeClass('period-font-selected');
            $(`#${elem}-font`).addClass('period-font-unselected');
          }
        })
      }
      if (period === 'calendar') {
        $('#calendar-div').removeClass('period-item-unselected');
        $('#calendar-div').addClass('period-item-selected');
        $('#calendar-font').removeClass('period-font-unselected');
        $('#calendar-font').addClass('period-font-selected');
        this.calendar = true;
        this.periods.forEach((elem) => {
          if (elem !== 'calendar') {
            $(`#${elem}-div`).removeClass('period-item-selected');
            $(`#${elem}-div`).addClass('period-item-unselected');
            $(`#${elem}-font`).removeClass('period-font-selected');
            $(`#${elem}-font`).addClass('period-font-unselected');
          }
        })
      }
    }


  }

  public sendPeriodData(type: string) {
    this.loading = true;
    this.fieldRequired = false;
    this.changeTotais = [];

    this.changeArray = new Array();
    if (type === 'period') {

      localStorage.setItem('view-atual', 'cambio-pronto');
      sessionStorage.setItem('excel_info', '{"selectedFilter":"cambio-pronto","dt_ini":"' + this.dataInicio + '","dt_fim":"' + this.dataFim + '"  }')

      this.changeService.getPeriodData(this.dataInicio, this.dataFim).subscribe((data) => {
        if (data.data.lista === null) {
          this.noData = true;
          this.loading = false;
          localStorage.setItem('canLoadReport', 'false');
        } else {
          if (data.data.lista.length === 0) {
            this.noData = true;
            this.loading = false;
            localStorage.setItem('canLoadReport', 'false');
          } else {
            this.changeData = data.data.lista;
            this.changeTotais = data.data.totais;
            this.changeArray = new Array();
            this.changeData.forEach((elem) => {
              elem.contratos.forEach((dataContract) => {
                this.changeArray.push(dataContract)
              })
            })
            this.changeArray.forEach((elem) => {
              elem.data = this.setDate(elem.dt_op);
              elem.dt_op = this.formatDate(elem.dt_op);
              elem.dt_liq = this.formatDate(elem.dt_liq);
              elem.valorFormatadoBrl = this.formatBrl(parseFloat(elem.valor_moeda_nacional));
              elem.valorFormatadoUsd = this.formatUsd(parseFloat(elem.valor_moeda_estrangeira));

            })
            this.changeArray = this.changeArray.reverse()
            this.noData = false;
            this.loading = false;
            this.hasError = false;
            this.comexReport.setValue(this.changeArray);
            sessionStorage.setItem('ready-change-total', JSON.stringify(this.changeTotais))
            if (this.periodoSelected !== 'calendar') {
              this.comexReport.setReportInfo({
                title: 'Câmbio Pronto',
                subtitle1: 'Período',
                subTitle1Value: `${this.periodoSelected} dias`,
                subtitle2: null,
                subtitle2Value: null
              })
            } else if (this.periodoSelected === 'calendar') {
              const ini = new Date(this.dataInicio);
              const fim = new Date(this.dataFim);
              this.comexReport.setReportInfo({
                title: 'Câmbio Pronto',
                subtitle1: 'Período',
                subTitle1Value: `${ini.toLocaleDateString()} à ${fim.toLocaleDateString()}`,
                subtitle2: null,
                subtitle2Value: null
              })
            }

            localStorage.setItem('view-atual', 'cambio-pronto');
            localStorage.setItem('canLoadReport', 'true');
            sessionStorage.setItem('excel_info', '{"selectedFilter":"cambio-pronto","dt_ini":"' + this.dataInicio + '","dt_fim":"' + this.dataFim + '"  }')

          }
        }
      }, () => {
        localStorage.setItem('canLoadReport', 'false');
        this.hasError = true;
        this.noData = false;
        this.loading = false;
      })
    } else {
      this.contractNum = this.changeForm.value['contractNum'];
      if (this.contractNum === '' || this.contractNum === '0000000000') {
        this.loading = false;
        this.fieldRequired = true;
        //alert('campo obrigatório!');
        return;
      }
      sessionStorage.setItem('excel_info', '{"selectedFilter":"cambio-pronto","referencia":"' + this.contractNum + '"}')
      this.changeService.getContractData(this.contractNum).subscribe((data) => {
        if (data.data.length == 0) {
          this.noData = true;
          this.loading = false;
          localStorage.setItem('canLoadReport', 'false');
        } else {
          this.changeArray = data.data
          this.changeArray.forEach((elem) => {
            elem.data = this.setDate(elem.dt_op);
            elem.dt_op = this.formatDate(elem.dt_op);
            elem.dt_liq = this.formatDate(elem.dt_liq);
            elem.valorFormatadoBrl = this.formatBrl(parseFloat(elem.valor_moeda_nacional));
            elem.valorFormatadoUsd = this.formatUsd(parseFloat(elem.valor_moeda_estrangeira));

            this.changeTotais = [{ swift: elem.swift, total: +elem.valor_moeda_estrangeira }];

          })
          this.changeArray = this.changeArray.reverse();
          this.noData = false;
          this.loading = false;
          this.hasError = false;
          sessionStorage.setItem('ready-change-total', JSON.stringify(this.changeTotais))
          this.comexReport.setValue(this.changeArray);
          this.comexReport.setReportInfo({
            title: 'Câmbio Pronto',
            subtitle1: 'Número de contrato',
            subTitle1Value: this.contractNum,
            subtitle2: null,
            subtitle2Value: null
          })
          localStorage.setItem('view-atual', 'cambio-pronto');
          localStorage.setItem('canLoadReport', 'true');
        }
      }, () => {
        this.hasError = true;
        this.loading = false;
        this.noData = false;
        localStorage.setItem('canLoadReport', 'false');
      })
    }

  }

  public setDate(data: string) {
    const today = new Date();
    const dateReceived = new Date(data);
    const previousDay = new Date(today.getTime() - 86400000);

    if (today.toLocaleDateString() === dateReceived.toLocaleDateString()) {
      return `Hoje, ${data.slice(8, 10)} de ${this.setMonthName(data.slice(5, 7))} de ${data.slice(0, 4)}`
    } else if (dateReceived.toLocaleDateString() === previousDay.toLocaleDateString()) {
      return `Ontem, ${data.slice(8, 10)} de ${this.setMonthName(data.slice(5, 7))} de ${data.slice(0, 4)}`
    } else {
      return `${data.slice(8, 10)} de ${this.setMonthName(data.slice(5, 7))} de ${data.slice(0, 4)}`
    }

  }

  public setMonthName(month: string) {

    switch (month) {
      case '01':
        return 'Janeiro'
      case '02':
        return 'Fevereiro'
      case '03':
        return 'Março'
      case '04':
        return 'Abril'
      case '05':
        return 'Maio'
      case '06':
        return 'Junho'
      case '07':
        return 'Julho'
      case '08':
        return 'Agosto'
      case '09':
        return 'Setembro'
      case '10':
        return 'Outubro'
      case '11':
        return 'Novembro'
      case '12':
        return 'Dezembro'
    }
  }
  public closeEvent(close: any) {
    this.showModalPeriodDatepicker = false;
  }
  public periodModal() {
    this.showModalPeriodDatepicker = !this.showModalPeriodDatepicker;
  }
}
