export { ApprovalReceiptDataShare } from '@src/core/usecases/data-share/approval-data-share.service';
export { SchedulingDataShare } from '@src/core/usecases/data-share/scheduling-data-share.service';
export { TransferPendingDataShare } from '@src/core/usecases/data-share/transfer-pending-data-share.service';
export { TransferConfirmDataShare } from '@src/core/usecases/data-share/transfer-confirm-data-share.service';
export { CompanySelectDataShare } from '@src/core/usecases/data-share/company-select-data-share.service';
export { DebitAccountDataShare } from '@src/core/usecases/data-share/debit-account-data-share.service';
export { BankDataShare } from '@src/core/usecases/data-share/bank-data-share.service';
export { TransferReceiptDataShare } from '@src/core/usecases/data-share/transfer-receipt-data-share.service';
export { SelectBeneficiaryDataShare } from '@src/core/usecases/data-share/select-beneficiary-data-share.service';
export { BeneficiaryDataShare } from '@src/core/usecases/data-share/beneficiary-data-share.service ';
export { FilterBillDataShare } from '@src/core/usecases/data-share/filter-bill-data-share.service';
export { FilterQueryDataShare } from '@src/core/usecases/data-share/filter-query-data-share.service';
export { InstructionDataShare } from '@src/core/usecases/data-share/instruction-data-share.service';
export { DetailsListShare } from '@src/core/usecases/data-share/instruction-event-share.service';
export { BalanceDataShare } from '@src/core/usecases/data-share/balance-data-share.service';
export { AccountDataShare } from '@src/core/usecases/data-share/account-data-share.service';
export { SelectorDataShare } from '@src/core/usecases/data-share/selector-data-share.service';
export { ShowValuesDashboardDataShare } from '@src/core/usecases/data-share/show-values-dashboard-data-share.service';
export { FilterArchiveDataShare } from '@src/core/usecases/data-share/filter-data-share.service';
export { StatusMessageDataShare } from '@src/core/usecases/data-share/status-message-data-share.service';
export { TabsDataShare, TabsOnChange } from '@src/core/usecases/data-share/tabs-data-share.service';
export { FirstStepDataShare } from '@src/core/usecases/data-share/firstStep-data-share.service';
export { SecondStepDataShare } from '@src/core/usecases/data-share/secondStap-data-share.service';
export { DownloadArchiveDataShare } from '@src/core/usecases/data-share/dnd-data-share.service';
export { UploadDataShare } from '@src/core/usecases/data-share/upload-data-share.service';
export { ChargeArchiveDataShare } from '@src/core/usecases/data-share/charge-archive-data-share.service';
export { DuplicateBillDataShare } from '@src/core/usecases/data-share/duplicate-bill-data-sharel.service';
export { TokenDataShare } from '@src/core/usecases/data-share/token-data-share.service';
export { LoadingDataShare } from '@src/core/usecases/data-share/loading-data-share.service';
export { TransactionsDataShare } from '@src/core/usecases/data-share/transactions-data-share.service';
export { RemoveClassDataShare } from '@src/core/usecases/data-share/remove-class-data-share.service';
export { DdaTermDataShare } from '@src/core/usecases/data-share/dda-term-data-share.service';
export const optionWallet = [
  { name: 'Todas', value: 'todas' },
  { name: 'Desconto', value: 'desconto' },
  { name: 'Cobrança terceiros', value: 'cobranca-terceiros' },
  { name: 'Arquivos em trânsito', value: 'arquivos-em-transito' },
  { name: 'Cobrança vinculada', value: 'cobranca-vinculada' },
];

export const optionWalletConsultas = [
  { name: 'Todas', value: 'todas' },
  { name: 'Desconto', value: 'desconto' },
  { name: 'Cobrança simples', value: 'cobranca-simples' },
  { name: 'Cobrança vinculada', value: 'cobranca-vinculada' },
];

export const optionTypeTransfer = [
  { name: 'Todas', value: 'Todas' },
  { name: 'Pagamento de Boleto', value: '4' },
  { name: 'Pagamento em Lote', value: '5' },
  { name: 'Pix', value: '7' },
  { name: 'Devolução Pix', value: '9' },
  { name: 'Pix - Open Finance', value: '99' },
  { name: 'TED', value: '2' },
  { name: 'TEF', value: '3' },
];

export const optionTypeReceipt = [
  { name: 'Todos', value: 'Todos' },
  { name: 'Pagamento de Boleto', value: '4' },
  { name: 'Pagamento em Lote', value: '5' },
  { name: 'Pix', value: '7' },
  { name: 'Devolução Pix', value: '9' },
  { name: 'Pix - Open Finance', value: '99' },
  { name: 'TED', value: '2' },
  { name: 'TEF', value: '3' },
];

export const optionInstructionsWallet = [
  { name: 'Abatimento', value: 'abatimento', codigo: '4' },
  { name: 'Alteração de vencimento', value: 'alteracao-vencimento', codigo: '6' },
  { name: 'Pedido de baixa', value: 'pedido-baixa', codigo: '2' },
  { name: 'Protesto', value: 'protesto', codigo: '9' },
  { name: 'Sustar protesto', value: 'sustar-protesto', codigo: '18' },
];

export const titleValues = [
  { name: 'Selecione', value: '0' },
  { name: 'Até R$ 10.000,00', value: '10000' },
  { name: 'Até R$ 100.000,00', value: '100000' },
  { name: 'Até R$ 500.000,00', value: '500000' },
  { name: 'Acima de R$ 500.000,00', value: '500001' },
];

export const instructionValues = [
  { name: 'Selecione', value: '' },
  { name: 'Abatimento', value: '0' },
  { name: 'Alteração de vencimento', value: '1' },
  { name: 'Pedido de baixa', value: '2' },
  { name: 'Protesto', value: '3' },
  { name: 'Sustar protesto', value: '4' },
];

export const stateList = [
  { name: 'UF', value: '-' },
  { name: 'AC', value: 'AC' },
  { name: 'AL', value: 'AL' },
  { name: 'AP', value: 'AP' },
  { name: 'AM', value: 'AM' },
  { name: 'BA', value: 'BA' },
  { name: 'CE', value: 'CE' },
  { name: 'DF', value: 'DF' },
  { name: 'ES', value: 'ES' },
  { name: 'GO', value: 'GO' },
  { name: 'MA', value: 'MA' },
  { name: 'MT', value: 'MT' },
  { name: 'MS', value: 'MS' },
  { name: 'MG', value: 'MG' },
  { name: 'PA', value: 'PA' },
  { name: 'PB', value: 'PB' },
  { name: 'PR', value: 'PR' },
  { name: 'PE', value: 'PE' },
  { name: 'PI', value: 'PI' },
  { name: 'RJ', value: 'RJ' },
  { name: 'RN', value: 'RN' },
  { name: 'RS', value: 'RS' },
  { name: 'RO', value: 'RO' },
  { name: 'RR', value: 'RR' },
  { name: 'SC', value: 'SC' },
  { name: 'SP', value: 'SP' },
  { name: 'SE', value: 'SE' },
  { name: 'TO', value: 'TO' },
];

export const indexadorList = [
  { name: 'Pré-fixado', value: 'Pré-fixado' },
  { name: 'Pós-fixado', value: 'Pós-fixado' },
];

// tslint:disable-next-line:class-name
export class statusMessage {
  messageTitle: string;
  messageStatus: string;
  image: string;
  button: string;
  tap: string;
}

export const periods = [
  { name: '7 dias', value: 7 },
  { name: '15 dias', value: 15 },
  { name: '30 dias', value: 30 },
  { name: '90 dias', value: 90 },
];

export const optionsTypeReceipt = [
  { name: 'Todos', value: 'todos' },
  { name: 'TED', value: 'ted' },
  { name: 'Pagamento de Boleto', value: 'pagamento-boleto' },
  { name: 'Transferência', value: 'transferencia' },
];

export const optionsStatusReceipt = [
  { name: 'Liquidado', value: 'liquidado' },
  { name: 'Agendado', value: 'agendado' },
];

export const optionTransit = [
  { idOption: 'query-list-all', option: 'Tudo' },
  { idOption: 'query-list-authorized', option: 'Aprovados' },
  { idOption: 'query-list-refused', option: 'Recusados' },
  { idOption: 'query-list-pending', option: 'Em análise' },
];

export const optionPortfolio = [
  { idOption: 'portfolio-list-all', option: 'Tudo', notification: true },
  { idOption: 'portfolio-list-authorized', option: 'Pagos' },
  { idOption: 'portfolio-list-pending', option: 'Vencidos' },
  { idOption: 'portfolio-list-refused', option: 'Baixados' },
  { idOption: null, option: 'À Vencer' },
];

export const optionInstruction = [
  { idOption: 'instruction-list-all', option: 'Tudo' },
  { idOption: 'instruction-list-authorized', option: 'Aprovadas' },
  { idOption: 'instruction-list-refused', option: 'Recusadas' },
  { idOption: 'instruction-list-pending', option: 'Em análise' },
];
 
export const optionPosicaoCarteira = [
  { idOption: 'posicao-carteira-list-all', option: 'Tudo', codigo_situacao: '', notification: true  },
  { idOption: 'posicao-carteira-list-paid', option: 'Pagos', codigo_situacao: '04' },
  { idOption: 'posicao-carteira-list-overdue', option: 'Vencidos', codigo_situacao: '07' },//Antigo '02'
  { idOption: 'posicao-carteira-list-in-notary', option: 'Em Cartório', codigo_situacao: '02' },
  { idOption: 'posicao-carteira-list-downloaded', option: 'Baixados', codigo_situacao: '05' },
  { idOption: 'posicao-carteira-list-expiring', option: 'À Vencer', codigo_situacao: '01' }
];

export const optionFranc = [
  { idOption: 'francesinha-list-all', option: 'Tudo' },
];

export const optionQueryDetail = [
  { idOption: 'sacado', option: 'Pagador' },
  { idOption: 'titulo', option: 'Título' },
  { idOption: 'tudo', option: 'Ver tudo' },
];

export const optionPortfolioDetail = [
  { idOption: 'sacado', option: 'Pagador' },
  { idOption: 'titulo', option: 'Título' },
  { idOption: 'sacador', option: 'Sacador' },
  { idOption: 'historico', option: 'Histórico' },
  { idOption: 'tudo', option: 'Ver tudo' },
];

export const optionExtract = [
  { idOption: 'extract-list-all', option: 'Tudo' },
  { idOption: 'extract-list-entrie', option: 'Entradas' },
  { idOption: 'extract-list-exit', option: 'Saídas' },
  { idOption: 'extract-list-scheduling', option: 'Agendamentos' },
];

export const optionsSideCard = [
  {
    id: 0,
    cardTitle: 'Movimentações previstas do dia',
    labelFirstRow: 'Entradas esperadas',
    labelSecondRow: 'Saídas esperadas',
    labelThirdRow: 'Saldo previsto',
  },
  {
    id: 1,
    cardTitle: 'Movimentações previstas do dia',
    labelFirstRow: 'Títulos aprovados',
    labelSecondRow: 'Títulos recusados',
    labelThirdRow: 'Títulos em análise',
  },
  {
    id: 2,
    cardTitle: 'Movimentações previstas do dia',
    labelFirstRow: 'Títulos pagos',
    labelSecondRow: 'Títulos vencidos',
    labelThirdRow: 'Títulos em à vencer',
  },
  {
    id: 3,
    cardTitle: 'Valores totais do período',
    labelFirstRow: 'Títulos pagos',
    labelSecondRow: 'Títulos vencidos',
    labelThirdRow: 'Títulos baixados',
    labelFourthRow: 'Títulos à vencer',
    labelFiveRow: "Títulos em cartório",
  },
];

export const mock = [
  {
    codigo: 3,
    cod_api_negocio: 4,
    cod_regra: 3,
    desc_transacao:
      'TRANSF;Favorecido: Testador Matos;CPF/CNPJ: 22222222222;Banco: 224 - Banco Fibra;CC: 0006678251|AG: 0001',
    info_transacao: {
      desc_tipoprocesso: 'TRANSF',
      desc_favorecido: 'Favorecido: Testador Matos',
      num_cpf_cnpj: 'CPF/CNPJ: 22222222222',
      desc_banco: 'Banco: 224 - Banco Fibra',
      desc_contaagencia: 'CC: 0006678251|AG: 0001',
    },
    texto_transacao: 'Compra de insumos',
    conta: '0006517226',
    dt_criacao: '2020-12-13T00:00:00',
    desc_operacao: 'Criação',
    desc_funcionalidade: 'Transferencia',
    vlr_transacao: 0.0,
    aprovadores: [
      {
        cod_aprovador: 1,
        nome_aprovador: 'Usuario Testes',
      },
    ],
    desc_status: 'EMPROCESSAMENTO',
    tipo_transacao: 'TRANSFERENCIA',
    num_cpf_cnpj: '12345678910',
  },
  {
    codigo: 4,
    cod_api_negocio: 4,
    cod_regra: 3,
    desc_transacao:
      'TRANSF;Favorecido: Testador Matos;CPF/CNPJ: 22222222222;Banco: 224 - Banco Fibra;CC: 0006678251|AG: 0001',
    info_transacao: {
      desc_tipoprocesso: 'TRANSF',
      desc_favorecido: 'Favorecido: Testador Matos',
      num_cpf_cnpj: 'CPF/CNPJ: 22222222222',
      desc_banco: 'Banco: 224 - Banco Fibra',
      desc_contaagencia: 'CC: 0006678251|AG: 0001',
    },
    texto_transacao: 'Compra de insumos',
    conta: '0006517226',
    dt_criacao: '2020-12-13T00:00:00',
    desc_operacao: 'Criação',
    desc_funcionalidade: 'Transferencia',
    vlr_transacao: 0.0,
    aprovadores: [
      {
        cod_aprovador: 1,
        nome_aprovador: 'Usuario Testes',
      },
    ],
    desc_status: 'AGENDADO',
    tipo_transacao: 'TRANSFERENCIA',
    num_cpf_cnpj: '12345678910',
  },
  {
    codigo: 52,
    cod_api_negocio: 66,
    cod_regra: 1,
    desc_transacao:
      'TRANSF;Favorecido: Testador Matos;CPF/CNPJ: 22222222222;Banco: 224 - Banco Fibra;CC: 0006678251|AG: 0001',
    info_transacao: {
      desc_tipoprocesso: 'TRANSF',
      desc_favorecido: 'Favorecido: Testador Matos',
      num_cpf_cnpj: 'CPF/CNPJ: 22222222222',
      desc_banco: 'Banco: 224 - Banco Fibra',
      desc_contaagencia: 'CC: 0006678251|AG: 0001',
    },
    texto_transacao: '',
    conta: '0006517226',
    dt_criacao: '2020-12-13T00:00:00',
    desc_operacao: 'Criação',
    desc_funcionalidade: 'Transferencia',
    vlr_transacao: 1.0,
    aprovadores: [
      {
        cod_aprovador: 1,
        nome_aprovador: 'Usuario Testes',
      },
    ],
    desc_status: 'EMPROCESSAMENTO',
    tipo_transacao: 'TRANSFERENCIA',
    num_cpf_cnpj: '12345678910',
  },
  {
    codigo: 52,
    cod_api_negocio: 66,
    cod_regra: 1,
    desc_transacao:
      'TRANSF;Favorecido: Testador Matos;CPF/CNPJ: 22222222222;Banco: 224 - Banco Fibra;CC: 0006678251|AG: 0001',
    info_transacao: {
      desc_tipoprocesso: 'TRANSF',
      desc_favorecido: 'Favorecido: Testador Matos',
      num_cpf_cnpj: 'CPF/CNPJ: 22222222222',
      desc_banco: 'Banco: 224 - Banco Fibra',
      desc_contaagencia: 'CC: 0006678251|AG: 0001',
    },
    texto_transacao: '',
    conta: '0006517226',
    dt_criacao: '2020-12-13T00:00:00',
    desc_operacao: 'Criação',
    desc_funcionalidade: 'Transferencia',
    vlr_transacao: 1.0,
    aprovadores: [
      {
        cod_aprovador: 1,
        nome_aprovador: 'Usuario Testes',
      },
    ],
    desc_status: 'VENCIDO',
    tipo_transacao: 'TRANSFERENCIA',
    num_cpf_cnpj: '12345678910',
  },
];

export const grupoProdutoList = [
  {name:'LCI POS',value:'LCI POS'},
  {name:'DPGE PRE',value:'DPGE PRE'},
  {name:'LCI PRE',value:'LCI PRE'},
  {name:'CDB POS',value:'CDB POS'},
  {name:'DPGE POS',value:'DPGE POS'},
  {name:'CDB PRE',value:'CDB PRE'},
  {name:'LCA POS',value:'LCA POS'},
  {name:'LCA PRE',value:'LCA PRE'}
]

export const titulosList = [
  {name: 'APOLICE DA DIVIDA PUBLICA APOLICE DIVIDA', value: 'APOLICE DA DIVIDA PUBLICA APOLICE DIVIDA'},
  {name: 'CDB POS P 100 FIBRABM', value: 'CDB POS P 100 FIBRABM'},
  {name: 'CDB POS P 1000 FIBRABM', value: 'CDB POS P 1000 FIBRABM'},
  {name: 'CDB POS P FIBRA', value: 'CDB POS P FIBRA'},
  {name: 'CDB PRE P FIBRA', value: 'CDB PRE P FIBRA'},
  {name: 'CDB SUB POS P 1000 FIBRABM', value: 'CDB SUB POS P 1000 FIBRABM'},
  {name: 'CDI POS P FIBRA - 1000 CDI POS FIBRA - 1', value: 'CDI POS P FIBRA - 1000 CDI POS FIBRA - 1'},
  {name: 'CDI POS PL FIBRA', value: 'CDI POS PL FIBRA'},
  {name: 'CDI PRE P FIBRA', value: 'CDI PRE P FIBRA'},
  {name: 'CDI PRE R. PRONAF SICREDIBM', value: 'CDI PRE R. PRONAF SICREDIBM'},
  {name: 'CDI PRE T MIC. CRE. YBNB', value: 'CDI PRE T MIC. CRE. YBNB'},
  {name: 'CDI PRE T MIC. CRE. YSICR', value: 'CDI PRE T MIC. CRE. YSICR'},
  {name: 'CDI PRE T RURAL BANCOOB', value: 'CDI PRE T RURAL BANCOOB'},
  {name: 'CDI PRE T RURAL BANCOOP', value: 'CDI PRE T RURAL BANCOOP'},
  {name: 'CDI PRE T RURAL BANSICREDI', value: 'CDI PRE T RURAL BANSICREDI'},
  {name: 'CDI PRE T RURAL SAFRABM', value: 'CDI PRE T RURAL SAFRABM'},
  {name: 'DEB POS T ATLS', value: 'DEB POS T ATLS'},
  {name: 'DEB POS T TEC TOY 11 00%', value: 'DEB POS T TEC TOY 11 00%'},
  {name: 'Deb CRI P CRI P - GOLDERMA', value: 'Deb CRI P CRI P - GOLDERMA'},
  {name: 'Debentures T J TUTOIA JR', value: 'Debentures T J TUTOIA JR'},
  {name: 'Debentures T S TUTOIASENIOR', value: 'Debentures T S TUTOIASENIOR'},
  {name: 'LFT LFT', value: 'LFT LFT'},
  {name: 'LTN LTN', value: 'LTN LTN'},
  {name: 'NTN-B NTN-B', value: 'NTN-B NTN-B'},
  {name: 'NTN-F NTN-F', value: 'NTN-F NTN-F'},
  {name: 'TDA E TDA', value: 'TDA E TDA'},
  {name: 'TDA E TDA D', value: 'TDA E TDA D'},
  {name: 'TDA E TDA E', value: 'TDA E TDA E'},
  {name: 'CDI PRE T RURAL SOFISABM', value: 'CDI PRE T RURAL SOFISABM'},
  {name: 'CDI PRE T PAULISTASA', value: 'CDI PRE T PAULISTASA'},
  {name: 'CDI POS T - 1000 BCO URUGUAI', value: 'CDI POS T - 1000 BCO URUGUAI'},
  {name: 'CDI POS T ITRICMA', value: 'CDI POS T ITRICMA'},
  {name: 'CDI POS P FIBRA', value: 'CDI POS P FIBRA'},
  {name: 'CDI POS T IPANAMC', value: 'CDI POS T IPANAMC'},
  {name: 'CDI POS T IPAULCO', value: 'CDI POS T IPAULCO'},
  {name: 'CDI POS T IPINEMA', value: 'CDI POS T IPINEMA'},
  {name: 'CPR FINANCEIRA CPR FINANC 0108', value: 'CPR FINANCEIRA CPR FINANC 0108'},
  {name: 'CPR FINANCEIRA CPR FINANC 0208', value: 'CPR FINANCEIRA CPR FINANC 0208'},
  {name: 'CDI POS T BCO RURAL', value: 'CDI POS T BCO RURAL'},
  {name: 'CDI RURAL GERAL BANCOOB', value: 'CDI RURAL GERAL BANCOOB'},
  {name: 'CDI RURAL SUBEX BANSICREDI', value: 'CDI RURAL SUBEX BANSICREDI'},
  {name: 'CDI RURAL GERAL BANSICRED', value: 'CDI RURAL GERAL BANSICRED'},
  {name: 'CPR FINANCEIRA CPR FINANC 0308', value: 'CPR FINANCEIRA CPR FINANC 0308'},
  {name: 'CDB PRE T PAULISTA', value: 'CDB PRE T PAULISTA'},
  {name: 'CDI RURAL SUBEX BANCOOBSA', value: 'CDI RURAL SUBEX BANCOOBSA'},
  {name: 'LCA BCO FIBRA LCA', value: 'LCA BCO FIBRA LCA'},
  {name: 'NTN-C NTN-C', value: 'NTN-C NTN-C'},
  {name: 'CDI POS T - 1000 BCO TRICURY', value: 'CDI POS T - 1000 BCO TRICURY'},
  {name: 'CDCA EMITENTE CDCA', value: 'CDCA EMITENTE CDCA'},
  {name: 'LCA BCO FIBRA LCA PU1000', value: 'LCA BCO FIBRA LCA PU1000'},
  {name: 'DPGE POS FIBRA', value: 'DPGE POS FIBRA'},
  {name: 'DPGE PRE FIBRA', value: 'DPGE PRE FIBRA'},
  {name: 'CDI PRE R. PRONAF SAFRABM', value: 'CDI PRE R. PRONAF SAFRABM'},
  {name: 'LCA BCO FIBRA lCA PU1000', value: 'LCA BCO FIBRA lCA PU1000'},
  {name: 'CPR FINANCEIRA CPR FINANC 0409', value: 'CPR FINANCEIRA CPR FINANC 0409'},
  {name: 'CPR FINANCEIRA CPF FINANC 0310', value: 'CPR FINANCEIRA CPF FINANC 0310'},
  {name: 'CPR FINANCEIRA CPF FINANC 0510', value: 'CPR FINANCEIRA CPF FINANC 0510'},
  {name: 'CPR FINANCEIRA CPR FINANC 0401', value: 'CPR FINANCEIRA CPR FINANC 0401'},
  {name: 'CPR FINANCEIRA CPR FINANC 0510', value: 'CPR FINANCEIRA CPR FINANC 0510'},
  {name: 'CPR FINANCEIRA CPR FINANC 0511', value: 'CPR FINANCEIRA CPR FINANC 0511'},
  {name: 'CPR FINANCEIRA CPR FINANC 0610', value: 'CPR FINANCEIRA CPR FINANC 0610'},
  {name: 'CPR FINANCEIRA CPR FINANC 0809', value: 'CPR FINANCEIRA CPR FINANC 0809'},
  {name: 'CPR FINANCEIRA CPR FINANC0712', value: 'CPR FINANCEIRA CPR FINANC0712'},
  {name: 'DPGE POS T TOPAZIO CFI', value: 'DPGE POS T TOPAZIO CFI'},
  {name: 'CPR FINANCEIRA CPR FINANC 051109', value: 'CPR FINANCEIRA CPR FINANC 051109'},
  {name: 'DEB POS T TEC TOY 11 0,0%', value: 'DEB POS T TEC TOY 11 0,0%'},
  {name: 'CDCA EMITENTE PRE CDCA PRE', value: 'CDCA EMITENTE PRE CDCA PRE'},
  {name: 'CDI PROGER BANSICREDIBM', value: 'CDI PROGER BANSICREDIBM'},
  {name: 'CDI RURAL PROGER BANSICREDIBM', value: 'CDI RURAL PROGER BANSICREDIBM'},
  {name: 'LCA BCO FIBRA LCA PU100', value: 'LCA BCO FIBRA LCA PU100'},
  {name:'LCA BCO FIBRA PU LCA1000',value:''},
  {name:'CPR FINANCEIRA CPR FINANC 15102010',value:'CPR FINANCEIRA CPR FINANC 15102010'},
  {name:'CPR FINANCEIRA CPR FINANC 16062010',value:'CPR FINANCEIRA CPR FINANC 16062010'},
  {name:'CPR FINANCEIRA CPR FINANC 1609',value:'CPR FINANCEIRA CPR FINANC 1609'},
  {name:'CPR FINANCEIRA CPR FINANC 16092010',value:'CPR FINANCEIRA CPR FINANC 16092010'},
  {name:'CPR FINANCEIRA CPR FINANC 16112010',value:'CPR FINANCEIRA CPR FINANC 16112010'},
  {name:'CPR FINANCEIRA CPR FINANC 17052010',value:'CPR FINANCEIRA CPR FINANC 17052010'},
  {name:'CPR FINANCEIRA CPR FINANC16072010',value:'CPR FINANCEIRA CPR FINANC16072010'},
  {name:'CPR FINANCEIRA CPR FINANC16082010',value:'CPR FINANCEIRA CPR FINANC16082010'},
  {name:'CDI PRE R. PRONAF BANCOOB',value:'CDI PRE R. PRONAF BANCOOB'},
  {name:'CPR FINANCEIRA CPR FINANC 29032010',value:'CPR FINANCEIRA CPR FINANC 29032010'},
  {name:'CPR FINANCEIRA CPR FINANC 12032010',value:'CPR FINANCEIRA CPR FINANC 12032010'},
  {name:'DEB POS T VBCR11',value:'DEB POS T VBCR11'},
  {name:'CPR FINANCEIRA CPR FINANC 14052010',value:'CPR FINANCEIRA CPR FINANC 14052010'},
  {name:'CDI POS T - 1000 CREDIFIBRA CFI',value:'CDI POS T - 1000 CREDIFIBRA CFI'},
  {name:'CDI POS T IGDSCMA060',value:'CDI POS T IGDSCMA060'},
  {name:'CDI RURAL GERAL BCOSAFRA',value:'CDI RURAL GERAL BCOSAFRA'},
  {name:'LCA BCO FIBRA  LCA PU1000',value:'LCA BCO FIBRA  LCA PU1000'},
  {name:'CPR FINANCEIRA CPR FINANC 30042010',value:'CPR FINANCEIRA CPR FINANC 30042010'},
  {name:'CDI POS T - 1000 GOLDMAN SACHS',value:'CDI POS T - 1000 GOLDMAN SACHS'},
  {name:'CDI POS T GOLDMAN SACHS',value:'CDI POS T GOLDMAN SACHS'},
  {name:'CPR FINANCEIRA CPR FINANC 16072010',value:'CPR FINANCEIRA CPR FINANC 16072010'},
  {name:'CPR FINANCEIRA CPR FINANC 26052011',value:'CPR FINANCEIRA CPR FINANC 26052011'},
  {name:'CPR FINANCEIRA CPR FINANC 19102010',value:'CPR FINANCEIRA CPR FINANC 19102010'},
  {name:'CDI RURAL SUBEX SAFRA',value:'CDI RURAL SUBEX SAFRA'},
  {name:'CPR FINANCEIRA CPR FINANC 15042011',value:'CPR FINANCEIRA CPR FINANC 15042011'},
  {name:'CPR FINANCEIRA CPR FINANC 15062011',value:'CPR FINANCEIRA CPR FINANC 15062011'},
  {name:'CPR FINANCEIRA CPR FINANC 16052011',value:'CPR FINANCEIRA CPR FINANC 16052011'},
  {name:'CPR FINANCEIRA CPR FINANC 15072011',value:'CPR FINANCEIRA CPR FINANC 15072011'},
  {name:'CPR FINANCEIRA CPR FINANC 05102010',value:'CPR FINANCEIRA CPR FINANC 05102010'},
  {name:'CPR FINANCEIRA CPR FINANC 15082011',value:'CPR FINANCEIRA CPR FINANC 15082011'},
  {name:'CDI RURAL GERAL VOTORANTIM',value:'CDI RURAL GERAL VOTORANTIM'},
  {name:'CDI RURAL SUBEX VOTORANTIM',value:'CDI RURAL SUBEX VOTORANTIM'},
  {name:'CDI POS T BANCO SANTANDER (BRASIL) S.A.',value:'CDI POS T BANCO SANTANDER (BRASIL) S.A.'},
  {name:'LCA BCO FIBRA LCA PU10000',value:'LCA BCO FIBRA LCA PU10000'},
  {name:'CDI POS T HSBC BANK BRASIL BCO MULTIPLO',value:'CDI POS T HSBC BANK BRASIL BCO MULTIPLO'},
  {name:'CDI RURAL PROGER BANCOOP',value:'CDI RURAL PROGER BANCOOP'},
  {name:'LCA BCO FIBRA LCA PU 1000',value:'LCA BCO FIBRA LCA PU 1000'},
  {name:'CDI RURAL PROGER/PRONAMP BANCOOP',value:'CDI RURAL PROGER/PRONAMP BANCOOP'},
  {name:'CDI POS T - 1000 BCO PAULISTA SA',value:'CDI POS T - 1000 BCO PAULISTA SA'},
  {name:'CDI T GAR BCO RURAL',value:'CDI T GAR BCO RURAL'},
  {name:'CDI PRE P CREDIFIBRA CFI',value:'CDI PRE P CREDIFIBRA CFI'},
  {name:'LF POS P FIBRA',value:'LF POS P FIBRA'},
  {name:'LCA BCO FIBRA LC PU1000',value:'LCA BCO FIBRA LC PU1000'},
  {name:'LF PRe P FIBRA',value:'LF PRe P FIBRA'},
  {name:'CDI POS T BANCO BNP PARIBAS',value:'CDI POS T BANCO BNP PARIBAS'},
  {name:'CDI POS T BCO NORDESTE',value:'CDI POS T BCO NORDESTE'},
  {name:'CDI POS T BCO NORDESTE MICR CRED',value:'CDI POS T BCO NORDESTE MICR CRED'},
  {name:'LCA BCO FIBRA 27358',value:'LCA BCO FIBRA 27358'},
  {name:'LCA BCO FIBRA 67558',value:'LCA BCO FIBRA 67558'},
  {name:'LCA PRE FIBRA',value:'LCA PRE FIBRA'},
  {name:'DPGE II POS FIBRA',value:'DPGE II POS FIBRA'},
  {name:'LCI POS FIBRA',value:'LCI POS FIBRA'},
  {name:'LCI POS INTERB LCI ENTESOUR',value:'LCI POS INTERB LCI ENTESOUR'},
  {name:'DPGE II PRE FIBRA',value:'DPGE II PRE FIBRA'},
  {name:'CDB POS T ABC BM',value:'CDB POS T ABC BM'},
  {name:'CDB POS T DAYCOVAL S/A',value:'CDB POS T DAYCOVAL S/A'},
  {name:'CDB VINCULADO FIBRA',value:'CDB VINCULADO FIBRA'},
  {name:'CRI PDG',value:'CRI PDG'},
  {name:'DEB POS T CAPRICORNIO CRNI',value:'DEB POS T CAPRICORNIO CRNI'},
  {name:'CDI RURAL PROGER/PRONAMP BANSICREDIBM',value:'CDI RURAL PROGER/PRONAMP BANSICREDIBM'},
  {name:'CDB POS DESAGIO FIBRABM',value:'CDB POS DESAGIO FIBRABM'},
  {name:'CDB PRE DESAGIO FIBRABM',value:'CDB PRE DESAGIO FIBRABM'},
  {name:'LCI PRE FIBRA',value:'LCI PRE FIBRA'},
  {name:'CDI RURAL COOPERATIVA BANSICREDI',value:'CDI RURAL COOPERATIVA BANSICREDI'},
  {name:'DPGE I POS FIBRA',value:'DPGE I POS FIBRA'},
  {name:'DPGE POS GARANTIA FIBRA',value:'DPGE POS GARANTIA FIBRA'},
  {name:'DPGE PRE GARANTIA FIBRA',value:'DPGE PRE GARANTIA FIBRA'},
  {name:'DEB POS T OMEGA ENERGIA OEII11',value:'DEB POS T OMEGA ENERGIA OEII11'},
  {name:'DEB POS T OMEGA ENERGIA OEII21',value:'DEB POS T OMEGA ENERGIA OEII21'},
  {name:'CDB POS T BBM',value:'CDB POS T BBM'},
  {name:'CDB POS T ORIGINAL S/A',value:'CDB POS T ORIGINAL S/A'},
  {name:'DEB POS T CEB DISTRIBUICAO CEBD11',value:'DEB POS T CEB DISTRIBUICAO CEBD11'},
  {name:'CDB VINCULADO PRe FIBRA',value:'CDB VINCULADO PRe FIBRA'},
  {name:'CDB POS T BTG PACTUAL',value:'CDB POS T BTG PACTUAL'},
  {name:'CDB POS T CSOFIME',value:'CDB POS T CSOFIME'},
  {name:'DEB POS T CONTOUR CGBP11',value:'DEB POS T CONTOUR CGBP11'},
  {name:'CDI PRE T DIR TC SICREDI',value:'CDI PRE T DIR TC SICREDI'},
  {name:'CDBV VINCULADO FLUXO FIBRA',value:'CDBV VINCULADO FLUXO FIBRA'},
  {name:'CDB VINC FLUXO POS FIBRA',value:'CDB VINC FLUXO POS FIBRA'},
  {name:'DEB POS T PADUAIV PADP11',value:'DEB POS T PADUAIV PADP11'},
  {name:'CDI POS T - 1000 BCO PANAMERICANO',value:'CDI POS T - 1000 BCO PANAMERICANO'},
  {name:'CDI POS T - 1000 BANCO PAN',value:'CDI POS T - 1000 BANCO PAN'},
  {name:'CDI POS T - 1000 BANCO ABC BRASIL',value:'CDI POS T - 1000 BANCO ABC BRASIL'},
  {name:'CDI POS T - 1000 BR PARTNERS',value:'CDI POS T - 1000 BR PARTNERS'},
  {name:'LF PRE P FIBRA',value:'LF PRE P FIBRA'},
  {name:'CDI POS T - 1000 SANTANDER',value:'CDI POS T - 1000 SANTANDER'},
  {name:'DPGE I PRE FIBRA',value:'DPGE I PRE FIBRA'},
  {name:'CDI PRE T MIC. CRE. SANTANDER',value:'CDI PRE T MIC. CRE. SANTANDER'},
  {name:'DPGE I POS RES.4050 FIBRA',value:'DPGE I POS RES.4050 FIBRA'},
  {name:'DPGE I PRE RES.4050 FIBRA',value:'DPGE I PRE RES.4050 FIBRA'}
];

export const productBannerList = [
  {name:'Capital de Giro',value:'Capital de Giro'},
  {name:'Antecipação de Recebíveis',value:'Antecipação de Recebíveis'},
  {name:'Fiança Bancária',value:'Fiança Bancária'},
  {name:'Conta Garantida',value:'Conta Garantida'},
  {name:'Cheque Empresa',value:'Cheque Empresa'},
  {name:'Outros',value:'Outros'}
];