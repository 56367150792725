import { Component, OnInit,OnDestroy } from '@angular/core';
import { LoansRequestCompanyCheck } from '@src/core/domain/loans/loan-model';
import { GetCompanyCheckUsecase } from '@src/core/usecases/loans/get-company-check';
import { Subscription } from 'rxjs';
import { GetCETFibraFacilUsecase } from '@src/core/usecases/loans/get-cet-fibrafacil';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'fibra-company-check',
  templateUrl: './company-check.component.html',
  styleUrls: ['./company-check.component.scss'],
})
export class CompanyCheckComponent implements OnInit,OnDestroy{
  public loading: boolean; 
  public total;
  public params;
  public type;
  public loansList=[]
  public hasData:boolean = false;
  public hasError=false;
  public getCompanyCheckSub : Subscription;

  constructor(private getCompanyCheck: GetCompanyCheckUsecase, private _getCETFibraFacil: GetCETFibraFacilUsecase) { 

  }

  ngOnInit(): void {

    const params: LoansRequestCompanyCheck = {}

    this.loading = true

    this.getCompanyCheckSub = this.getCompanyCheck.execute(params).subscribe((data) => {
      this.loansList = data.data      

      if( this.loansList.length > 0)
        this.hasData = true
      
      //PDF
      sessionStorage.setItem('cheque_empresa_pdf', JSON.stringify(this.loansList));
      sessionStorage.setItem('informations_pdf', '{"title":"Cheque Empresa", "filters":false, "report_name":"ChequeEmpresa","name":"cheque-empresa"}')
            
      this.requestSuccess(this.loansList)
      this.loading = false
    },(err) => {
      this.loading = false
      this.hasData = false
      this.hasError = true
    })
  }

  private requestSuccess = (value: any) => {    
    localStorage.setItem(
      'company-check-export-data',
      JSON.stringify({
        data: this.loansList,
        type: null,
        date: null,
        tabs: 'check-company'
      })
    );
  };

  ngOnDestroy() {
    this.getCompanyCheckSub.unsubscribe()
    sessionStorage.removeItem('cheque_empresa_pdf')
    sessionStorage.removeItem('informations_pdf')
  }

  public cet(item: any) {
    if (item.loading) {
      return;
    }

    const expanded = !item.expanded;

    if (expanded) {
      item.loading = true;

      item.sub = this._getCETFibraFacil
        .execute(item.nro_conta)
        .pipe(finalize(() => { item.loading = false; item.expanded = true; }))
        .subscribe(cet => item.cet = cet);
    } else {
      item.expanded = false;
    }
  }

  public get temFibraFacil(): boolean {
    return this.loansList && this.loansList.some(l => l.codigo_modalidade == '00094');
  }
}
