import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IOpenBankingConsent } from '@src/core/domain/openbanking/openbanking.consent.model';

@Component({
    selector: 'fibra-openbanking-paymentrequest-confirmtransaction',
    templateUrl: './openbanking-paymentrequest-confirmtransaction.component.html',
    styleUrls: ['./openbanking-paymentrequest-confirmtransaction.component.scss']
})
export class OpenbankingPaymentRequestConfirmTransactionComponent {

    @Input() public consent: any;
    @Input() public unicoAprovador: boolean;
    @Input() public busy: boolean;

    @Output() onOk: EventEmitter<void> = new EventEmitter<void>();
    @Output() onCancel: EventEmitter<void> = new EventEmitter<void>();

    public isScheduling: boolean = false;

    public get cpfCnpjCreditor(): string {
        this.scheduling();
        if (this.consent.creditors[0] && this.consent.creditors[0].personType && this.consent.creditors[0].cpfCnpj) {
            let result = this.consent.creditor.cpfCnpj.replace(/\./g, '').replace('-', '').replace('/', '');

            if (this.consent.creditor.personType == 'PESSOA_NATURAL') {
                result = '***.' + result.substr(3, 3) + '.' + result.substr(6, 3) + '-**';
            } else {
                result = result.substr(0, 2) + '.' + result.substr(2, 3) + '.' + result.substr(5, 3) + '/' + result.substr(8, 4) + '-' + result.substr(12, 2);
            }

            return result;
        }

        return null;
    }

    public get currencyStr(): string {
        switch (this.consent.payment.currency) {
            case "BRL": return "R$";
            default: return "";
        }
    }

    public get paymentTypeStr(): string {
        switch (this.consent.payment.type) {
            case "PIX": return "Pix - Open Finance";
            default: return ""
        }
    }

    public ok(): void {
        if (!this.busy) {
            this.onOk.emit();
        }        
    }
    
    public cancel(): void {
        if (!this.busy) {
            this.onCancel.emit();
        }        
    }

    scheduling() {
        this.isScheduling = false;
    }
}
