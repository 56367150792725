import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DetailsListShare, InstructionDataShare } from '@src/data/repository/data-share-repository';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DateValidator } from '@src/shared';

@Component({
  selector: 'fibra-query-transit-details-list',
  templateUrl: './query-transit-details.component.html',
  styleUrls: ['./query-transit-details.component.scss'],
})
export class QueryTransitRefusedListComponent implements OnInit {
  @Input() data;
  @Input() index;
  @Input() item;
  @Input() valueChoiceQuery
  @Input() valueChoiceVenc
  @Output() valueChoice = new EventEmitter();
  public choice;
  public createDate: NgbDate = new NgbDate(2000,1,1);
  public fromDate: NgbDate
  public toDate: NgbDate
  public dataInvalida: boolean = false
  @Output() isDateValid = new EventEmitter()
  @Output() isExpirationDateInvalid = new EventEmitter();
  public checkExpirationDate = false;
  createDefaultDateVencimento: NgbDate = new NgbDate(2000,1,1);
  date: Date;
  public expirationChange: NgbDate;
  public today: NgbDate;
  public myGroup: FormGroup;
  public value: string;
  public _isBatchChoose: boolean;
  public _isBatchAbatimentoChoose: boolean;
  public _isBatchAltVencimentoChoose: boolean;
  public _readonlyInput: boolean;  
  public invalidDateMessage = "Data inválida. Informe uma data superior a data atual.";
  public invalidExpirationDateMessage = "Data inválida. Informe uma data superior a data atual de vencimento";


  @Output() dateSelected: EventEmitter<NgbDate>;

  private _limparInput: boolean;

  get limparInput(): boolean {
    return this._limparInput;
  }

  @Input() set limparInput(value: boolean) {
    this._limparInput = value;
    if (value === true) {
      this.setValueChoice('');
    }  
  };

  get isBatchChoose(): boolean {
    return this._isBatchChoose;
  }

  @Input() set isBatchChoose(value: boolean) {
    this._isBatchChoose = value;
  }

  // verificar lote Abatimento
  get isBatchAbatimentoChoose(): boolean {
    return this._isBatchAbatimentoChoose;
  }

  @Input() set isBatchAbatimentoChoose(value: boolean) {
    this._isBatchAbatimentoChoose = value;    
  }

  // verificar lote Alteracao Vencimento
  get isBatchAltVencimentoChoose(): boolean {
    return this._isBatchAltVencimentoChoose;
  }

  @Input() set isBatchAltVencimentoChoose(value: boolean) {
    this._isBatchAltVencimentoChoose = value;
  }

  // verificar se foi Adicionado, assim deixar input como readonly
  get readonlyInput(): boolean {
    return this._readonlyInput;
  }

  @Input() set readonlyInput(value: boolean) {
    this._readonlyInput = value;
    
  }

  constructor(private shareValueChoice: DetailsListShare, private shareInstruction: InstructionDataShare, calendar: NgbCalendar) {
    this.today = calendar.getToday();
    this.createDate = calendar.getPrev(this.today, 'd', 0);
  }

  public dateToString = (date) => `${date.day}/${date.month}/${date.year}`; 

  public convertNgbDateToDate = (date): Date => new Date(date.year, date.month - 1, date.day);

  validateExpirationDate(selectedDate: string = ''): boolean {
    // Nova data de vencimento não pode ser inferior a anterior
    const newDate: string[] = selectedDate.split("/");
    const dataVencimento = moment(this.item.dt_vencimento).format('DD/MM/YYYY').split("/");
    const { dateNgb, dateNgbAn } = this.transformExpirationDate(newDate, dataVencimento);

    const invalidDate = dateNgbAn.after(dateNgb) || dateNgbAn.equals(dateNgb);
    
    this.isExpirationDateInvalid.emit(invalidDate);
    this.checkExpirationDate = invalidDate;

    return invalidDate;
  }

  ngOnInit() {
    if (this.valueChoiceVenc !== undefined) {
      if (this.validateExpirationDate(this.valueChoiceVenc)) {
        this.isDateValid.emit(false);
      }
      else {
        this.isExpirationDateInvalid.emit(false);
        this.isDateValid.emit(true);
      } 
    }

    this.sumDate();
    this.myGroup = new FormGroup({
      expirationChange: new FormControl()
    });
    this.shareValueChoice.dataShared.subscribe((res) => {
      this.choice = res && res.valueChoice;
    })
    this.shareInstruction.dataShared.subscribe((res) =>{
      this.data.inputServiceMoney =  res && res.dataQueryComponent.inputServiceMoney;
      this.data.inputServiceNumber =  res && res.dataQueryComponent.inputServiceNumber;
      this.data.calendarService = res && res.dataQueryComponent.calendarService;
    })
    this.date = new Date();
  }

  dataChanged(value: Event) {
    var isDate = value.target['value'].replaceAll(" ", "");
    if (isDate && isDate.length == 10) {
      var teste = value.target['value']
      const mdt = moment(teste, 'DD/MM/YYYY', true);
      if (!mdt.isValid()) {
        this.dataInvalida = true;
        this.isDateValid.emit(false);
      }
      else {
        let date = value.target['value'].split("/");
        var data = {
          day: parseInt(date[0], 10),
          month: parseInt(date[1], 10),
          year: parseInt(date[2], 10),
        };
        var dateNgb = new NgbDate(data.year, data.month, data.day)
        if ((this.item !== undefined && this.validateExpirationDate(value.target['value'])) || dateNgb.before(this.createDefaultDateVencimento)) {
          //data invalida
          this.dataInvalida = true;
          this.isDateValid.emit(false);
          this.setValueChoice('');
        } else {
          this.dataInvalida = false;
          this.isDateValid.emit(true);
          this.setValueChoice(moment(this.convertNgbDateToDate(dateNgb)).format('DD/MM/YYYY'));
        } 
      }
    }
  }

  transformExpirationDate(data1, data2) {
    var dateNgb =  new NgbDate(Number(data1[2]), Number(data1[1]), Number(data1[0]));
    var dateNgbAn =  new NgbDate(Number(data2[2]), Number(data2[1]), Number(data2[0]));

    return {dateNgb, dateNgbAn}
  }

  todate(value) {
    let date = value.split("/");
    let dataVenc = moment(this.item.dt_vencimento).format('DD/MM/YYYY').split("/");
    const { dateNgb, dateNgbAn } = this.transformExpirationDate(date, dataVenc);

    let invalidDate = false;

    if (dateNgbAn.after(dateNgb) || dateNgbAn.equals(dateNgb)) {
      invalidDate = true;
      this.checkExpirationDate = true;
    }

    if (dateNgb.before(this.createDefaultDateVencimento) || invalidDate) {
      //data invalida
      this.dataInvalida = true;
      this.isDateValid.emit(false);
      this.setValueChoice('');
    } else {
      this.dataInvalida = false;
      this.isDateValid.emit(true);
      this.setValueChoice(moment(this.convertNgbDateToDate(dateNgb)).format('DD/MM/YYYY'));
    } 
  }

  selectDate(obj) {
    const expirationDate = typeof(obj) === 'string' ? obj : this.dateToString(obj);
    
    if (typeof(obj) === 'string') {
      let aux = obj.split("/");
      obj = new NgbDate(Number(aux[2]), Number(aux[1]), Number(aux[0]));
    }

    if(obj) {
      this.setValueChoice(moment(this.convertNgbDateToDate(obj)).format('DD/MM/YYYY'));
    } else {
      this.setValueChoice('');
    }

    if (obj.before(this.createDefaultDateVencimento) || this.validateExpirationDate(expirationDate)) {
      //data invalida
      this.dataInvalida = true;
      this.isDateValid.emit(false);
    } else {
      this.isDateValid.emit(true);
      this.dataInvalida = false;
    } 
    
  }

  setValueChoice(event) {

    this.shareValueChoice.dataShared.subscribe((res) => {
      this.choice = res && res.valueChoice;
    })

    this.valueChoice.emit(event.target ? event.target.value : event)
    this.choice = event.target ? event.target.value : event;

    this.shareValueChoice.setValue({ // para quando for individual e houver detalhes
      "valueChoice": event.target ? event.target.value : event,  /////////////// Esse é "dt_vencimento, vlr_abatimento, num_dias_protesto"
      // "identifier": this.data.numberItem[this.index], /////////////// Esse é o seu_numero no request?
      // "vlr_titulo": this.data.originalValue[this.index], /////////////// Esse é o vlr_titulo no request?
      "vlr_titulo": this.item.vlr_titulo,
      "seu_numero": this.item.seu_numero
    })
  }


  sumDate(){
    const date = new Date()
    const newDate = moment(date).add(1, 'days').format('DD/MM/YYYY');
    const dateSplit = newDate.split('/');
    
    const itemExpirationDate = moment(this.item.dt_vencimento).add(1, 'days').format('DD/MM/YYYY').split("/");
    const ngbExpirationDate = new NgbDate(Number(itemExpirationDate[2]), Number(itemExpirationDate[1]), Number(itemExpirationDate[0]));
    const todayDate = new NgbDate(Number(dateSplit[2]), Number(dateSplit[1]), Number(dateSplit[0]));

    if (ngbExpirationDate.after(todayDate) || ngbExpirationDate.equals(todayDate))
      this.createDefaultDateVencimento = ngbExpirationDate;
    else 
      this.createDefaultDateVencimento = todayDate;

    //  var  getDateVencimento = moment(date).format('DD/MM/YYYY')
    //  const dateSplit = getDateVencimento.split('/');
 
    //  this.createDefaultDateVencimento = new NgbDate(Number(dateSplit[2]), Number(dateSplit[1]), Number(dateSplit[0]));

    }


}

