import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PixAccountEntity, AccountWithNoTermEntity } from '@src/data/repository/pix/accounts/pix-accounts-entity';
import { PixGetAccountsDataRepository } from '@src/data/repository/pix/accounts/pix-get-accounts-repository';
import { PixGetTermsDataRepository } from '@src/data/repository/pix/terms/pix-get-terms-repository';
import { AccessDataRepository } from '@src/data/repository/access-repository/access.repository';
import { PixGetBeneficiariesDataRepository } from '@src/data/repository/pix/beneficiaries/pix-get-beneficiaries-repository';
import { ToastErrorMessageComponent } from '../../components/toast-error-message/toast-error-message.component';
import jwt_decode from "jwt-decode";
import { RolesService } from '@src/shared/services/roles.service';

@Component({
  selector: 'fibra-pix-transferir',
  templateUrl: './pix-transferir.component.html',
  styleUrls: ['./pix-transferir.component.scss']
})
export class PixTransferirComponent implements OnInit {
  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;
  // public accountsWithPix: PixAccountEntity[];
  public accountsWithPix: any;
  public accountsWithoutPix: PixAccountEntity[];
  public accountsWithNoTerms: AccountWithNoTermEntity[]
  public loading = true;
  public hasError = false;
  public noData = false;
  @Input() public getAccWithNoTerms = false;
  @Input() navBarSelected = '';
  @Output() changeComponent = new EventEmitter();
  @Output() accountSelecionado = new EventEmitter();
  @Output() lerTermo = new EventEmitter();

  public showModalValidation = false
  public apiCallback = 85;
  public payload: any;

  public selectedAccount: any;
  public firstTime: any;
  public restriction: number = 1;

  public showTerms = false;
  public AlertCard = false;
  public showCardSucess = false;
  public showContent = false;
  public AlertCardTerms = false;
  public AlertCardTermsKey = false;
  public isMaster: any = false;
  public isShowTerm = false;
  @Output() handleGoto = new EventEmitter();

  public accountWithTermPending = [];
  public accountWithTermApprov = [];

  showByHavingTerm: boolean;

  constructor(
    public accountService: PixGetAccountsDataRepository,
    private modalService: NgbModal,
    private accesService: AccessDataRepository,
    private termsService: PixGetTermsDataRepository,
    public getBeneficiariesDataRepository: PixGetBeneficiariesDataRepository,
    private roles: RolesService
  ) { }


  ngOnInit() {
    this.setContentMenu();
      
    this.accesService.getAccess().subscribe((data: any) => {
      this.isMaster = data['data'].acessoMaster;
    })
    this.loading = true;
    if (this.getAccWithNoTerms) {
      this.getAccWithNoTerm();
    } else {
      this.geAccountsWithoutPix();
      this.getAccountsWithPix();
    }
  }

  public getAccounts() {
    this.accountsWithPix = new Array();
    this.accountsWithoutPix = new Array();
    this.accountService.getAccounts().subscribe((accs) => {
      const dados = accs;
      dados.data.forEach((res) => {
        if (res.chaves == null || res.chaves.length == 0) {
          this.accountsWithoutPix.push(res);
        } else {
          this.accountsWithPix.push(res)
        }
      })
      this.loading = false;
      this.hasError = false
    }, (_err) => {
      this.loading = false;
      this.hasError = true;
    })
  }
  public getAccountsWithPix() {
    this.termsService.getPixTerms().subscribe((data: any) => {
      this.accountsWithPix = data['data'];
      this.accountWithTermApprov = this.accountsWithPix.map((el, _i) => {
        return el.status === 'APROVADO';
      });

      this.accountWithTermPending = this.accountsWithPix.map((el, _i) => {
        return el.status === 'PENDENTE';
      });      
    });
  }

  public geAccountsWithoutPix() {
    this.termsService.getTerms().subscribe(data => {
      this.accountsWithoutPix = data['data'];
      this.loading = false;
      console.log( this.accountsWithoutPix)
    }, _error => {
      this.setValues();
    })
  }


  public getAccWithNoTerm() {
    this.accountsWithoutPix = [];
    this.accountService.getAccountsWithNoTerms().subscribe((res) => {
      this.accountsWithNoTerms = res.data;
      this.loading = false;
    }, (_err) => {
      this.setValues();
    })
  }

  setValues(){
    this.loading = false;
    this.noData = true;
    this.accountsWithoutPix = [];
  }

  public getIdAndSwitch(conta, dataAccount) {
    this.loading = true;
    this.hasError = false;

    //Conta selecionada para devolução Pix
    localStorage.setItem('refund-account-selected', dataAccount.numero);
    
    this.accountService.changeSelectedAccount(conta).subscribe((_res) => {
      localStorage.setItem('conta-selecionada', conta);
      if (this.navBarSelected === 'transferir') {
        localStorage.setItem('view-selecionada', 'transferir-beneficiario');
        window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
      } else if (this.navBarSelected === 'minhas-chaves') {
        localStorage.setItem('view-selecionada', 'minhaschaves-chaves');
        window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
      } else if (this.navBarSelected === 'refund') {
        localStorage.setItem('view-selecionada', 'refund-pix');
        window.dispatchEvent(new Event('FIBRA_SWITCH_VIEW'));
      }
      this.loading = false;
      this.hasError = false;

    }, (_error) => {
      this.loading = false;
      this.hasError = true;
    })
  }

  public goToTerm(id: number, _number?: string) {
    this.lerTermo.emit('true');
    this.accountService.changeSelectedAccount(id.toString()).subscribe((_res) => {

      this.loading = false;
      this.hasError = false;

    }, (_error) => {
      this.loading = false;
      this.hasError = true;
    })
  }


  public approveTerms() {
    this.closeModalApi("");
    this.showModalValidation = true;
  }

  showTerm() {
    this.isShowTerm = true;
    this.showContent = true;
    this.showTerms = true;
    this.selectedAccount = { num_conta: this.selectedAccount.num_conta, desc_conta: this.selectedAccount.desc_conta, exibicao: this.selectedAccount.exibicao, isApprovals: true };
    this.closeModal("");
  }

  public openModal(content, accs): void {
    if(accs.status_aprovacao_adesao === 'PENDENTE') {
      this.showContent = true;
      this.AlertCardTerms = this.navBarSelected === 'minhas-chaves' ? false : true;
      this.AlertCardTermsKey = this.navBarSelected === 'minhas-chaves' ? true : false;
      return;
    }
    this.selectedAccount = accs
    this.payload = { tipo_adesao: 1, num_conta: accs.num_conta }
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'modal-xxl',
      backdrop: 'static',
    });
  }

  selectedAccountNotTerms(accs, aderiu) {
    this.showContent = true;

    if(this.isMaster){
      this.AlertCardTerms = this.navBarSelected === 'minhas-chaves' ? false : true;
      this.AlertCardTermsKey = this.navBarSelected === 'minhas-chaves' ? true : false;
      this.showModalValidation = false;
    }
    
    if(!this.isMaster){
      this.AlertCardTerms = this.navBarSelected === 'minhas-chaves' ? false : true;
      this.AlertCardTermsKey = this.navBarSelected === 'minhas-chaves' ? true : false;

      if(!aderiu){
        this.selectedAccount = accs
        this.payload = { tipo_adesao: 1, num_conta: accs.num_conta }
        this.getBeneficiariesDataRepository.postPixTermoOperador(this.payload).subscribe((_res) => {
          this.showContent = true;
          this.AlertCardTerms = this.navBarSelected === 'minhas-chaves' ? false : true;
          this.AlertCardTermsKey = this.navBarSelected === 'minhas-chaves' ? true : false;
          this.showModalValidation = false;
        }, (_err) => {
          this.toast.callModalMessage(null, "Ocorreu um erro no envio.", "Favor tentar novamente.");
        })
      }
      
      if(aderiu){
        this.showContent = true;
        this.AlertCardTerms = this.navBarSelected === 'minhas-chaves' ? false : true;
        this.AlertCardTermsKey = this.navBarSelected === 'minhas-chaves' ? true : false;
        this.showModalValidation = false;
      }
    }
  }

  responseApiModal($event) {
    this.isShowTerm = false;
    this.showModalValidation = false;
    this.showContent = true;
    if ($event.data.status === "APROVADO") {
      this.showCardSucess = true;
      this.showContent = true;
      this.showModalValidation = false;
    }
    if ($event.data.status === "PENDENTE") {
      this.showContent = true;
      this.AlertCard = true;
      this.showModalValidation = false;
    }
    this.geAccountsWithoutPix();
    this.getAccountsWithPix();
  }

  goBack() {
    this.geAccountsWithoutPix();
    this.getAccountsWithPix();
    this.showCardSucess = false;
    this.AlertCardTerms = false;
    this.AlertCardTermsKey = false;
    this.showModalValidation = false;
    this.AlertCard = false;
    this.showContent = false;
  }

  closeModal(_$event) {
    this.showModalValidation = false;
    this.modalService.dismissAll();

  }

  changeTerm(_$event) {
    if (this.isShowTerm) {
      this.showTerms = false;
      this.showContent = false;
    } else {
      this.showTerms = false;
      this.showContent = true;
    }
  }

  public closeModalApi(_$event): void {
    this.showModalValidation = false;
    this.modalService.dismissAll();
  }


  public goToTranfer() {
    this.handleGoto.emit('trans');
  }

  setContentMenu() {
    if (this.navBarSelected === 'transferir') {
      this.restriction = 1;
    }

    if (this.navBarSelected === 'minhas-chaves') {
      this.restriction = 2;
    }

    if (this.navBarSelected === 'refund') {
      this.restriction = 3;
    }
  }

  public get rolesSemRestricao(): string {
    let result = '!PROBLEMA_CREDITO, !SAIDA_RISCO';

    if (this.navBarSelected === 'refund') {
      result = '!REFUND';
    }

    return result;
  }

  public get rolesComRestricao(): string {
    let result = 'PROBLEMA_CREDITO, SAIDA_RISCO';

    if (this.navBarSelected === 'refund') {
      result = '';
    }

    return result;
  }

  accountWithAuthorizationPix(account){

    const isMaster = this.roles.get().some(e => e === 'MASTER');
    if(isMaster){
      return true;
    }

    const token = sessionStorage.getItem('fibra-session');
    const decodedRolePix: any = jwt_decode(token);
    const rolesPix = decodedRolePix.UserRoles.filter(r => r.Name.startsWith('PIX.TRANS'))
    const accountRolesPix = rolesPix.map(r => r.NumeroConta)

    let groupRoles = [];
    for(let i = 0; i < accountRolesPix.length; i++){
      groupRoles = groupRoles.concat(accountRolesPix[i])
    }

    return groupRoles.some(ac => ac === account);
  }

  accountWithAuthPix(account, type){
    
    const isMaster = this.roles.get().some(e => e === 'MASTER');
    if(isMaster){
      return true;
    }

    let accounts = account.map(e => {
      if(type === 'c') return e.conta.numero;
      return e.num_conta;
    });

    const token = sessionStorage.getItem('fibra-session');
    const decodedRolePix: any = jwt_decode(token);
    const rolesPix = decodedRolePix.UserRoles.filter(r => r.Name.startsWith('PIX.TRANS'));
    const accountRolesPix = rolesPix.map(r => r.NumeroConta);

    let groupRoles = [];
    for(let i = 0; i < accountRolesPix.length; i++){
      groupRoles = groupRoles.concat(accountRolesPix[i])
    } 

    return groupRoles.some(i => accounts.includes(i));  
  }
}
