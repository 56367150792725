<ng-template #content let-modal>
  <div class="cobranca-online-container">
    <div class="modal-header">
      <div class="generic-info">
        <img alt="" src="/assets/svg/icon-check.svg"  />
        <h5>Título cadastrado com sucesso!</h5>
        <span *ngIf="countError>=2 && hasRoleSegViaBoleto">Não foi possível realizar o download do boleto. Acesse a segunda via de boleto.</span>
      </div>
    </div>
    <div class="text-center">
    <div class="col-12 buttons">
      <button
        *ngIf="showDownload"
        type="button"
        (click)="download()"
        class="secondary-button mt-2"
        [disabled]="countError>=2"
      >
        Download do Boleto
      </button>
    </div> 
    <div class="col-12 buttons">
      <button
        type="button"
        (click)="redirect()"
        class="secondary-button mt-2"
        *ngIf="countError>=2 && hasRoleSegViaBoleto"
      >
        Segunda via do Boleto
      </button>
    </div> 
    <div class="col-12 buttons">
      <button
        type="button"
        class="leaked-button mt-2"
        (click)="newCharge()"
      >
        Cadastrar novo título
      </button>
      </div>
    <div class="col-12 buttons">
      <button
        type="button"
        class="leaked-button mt-2 mb-5"
        (click)="close()"
      >
        Fechar
      </button>
    </div>
  </div>
</div>
</ng-template>
