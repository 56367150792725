import { FilterArchiveDataShare } from '@src/core/usecases/data-share/filter-data-share.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ArchiveModel } from '@src/core/domain/charge/archive.model';
import { GetListArchiveReturnUsecase } from '@src/core/usecases/charge/get-archiveListReturn.usecase';
import { GetListArchiveUsecase } from '@src/core/usecases/charge/get-archivesListTypes.usecase';
import { ListArchivesEntity } from '@src/data/repository/charge/archive-repository/archive.entity';
import * as Util from '@src/shared/util-common';
import { UploadDataShare } from '@src/data/repository/data-share-repository';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';

@Component({
  selector: 'fibra-list-archive',
  templateUrl: './list-archive.component.html',
  styleUrls: ['./list-archive.component.scss'],
})
export class ListArchiveComponent implements OnInit, OnDestroy {
  private _subFilter: Subscription;
  private _subRemessa: Subscription;
  public loading;
  public list = [];
  public listReturn = [];
  @Output() currentTab = new EventEmitter();
  @Output() listInput: EventEmitter<ArchiveModel>;
  public hide;
  params;
  active;
  @Input() upload;
  listFilesGroup: {};
  public showModalErro = false;
  nomeArquivo;
  mensagemErro;
  tamanhoArquivo;

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  constructor(
    private getArchiveList: GetListArchiveUsecase,
    private getArchiveReturnList: GetListArchiveReturnUsecase,
    private filterShared: FilterArchiveDataShare,
    private uploadDataShare: UploadDataShare
  ) {
    this.list = [];
    this.listReturn = [];
    this.params = [];
    this.listInput = new EventEmitter();
  }

  private showToast(value: any): void {
    const isMessageServer: boolean = !!(value && value.code && value.message && value.message.length > 0);
    isMessageServer && this.toast && this.toast.callModalMessage(null, value.message[0], null, null, null);
  }

  ngOnInit() {
    this.showModalErro = false;
    this.filterShared.dataToShare.next([])
    this.handleTabs('first', false);
    this.initList();
    this.reloadForUpload();
  }

  ngOnDestroy(): void {
    if (this._subFilter) {
      this._subFilter.unsubscribe();
    }

    if (this._subRemessa) {
      this._subRemessa.unsubscribe();
    }
  }

  private initList() {
    if (this._subFilter) {
      this._subFilter.unsubscribe();
    }

    this._subFilter = this.filterShared.dataShared.subscribe(
      (res: { account: string; dt_Inicio: string; dt_Final: string, codigo_especie: string, param_carteira: any }) => {
        if (res && res.dt_Inicio) {
          this.params = {
            num_conta: res.account,
            dt_Final: res.dt_Final,
            dt_Inicio: res.dt_Inicio,
            codigo_especie: res.codigo_especie,
            param_carteira: res.param_carteira
          };

          if (this.active == 'first') {
            this.requestArchiveList()
          } else {
            this.requestArchiveReturnList()
          }
        }
      }, (error) => this.showToast(error)
    );
  }

  private reloadForUpload() {
    this.uploadDataShare.dataShared.subscribe(
      (res) => {
        if (res === true) {
          this.handleTabs('first');
        }
      }, (error) => this.showToast(error));
  }

  private requestArchiveList = () => {
    this.hide = false;
    this.loading = true;

    if (this._subRemessa) {
      this._subRemessa.unsubscribe();
    }

    this._subRemessa = this.getArchiveList
      .execute(this.params)
      .subscribe(this.requestSuccess, this.requestError, this.requestComplete);
  };

  private requestArchiveReturnList = () => {
    this.getArchiveReturnList
      .execute(this.params)
      .subscribe(this.requestSuccessReturn, this.requestError, this.requestComplete);
  };

  private requestSuccessReturn = (value: ArchiveModel) => {
    this.listReturn = this.orderByDataReturn(Util.verifyArray(value.data));

    this.listInput.emit(value.data);

    if (this.active == "second" && !Util.isEmpty(this.listReturn)) {
      this.hide = false;
    }
    else
      this.hide = true;
  };

  private requestSuccess = (value: ArchiveModel) => {
    this.list = [];
    //this.list = Util.verifyArray(value.data);

   this.list = this.orderByData(Util.verifyArray(value.data));

    this.listInput.emit(value.data);

    if (this.active == "first" && this.list) {
      this.hide = false;
    }
    else
      this.hide = true;
  };

  handleTabs(stage, request = true) {
    this.showModalErro = false;
    this.active = stage;
    this.currentTab.emit(this.active);
    this.loading = true;
    this.hide = false;
    this.list = [];
    this.listReturn = [];

    if (request) {
      if (stage === 'first') {
        setTimeout(() => {
          this.requestArchiveList();
        }, 700);
      } else if (stage === 'second') {
        setTimeout(() => {
          this.requestArchiveReturnList();
        }, 600);
      }
    }
  }

  public showModal(value) {
    this.showModalErro = !this.showModalErro;
    if(this.showModalErro) {
    this.nomeArquivo = value.nomeArquivo;
    this.mensagemErro = value.mensagemErro;
    this.tamanhoArquivo = value.tamanhoArquivo;
    }
  }

  public closeEvent(close: any) {
    this.showModalErro = false;
  }

  private requestError = (err) => {
    this.list = [];
    this.listReturn = [];
    this.listInput.emit(null);
    this.showToast(err);
    this.hide = true; //Util.isEmpty(this.list);
    this.loading = false;
    return console.log(err);
  };

  private requestComplete = () => {
    this.loading = false;
  };

  isNull = (value) => {
    return Util.isNull(value);
  };

  iconPath(cod, desc): string {
    const pathCredit = '/assets/svg/icon_coin.svg';
    const pathDebit = '/assets/svg/icon_boleto.svg';
    const pathTransfer = '/assets/svg/icon_transferencia.svg';
    const isTransf = desc.includes('TRANSF.');
    if (cod === 'C' && isTransf === false) {
      return pathCredit;
    }

    if (cod === 'D' && isTransf === false) {
      return pathDebit;
    }

    if (cod === 'T') {
      return pathTransfer;
    }

    if (cod === 'D' && isTransf === true) {
      return pathTransfer;
    }

    if (cod === 'C' && isTransf === true) {
      return pathTransfer;
    }
  }

  formatBytes(bytes, decimals?) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const resp = parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];

    let nonundef = resp.indexOf('NaN undefined') < 0;

    if (nonundef)
      return resp;

    return '0 KB';
  }

  download(element) {
    this.getArchiveReturnList
      .executeDownload(element.chaveArquivo)
      .subscribe((value) => {
        if (value && value.data) {
          let fileUrl = value.data.split("u0026").join("&");
          window.open(fileUrl, "_blank");
        }
      },
        this.requestError,
        this.requestComplete);
  }

  orderByData = (dt) => {
    // Cria um array com grupo de datas e ordena pelas mesmas
    const dataFiles = Util.verifyArray(dt).reduce((group, files) => {
      const data = files.dataEnvio.split('T')[0];

      if(!group[data]){
        group[data] = [];
      }

      group[data].push(files);

      return group;
    }, {});

    const filesGroup = Object.keys(dataFiles).map((date) => {
      return {
        date,
        files: dataFiles[date]
      };
    });

   return filesGroup.sort((a, b) => <any>new Date(b.date) - <any>new Date(a.date));
  }
  orderByDataReturn = (dt) => {
    // Cria um array com grupo de datas e ordena pelas mesmas
    const dataFiles = Util.verifyArray(dt).reduce((group, files) => {
      const data = files.data.split('T')[0];

      if(!group[data]){
        group[data] = [];
      }

      group[data].push(files);

      return group;
    }, {});

    const filesGroup = Object.keys(dataFiles).map((date) => {
      return {
        date,
        files: dataFiles[date]
      };
    });

   return filesGroup.sort((a, b) => <any>new Date(b.date) - <any>new Date(a.date));
  }
}