import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PixGetTermsDataRepository } from '@src/data/repository/pix/terms/pix-get-terms-repository';
import { PixCreateTermDataRepository } from '@src/data/repository/pix/terms/pix-create-terms-repository';
import { PixTermEntity } from '@src/data/repository/pix/terms/pix-terms.entity';
import * as $ from 'jquery';
import { tick } from '@angular/core/testing'; 
import { versionTerm } from '@src/shared/util-common';
import { PostAcceptTermUsecase } from '@src/core/usecases/accept-term/accept-term.usecase'; 
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators'; 

@Component({
  selector: 'fibra-pix-terms-approvals',
  templateUrl: './pix-terms-approvals.component.html',
  styleUrls: ['./pix-terms-approvals.component.scss']
})
export class PixTermsApprovalsComponent implements OnInit {

  @ViewChild('content', { static: true }) content: HTMLElement;
  @ViewChild('contentReprove', { static: true }) contentReprove: HTMLElement;
  @Input() accountNum: string;
  @Output() handleGoto = new EventEmitter();
  @Output() updateNotif = new EventEmitter();

  public showTerm = false;
  public showModal = false;
  public enable = false;
  public termsData: any[];
  public termsDataFiltered: any[];
  public termoId;
  public noDataMessage = '';
  public firstTime = false;
  public accNum: string;
  public contaSelected: string;
  public noData = false;
  public loading = false;
  public hasError = false;
  public errorMessage = '';
  public filterByAcc = false;
  public filterWithCards = false;
  public ids = ['adesao', 'pendente', 'aprovado', 'reprovado'];

  public accountsWithoutMembership: [];
  public selectedAccount: any;
  public showCardSucess = false;
  public AlertCard = false;
  public showlabel = false;
  public alertTitle = '';
  public alertText = ''
  public notif = false;

  public isConfirmad;

  public apiCallback = 85;
  public payload;
  public showModalValidation: any = false;
  public idTerm;
  public isRefused = false;
  public cardRefused = false;

  public cardPedding = false;
  public userNanme: any;
  public goStatusApproved = true;
  public showContent: boolean = false;
  isPadding = false

  showCards = true;

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  constructor(private modalService: NgbModal,
    private termsService: PixGetTermsDataRepository,
    public createTermService: PixCreateTermDataRepository,
    private acceptTermUsecase: PostAcceptTermUsecase) { }

  ngOnInit() {
    this.filterByAcc = true;
    this.filterWithCards = false;
    this.getTermsData();
    this.termsDataFiltered = this.termsData;
    this.getAccountsWithoutMembership();
    this.userNanme = localStorage.getItem('useremail')
    this.updateNotif.emit('');


  }

  public openModal(content): void {
    this.showModalValidation = false;
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'modal-xxl',
      backdrop: 'static',
    });
  }

  public closeModal($event): void {
    this.showModalValidation = false;
    this.modalService.dismissAll();
  }
  public closeModalApi($event) {
    this.showModalValidation = false;
    this.modalService.dismissAll();
  }

  public async getTermsData(): Promise<boolean> {
    const obs = new Subject<boolean>();

    this.notif = false;
    this.loading = true;
    this.termsData = new Array();
    this.termsDataFiltered = new Array();
    this.termsService.getPixTerms()
    .pipe(finalize(() => {
      obs.next(true);
      obs.complete();
      this.loading = false;
    }))
    .subscribe((terms) => {
      this.termsData.push(terms.data)
      this.termsData[0].forEach((res) => {
        if (res.status === 'REPROVADO') {
          res.statusMessage = 'Recusado';
          res.circleClass = 'circle-red';
          res.statusClass = 'status-message-red'
        } else if (res.status === 'PENDENTE') {
          this.isPeddingotherMaster(res.aprovacoes) === true  ? this.notif = true : this.notif = false;
          res.statusMessage = 'Pendente de aceite';
          res.circleClass = 'circle-orange';
          res.statusClass = 'status-message-orange'
        } else if (res.status === 'APROVADO') {
          res.statusMessage = 'Aceito';
          res.circleClass = 'circle-green';
          res.statusClass = 'status-message-green'
        }
      })
      this.loading = false;
      this.hasError = false;
    }, (error) => {
      this.loading = false;
      this.noData = true;
      // this.errorMessage = error.error.message[0];
    })

    return obs.toPromise();
  }

  getAccountsWithoutMembership() {
    this.loading = true;
    this.termsService.getTerms().subscribe(data => {
      this.accountsWithoutMembership = data['data'];
      this.loading = false;
      this.showlabel = true;
    }, error => {
      // if (error.status === '404') {
      //   this.alertTitle = 'Você não possui uma conta ativa';
      //   this.alertTitle = 'Para realizar um Pix, é necessário possuir conta corrente ativa.';
      // }
      this.accountsWithoutMembership = [];
      this.showlabel = false;
      this.termsDataFiltered = [];
      this.loading = false;
      this.noDataMessage = "Nenhum registro encontrado";

    })
    this.getTermsData();
  }

  public getTermoId(account: any, type) {
    this.isPadding = type;
    this.selectedAccount = account.conta;
    this.idTerm = account.id;
  }

  public verTermo(item, number: any, desc_acount: string, isApprovals, id) {
    if(item.status_aprovacao_adesao === 'PENDENTE') {
      this.showContent = true;
      return;
    }
    this.isConfirmad = isApprovals;
    this.showTerm = true;
    this.selectedAccount = { num_conta: number, desc_conta: desc_acount, isApprovals: isApprovals, id: this.idTerm };
    this.closeModal("");
    console.log(item);
  }

  public approveTerm() {
    this.loading = true;
    this.hasError = false;
    this.termsService.approvePixTerm(this.termoId).subscribe(() => {
      this.modalService.dismissAll();
    }, (err) => {
      this.loading = false;
      this.hasError = true;
      this.errorMessage = err.error.message[0];
      this.modalService.dismissAll();
    });
  }

  public acceptTermByComponent(event) {
    this.loading = true;
    this.hasError = false;
    if (this.firstTime) {
      this.createTermService.createPixTerm().subscribe((res) => {
        this.showTerm = !this.showTerm;
      }, (err) => {
        this.loading = false;
        this.hasError = true;
        this.errorMessage = err.error.message[0];
      });
    } else {
      this.termsService.approvePixTerm(this.termoId).subscribe(() => {
        this.showTerm = !this.showTerm;
      }, (err) => {
        this.loading = false;
        this.hasError = true;
        this.errorMessage = err.error.message[0];
      });
    }

  }

  public changeTerm(evento) {
    this.changeFilterClass('pendente');
    if (this.isPadding) {

    }

    this.showTerm = !this.showTerm;

  }

  public filterTerms(status: string) {
    this.hasError = false;
    this.noData = false;
    this.loading = false;
    this.showCardSucess = false;

    if (status === 'SEM-TERMO') {
      this.getAccountsWithoutMembership();
      this.AlertCard = false;
      this.filterByAcc = true;
      this.filterWithCards = false;
      this.noDataMessage = '';
      this.showContent = false;
    } else if (status === 'PENDENTE') {
      this.termsDataFiltered = this.termsData[0] ? this.termsData[0].filter((term) => {
        this.showContent = false;
        return term.status === 'PENDENTE';
      }) : [];

      this.filterByAcc = false;
      this.filterWithCards = true;
      this.noDataMessage = 'Nenhuma solicitação de aceite pendente';
    } else if (status === 'REPROVADO') {
      this.termsDataFiltered = this.termsData[0] ? this.termsData[0].filter((term) => {
        this.showContent = false;
        return term.status === 'REPROVADO';
      }) : []
      this.filterByAcc = false;
      this.filterWithCards = true;
      this.noDataMessage = 'Nenhum termo recusado';
    } else if (status === 'APROVADO') {
      this.termsDataFiltered = this.termsData[0] ? this.termsData[0].filter((term) => {
        this.showContent = false;
        return term.status === 'APROVADO';
      }) : []
      this.filterByAcc = false;
      this.filterWithCards = true;
      this.noDataMessage = 'Nenhum termo aceito ainda';

      console.log(this.termsDataFiltered);
    }
  }

  public changeFilterClass(type: string) {



    if (type === 'adesao') {
      $('#adesao').removeClass('option-unselected');
      $('#adesao').addClass('option-selected');
      this.ids.forEach((elem) => {
        if (elem !== 'adesao') {
          $(`#${elem}`).removeClass('option-selected');
          $(`#${elem}`).addClass('option-unselected');
        }
      })
    } else if (type === 'pendente') {
      $('#pendente').removeClass('option-unselected');
      $('#pendente').addClass('option-selected');
      this.ids.forEach((elem) => {
        if (elem !== 'pendente') {
          $(`#${elem}`).removeClass('option-selected');
          $(`#${elem}`).addClass('option-unselected');
        }
      })
    } if (type === 'aprovado') {
      $('#aprovado').removeClass('option-unselected');
      $('#aprovado').addClass('option-selected');
      this.ids.forEach((elem) => {
        if (elem !== 'aprovado') {
          $(`#${elem}`).removeClass('option-selected');
          $(`#${elem}`).addClass('option-unselected');
        }
      })
    } else if (type === 'reprovado') {
      $('#reprovado').removeClass('option-unselected');
      $('#reprovado').addClass('option-selected');
      this.ids.forEach((elem) => {
        if (elem !== 'reprovado') {
          $(`#${elem}`).removeClass('option-selected');
          $(`#${elem}`).addClass('option-unselected');
        }
      })
    }
  }

  public readTermFirstTime(event) {
    this.showTerm = !this.showTerm;
    this.firstTime = true;
  }

  public createTerm() {
    this.createTermService.createPixTerm().subscribe((res) => {
      this.showTerm = false;
    })
  }


  public reproveSelectedTerm() {
    this.termsService.reprovePixTerm(this.termoId).subscribe((res) => {
      this.showTerm = !this.showTerm;
      this.getTermsData();
    }, (err) => {
      this.loading = false;
      this.hasError = true;
      this.errorMessage = err.error.message[0];
      this.modalService.dismissAll();
    });
  }

  refusedTerm() {
    this.isRefused = true;
    this.apiCallback = 87;
    this.payload = this.idTerm;
    this.showModalValidation = true
  }

  approverdPeddingTerms() {
    this.closeModal("");
    this.isRefused = false;
    this.apiCallback = 86;
    this.payload = this.idTerm;
    this.showModalValidation = true
  }

  seletecAccount(id: string) {


  }

  responseApi($event) {
    this.updateNotif.emit('');
    if (this.isConfirmad === false) {
      this.showlabel = false;
      this.cardPedding = true;
    }
    if ($event.data.status === "APROVADO") {
      this.showCardSucess = true;
      this.accountsWithoutMembership = [];
      this.AlertCard = false;
      this.showlabel = false;
      this.cardPedding = false;
      this.noDataMessage = '';

    }
    if ($event.data.status === "PENDENTE") {
      this.accountsWithoutMembership = [];
      this.alertTitle = 'Adesão ao Pix pendente';
      this.alertText = `A adesão ao Termo para a conta ${this.selectedAccount.num_conta} - ${this.selectedAccount.desc_conta} está pendente de aprovação dos outros masters.`;
      this.showlabel = false;
      this.AlertCard = true;
    }

    this.getTermsData();
    this.isConfirmad === true;

  }



  goBack() {
    this.updateNotif.emit('');
    this.showlabel = true;
    this.cardPedding = false
    this.showCardSucess = false;
    this.AlertCard = false;
    this.cardRefused = false;
    this.showCards = true;


  }

  goBackRefused() {
    this.updateNotif.emit('');
    this.getTermsData();
    this.cardRefused = false;

  }

  goStatus() {
    this.updateNotif.emit('');
    this.getTermsData();
    this.cardPedding = false

  }

  async responseApiModal($event) {
    this.updateNotif.emit('');
    this.showModalValidation = false;
    if (this.isRefused) {
      this.showCards = false;
      this.getTermsData();
      this.showCards = false;
      this.cardRefused = true;
    }

    if ($event.error && $event.error.code && $event.error.code == 400 && $event.error.message && $event.error.message.length) {
      this.toast.callModalMessage(null, $event.error.message[0], null, false, true);
      await this.getTermsData();
      this.filterTerms('PENDENTE');
    } else {
      if (!this.isRefused && $event['data'].status === "APROVADO") {
        this.showCardSucess = true; 
      this.aceptNewTerm();
      this.getTermsData(); 
        this.getTermsData();
      } else {
        this.noDataMessage = '';
      }
  
      if (!this.isRefused && $event['data'].status === "PENDENTE") {
        this.cardPedding = true;
        this.getTermsData();
      } else {
        this.noDataMessage = '';
      }      
    }
 
  }

  aceptNewTerm(){
    let params = {
      idTermo: versionTerm.VERSAO
    };

    this.acceptTermUsecase.execute(params).subscribe(rs => {
      console.log(rs)
    }); 
  }


  isPeddingotherMaster(approveds) {
    let isOtherMaster = false;
    approveds.map(data => {
      if (data.status === 'PENDENTE' && this.userNanme === data.pessoa.email) {
        isOtherMaster = true
      }
    })
    return isOtherMaster
  }

  goKeys() {
    this.handleGoto.emit('keys');
  }
  goTransf() {
    this.handleGoto.emit('trans');
  }

  returnContentFalse() {
    this.showContent = false;
  }


}
