import { AbstractControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { VerifyValidData } from './verifyValidDate'

export class DateValidator {
  /**
   * Método estático responsável pela validação dos dados.
   *
   * @param AbstractControl control
   * @return object ou null caso válido
   */

  static onlyDateEqualOrLessThanToday(control: AbstractControl): { [key: string]: boolean } {
    let dateFormated = null
    let date: any

    if (control.value && !control.value.year) {
      const splitDate = control.value.split('/')
      dateFormated = `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`
      date = new Date(splitDate[2], splitDate[1] - 1, splitDate[0])
    } else if (control.value) {
      dateFormated = `${control.value.year}/${control.value.month}/${control.value.day}`
      date = new Date(control.value.year, control.value.month - 1, control.value.day)
    }

    const today = new Date();

    const isValidDate = VerifyValidData.validator(dateFormated)

    const hoje = (moment(today).format("DD/MM/YYYY"))
    const dataSelecionada = (moment(date).format("DD/MM/YYYY"))
    var parts = hoje.split('/');
    var dateToday = Number(parts[2] + parts[1] + parts[0]);
    parts = dataSelecionada.split('/');
    var dateSelect = Number(parts[2] + parts[1] + parts[0]);

    if (isValidDate && dateSelect <= dateToday) {
      return null;
    }

    return { dateGreaterToday: true };
  }

  static onlyDateEqualOrGreaterThanToday(control: AbstractControl): { [key: string]: boolean } {
    const date = new Date(control.value.year, control.value.month - 1, control.value.day)
    const today = new Date();

    if (date >= today) {
      return null;
    }

    return { dateLessToday: true };
  }

  static onlyDateEqualOrGreaterThanFiveDays(control: AbstractControl): { [key: string]: boolean } {
    let date: any
    let dateFormated = null

    if (control.value && !control.value.year) {
      const splitDate = control.value.split('/')
      date = new Date(splitDate[2], splitDate[1] - 1, splitDate[0])
      dateFormated = `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`
    } else if (control.value && control.value.year) {
      dateFormated = `${control.value.year}/${control.value.month}/${control.value.day}`
      date = new Date(control.value.year, control.value.month - 1, control.value.day)
    }

    const today = new Date();
    const newToday = new Date(moment(today).format('YYYY/MM/DD')); 
    const newDate = new Date(moment(today).add(5, 'days').format('YYYY/MM/DD'));
    const newDate1 = new Date(newDate);

    const isValidDate = VerifyValidData.validator(dateFormated)

    if (isValidDate && date >= newDate) {
      return null;
    }

    return { dateLessTodayFiveDays: true };
  }

  static dateEqualOrGreaterThanToday(control: AbstractControl): { [key: string]: boolean } {
    let date

    if (control.value && !control.value.year) {
      const splitDate = control.value.split('/')
      date = new Date(splitDate[2], splitDate[1] - 1, splitDate[0])
    } else if (control.value && control.value.year) {
      date = new Date(control.value.year, control.value.month - 1, control.value.day)
    }

    const today = new Date();
    const hoje = (moment(today).format("DD/MM/YYYY"))
    const dataSelecionada = (moment(date).format("DD/MM/YYYY"))
    var parts = hoje.split('/');
    var dateToday = Number(parts[2] + parts[1] + parts[0]);
    parts = dataSelecionada.split('/');
    var dateSelect = Number(parts[2] + parts[1] + parts[0]);

    if (dateSelect >= dateToday) {

      return null;
    }

    return { dateLessToday: true };
  }

  static onlyDateLessOtherDate(controlName: string, otherControlname: string) {
    return (formGroup: FormGroup) => {
      let controlDate: any
      let otherControlDate: any
      let dateFormated = null

      const control = formGroup.controls[controlName];
      const otherControl = formGroup.controls[otherControlname];

      if (control.errors || !otherControl.value || !control.value) return;

      if (control.value && control.value.year) {
        controlDate = new Date(control.value.year, control.value.month - 1, control.value.day)
        dateFormated = `${control.value.year}/${control.value.month}/${control.value.day}`
      } else if (control.value) {
        const splitDate = control.value.split('/')
        controlDate = new Date(splitDate[2], splitDate[1] - 1, splitDate[0])
        dateFormated = `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`
      }

      if (otherControl.value && otherControl.value.year) {
        otherControlDate = new Date(otherControl.value.year, otherControl.value.month - 1, otherControl.value.day);
      } else if (otherControl.value) {
        const splitDate = otherControl.value.split('/')
        otherControlDate = new Date(splitDate[2], splitDate[1] - 1, splitDate[0])
      }

      const isValidDate = VerifyValidData.validator(dateFormated)

      if (!isValidDate || controlDate > otherControlDate) {
        control.setErrors({ mustSmallerDate: true })
      } else {
        control.setErrors(null)
      }
    }
  }
}
