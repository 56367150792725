<section class="sideCard" [style.height]="options.labelFiveRow ? '682px' : '545px'">
  <h1 *ngIf="!loading" class="title">{{ options.cardTitle }}</h1>

  <div class="side-card-content" *ngIf="predicted && !loading">
    <div class="list" id="sideCardFirstRow">
      <div class="circle-progress">
        <circle-progress
          [percent]="50"
          [outerStrokeColor]="'#72b699'"
          [imageSrc]="'/assets/svg/arrow_green.svg'"
        ></circle-progress>
      </div>

      <div class="item">
        <h1>{{ options.labelFirstRow }}</h1>
        <p>
          <span>R$</span
          >{{
            isEmpty(predicted.expected_entries)
              ? '0,0'
              : (predicted.expected_entries | money)
          }}
        </p>
      </div>
    </div>
    <div class="list" id="sideCardSecondRow">
      <div class="circle-progress">
        <circle-progress
          [percent]="50"
          [outerStrokeColor]="'#ed6b6c'"
          [imageSrc]="'/assets/svg/arrow_red.svg'"
        ></circle-progress>
      </div>

      <div class="item">
        <h1>{{ options.labelSecondRow }}</h1>
        <p>
          <span>R$</span
          >{{
            isEmpty(predicted.expected_exits)
              ? '0,0'
              : (predicted.expected_exits | money)
          }}
        </p>
      </div>
    </div>

    <div 
    *ngIf="options.labelFiveRow"
      class="list" 
      id="sideCardFiveRow"
    >
      <div class="circle-progress">
        <circle-progress
          [percent]="50"
          [outerStrokeColor]="'#ed6b6c'"
          [imageSrc]="'/assets/svg/arrow_red.svg'"
        ></circle-progress>
      </div>
      <div class="item">
        <h1>{{ options.labelFiveRow }}</h1>
        <p>
          <span>R$</span
          >{{
            isEmpty(predicted.expected_the_registry_office)
              ? '0,00'
              : (predicted.expected_the_registry_office | number: '1.2-2')
          }}
        </p>
      </div>
    </div>


    <div class="list" id="sideCardThirdRow">
      <div class="circle-progress">
        <circle-progress
          *ngIf="options.labelThirdRow !== 'Títulos baixados'"
          [percent]="50"
          [outerStrokeColor]="'#d69e15'"
          [imageSrc]="'/assets/svg/arrow_graph.svg'"
          [imageHeight]="18"
          [imageWidth]="28"
        ></circle-progress>
        <circle-progress
        *ngIf="options.labelThirdRow === 'Títulos baixados'"
        [percent]="50"
        [outerStrokeColor]="'#B594BD'"
        [imageSrc]="'/assets/svg/arrow_lilac.svg'"
      ></circle-progress>
      </div>
      <div class="item">
        <h1>{{ options.labelThirdRow }}</h1>
        <p *ngIf="!isCarteira">
          <span>R$</span
          >{{
            isEmpty(predicted.expected_real_balance)
              ? '0,0'
              : (predicted.expected_real_balance | number: '1.2-2')
          }}
        </p>

        <p *ngIf="isCarteira">
          <span>R$</span
          >{{
            isEmpty(predicted.expected_downloaded)
              ? '0,0'
              : (predicted.expected_downloaded | number: '1.2-2')
          }}
        </p>
      </div>
    </div>
    <div 
    *ngIf="options.labelFourthRow"
      class="list" 
      id="sideCardFourthRow"
    >
      <div class="circle-progress">
        <circle-progress
          [percent]="50"
          [outerStrokeColor]="'#d69e15'"
          [imageSrc]="'/assets/svg/arrow_graph.svg'"
          [imageHeight]="18"
          [imageWidth]="28"
        ></circle-progress>
      </div>
      <div class="item">
        <h1>{{ options.labelFourthRow }}</h1>
        <p>
          <span>R$</span
          >{{
            isEmpty(predicted.expected_real_balance)
              ? '0,0'
              : (predicted.expected_real_balance | number: '1.2-2')
          }}
        </p>
      </div>
    </div>
  </div>

  <button
    *ngIf="!loading && !isCobranca"
    [hidden]="hide"
    type="button"
    class="button-continue"
    routerLink="/transfer"
  >
    Transferências entre contas
  </button>
  <div
    class="loading-container d-flex justify-content-center align-items-center"
    *ngIf="loading"
  >
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</section>
