import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as models from '@src/data/repository/data-share-repository';
import { InvestimentsDataRepository } from '@src/data/repository/investiments/investiments.repository';
import { ExtractListEnum, ExtractProdutosListEnum } from '@src/data/repository/investiments/investiments.entity';
import { grupoProdutoList } from '@src/data/repository/data-share-repository';
import * as $ from 'jquery';

@Component({
  selector: 'fibra-investments-extrato-table',
  templateUrl: './investments-extrato-table.component.html',
  styleUrls: ['./investments-extrato-table.component.scss']
})
export class InvestmentsExtratoTableComponent implements OnInit, OnDestroy {
  public extract: ExtractListEnum[];
  public loading: boolean = false;
  public noData: boolean = false;
  public noDataProdutos: boolean = true;
  public hasError: boolean = false;
  public produtos: ExtractProdutosListEnum[];
  public filterByPeriod = false;
  public calendar = false;
  public periods = ['seven', 'fifteen', 'thirthy', 'ninethy', 'calendar'];
  public dataInicio: string;
  public dataFim: string;
  public periodoSelected = '';
  public maxDate: NgbDate;
  public sorted: boolean;
  public produtoSelect: string;
  public dataSelecionada: Date;
  public dataFilterChecked: string = null;

  constructor(
    private calendario: NgbCalendar,
    private formBuilder: FormBuilder,
    private InvestimentsService: InvestimentsDataRepository
  ) { }

  ngOnInit() {
    this.getExtractProdutos();
    this.maxDate = this.calendario.getToday();
    this.getExtract(new Date(), this.produtoSelect);
  }

  getExtractProdutos() {
    this.InvestimentsService.getExtractProdutos().subscribe((data) => {
      this.produtos = data.data;
      if (this.produtos && this.produtos.length > 0) {
        this.noDataProdutos = false;
        this.produtos.splice(0, 0, { name: "Todos", value: "Todos" });
      }
      console.log(this.produtos);
    }, () => {
      this.produtos = [];
    });
  }

  getExtract(dt: Date, produto?: string) {
    sessionStorage.setItem('informations_pdf', '{"title":"Investimentos", "name":"investiments-extract", "report_name":"investiments-extract", "filter":"Extrato", "dt_ref":"' + dt.toString() + '"}')

    if (produto !== null)
      sessionStorage.setItem('produtoInvestimentoExcel', produto)


    var tipoOpera = this.dataFilterChecked;
    this.dataSelecionada = dt;
    var dataSelecionada = `${dt.toLocaleString().slice(6, 10)}-${dt.toLocaleString().slice(3, 5)}-${dt.toLocaleString().slice(0, 2)}`;
    this.loading = false;
    this.InvestimentsService.getExtract(dataSelecionada, null, produto, tipoOpera).subscribe((data) => {
      this.extract = data.data;

      //PDF
      sessionStorage.setItem('investiments_extrato_list_pdf', JSON.stringify(this.extract));
      console.log(this.extract);
      if (this.extract && this.extract.length > 0) {
        this.noData = false;
        this.loading = true;
        this.hasError = false;
      } else {
        this.noData = true;
        this.loading = true;
        this.hasError = false;
      }
    }, () => {
      this.hasError = true;
      this.noData = true;
      this.loading = true;
      this.extract = [];
    });
  }

  getExtractPeriod(dt_inic: string, dt_fim: string, produto?: string, tipoOpera?: string) {
    this.dataInicio = dt_inic;
    this.dataFim = dt_fim;


    sessionStorage.setItem('dt_op', this.dataInicio)
    sessionStorage.setItem('dt_vencto', this.dataFim)

    if (produto !== null)
      sessionStorage.setItem('produtoInvestimentoExcel', produto)


    this.loading = false;
    this.InvestimentsService.getExtract(dt_inic, dt_fim, produto, tipoOpera).subscribe((data) => {
      this.extract = data.data;
      //PDF
      sessionStorage.setItem('investiments_extrato_list_pdf', JSON.stringify(this.extract));
      sessionStorage.setItem('informations_pdf', '{"title":"Investimentos", "name":"investiments-extract", "report_name":"investiments-extract", "filter":"Extrato", "dt_ref":"' + dt_inic.toString() + '"}')
      console.log(this.extract);
      if (this.extract && this.extract.length > 0) {
        this.noData = false;
        this.loading = true;
        this.hasError = false;
      } else {
        this.noData = true;
        this.loading = true;
        this.hasError = false;
      }
    }, () => {
      this.hasError = true;
      this.noData = true;
      this.loading = true;
      this.extract = [];
    });
  }

  filterProduct(filterProduct: any) {
    this.produtoSelect = filterProduct;
    if (this.produtoSelect == 'Todos') {
      this.produtoSelect = null;

    }
    if (this.dataInicio && this.dataFim) {
      sessionStorage.setItem('produtoInvestimentoExcel', this.produtoSelect)
      this.getExtractPeriod(this.dataInicio, this.dataFim, this.produtoSelect, this.dataFilterChecked);
    } else {
      sessionStorage.setItem('produtoInvestimentoExcel', this.produtoSelect)
      this.getExtract(this.dataSelecionada, this.produtoSelect);
    }
  }

  //FILTRO DE DATAS PERIODO
  public changePeriodFilter(period: string) {
    if (period === '7') {
      $('#seven-div').removeClass('period-item-unselected');
      $('#seven-div').addClass('period-item-selected');
      $('#seven-font').removeClass('period-font-unselected');
      $('#seven-font').addClass('period-font-selected');
      this.calendar = false;
      $('#calendar').attr('src', '/assets/svg/icon_calendar_gray.svg')
      this.periods.forEach((elem) => {
        if (elem !== 'seven') {
          $(`#${elem}-div`).removeClass('period-item-selected');
          $(`#${elem}-div`).addClass('period-item-unselected');
          $(`#${elem}-font`).removeClass('period-font-selected');
          $(`#${elem}-font`).addClass('period-font-unselected');
        }
      })
    }
    if (period === '15') {
      $('#fifteen-div').removeClass('period-item-unselected');
      $('#fifteen-div').addClass('period-item-selected');
      $('#fifteen-font').removeClass('period-font-unselected');
      $('#fifteen-font').addClass('period-font-selected');
      this.calendar = false;
      $('#calendar').attr('src', '/assets/svg/icon_calendar_gray.svg')
      this.periods.forEach((elem) => {
        if (elem !== 'fifteen') {
          $(`#${elem}-div`).removeClass('period-item-selected');
          $(`#${elem}-div`).addClass('period-item-unselected');
          $(`#${elem}-font`).removeClass('period-font-selected');
          $(`#${elem}-font`).addClass('period-font-unselected');
        }
      })

    }
    if (period === '30') {
      $('#thirthy-div').removeClass('period-item-unselected');
      $('#thirthy-div').addClass('period-item-selected');
      $('#thirthy-font').removeClass('period-font-unselected');
      $('#thirthy-font').addClass('period-font-selected');
      this.calendar = false;
      $('#calendar').attr('src', '/assets/svg/icon_calendar_gray.svg')
      this.periods.forEach((elem) => {
        if (elem !== 'thirthy') {
          $(`#${elem}-div`).removeClass('period-item-selected');
          $(`#${elem}-div`).addClass('period-item-unselected');
          $(`#${elem}-font`).removeClass('period-font-selected');
          $(`#${elem}-font`).addClass('period-font-unselected');
        }
      })
    }
    if (period === '90') {
      $('#ninethy-div').removeClass('period-item-unselected');
      $('#ninethy-div').addClass('period-item-selected');
      $('#ninethy-font').removeClass('period-font-unselected');
      $('#ninethy-font').addClass('period-font-selected');
      this.calendar = false;
      $('#calendar').attr('src', '/assets/svg/icon_calendar_gray.svg')
      this.periods.forEach((elem) => {
        if (elem !== 'ninethy') {
          $(`#${elem}-div`).removeClass('period-item-selected');
          $(`#${elem}-div`).addClass('period-item-unselected');
          $(`#${elem}-font`).removeClass('period-font-selected');
          $(`#${elem}-font`).addClass('period-font-unselected');
        }
      })
    }
    if (period === 'calendar') {
      $('#calendar-div').removeClass('period-item-unselected');
      $('#calendar-div').addClass('period-item-selected');
      $('#calendar-font').removeClass('period-font-unselected');
      $('#calendar-font').addClass('period-font-selected');
      this.calendar = true;
      this.periods.forEach((elem) => {
        if (elem !== 'calendar') {
          $(`#${elem}-div`).removeClass('period-item-selected');
          $(`#${elem}-div`).addClass('period-item-unselected');
          $(`#${elem}-font`).removeClass('period-font-selected');
          $(`#${elem}-font`).addClass('period-font-unselected');
        }
      })
    }
  }


  public getFilterData(period: string) {
    const data = new Date();
    if (period === '7') {
      this.periodoSelected = period;
      const timeBetween = data.getTime() - 86400000 * 7;
      const dataMenosSete = new Date(timeBetween);
      this.dataInicio =
        `${dataMenosSete.toLocaleString().slice(6, 10)}-${dataMenosSete.toLocaleString().slice(3, 5)}-${dataMenosSete.toLocaleString().slice(0, 2)}`;

      this.dataFim =
        `${data.toLocaleString().slice(6, 10)}-${data.toLocaleString().slice(3, 5)}-${data.toLocaleString().slice(0, 2)}`;

      sessionStorage.setItem('dt_op', this.dataInicio)
      sessionStorage.setItem('dt_vencto', this.dataFim)

      this.getExtractPeriod(this.dataInicio, this.dataFim);
    }
    else if (period === '15') {
      this.periodoSelected = period;
      const timeBetween = data.getTime() - 86400000 * 15;
      const dataMenosSete = new Date(timeBetween);
      this.dataInicio =
        `${dataMenosSete.toLocaleString().slice(6, 10)}-${dataMenosSete.toLocaleString().slice(3, 5)}-${dataMenosSete.toLocaleString().slice(0, 2)}`;

      this.dataFim =
        `${data.toLocaleString().slice(6, 10)}-${data.toLocaleString().slice(3, 5)}-${data.toLocaleString().slice(0, 2)}`;


      sessionStorage.setItem('dt_op', this.dataInicio)
      sessionStorage.setItem('dt_vencto', this.dataFim)


      this.getExtractPeriod(this.dataInicio, this.dataFim);
    }
    else if (period === '30') {
      this.periodoSelected = period;
      const timeBetween = data.getTime() - 86400000 * 30;
      const dataMenosSete = new Date(timeBetween);
      this.dataInicio =
        `${dataMenosSete.toLocaleString().slice(6, 10)}-${dataMenosSete.toLocaleString().slice(3, 5)}-${dataMenosSete.toLocaleString().slice(0, 2)}`;

      this.dataFim =
        `${data.toLocaleString().slice(6, 10)}-${data.toLocaleString().slice(3, 5)}-${data.toLocaleString().slice(0, 2)}`;


      sessionStorage.setItem('dt_op', this.dataInicio)
      sessionStorage.setItem('dt_vencto', this.dataFim)
      this.getExtractPeriod(this.dataInicio, this.dataFim);
    }
    else if (period === '90') {
      this.periodoSelected = period;
      const timeBetween = data.getTime() - 86400000 * 90;
      const dataMenosSete = new Date(timeBetween);
      this.dataInicio =
        `${dataMenosSete.toLocaleString().slice(6, 10)}-${dataMenosSete.toLocaleString().slice(3, 5)}-${dataMenosSete.toLocaleString().slice(0, 2)}`;

      this.dataFim =
        `${data.toLocaleString().slice(6, 10)}-${data.toLocaleString().slice(3, 5)}-${data.toLocaleString().slice(0, 2)}`;


      sessionStorage.setItem('dt_op', this.dataInicio)
      sessionStorage.setItem('dt_vencto', this.dataFim)
      this.getExtractPeriod(this.dataInicio, this.dataFim);
    }
  }

  public changePeriodDate(evento) {
    this.dataInicio = `${evento.from.year}-${evento.from.month}-${evento.from.day}`;
    this.dataFim = `${evento.to.year}-${evento.to.month}-${evento.to.day}`;

    sessionStorage.setItem('dt_op', this.dataInicio)
    sessionStorage.setItem('dt_vencto', this.dataFim)
    //sessionStorage.setItem('produtoInvestimentoExcel',this.produtoSelect)
    this.getExtractPeriod(this.dataInicio, this.dataFim);
    $('#calendar').attr('src', '/assets/svg/icon_calendar.svg');
    this.periodoSelected = 'calendar';
  }

  changeFilterRadio() {
    var resgateBox = document.getElementById('resgateCheckbox');
    var emissaoBox = document.getElementById('emissaoCheckbox');
    if (emissaoBox && emissaoBox['checked'] && resgateBox && resgateBox['checked']) {
      this.dataFilterChecked = null;
      sessionStorage.removeItem('tipoOp')
    } else if (resgateBox && resgateBox['checked']) {
      this.dataFilterChecked = 'Resgate';
      sessionStorage.setItem('tipoOp', 'Resgate')
    } else if (emissaoBox && emissaoBox['checked']) {
      this.dataFilterChecked = 'Emissão';
      sessionStorage.setItem('tipoOp', 'Emissão')
    } else {
      this.dataFilterChecked = null;
      sessionStorage.removeItem('tipoOp')
    }

    if (this.dataInicio && this.dataFim) {
      this.getExtractPeriod(this.dataInicio, this.dataFim, this.produtoSelect, this.dataFilterChecked);
    } else {
      this.getExtract(this.dataSelecionada, this.produtoSelect);
    }
  }

  ///DOWNLOAD URL
  downloadFile(url: any) {
    fetch(url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "ComprovanteInvestimentosExtrato.pdf";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      })
  }


  ngOnDestroy() {
    sessionStorage.removeItem('produtoInvestimentoExcel')
    sessionStorage.removeItem('dt_op')
    sessionStorage.removeItem('dt_vencto')
    sessionStorage.removeItem('tipoOp')
    sessionStorage.removeItem('investiments_extrato_list_pdf')
    sessionStorage.removeItem('informations_pdf')
  }
}
