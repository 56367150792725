<div id="printarea" *ngIf="readyToLoad">
    <style media="print">
        @page {
            size: auto;
            margin: 0;
            padding: 0;
        }

        @media print {
            html,
            body {
                width: 210mm;
                height: 297mm;
                margin: 0;
                padding: 0;
                background: #000;

            }
        }

        @font-face {
            font-family: 'fibraDMSans-regular';
            src: url('/assets/fonts/DMSans-Regular.ttf') format('truetype');
            font-weight: 400;
            font-style: normal;
        }

        @font-face {
            font-family: 'fibraDMSans-medium';
            src: url('/assets/fonts/DMSans-Medium.ttf') format('truetype');
            font-weight: 500;
            font-style: normal;
        }

        @font-face {
            font-family: 'fibraDMSans-bold';
            src: url('/assets/fonts/DMSans-Bold.ttf') format('truetype');
            font-weight: 700;
            font-style: bold;
        }

        @font-face {
            font-family: 'fibraDMSefif-regular';
            src: url('/assets/fonts/DMSerifText-Regular.ttf') format('truetype');
            font-weight: 400;
            font-style: normal;
        }

        .errorMesage {
            margin-top: 0px;
        }
    </style>

    <div style="width:100%; margin:0; padding:0; float:left;" id="comprovante">

        <div style="box-sizing: border-box; width:100%; float:left; background:#fff; -webkit-print-color-adjust: exact; margin:0; padding: 56px 0 32px 26px">
            <!--LOGO-->
            <div style="width:100%; float:left; margin-bottom:18px;">
                <img src="/assets/svg/logo_fibra.svg" alt="logo" style="width:165px; height: 49px;">
            </div>
            <span style="width:100%; float:left; margin-bottom:51px; font-family: 'fibraDMSefif-regular'; font-style: normal; font-weight: normal; font-size: 14px; color: #495057;">
                Internet Banking - Banco Fibra - Empresa: {{company[0].name}} - CNPJ {{company[0].modality_account}} - {{NumeroConta}} -
                {{TipoConta}} - {{NomeUsuario}} - {{day}} {{hours}}</span>
            <span style="font-family: 'fibraDMSefif-regular'; font-style: normal; font-weight: normal; font-size: 46px; color: #082A4D;">Extrato</span>
        </div>

        <div style="width:100%; float:left;box-sizing:border-box;  padding: 24px 18px 41px 26px; background:#fff; -webkit-print-color-adjust: exact;">
            <!-- TíTulo-->
            <span style="width:100%; float:left; margin-bottom:16px; font-family: 'fibraDMSefif-regular'; font-style: normal; font-weight: normal; font-size: 28px; color: #082A4D;">Detalhamento
                das movimentações</span>

            <!-- SubTítulo -->
            <div style="width:100%; float:left; margin-bottom:16px;">
                <span style="width:63px; font-family: 'fibraDMSans-bold'; font-style: normal; font-weight: 500; font-size: 14px; color: #495057; float:left;">Exibir</span>
                <span style="font-family: 'fibraDMSefif-regular'; font-style: normal; font-weight: normal; font-size: 14px; color: #8F969D; float:left;">
                    {{ informations.exibir }}</span>
            </div>

            <div style="width:100%; float:left; margin-bottom:24px;">
                <span style="width:63px;  font-family: 'fibraDMSans-bold'; font-style: normal; font-weight: 500; font-size: 14px; color: #495057; float:left;">Período</span>
                <span style="font-family: 'fibraDMSefif-regular'; font-style: normal; font-weight: normal; font-size: 14px; color: #8F969D; float:left;">{{
                    informations.periodo }}</span>
            </div>

            <!--EXTRATO-->
            <div *ngIf="informations.name=='extrato-all'"> 
                <!--CARDS-->
                <fibra-balance-list></fibra-balance-list> 
                <div *ngIf="releases.length > 0; else financialInformationEmpty">
                    <div style="padding:29px 40px 36px 30px;box-sizing:border-box; width:100%; float:left; background: #FFFFFF; box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04); border-radius: 6px;">
                        <ul *ngFor="let release of releases;let i = index" style="list-style: none; margin: 0; padding: 0; width:100%; float:left;">
                            <li style="margin-top: 10px; margin-right: 0px; padding: 10px 20px 10px 0px; margin-left: 0px !important; margin-bottom: 0px; display: flex;"
                                [ngStyle]="{'margin-bottom': release.contador < 16 && release.contador%15===0 || release.contador > 27 && release.contador2%24===0  ? '10.00mm' : '0'}">

                                <span style="margin-top: 17px;font-family: 'fibraDMSans-regular'; width: 50%;color: #4e85bf;">{{ formatDate(release.data) }}</span>

                                <!-- ads ini    -->
                                <div *ngIf="this.informations.exibir!='Agendados'" class="sale" style="color: #495057; width: 50%;display: flex; justify-content: flex-end;">
                                    <div class="saldo-dia" style="font-family: 'fibraDMSans-regular'; width: 50%; justify-content: flex-end; color: #495057;margin-right: -20px;">
                                        Saldo do dia:
                                    </div>
                                    <div style="margin-top:17px">
                                        <strong style="color: #495057; font-style:normal;margin-left: 30px; font-size:16px; font-family: 'fibraDMSans-bold'; font-weight: 500;"> R$
                                            {{ isNull(release.vlr_saldo_dia) ? "0,0" : (release.vlr_saldo_dia | number: "1.2-2")
                                            }} </strong>
                                    </div>
                                </div>

                                <!-- ads fim  -->

                                <!-- <div *ngIf="this.informations.exibir==='Agendados'" class="sale col-lg-7" style="color: #495057; padding: 0px; float: right; margin-left: -42px; display: flex; justify-content: flex-end;">

                                    <div style="text-align:right;  font-family: 'fibraDMSans-regular'; font-style: normal; font-weight: normal; font-size: 16px; margin-right: 15px; display: flex; align-items: flex-end; text-align: right; color: #8F969D;">
                                        Saldo previsto:
                                    </div>

                                    <div style="margin-right: 10px;margin-top: 2px;">
                                        <strong style="font-family: 'fibraDMSans-bold'; font-weight: 500;"> R$ {{isNull(release.vlr_saldo_previsto)?
                                            "0,0" : (release.vlr_saldo_previsto | number: "1.2-2")}}</strong>
                                    </div>
                                </div> -->

                            </li>
                            <li class="list-group-item" style="height:auto!important; padding-top: 16px; font-weight: 600; border: 0px; border-top: 1px solid #e7e8ec;"
                                [ngStyle]="{'margin-bottom': items.contador < 16 && items.contador%15===0 || items.contador > 27 && items.contador2%24===0  ? '0' : '0'}"
                                *ngFor="let items of release.lancamentos">
                                <div class="date-list" style="font-weight: 500; font-size: 16px; line-height: 21px; letter-spacing: 0.025em; color: #35384d;">
                                    <div style="float:left;">
                                        <img style="position: relative; bottom: -4px; width:28px; height:22px;" [src]="iconPath(items.cod_deb_cred, items.desc_tipo_transacao)"
                                             alt="" /> {{ items.desc_historico | titlecase
                                        }}
                                    </div>
                                    <div *ngIf="items.cod_deb_cred == 'C'">
                                            <span class="value" style="color: #73b599; float: right; padding-top: 4px;">
                                                R$ {{ items.vlr_lancamento | money }}
                                                <img style="-webkit-print-color-adjust: exact; width:20px; height:20px;" src="/assets/svg/comprovante.svg" alt="Comprovante" />
                                            </span>
                                        <br />
                                        <span style="display:block;font-size:13px;margin-top: 12px;margin-left: 35px;color: #8F969D;">
                                                <span *ngIf="temDescricao(items) && HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)"
                                                      class="text-desc">{{  items.compl_det.desc_nome
                                                    }}
                                                </span>
                                                <br *ngIf="temDescricao(items) && HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)" />
                                                <span class="text-desc" *ngIf="items.desc_historico.toUpperCase().indexOf('PAGTO TIT') !== -1">{{
                                                items.compl_det.desc_finalidade }}
                                                </span>
                                                <br *ngIf="items.desc_historico.toUpperCase().indexOf('PAGTO TIT') !== -1" />
                                                <span class="text-desc" *ngIf="HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)"> CPF/CNPJ:
                                                    {{ formatCpfCnpj(items.num_cpf_cnpj) }}
                                                </span>
                                                <br *ngIf="HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)" />
                                                <span *ngIf="temInstituicao(items) && HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)"
                                                      class="text-desc">
                                                    Instituição: {{
                                                items.compl_det.dados_conta.nome_banco ? items.compl_det.dados_conta.nome_banco
                                                : ''
                                                    }}
                                                </span>
                                                <br *ngIf="temInstituicao(items) && HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)" />
                                                <span class="text-desc" *ngIf="HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj,
                                            items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)">
                                                    CC:
                                                    {{formatAccountNumber(items.compl_det.dados_conta.num_conta)}} | AG: {{
items.compl_det.dados_conta.cod_agencia.padStart(4,
                                                '0')
                                                    }}
                                                </span>
                                                <br *ngIf="temChave(items)" />
                                                <span *ngIf="temChave(items)" class="text-desc">
                                                    Chave: {{
 items.compl_det.dados_conta.chave
                                                ? items.compl_det.dados_conta.chave : ''
                                                    }}
                                                </span>
                                            </span>
                                    </div>

                                    <div *ngIf="items.cod_deb_cred == 'D'">
                                            <span class="value" style="-webkit-print-color-adjust: exact; color:#ff0000; float: right; padding-top: 4px;">
                                                R$ -{{ items.vlr_lancamento | money }}
                                                <img style="-webkit-print-color-adjust: exact; width:20px; height:20px;" src="/assets/svg/comprovante.svg" alt="Comprovante" />
                                            </span>
                                        <br />
                                        <span style="display:block;font-size:13px;margin-top: 12px;margin-left: 35px;color: #8F969D;">
                                                <span *ngIf="temDescricao(items) && HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)"
                                                      class="text-desc">
                                                    {{
 items.compl_det.desc_nome
                                                    }}
                                                </span>
                                                <br *ngIf="temDescricao(items) && HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)" />
                                                <span class="text-desc" *ngIf="HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)">
                                                    CPF/CNPJ:
                                                    {{ formatCpfCnpj(items.num_cpf_cnpj) }}
                                                </span>
                                                <br *ngIf="HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)" />
                                                <span *ngIf="temInstituicao(items) && HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)"
                                                      class="text-desc">
                                                    Instituição: {{
                                                items.compl_det.dados_conta.nome_banco ? items.compl_det.dados_conta.nome_banco
                                                : ''
                                                    }}
                                                </span>
                                                <br *ngIf="temInstituicao(items) && HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj, items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)" />
                                                <span class="text-desc" *ngIf="HasRemetInfo(items.compl_det.desc_finalidade, items.compl_det.desc_nome, items.num_cpf_cnpj,
                                            items.compl_det.dados_conta.cod_agencia, items.compl_det.dados_conta.num_conta)">
                                                    CC:
                                                    {{formatAccountNumber(items.compl_det.dados_conta.num_conta)}} | AG: {{
items.compl_det.dados_conta.cod_agencia.padStart(4,
                                                '0')
                                                    }}
                                                </span>
                                                <br *ngIf="temChave(items)" />
                                                <span *ngIf="temChave(items)" class="text-desc">
                                                    Chave: {{
 items.compl_det.dados_conta.chave
                                                ? items.compl_det.dados_conta.chave : ''
                                                    }}
                                                </span>
                                            </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <!-- footer-->
                    <div class="card-content">
                        <div class="card">
                            <div class="html2pdf__page-break"></div>
                            <br /><br /><br />
                                <div style="display: table; padding: 25px 0px 10px 20px; width:100%;">
                                    <p>Se precisar de ajuda, entre em contato com a gente.</p>
                                </div> 
                            <br />
                            <div class="card-body" #atendimento>
                                <img class="icon" src="/assets/png/apoio 1.png" alt="Atendimento" />
                                <div class="text">
                                    <div class="title">Canais exclusivos</div>
                                    <div class="desc">Dúvidas e apoio</div>
                                </div>
                            </div>
                            <div class="card-bottom">
                                <p>
                                    <strong>Corporate, Agro e PMEs</strong>
                                    (11) 4130-7449, das 9h às 18h, de segunda a sexta-feira, exceto feriados.
                                </p>
                                <p>
                                    <a href="mailto:relacionamento@bancofibra.com.br">relacionamento@bancofibra.com.br</a>
                                </p>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body" #sac>
                                <img class="icon" src="/assets/png/telefone 1.png" alt="Atendimento" />
                                <div class="text">
                                    <div class="title">SAC</div>
                                    <div class="desc">Sugestões, reclamações e elogios</div>
                                </div>
                            </div>
                            <div class="card-bottom">
                                <p>
                                    <strong>Canal de Atendimento ao Cliente</strong>
                                </p>
                                <p>
                                    0800 727 0132
                                </p>
                                <p>
                                    <strong>Canal exclusivo para deficientes auditivos ou de fala</strong>
                                </p>
                                <p>
                                    0800 200 6921
                                </p>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body" #ouvidoria>
                                <img class="icon" src="/assets/png/alto-falante.png" alt="Atendimento" />
                                <div class="text">
                                    <div class="title">Ouvidoria</div>
                                    <div class="desc">Demandas não solucionadas</div>
                                </div>
                            </div>
                            <div class="card-bottom">
                                <p>
                                    0800 727 0132, das 9h às 18h, de segunda a sexta-feira, exceto feriados.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-template #financialInformationEmpty>
                    <div class="errorMesage" style="width: 100%; float: left;">
                        <fibra-generic-info type="warn" title="Nenhum resultado encontrado" message="Tente uma nova pesquisa inserindo novos parâmetros.">
                        </fibra-generic-info>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
