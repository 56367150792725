<div style="margin-top:10vh">
    <div class="row mt-25">
        <div class="cheque-empresa-table">

            <div class="company-check-content">

                <div *ngIf="loading" class="loading-content">
                    <div class="text-center loading">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Carregando dados...</span>
                        </div>
                    </div>
                </div>


                <div *ngIf="hasData" class="company-check-header">
                    <div class="col-header width-22">Conta</div>
                    <div class="col-header width-13">Limite cheque empresa</div>
                    <div class="col-header width-10">Data de contratação</div>
                    <div class="col-header width-10">Data de vencimento</div>
                    <div class="col-header numeric" [ngClass]="'width-' + (temFibraFacil ? '8' : '9')">Taxa de juros efetiva(%a.m.)</div>
                    <div class="col-header numeric" [ngClass]="'width-' + (temFibraFacil ? '8' : '9')">Taxa de juros efetiva(%a.a.)</div>
                    <div class="col-header numeric" [ngClass]="'width-' + (temFibraFacil ? '8' : '9')">Alíquota de IOF diária</div>
                    <div class="col-header numeric" [ngClass]="'width-' + (temFibraFacil ? '8' : '9')" style="margin-left: 2px;">Alíquota de IOF adicional</div>
                    <div class="col-header numeric" [ngClass]="'width-' + (temFibraFacil ? '8' : '9')">Carência (dias)</div>
                    <div *ngIf="temFibraFacil" class="col-header width-5" style="text-align: right">CET (**)</div>
                </div>

                <div *ngIf="!loading && !hasData && !hasError" class="no-show-information">
                    <fibra-generic-info
                    type="warn"
                    title="Nenhum resultado encontrado"
                    message="Não há informações a serem exibidas"
                  ></fibra-generic-info>
                </div>
                <div class="empty-info no-show-information" *ngIf="hasError">
                    <fibra-generic-info
                    type="error"
                    title="Ocorreu um erro"
                    message="Não foi possível carregar suas operações. Tente novamente mais tarde."
                  ></fibra-generic-info>
                </div>


                <div class="company-check-body" *ngIf="hasData">

                    <table class="table" *ngIf="hasData" class="table company-check-table">

                        <tbody style="line-height:1.5">
                            <tr *ngFor="let item of loansList">
                                <td>

                                    <table cellspacing="0" cellpadding="0" class="table" *ngIf="hasData" class="table company-check-table" style="margin-bottom: 0" >

                                        <tbody style="line-height:2.5">
                                            <tr>
                                                <td class="col-value width-22">{{ item.nro_conta }} - {{ item.descricao_modalidade }}</td>
                                                <td class="col-value width-13">{{ item.valor_limite | currency: 'BRL' }}</td>
                                                <td class="col-value width-10">{{ item.dt_contratacao | date: 'dd/MM/yyyy' }}</td>
                                                <td class="col-value width-10">{{ item.data_vencimento | date: 'dd/MM/yyyy' }}</td>
                                                <td class="col-value numeric" [ngClass]="'width-' + (temFibraFacil ? '8' : '9')">{{ item.taxa_juros_mes.toString().replace(".",",") }}%</td>
                                                <td class="col-value numeric" [ngClass]="'width-' + (temFibraFacil ? '8' : '9')">{{ item.taxa_juros_efetiva.toString().replace(".",",") }}%</td>
                                                <td class="col-value numeric" [ngClass]="'width-' + (temFibraFacil ? '8' : '9')">{{ item.aliquota_iof_diaria.toString().replace(".",",") }}%</td>
                                                <td class="col-value numeric" [ngClass]="'width-' + (temFibraFacil ? '8' : '9')">{{ item.aliquota_iof_adicional.toString().replace(".",",") }}%</td>
                                                <td class="col-value numeric" [ngClass]="'width-' + (temFibraFacil ? '8' : '9')"><span>{{ item.num_dias_carencia }}</span></td>
                                                <td *ngIf="temFibraFacil" class="col-value width-5" style="text-align: right"><span *ngIf="item.codigo_modalidade == '00094'" (click)="cet(item)" [ngStyle]="{ cursor: item.loading ? 'default' : 'pointer'}">{{ item.expanded ? 'Ver menos' : 'Ver mais' }}</span></td>
                                            </tr>
                                            <tr *ngIf="item.loading" class="cet">
                                                <td class="item" colspan="10" style="padding-top: 10px; padding-bottom: 10px">
                                                    <div class="d-flex justify-content-center position loading-container">
                                                        <div class="spinner-border text-primary" role="status"></div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngIf="item.expanded" class="cet">
                                                <td class="item" style="padding-top: 20px">Valor do limite de crédito</td>
                                                <td colspan="9" style="padding-top: 20px">{{ item.valor_limite | currency: 'BRL' }}</td>
                                            </tr>
                                            <tr *ngIf="item.expanded" class="cet">
                                                <td class="item" colspan="10">Índice Pós Fixado (*)</td>
                                            </tr>
                                            <tr *ngIf="item.expanded" class="cet">
                                                <td class="item bold">Componentes</td>
                                                <td class="bold">R$</td>
                                                <td class="bold" colspan="8">%</td>
                                            </tr>
                                            <tr *ngIf="item.expanded" class="cet">
                                                <td class="item">a. Taxa de juros</td>
                                                <td>{{ item.cet.vl_taxa_juros | currency: 'BRL' }}</td>
                                                <td colspan="8">{{ item.cet.pc_taxa_juros | number: '1.2-2' }}</td>
                                            </tr>
                                            <tr *ngIf="item.expanded" class="cet">
                                                <td class="item">b. Despesas vinculadas á concessão do crédito</td>
                                                <td>{{ item.cet.valor_concessao | currency: 'BRL' }}</td>
                                                <td colspan="8">{{ item.cet.pc_concessao | number: '1.2-2' }}</td>
                                            </tr>
                                            <tr *ngIf="item.expanded" class="cet">
                                                <td class="subitem">b.1 Tarifas, quando houver</td>
                                                <td>{{ item.cet.vl_tarifas | currency: 'BRL' }}</td>
                                                <td colspan="8">{{ item.cet.pc_tarifas | number: '1.2-2' }}</td>
                                            </tr>
                                            <tr *ngIf="item.expanded" class="cet">
                                                <td class="subitem">b.2 Tributos, quando houver</td>
                                                <td>{{ item.cet.vl_tributos | currency: 'BRL' }}</td>
                                                <td colspan="8">{{ item.cet.pc_tributos | number: '1.2-2' }}</td>
                                            </tr>
                                            <tr *ngIf="item.expanded" class="cet">
                                                <td class="subitem">b.3 Seguros, quando houver</td>
                                                <td>{{ item.cet.vl_seguros | currency: 'BRL' }}</td>
                                                <td colspan="8">{{ item.cet.pc_seguros | number: '1.2-2' }}</td>
                                            </tr>
                                            <tr *ngIf="item.expanded" class="cet">
                                                <td class="subitem">b.4 Outras (especificar), incluindo serviços de terceiros, quando houver</td>
                                                <td>{{ item.cet.vl_outras | currency: 'BRL' }}</td>
                                                <td colspan="8">{{ item.cet.pc_outras | number: '1.2-2' }}</td>
                                            </tr>
                                            <tr *ngIf="item.expanded" class="cet">
                                                <td class="item">c. Valor total devido</td>
                                                <td>{{ item.cet.vl_totaldevido | currency: 'BRL' }}</td>
                                                <td colspan="8">{{ item.cet.pc_totaldevido | number: '1.2-2' }}</td>
                                            </tr>
                                            <tr *ngIf="item.expanded" class="cet">
                                                <td class="item bold">d. Custo Efetivo Total</td>
                                                <td class="bold" colspan="9">{{ item.cet.custo_efetivo_total | number: '1.2-2' }}% a.a</td>
                                            </tr>
                                            <tr *ngIf="item.expanded" class="cet">
                                                <td class="item">e. Somatório das parcelas que compõem a operação</td>
                                                <td>Não aplicável</td>
                                                <td colspan="8">Não aplicável</td>
                                            </tr>
                                            <tr *ngIf="item.expanded" class="cet">
                                                <td class="item" colspan="10" style="padding-top: 20px">(*) Conforme regulamentação em vigor do Banco Central do Brasil, o cálculo do CET não engloba o índice Pós-Fixado e é feito considerando o prazo de 30 (trinta) dias e o Valor do Limite de Crédito disponibilizado.</td>
                                            </tr>
                                            <tr *ngIf="item.expanded" class="cet">
                                                <td class="item" colspan="10" style="padding-bottom: 20px">(**) Condições vigentes da data da disponibilização do Limite emconta corrente.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div *ngIf="hasData" class="texto-informativo">
            *Valores sujeitos a confirmação no momento da liquidação.
        </div>
    </div>

</div>
