import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as Util from '@src/shared/util-common';
import { FilterQueryDataShare, TabsDataShare, optionInstruction } from '@src/data/repository/data-share-repository';
import { ToastErrorMessageComponent } from '@src/presentation/web/components/toast-error-message/toast-error-message.component';
import { GetInstructionUsecase } from '@src/core/usecases/query/instruction-list/get-instructions.usecase';
import { Subscription } from 'rxjs';
import { InstructionFilter, InstructionRequest, InstructionResponse } from '@src/core/domain/query/query.model';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'fibra-query-instruction-list',
  templateUrl: './instruction-list.component.html',
  styleUrls: ['./instruction-list.component.scss'],
})
export class InstructionListComponent implements OnInit, OnDestroy {
  private _selectedTab: string = 'instruction-list-all';
  private _subscriptions: Subscription[] = [];
  private _params: InstructionRequest = {
    status: null,
    num_carteira: null,
    num_conta: null,
    tipo_data: null,
    valor: 0,
    nossonumero: null,
    seunumero: null,
    pagador: null,
    tipo_instrucao: 0,
    dt_inicial: null,
    dt_final: null,
    paginar: null,
    offset: null,
    campo_ordenacao: null,
    sentido_ordenacao: null
  };
  private _eof: boolean = false;  
  private _campo_ordenacao: string;
  private _sentido_ordenacao: string;
  private _tableBody: ElementRef = null;
  private _getAllQueryListSub: Subscription;
  
  public loadingMore: boolean = false;

  public instructions = [];
  filter = new FormControl('');
  public hide = false;
  public loading = false;

  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;

  @ViewChild('tablebody', null) set tableBody(value: ElementRef) {
    if (value) {
      this._tableBody = value;

      this._tableBody.nativeElement.addEventListener("scroll", () => {
        if (!this.loadingMore && !this._eof && (this._tableBody.nativeElement.scrollTop + this._tableBody.nativeElement.clientHeight + 20 >= this._tableBody.nativeElement.scrollHeight)) {
          this.loadMore();
        }
      });
    }
  }

  private ShowToast(value: any): void {    
    const isMessageServer: boolean = !!(value && value.code && value.message && value.message.length > 0);
    isMessageServer && this.toast && this.toast.callModalMessage(null, value.message[0], null, null, null);
  }

  constructor(
    private _getInstructions: GetInstructionUsecase,
    private _filter: FilterQueryDataShare,
    private _tab: TabsDataShare
  ) { }

  ngOnInit() {
    this.subscribeFilter();
    this.subscribeTab();
  }

  subscribeFilter() {
    this.loading = true;
    this._subscriptions.push(
      this._filter.dataShared.pipe(filter(f => f && f.dt_Inicio && f.dt_Final)).subscribe((res: InstructionFilter) => {
        if (this._selectedTab.startsWith('instruction-list')) {
          this._params = {
            num_carteira: res.carteira,
            num_conta: res.account,
            dt_inicial: res.dt_Inicio,
            dt_final: res.dt_Final,
            nossonumero: res.ourNumber,
            seunumero: res.yourNumber,
            pagador: res.draweeName,
            valor: res.valorTitulo,
            paginar: true,
            offset: null,
            tipo_data: res.tipo_data_instrucoes,
            campo_ordenacao: null,
            sentido_ordenacao: null,
            tipo_instrucao: res.instructionValue,
            status: null
          };
          this.hide = false;
          this._eof = false;
          this.loading = true;
          this.instructions = [];
          this._campo_ordenacao = null;
          this.load(this._params);
        }
      })
    );
  }

  subscribeTab() {
    this._subscriptions.push(this._tab.dataShared.pipe(filter(t => t && t.typeList && t.typeList.startsWith('instruction-list'))).subscribe((res) => {
        this._selectedTab = res.typeList;
    }));
  }

  private load(params: InstructionRequest) {
    if (this._getAllQueryListSub) {
      this._getAllQueryListSub.unsubscribe();
    }

    params.status = (() => {
      switch (this._selectedTab) {
        case 'instruction-list-all':
          return 'todos';
        case 'instruction-list-authorized':
          return 'aprovadas';
        case 'instruction-list-refused':
          return 'recusadas';
        case 'instruction-list-pending':
          return 'emanalise';
      }})();

    this._subscriptions.push(this._getAllQueryListSub = this._getInstructions
      .execute(params)
      .subscribe(
        this.requestSuccess,
        this.requestError,
        this.requestComplete
      ));

    this._filter.setParamsInstrucoes(params);
  }

  public loadMore() {
    this.loadingMore = true;

    const params = { ...this._params };

    params.offset = this.instructions[this.instructions.length - 1];
    params.campo_ordenacao = this._campo_ordenacao;
    params.sentido_ordenacao = this._sentido_ordenacao;

    this.load(params);
  }

  private requestSuccess = (value: InstructionResponse) => {
    const result = Util.verifyArray(value.data.rows);

    this._eof = result.length < 30;

    if (!this.loadingMore) {
      this.instructions = result;
    } else {
      this.instructions = this.instructions.concat(result);            
    }

    this.hide = Util.isEmpty(this.instructions);
    this.loading = this.loadingMore = false;
  };

  private requestError = (err) => {
    this.ShowToast(err);
    this.instructions = [];
    this.hide = true;
    this.loading = this.loadingMore = false;
  };

  private requestComplete = () => {
    this.hide = Util.isEmpty(this.instructions);
    this.loading = this.loadingMore = false;
  };

  sort(field: string) {
    const params = { ...this._params };

    if (this._campo_ordenacao === field) {
      this._sentido_ordenacao = this._sentido_ordenacao === 'asc' ? 'desc' : 'asc';
    } else {
      this._campo_ordenacao = field;
      this._sentido_ordenacao = 'asc';
    }
    params.campo_ordenacao = this._campo_ordenacao;
    params.sentido_ordenacao = this._sentido_ordenacao;

    this.hide = false;
    this.loading = true;
    this.instructions = [];

    this.load(params);
  }

  ngOnDestroy() {
    this._subscriptions.forEach((sb) => sb.unsubscribe());
  }
}

