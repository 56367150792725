<ng-template #content let-modal>
    <div class="dda-container">
      <div class="modal-header">
        <div class="generic-info">
          <img alt="" [src]="imgSrc" class="img-medium mb-3" />
          <h5
            class="font-weight-bold"
            [ngClass]="{'fg-yellow': typeMessage === 'warn', 'fg-green': typeMessage === 'success', 'fg-red': typeMessage === 'error'}"
          >
            {{ title }}
          </h5>
        </div>
      </div>
      
    <div class="modal-body card" style="z-index: 99999;">
        <div class="card-body">
          <div class="list-value">
            <div *ngIf="boletos != undefined">
                <table class="table table-fibra" aria-describedby="desc-tabela">
                  <thead>
                    <tr class="header-list">
                        <th id="sacado-cnpj-cpf-boleto">
                            Sacado<br>CNPJ/CPF&nbsp;<img
                              src="/assets/svg/duo_arrow.svg"
                              alt=""
                              (click)="util.sortList('sacado', boletos)"
                            />
                          </th>
                      <th id="valor-boleto">
                        Valor&nbsp;<img
                          src="/assets/svg/duo_arrow.svg"
                          alt=""
                          (click)="util.sortList('valor', boletos)"
                        />
                      </th>                          

                        <th id="data-vencimento-boleto">
                        Data de Vencimento&nbsp;<img
                          src="/assets/svg/duo_arrow.svg"
                          alt=""
                          (click)="util.sortList('dt_pagamento', boletos)"
                        />
                      </th>
                      <th id="status-boleto">
                        Status&nbsp;<img
                          src="/assets/svg/duo_arrow.svg"
                          alt=""
                          (click)="util.sortList('status', boletos)"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let boleto of boletos; index as i">
                      <tr>
                        <td>{{ boleto.sacado }}<br><span class="display-document">{{ boleto.cnpj_cpf_sacado }}</span></td>
                        <td>{{ boleto.valor | currency : "BRL" }}</td>
                        <td>{{ boleto.dt_vencimento | date : "dd/MM/yyyy" }}</td>
                        <td>
                          <container-element>
                            <some-element>
                              <div *ngIf="!pagamentoPendente(boleto)">
                                <span [appTooltip]="tooltipAll" class="notification">
                                  <img alt="logo" src="assets/svg/bullet_yellow.svg" style="margin-right: 12px !important;" />
                                  <div #tooltipAll class="tooltip-custom">
                                    <h3>Em processamento</h3>
                                    <p>
                                      O pagamento será realizado,
                                      mediante disponibilidade de saldo em conta.
                                    </p>
                                  </div>
                                </span>&nbsp;Em processamento
                              </div>                              
                              <div *ngIf="pagamentoPendente(boleto)">
                                <span class="icon-info notification" [appTooltip]="tooltipAll">
                                  <img width="10" height="10" src="/assets/svg/tooltip-alert-orange.svg" alt="logo" />
                                  <div #tooltipAll [ngClass]="i <= 2 ? 'tooltip-custom-down' : 'tooltip-custom'">
                                    <h3>Aguardando aprovação</h3>
                                    <div class="info">
                                      <ul class="info-list">
                                        <li class="header" *ngIf="showApprovalMaster(boleto)">
                                          <img src="../../../../assets/img/icon-dot.svg" alt="" />
                                          Masters
                                        </li>
                                        <li class="item-mt" *ngFor="let master of getAprovadores(boleto)">
                                          <div *ngIf="master.master === true">
                                            <span data-hj-suppress>{{master.nome_pessoa }}</span>
                                          </div>
                                        </li>
                                      </ul>
                                      <ul *ngIf="showApprovalOperator(boleto)" class="info-list">
                                        <li class="header">
                                          <img src="../../../../assets/img/icon-dot.svg" alt="" /> Operador
                                        </li>
                                        <li class="item-op" *ngFor="let operator of getAprovadores(boleto)">
                                          <div data-hj-suppress class="data-hj-suppress" *ngIf="operator.master === false">
                                            {{ operator.nome_pessoa }}
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                    <div [ngClass]="i <= 2 ? 'arrow-top' : 'arrow'"></div>
                                  </div>
                                </span>&nbsp;Pendente
                              </div>
                            </some-element>
                          </container-element>
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>            
          </div>
        </div>
    </div>

      <div class="text-center button-container">
        <div class="col-12">
          <button type="button" class="btn btn-block btn-outline-primary" (click)="closeModal();goToView('/dda')">Voltar</button>
        </div>
        <div class="col-12">
          <button type="button" class="btn btn-block btn-outline-primary" (click)="closeModal();goToView('/dda','boletos')">Novo pagamento de boleto</button>
        </div> 
        <div class="col-12 mt-2 mb-3">
          <button type="button" class="btn btn-block secondary-button-dda w-100" (click)="closeModal();goToView('/transaction-approval')">Acompanhar transação</button>
        </div>
      </div>
    </div>
</ng-template>
