
import { AfterContentChecked, AfterViewChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as $ from 'jquery';
import {ToastErrorMessageComponent} from '@src/presentation/web/components/toast-error-message/toast-error-message.component';




@Component({
  selector: 'fibra-loans-report',
  templateUrl: './loans-report.component.html',
  styleUrls: ['./loans-report.component.scss']
})
export class LoansReportComponent implements OnInit, OnDestroy {
  @ViewChild(ToastErrorMessageComponent, null) toast: ToastErrorMessageComponent;
  @Output() public closeReport = new EventEmitter();
  public contractsArray = [];
  public contractsCheckArray = [];
  public contractInfoArray = [];
  public parcelasArray = [];
  public dailyPositionArray = [];
  public reportData;
  public date = new Date();
  public day;
  public hours;
  public company;
  public hide = false;
  public readyToLoad = false;
  public listAllContracts = false;
  public informations;
  public dateReferenceToShow;
  public listValuesDailyPosition;
  public listValuesParcelas;

  public typToastColor = '#ED6C6C';
  public REDCOLOR = '#ED6C6C';
  public GREENCOLOR = '#73B599';

  constructor() { }

  ngOnInit() {

    //Inicializa Arrays
    this.contractsArray = null;
    this.dailyPositionArray = null;
    this.contractInfoArray = null;
    this.contractsCheckArray = null;

    this.hide = false;
    this.day = this.date.toLocaleDateString();
    this.hours = this.date.toLocaleTimeString();
    this.company = JSON.parse(sessionStorage.getItem('defaultCompany'));
    if (this.company[0].modality_account.length === 11) {
      this.company[0].modality_account = this.company[0].modality_account.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else {
      this.company[0].modality_account = this.company[0].modality_account.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/, '$1.$2.$3/$4-$5');
    }
    this.informations = JSON.parse(sessionStorage.getItem('informations_pdf'))
    this.contractsArray = JSON.parse(sessionStorage.getItem('contracts_list_pdf'))
    this.contractsCheckArray = JSON.parse(sessionStorage.getItem('cheque_empresa_pdf'))
    this.contractInfoArray = JSON.parse(sessionStorage.getItem('info_contrato_pdf'))
    this.dailyPositionArray = JSON.parse(sessionStorage.getItem('posicao_diaria_pdf'))
    this.parcelasArray = JSON.parse(sessionStorage.getItem('parcelas_pdf'))
    this.listValuesDailyPosition = JSON.parse(sessionStorage.getItem('daily-position-total-values'))
    this.listValuesParcelas = JSON.parse(sessionStorage.getItem('parcelas-total-values'))

    this.hide = (this.contractsArray === null || this.contractsArray.length === 0) && this.contractsCheckArray === null && 
    this.contractInfoArray === null && (this.dailyPositionArray === null ||this.dailyPositionArray.length === 0) && this.parcelasArray === null
    && this.listValuesParcelas === null ? true : false;

    
    this.readyToLoad = true;
    setTimeout(() => {
      this.generatePDF(this.informations.report_name)
    }, 2000);

  }

  ngOnDestroy() {
  }


  public generatePDF(reportName: string) {


    const data = document.getElementById('comprovante');


    if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1)
    {
        html2canvas(data,{
          windowWidth: document.body.scrollWidth,
          windowHeight: document.body.scrollHeight,
          x: window.pageXOffset,
          y: window.pageYOffset,
        }).then((canvas) => {

        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        
        var doc = new jsPDF('p', 'mm');
        var position = 0;

        const imgData = canvas.toDataURL("image/jpeg")


        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        this.typToastColor = this.GREENCOLOR;
        this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.')
        doc.save(reportName+'.pdf');   
        
      });
    }
    else
    {

      html2canvas(data).then((canvas) => {
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      
      var doc = new jsPDF('p', 'mm');
      var position = 0;

      const imgData = canvas.toDataURL("image/jpg")


      doc.addImage(imgData, 'JPG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      this.typToastColor = this.GREENCOLOR;
      this.toast.callModalMessage(null, 'Arquivo baixado com sucesso.');
      doc.save(reportName+'.pdf');   
      
    });

    }

    


 

    $("#ico-fechar").css({ display: "block" });
    this.goBack();

  }

  public goBack() {
    this.closeReport.emit('true');
  }
}

