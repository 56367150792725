import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ExtractModel } from '@src/core/domain/extract/extract.model';
import { GetExitExtractsUsecase } from '@src/core/usecases/extract/get-exit-extracts.usecase';
import * as Util from '@src/shared/util-common';
import * as moment from 'moment';

@Component({
  selector: 'fibra-exit-list',
  templateUrl: './exit-list.component.html',
  styleUrls: ['./exit-list.component.scss'],
})
export class ExitListComponent implements OnInit, OnDestroy {
  exits: Array<ExtractModel> = [];
  @Output() extractExit: EventEmitter<ExtractModel>;
  @Input() balance: any;
  @Input() params;
  public loading: boolean;
  public hide: boolean;
  public data;
  public conta = ''
  public agencia = ''
  public cpfCnpj=''
  public nomeRemet=''
  
  public loadingMore: boolean = false;
  private _eof: boolean = false;
  private pagina: number;

  private _tableBody: ElementRef = null;

  @ViewChild('tablebody', null) set tableBody(value: ElementRef) {
    if (value) {
      this._tableBody = value;
      this._tableBody.nativeElement.addEventListener("scroll", () => {
        if (!this.loadingMore && !this._eof && (this._tableBody.nativeElement.scrollTop + this._tableBody.nativeElement.clientHeight + 20 >= this._tableBody.nativeElement.scrollHeight)) {
          this.loadMore();
        }
      });
    }
  }
  
  constructor(private getExitExtracts: GetExitExtractsUsecase, private cdr: ChangeDetectorRef) {
    this.extractExit = new EventEmitter();
  }
  public iconPath = (cod: string, desc: string) => Util.iconPath(cod, desc);

  /**
   * @description
   * Add a Title to componente on view
   */

  ngOnInit() {
    this.requestEntries();
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('extrato_all_pdf')
    sessionStorage.removeItem('informations_pdf')
  }

  loadMore() {
    this.loadingMore = true;
    this.cdr.detectChanges();
    this._tableBody.nativeElement.scrollTop = this._tableBody.nativeElement.scrollHeight;
    let paramsUpdated = JSON.parse(this.params)
    this.pagina = paramsUpdated.numeroPagina;
    delete paramsUpdated.numeroPagina
    delete paramsUpdated.quantidadeRegistrosPagina
    this.params = {
      ...paramsUpdated,
      numeroPagina: this.pagina + 1,
      quantidadeRegistrosPagina: 30
    }
    this.params = JSON.stringify(this.params)

    this.requestEntries();
  }

  private requestEntries = () => {
    if (this.exits.length == 0) {
      this.loading = true;
    }    

    this.getExitExtracts
      .execute(JSON.parse(this.params))
      .subscribe(this.requestSuccess, this.requestError, this.requestComplete);
  };

  private requestSuccess = (value: ExtractModel) => {
    if (this.data === undefined) {
      this.data = value.data.data;
    } else if (
      this.data.length > 0 &&
      value.data.data.length > 0 &&
      this.data[this.data.length - 1].data === value.data.data[0].data
    ) {
      const lastData = this.data[this.data.length - 1];
      const newLancamentos = lastData.lancamentos.concat(value.data.data[0].lancamentos);
      this.data = [
        ...this.data.slice(0, -1),
        {
          ...lastData,
          lancamentos: newLancamentos,
        },
        ...value.data.data.slice(1),
      ];
    } else {
      this.data = [...this.data, ...value.data.data];
    }

    this.exits = Util.verifyArray(this.data);
    this.extractExit.emit(this.data);
    this.hide = Util.isEmpty(this.exits);
    const aux = value.data.data.reduce((p, c) => p + c.lancamentos.length, 0);
    this._eof = aux < 30;


    //PDF
    //this.data = value.data
    sessionStorage.setItem('extrato_all_pdf', JSON.stringify(this.data));
    sessionStorage.setItem('informations_pdf', '{"exibir":"Saída", "periodo": "'+Util.calcDiffInDays(this.params)+' dia(s)", "report_name":"Extrato","name":"extrato-all"}')

    //Excel
    let paramsFilter = JSON.parse(this.params)
    let dataInicio  = paramsFilter.dataInicio;
    let dataFinal = paramsFilter.dataFinal;

    dataFinal = moment(dataFinal, "YYYY-MM-DD").format("YYYY-MM-DD");
    dataInicio = moment(dataInicio, "YYYY-MM-DD").format("YYYY-MM-DD");
    sessionStorage.setItem('extract_excel', '{"tipo":"saida", "dataInicio":"'+dataInicio+'", "dataFinal":"'+dataFinal+'"}')
    
  };

  private requestError = (err) => {
    // PDF
    const data = [];
    sessionStorage.setItem('extrato_all_pdf', JSON.stringify(data));
    sessionStorage.setItem('informations_pdf', '{"exibir":"Saída", "periodo": "'+Util.calcDiffInDays(this.params)+' dia(s)", "report_name":"Extrato","name":"extrato-all"}');

    // Excel
    let paramsFilter = JSON.parse(this.params)
    let dataInicio  = paramsFilter.dataInicio;
    let dataFinal = paramsFilter.dataFinal;

    dataFinal = moment(dataFinal, "YYYY-MM-DD").format("YYYY-MM-DD");
    dataInicio = moment(dataInicio, "YYYY-MM-DD").format("YYYY-MM-DD");
    sessionStorage.setItem('extract_excel', '{"tipo":"saida", "dataInicio":"'+dataInicio+'", "dataFinal":"'+dataFinal+'"}');
    
    this.hide = Util.isEmpty(this.exits);
    console.warn(err);
    this.loading = false;
  };

  private requestComplete = () => {
    this.hide = Util.isEmpty(this.exits);
    this.loading = this.loadingMore = false;
  };

  parseValue = (value) => {
    const newValue = Number(value);
    return newValue;
  };

  isNull = (value) => {
    return Util.isNull(value);
  };
  
  formatDate(date: string) {
    moment.locale('pt-br');
    const today = moment().startOf('day');
    const patternDate = moment(date.split('T')[0]);
    const diffDays = today.diff(patternDate, 'days');

    return diffDays === 0
      ? 'Hoje, ' + patternDate.format('LL')
      : diffDays === 1
      ? 'Ontem, ' + patternDate.format('LL')
      : diffDays > 1 && diffDays <= 7
      ? patternDate.format('dddd').charAt(0).toUpperCase() +
        patternDate.format('dddd').slice(1) +
        ', ' +
        patternDate.format('LL')
      : patternDate.format('DD') +
        ' de ' +
        patternDate.format('MMMM') +
        ' de ' +
        patternDate.format('YYYY');
  }

  public isPix(item): boolean {
    return (<string>item.desc_historico).toUpperCase().indexOf("PIX") > -1;
  }

  public HasRemetInfo(txt:string, nomeRemet:string, cnpj:string,  agencia:string, conta:string)
  {
    
    if(txt.trim() !== "" && nomeRemet.trim() !== "" && cnpj.trim() !== "")
    {
      const splits = txt.split(' ')
      // this.agencia = "AG:"+splits[1] 
      // this.conta = "CC:"+txt// "CC:"+splits[0]
      this.agencia = "AG:"+agencia.padStart(4,'0') 
      this.conta = "CC:"+conta// "CC:"+splits[0]
      this.cpfCnpj = this.FormatcpfCnpj(cnpj) // cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
      this.nomeRemet = nomeRemet

      // if(splits[0].length > 1 &&
      //    splits[1].length > 2)
      //      return true
      // else
      //   return false

      return true
    }
    else
    {
      return false
    }
  }

  public formatCpfCnpj = (value: string) => Util.formatCpfCnpj(value);

  public FormatcpfCnpj(v:string){
    
    v=v.replace(/\D/g,"")
    let v2= +(v)
    v = v2.toString();
    
    //Remove tudo o que não é dígito
    
 
    if (v.length <= 11) { //CPF

      

      v  = v.padStart(11,'0')
 
        //Coloca um ponto entre o terceiro e o quarto dígitos
        v=v.replace(/(\d{3})(\d)/,"$1.$2")
 
        //Coloca um ponto entre o terceiro e o quarto dígitos
        //de novo (para o segundo bloco de números)
        v=v.replace(/(\d{3})(\d)/,"$1.$2")
 
        //Coloca um hífen entre o terceiro e o quarto dígitos
        v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
 
    } else { //CNPJ

      v  = v.padStart(14,'0')
        
 
        //Coloca ponto entre o segundo e o terceiro dígitos
        v=v.replace(/^(\d{2})(\d)/,"$1.$2")
 
        //Coloca ponto entre o quinto e o sexto dígitos
        v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
 
        //Coloca uma barra entre o oitavo e o nono dígitos
        v=v.replace(/\.(\d{3})(\d)/,".$1/$2")
 
        //Coloca um hífen depois do bloco de quatro dígitos
        v=v.replace(/(\d{4})(\d)/,"$1-$2")
    }
    return v
  }

  public temDescricao(item): boolean {
    return item.compl_det && item.compl_det.desc_nome && item.compl_det.desc_nome.trim() != '';
  }

  public temInstituicao(item): boolean {
    return item.compl_det && item.compl_det.dados_conta && item.compl_det.dados_conta.nome_banco && item.compl_det.dados_conta.nome_banco != '0' && item.compl_det.dados_conta.nome_banco != '00';
  }

  public formatAccountNumber(accNumber: string): string {
    return `${accNumber.substring(0, accNumber.length - 1)}-${accNumber[accNumber.length - 1]}`;
  }

  public temChave(item): boolean {
    return item.compl_det && item.compl_det.dados_conta && item.compl_det.dados_conta.chave;
  }
}
