import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GetAllQueryUsecase } from '@src/core/usecases/query/get-all-query.usecase';
import * as Util from '@src/shared/util-common';
import { ListQueryModel } from '@src/core/domain/query/query.model';
import { FilterQueryDataShare, TabsDataShare, TabsOnChange } from '@src/data/repository/data-share-repository';
import { GetAuthorizedQueryUsecase } from '@src/core/usecases/query/get-authorized-query.usecase';
import { GetRefusedQueryUsecase } from '@src/core/usecases/query/get-refused-query.usecase';
import { GetPendingQueryUsecase } from '@src/core/usecases/query/get-pending-query.usecase';
import { Subscription } from 'rxjs';
import * as $ from 'jquery';

@Component({
  selector: 'fibra-query-transit-list',
  templateUrl: './query-transit.component.html',
  styleUrls: ['./query-transit.component.scss'],
})

export class QueryTransitListComponent implements OnInit, OnDestroy{
  @Input() params;
  @Input() isTransit: boolean = false;
  @Output() emitItem: EventEmitter<any>;
  public transit;
  public filter = new FormControl('');
  public getOrderedDate;
  public getOrderedPayer;
  public getOrderedValue;
  public hide;
  public loading;
  public option;
  public util = Util;
  public show = false;
  private subscriptions: Subscription[] = [];
  private _subTabs: Subscription;
  paramsDefault = {};
  tabprevious = '';
  paramsAux = {};

  constructor(
    private getAllQueryList: GetAllQueryUsecase,
    private getAuthorizedList: GetAuthorizedQueryUsecase,
    private getRefusedList: GetRefusedQueryUsecase,
    private getPendingList: GetPendingQueryUsecase,
    private sharedFilter: FilterQueryDataShare,
    private shareTab: TabsDataShare,
    private _tabsOnChange: TabsOnChange
  ) {
    this.emitItem = new EventEmitter();
    this.transit = [];
    this.loading = false;
    this.hide = false;
    this.option = [
      { title: 'Data da emissão', value: 1 },
      { title: 'Data de envio', value: 2 },
      { title: 'Pagador', value: 3 },
      { title: 'Valor do título', value: 4 },
    ];
  }

  ngOnInit() {
    this.initList();

    this._subTabs = this._tabsOnChange.onTabChange.subscribe(t => {
        this.subscriptions.forEach((sb) => sb.unsubscribe());
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
    this._subTabs.unsubscribe();
  }

  private initList() {
    this.subscriptions.push(this.sharedFilter.dataShared.subscribe(
      (res: any) => {
        if (res) {
          this.paramsDefault = {
            conta: {
              numero_conta: res.account != undefined ? res.account : '',
              codigo_coligada: '001',
              codigo_agencia: res.cod_agencia,
            },
            param_carteira: res.param_carteira || [], 
            data_arquivo_inicial: res.dt_Inicio || '',
            data_arquivo_final: res.dt_Final || '',
            tipo_data: res.tipo_data || '',
            codigo_cliente: null,
            codigo_especie: res.codigo_especie || '',
            codigo_produto: null,
            seu_numero: res.yourNumber,
            nome_pagador: res.draweeName,
            valor_titulo_inicial: res.valorTitulo,
            valor_titulo_final: res.valorTitulo,
            codigo_situacao: this.getSituacao(this.tabprevious ? this.tabprevious : 'query-list-all'),
            dados_blocos: null,
            nome_sacado: null,
            bloco_entrada: {
              numero_pagina: 1,
              quantidade_registros_pagina: 99999999
            }
          };

          this.hide = false;
          this.loading = true;
          this.switList(this.tabprevious ? this.tabprevious : 'query-list-all', this.paramsDefault);
        }
      }
    ));

    this.subscriptions.push(
      this.shareTab.dataShared.subscribe((res) => {
        if (res && res.typeList){
          this.hide = false;
          this.loading = true;
          let codigo_situacao = this.getSituacao(res.typeList ? res.typeList : 'query-list-all');
          this.paramsDefault = {...this.paramsDefault, codigo_situacao};
          this.switList(res.typeList, this.paramsDefault);
        }
      })
    );
  }

  private getSituacao(param) {
    this.transit = [];
    switch (param) {
      case 'query-list-all':
        return null;
      case 'query-list-authorized':
        return 1;
      case 'query-list-refused':
        return 3;
      case 'query-list-pending':
        return 2;
      default:
        break;
    }
  }


  private switList(list, param) { 
    let filterPayload = { ...param };
      
    if (param.codigo_situacao === 1 || list === 'query-list-authorized')
    {
      filterPayload.tipo_data = 'envio';
      filterPayload.data_arquivo_inicial = param.data_arquivo_final;
    }

    if (this.isTransit && list && list !== 'posicao-carteira-list-all' && 
        list !== 'francesinha-list-all' && param.data_arquivo_inicial && param.data_arquivo_final && (this.tabprevious !== list || this.paramsAux != param)
    ) {
      this.requestQueryListAll({ ...filterPayload });
      this.tabprevious = list;
      this.paramsAux = param;
    }
    else{
      this.loading = false;
      this.hide = Util.isEmpty(this.transit);
    }
  }

  // private switList(list, param?) {
  // this.transit = [];
  // switch (list) {
  //   case 'query-list-all':
  //     return this.requestQueryListAll(param);
  //   case 'query-list-authorized':
  //     return this.requestQueryListAuthorized(param);
  //   case 'query-list-refused':
  //     return this.requestQueryListRefused(param);
  //   case 'query-list-pending':
  //     return this.requestQueryListPending(param);
  //   default:
  //     break;
  // }
  // }

  getStatusFormated(status) {
    let newStatus = '';
    if (['ACA', 'ACN', 'ACR', 'AN', 'AR', 'AS', 'CCA', 'CCN', 'CCR', 'CE', 'CH', 'PS'].includes(status))
      newStatus = 'Em análise';
    else if (['AC', 'REM'].includes(status))
      newStatus = 'Aprovado';
    else if (['CPC', 'ECP', 'EL', 'RC', 'RE', 'RET', 'RF'].includes(status))
      newStatus = 'Recusado';

    return newStatus;
  }

  private requestQueryListAll(param?) {
    if (param) {
      this.loading = true;
      this.subscriptions.push(
        this.getAllQueryList.execute(param).subscribe(
          this.requestSuccess,
          this.requestError,
          this.requestComplete
        ));
    }
  }
  private requestQueryListAuthorized(param?) {
    if (param) {
      this.subscriptions.push(this.getAuthorizedList
        .execute(param)
        .subscribe(
          this.requestSuccess,
          this.requestError,
          this.requestComplete
        ));
    }
  }
  private requestQueryListRefused(param?) {
    if (param) {
      this.subscriptions.push(this.getRefusedList
        .execute(param)
        .subscribe(
          this.requestSuccess,
          this.requestError,
          this.requestComplete
        ));
    }
  }
  private requestQueryListPending(param?) {
    if (param) {
      this.subscriptions.push(this.getPendingList
        .execute(param)
        .subscribe(
          this.requestSuccess,
          this.requestError,
          this.requestComplete
        ));
    }
  }

  private requestSuccess = (value: ListQueryModel) => {
    this.transit = Util.verifyArray(value.data);
    this.hide = Util.isEmpty(this.transit);
    this.loading = false;
  };

  private requestError = (err) => {
    this.loading = false;
    this.hide = true;
    this.transit = [];
    return console.log(err);
  };

  private requestComplete = () => {
    this.hide = Util.isEmpty(this.transit);
    this.loading = false;
  };

  isNull = (value) => {
    return Util.isNull(value);
  };

  // ordered(value) {
  //    switch (value) {
  //      case 'date':
  //       return (this.getOrderedDate = getOrderedDate(this.transit));
  //     case 'payer':
  //        return (this.getOrderedPayer = getOrderedPayer(this.transit));
  //      case 'value':
  //        return (this.getOrderedValue = getOrderedValue(this.transit));
  //    }
  // }

  sendItemList(item) {
    this.emitItem.emit(item);
    this.show = true;
  }

  public toogleDetail = (index, id) => {
    $('.collapse').removeClass('show');
  }

  public getStatusItem(item): string {
    if (['ACA', 'ACN', 'ACR', 'AN', 'AR', 'AS', 'CCA', 'CCN', 'CCR', 'CE', 'CH', 'PS'].includes(item.codigo_status_linha)) {
      return 'pending';
    } else if (['AC', 'REM'].includes(item.codigo_status_linha)) {
      return 'authorized'
    } else if (['CPC', 'ECP', 'EL', 'RC', 'RE', 'RET', 'RF'].includes(item.codigo_status_linha)) {
      return 'refused'
    } else {
      return null;
    }
  }
  
  public toogleShowMore(item) {
    item.showMore = !item.showMore;
  }

  replaceUnicode(s: string) {
    let aux = s.replace(/u0022/g, '"');
    aux = aux.replace(/u0027/g, `'`);
    return aux;
  }

  public ordenar(coluna: string) {
    Util.sortList(coluna, this.transit);
  }
}