import { Environment } from '@src/environments/model';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


// !Important this enviroment is based on enviroment.local.ts. Use this ref to add here.

export const environment: Environment = {
  production: true,
  env: 'production',
  URLBase: 'https://api.bancofibra.com.br/tidigital-ib/',
  clientId: 'e20ec609-1e69-48d7-bffc-c9a419b55ec5',
  tenantId: '34b30504-7edc-404e-826e-271d17d3c411',
  tenantName: 'bancofibrab2c',
  objectId: '',
  tokenApi: '',
  applicationId: '',
  redirectUri: 'https://internetbanking.bancofibra.com.br',
  firebase: {
    apiKey: 'AIzaSyBFhUaKsVeAwMUqZiGMf1qsZQ2b4i4JrWk',
    authDomain: 'banco-fibra---mobile-banking.firebaseapp.com',
    databaseURL: 'https://banco-fibra---mobile-banking.firebaseio.com',
    projectId: 'banco-fibra---mobile-banking',
    storageBucket: 'banco-fibra---mobile-banking.appspot.com',
    messagingSenderId: '802539032539',
    appId: '1:802539032539:web:5b3113368c641ce66421fe',
    measurementId: 'G-0JM48YZT2S'
  },
  zendesk: {
    chatKey: '6c1ed307-04ed-4e5b-8aa7-b98ece2a5484'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
