<div class="content">
    <div class="div">
        <form class="flex" [formGroup]="changeForm">
            <div class="form-check form-check-inline">
                <input type="radio" class="form-check-input" id="periodRadio" name="filter" formControlName="filter" mdbInput (click)="changeFilterType('period')" value="period" />
                <div>
                    <label class="form-check-label" for="digitableLine">Período</label
        >
        </div>
        
        <div class="period-div" id="period-div">
          <div class="period-item-unselected" id="seven-div" (click) = "changePeriodFilter('7');getFilterData('7')">
            <span class="period-font-unselected"  id="seven-font"> 7 dias </span>
          </div>
          <div class="period-item-unselected" id="fifteen-div" (click) = "changePeriodFilter('15');getFilterData('15')">
            <span class="period-font-unselected"  id="fifteen-font"> 15 dias </span>
          </div>
          <div class="period-item-unselected"  id="thirthy-div" (click) = "changePeriodFilter('30');getFilterData('30')">
            <span class="period-font-unselected"  id="thirthy-font"> 30 dias</span>
          </div>
          <div class="period-item-unselected"  id="ninethy-div" (click) = "changePeriodFilter('90');getFilterData('90')">
            <span class="period-font-unselected"  id="ninethy-font"> 90 dias</span>
          </div>
          <div class="period-item-unselected"  id="calendar-div" (click) = "changePeriodFilter('calendar')">
            <div class="icon-button">
              <fibra-range-datepicker 
              (change)="changePeriodDate($event)" [maxDate]="maxDate"
              ></fibra-range-datepicker>
            </div>
          </div>
        </div>
     
      </div>
      <div class="form-check form-check-inline">
        <input
          type="radio"
          class="form-check-input"
          id="contractRadio"
          name="filter"
          formControlName="filter"
          mdbInput
          (click) = "changeFilterType('contract')"
          value="contract"
        />
        <label class="form-check-label custom-label" for="opticalReader"
          >Número do contrato</label
        >
          <input
            type="text"
            maxlength="10"
            onlyNumber
            placeholder="0000000000"
            class="line-code-bar"
            name="contractNum"
            formControlName="contractNum"
            [readonly]="filterByPeriod"
            [disabled]="!filterByPeriod"
          >
          <div class="button-receipt">
            <button
              type="button"
              class="button-continue"
              (click)="sendPeriodData('contract')"
              [disabled]="!filterByContract"
            >
            Consultar
            </button>
          </div>
          <div>
            <br><br><br><br>
            <span class="field-required" *ngIf="(contractNum === '' || !contractNum) && fieldRequired">Campo obrigatório</span>      
          </div>
      </div>
    </form>
  </div>
  <div class="lado-a-lado">

    
    <div class="datatable" *ngIf="!loading && !noData && !hasError">
      <div class="table-title">
          <div class="table-item">
            <span class="item-font">Contrato</span>
          </div>
          <div class="table-item">
            <span class="item-font">Data de contratação</span>
          </div>
          <div class="table-item">
            <span class="item-font">Data de liquidação</span>
          </div>
          <div class="table-item">
            <span class="item-font">Moeda estrangeira</span>
          </div>
          <div class="table-item">
            <span class="item-font">Moeda nacional</span>
          </div>
          <div class="table-item" style="width: 10% !important;">
            <span class="item-font">OP</span>
          </div>
          <!-- <div class="table-item">
            <span class="item-font">Totais</span>
          </div> -->
          
      </div>

      <div *ngIf="isFilter" class="periodo">Período: {{ dataInicioFilter }} à {{ dataFimFilter }}</div>
      <div class="table-content" *ngFor="let change of changeArray; let i = index">
        
        <div class="title">
          <span class="title-font"> {{change.data}}</span>
        </div>
        <div class="type">
          <span class="type-font"> Tipo de operação: {{change.tipo}} </span>
        </div>
        <div class="table-title">
          <div class="table-item">
            <span class="item-font">{{change.num_contrato}}</span>
          </div>
          <div class="table-item">
            <span class="item-font">{{change.dt_op}}</span>
          </div>
          <div class="table-item">
            <span class="item-font">{{change.dt_liq}}</span>
          </div>
          <div class="table-item">
            <span class="item-font"> USD {{change.valorFormatadoUsd}}</span>
          </div>
          <div class="table-item">
            <span class="item-font">BRL {{change.valorFormatadoBrl}}</span>
          </div>
          <div class="table-item op-align" style="width: 10% !important;">
            <span class="item-font">
              <img class="icon-download" src="/assets/svg/icon_download.svg" alt="download" (click)="getPDFNotes(change.num_contrato, 'CambioPronto')" />
            </span> 
          </div>
          <div class="table-item">
            <span class="item-font"></span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!loading">
      <fibra-side-card-ready-change
        [options]="changeTotais"
        class="totais"
      ></fibra-side-card-ready-change>
    </div>
    <div class="datatable warning-display" *ngIf="loading">
      <div
        class="d-flex justify-content-center position loading-container"
      >
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
   
  
    <div class="datatable warning-display"  *ngIf="noData && !loading">
      <div class="empty-info">
        <fibra-generic-info
        type="warn"
        title="Nenhum resultado encontrado"
        message="Tente uma nova pesquisa inserindo novos parâmetros."
      >
      </fibra-generic-info>
    
      <fibra-generic-info
      type="error"
      title="Ocorreu um erro inesperado"
      message="Não foi possível encontrar dados de movimentação!"
      *ngIf="hasError && !loading"
    >
    </fibra-generic-info>
      </div>
    </div>
    
  
    <div class="datatable warning-display" *ngIf="hasError && !loading">
      <div class="empty-info" >
        <fibra-generic-info
          type="error"
          title="Ocorreu um erro inesperado"
          message="Não foi possível encontrar dados de movimentação!"
        >
        </fibra-generic-info>
      </div>
    </div>
  </div>

</div>