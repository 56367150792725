import { PendencyLimitsDataShare } from './../../../../core/usecases/data-share/pendency-limits-data-share.service';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { PixGetTermsDataRepository } from '@src/data/repository/pix/terms/pix-get-terms-repository';
import { ToastErrorMessageComponent } from '../../components/toast-error-message/toast-error-message.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProfileAccessProvider } from '@src/data/repository/perfilamento/profile.repository';
import { AccessDataRepository } from '@src/data/repository/access-repository/access.repository';
import { PixGetLimitesDataRepository } from '@src/data/repository/pix/limites/pix-get-limites-repository';
import { PixGetKeysDataRepository } from '@src/data/repository/pix/keys/pix-get-keys-repository';
import { ServiceChannelsComponent } from '@src/presentation/web/components/service-channels/service-channels.component';
import { PixGetAccountsDataRepository } from '@src/data/repository/pix/accounts/pix-get-accounts-repository';
import { AccountPixDataShare } from '@src/core/usecases/data-share/account-pix-data-share.service';
import { RolesService } from '@src/shared/services/roles.service';
import { Router } from '@angular/router';

@Component({
  selector: 'fibra-pix',
  templateUrl: './pix.component.html',
  styleUrls: ['./pix.component.scss']
})
export class PixComponent implements OnInit {
  public stepsInformationPix: number = 1;
  public readInfomationKeyPix = false;
  public selectedNavBar: string = "minhas-chaves";
  public typeOfuser: string;
  public existTermsOfapprovals: boolean = false;
  public showOnboarding: boolean = false;

  public notif = false;
  public notifLimites = false;
  public initPix = false;
  public stepsInformationPixKey: number = 1;
  public temPendencias = false;
  public temPendenciasChave = false;
  public temPendenciasDoacao = false;
  public temPendenciasRequisicao = false;

  public isTransfer = false;
  public isKeys = false;
  public isTerms = false;
  public minutes = 5;
  public pixKeys = false;
  public isAccounts = true;
  public pixCadastro = false;
  public pixBeneficiaries = false;
  public pixConfirm = false;
  public accountNumSelecionado = '';
  public pixNewTransfer = false;
  public isSolicitations = false;
  public isApprovals = false;
  public isTermApprovals = false;
  public isLimits = false;
  public limiteCadastro = false;
  public pixBanks = false;
  public pixFibra = false;
  public pixOutroBanco = false;
  public pixComprovante = false;
  public loadingConfirmPayment = false;
  public readInfomartionTextComprovante = false;
  public permissoes;
  public isSolicitacao: boolean = false;
  public userEmail:string;
  public receivedPendency: boolean = false;
  //public ids = ["transferir", 'minhas-chaves', 'solicitacoes', 'termos', 'limits'];
  public isProof = false;
  counter = 0;
  isMaster;
  interval;
  @ViewChild('content', null) content;
  @Output() changeNav = new EventEmitter()
  @ViewChild(ToastErrorMessageComponent, null)
  toast: ToastErrorMessageComponent;
  public solicitationKey: any;
  public userNanme;

  public isApprovalsKeys = true;
  public isAprovalsSolicitationKeys: boolean;
  public keySolicitation: any;
  public subMenuAprovacoes: string = null;

  public refundOnOf: boolean = false;
  public newRefundOnOf: boolean = false;
  public confirmRefund: boolean = false;
  public showReceiptDevolution: boolean = false;

  constructor(private serviceTerms: PixGetTermsDataRepository,
    private limitesService: PixGetLimitesDataRepository,
    private modalService: NgbModal,
    private profileService: ProfileAccessProvider,
    private accesService: AccessDataRepository,
    public getKeys: PixGetKeysDataRepository,
    public getAccService: PixGetAccountsDataRepository,
    private sharePixAccount: AccountPixDataShare,
    private roles: RolesService,
    private pendencyLimitsDataShare: PendencyLimitsDataShare,
    private router: Router
  ) {
    window.addEventListener('FIBRA_SWITCH_VIEW', () => {
      window.scrollTo(0, 0);

      const cache = localStorage.getItem('view-selecionada');
      const cacheSplit = cache.split('-');
      
      if (cacheSplit[0] === 'transferir') {
        if (cacheSplit.length === 3 && cacheSplit[2] === 'forcechangetab') {
          this.changeNavbarClass('transferir');
        }

        this.isKeys = false;
        this.isApprovals = false;
        this.isTransfer = true;
        this.initPix = true;

        this.refundOnOf = false;
        this.newRefundOnOf = false
        this.confirmRefund = false;
        this.showReceiptDevolution = false;

        if (cacheSplit[1] === 'transferir') {
          this.readInfomartionTextComprovante = false;
          this.pixBeneficiaries = false;
          this.pixConfirm = false;
          this.pixNewTransfer = false;
          this.isAccounts = true;
          this.pixBanks = false;
          this.pixFibra = false;
          this.pixOutroBanco = false;
          this.pixComprovante = false;

          this.refundOnOf = false;
          this.newRefundOnOf = false
          this.confirmRefund = false;
          this.showReceiptDevolution = false;
        } else if (cacheSplit[1] === 'beneficiario') {
          this.readInfomartionTextComprovante = false;
          this.pixBeneficiaries = true;
          this.pixConfirm = false;
          this.pixNewTransfer = false;
          this.isAccounts = false;
          this.pixBanks = false;
          this.pixFibra = false;
          this.pixOutroBanco = false;
          this.pixComprovante = false;

          this.refundOnOf = false;
          this.newRefundOnOf = false
          this.confirmRefund = false;
          this.showReceiptDevolution = false;
        }
        else if (cacheSplit[1] === 'newtransfer') {
          this.readInfomartionTextComprovante = false;
          this.isAccounts = false;
          this.pixConfirm = false;
          this.pixBeneficiaries = false;
          this.pixNewTransfer = true;
          this.pixBanks = false;
          this.pixFibra = false;
          this.pixOutroBanco = false;
          this.pixComprovante = false;

          this.refundOnOf = false;
          this.newRefundOnOf = false
          this.confirmRefund = false;
          this.showReceiptDevolution = false;

        }else if(cacheSplit[1] === 'confirmTransfer'){
          this.readInfomartionTextComprovante = false;
          this.isAccounts = false;
          this.pixConfirm = true;
          this.pixBeneficiaries = false;
          this.pixNewTransfer = false;
          this.pixBanks = false;
          this.pixFibra = false;
          this.pixOutroBanco = false;
          this.pixComprovante = false;
        }else if(cacheSplit[1] === 'listBancos'){
          this.readInfomartionTextComprovante = false;
          this.isAccounts = false;
          this.pixConfirm = false;
          this.pixBeneficiaries = false;
          this.pixNewTransfer = false;
          this.pixBanks = true;
          this.pixFibra = false;
          this.pixOutroBanco = false;
          this.pixComprovante = false;
        }else if(cacheSplit[1] === 'transferFibra'){
          this.readInfomartionTextComprovante = false;
          this.isAccounts = false;
          this.pixConfirm = false;
          this.pixBeneficiaries = false;
          this.pixNewTransfer = false;
          this.pixBanks = false;
          this.pixFibra = true;
          this.pixOutroBanco = false;
          this.pixComprovante = false;
        }else if(cacheSplit[1] === 'transferOutroBanco'){
          this.readInfomartionTextComprovante = false;
          this.isAccounts = false;
          this.pixConfirm = false;
          this.pixBeneficiaries = false;
          this.pixNewTransfer = false;
          this.pixBanks = false;
          this.pixFibra = false;
          this.pixOutroBanco = true;
          this.pixComprovante = false;
        }else if(cacheSplit[1] === 'pixTransferComprovante'){
          this.isAccounts = false;
          this.pixConfirm = false;
          this.pixBeneficiaries = false;
          this.pixNewTransfer = false;
          this.pixBanks = false;
          this.pixFibra = false;
          this.pixOutroBanco = false;
          this.pixComprovante = true;
          this.initPix = false;
          this.readInfomartionTextComprovante = true;
        }

      } else if (cacheSplit[0] === 'minhaschaves') {
        this.changeNavbarClass('minhas-chaves');
        this.isApprovalsKeys = false;
        this.isTransfer = false;
        this.isKeys = true;
        this.isApprovals = false;
        this.initPix = true;

        this.refundOnOf = false;
        this.newRefundOnOf = false
        this.confirmRefund = false;
        this.showReceiptDevolution = false;

        if (cacheSplit[1] === 'transferir') {
          this.pixCadastro = false;
          this.pixKeys = false;
          this.isAccounts = true

          this.refundOnOf = false;
          this.newRefundOnOf = false
          this.confirmRefund = false;
          this.showReceiptDevolution = false;

        } else if (cacheSplit[1] === 'chaves') {
          this.pixKeys = true;
          this.isAccounts = false
          this.pixCadastro = false;

          this.refundOnOf = false;
          this.newRefundOnOf = false
          this.confirmRefund = false;
          this.showReceiptDevolution = false;

        } else if (cacheSplit[1] === 'cadastro') {
          this.pixCadastro = true;
          this.pixKeys = false;
          this.isAccounts = false

          this.refundOnOf = false;
          this.newRefundOnOf = false
          this.confirmRefund = false;
          this.showReceiptDevolution = false;

        }

      } else if (cacheSplit[0] === 'aprovacoes') {
        this.initPix = true;
        this.subMenuAprovacoes = cacheSplit[1];
        this.showReceiptDevolution = false;

        if (this.isSolicitacao) {
          this.changeNavbarClass('solicitacoes-chaves');
        } else {
          this.changeNavbarClass('solicitacoes');
        }        
      } else if (cacheSplit[0] === 'termo') {
        this.isTransfer = false;
        this.isKeys = false;
        this.isApprovals = false;
        this.isTerms = true;
        this.initPix = true;

        this.showReceiptDevolution = false;
        if (cacheSplit[1] === 'transferir') {
          this.isTermApprovals = false;
          this.isAccounts = true;

          this.refundOnOf = false;
          this.newRefundOnOf = false
          this.confirmRefund = false;
          this.showReceiptDevolution = false;
        }
        else if (cacheSplit[1] === 'aprovacoes') {
          this.isTermApprovals = true;
          this.isAccounts = false;
          this.refundOnOf = false;
          this.newRefundOnOf = false
          this.confirmRefund = false;
          this.showReceiptDevolution = false;
        }
      }
      else if (cacheSplit[0] === 'limite') {
        this.initPix = true;

        this.refundOnOf = false;
        this.newRefundOnOf = false
        this.confirmRefund = false;
        this.showReceiptDevolution = false;

        if (cacheSplit[1] === 'home') {
          this.limiteCadastro = false;
          this.isLimits = true;
          this.isAccounts = false

          this.refundOnOf = false;
          this.newRefundOnOf = false
          this.confirmRefund = false;
          this.showReceiptDevolution = false;
        }
        if (cacheSplit[1] === 'cadastro') {
          this.limiteCadastro = true;
          this.isLimits = false;
          this.isAccounts = false

          this.refundOnOf = false;
          this.newRefundOnOf = false
          this.confirmRefund = false;
          this.showReceiptDevolution = false;
        }
      } else if (cacheSplit[0] === 'refund'){
        this.refundOnOf = true;
        this.isAccounts = false;
        this.newRefundOnOf = false
        this.confirmRefund = false;
        this.showReceiptDevolution = false;

      } else if (cacheSplit[0] === 'confirmRefundPix'){
        this.refundOnOf = false;
        this.isAccounts = false;
        this.newRefundOnOf = false
        this.confirmRefund = false;
        this.showReceiptDevolution = true;
      }
    });



    if(this.router.getCurrentNavigation() && this.router.getCurrentNavigation().extras.state){
      let comingFrom = this.router.getCurrentNavigation().extras.state.comingFrom;
      if(comingFrom === 'change-transactional'){
        this.accountNumSelecionado = this.router.getCurrentNavigation().extras.state.accountSelected;
      }
    }

  }

  ngOnInit() { 
    this.permissoes = this.roles.get();
    this.getTemPendencias();
    this.getAccounts();
    this.accesService.getAccess().subscribe((data: any) => {
      this.isMaster = data['data'].acessoMaster;
      if(!this.isMaster && this.permissoes.includes('PIX.CONS_SOLIC')){
        this.isSolicitacao = true;
      }
    });
    this.userNanme = localStorage.getItem('useremail')
    

    this.existTermsOfapprovals = true;

    this.wasReadInfomationPix("1");
    this.wasReadInfomationPixKey();

    this.isTherePortabilityOrClaim();

    this.limitesService.getPixLimitesPendenteAprovacao().subscribe(data => {
      let accounts: any;
      accounts = data['data'];
      this.notifLimites = accounts > 0 ? true : false;
    })

    this.isTransfer = true;

    this.isTermsPending();
    if(this.permissoes.includes('PIX.TRANSF_PIX_CHAVE') || 
      this.permissoes.includes('PIX.TRANSF_PIX_MSM_TIT') || 
      this.permissoes.includes('PIX.TRANS_PIX_OTR_TIT') || 
      this.permissoes.includes('PIX.TRANSF_PIX_MSM_TIT_BC') || 
      this.permissoes.includes('PIX.TRANSF_PIX_OTR_TIT_BC')){

        this.selectedNavBar = "transferir";
    }else if(this.permissoes.includes('PIX.CAD_CHAVE') || this.permissoes.includes('PIX.EXC_CHAVE')){
      this.selectedNavBar = "minhas-chaves";
    }else if(this.permissoes.includes('PIX.CONS_SOLIC') ){
      this.selectedNavBar = "solicitacoes";
    }

    return false;
  }
  
  ngAfterViewInit() {
    this.getPendency();
    if(localStorage.getItem('redirectPixLimits')) {
      this.selectedNavBar = 'limites';
      this.changeNavbarClass(this.selectedNavBar);
      localStorage.removeItem('redirectPixLimits');
    }
  }

  onOfComponent(ev){
    this.refundOnOf = ev;
    this.newRefundOnOf = true;
  }

  onOfNewRefundComponent(ev){
    this.newRefundOnOf = false;
    this.refundOnOf = ev;
    this.confirmRefund = true;
  }


  public getAccounts() {
    this.getAccService.getAccounts().subscribe((accs) => {
      this.sharePixAccount.setValue(accs.data);
    })
  }

  openModalServicesChannels() {
    this.modalService.open(ServiceChannelsComponent, {
      centered: true,
      size: '400px',
      windowClass: 'modal-xxl',
      backdrop: 'static',
    });
  }
  public forward() {
    if(this.stepsInformationPix >= 4){
      this.stepsInformationPix = 4;
      return;
    }
    
    this.stepsInformationPix += 1;
  }

  public backward() {
    if(this.stepsInformationPix > 1){
      this.stepsInformationPix--
    }    
  }

  public finalizarIntroKeyPix() {
    this.readInfomationKeyPix = true;
    this.serviceTerms.alterReadInformartionPix('2').subscribe(_data => {
      this.readInfomationKeyPix = true;
    }, error => {
      console.log(error)
    })

  }

  public finalizarIntro() {
    this.serviceTerms.alterReadInformartionPix('1').subscribe();

    this.showOnboarding = false;
    this.isProof = false;

    if (this.readInfomartionTextComprovante) {
      this.pixComprovante = true;
      this.initPix = false;
    } else {
      this.readInfomartionTextComprovante = false;
      this.isKeys = false;
      this.isApprovals = false;
      this.isTransfer = true;
      this.initPix = true;
      this.isAccounts = true;
    }
  }

  public changeNavbarClass(id: string) {
    this.selectedNavBar = id;
    if (id === 'transferir') {
      this.isKeys = false;
      this.isTransfer = true;
      this.isTerms = false;
      this.isApprovals = false;
      this.isAccounts = true;
      this.pixBeneficiaries = false;
      this.pixConfirm = false;
      this.pixNewTransfer = false;
      this.pixBanks = false;
      this.pixFibra = false;
      this.pixOutroBanco = false;
      this.isLimits = false;
      this.receivedPendency = false;

      this.refundOnOf = false;
      this.newRefundOnOf = false
      this.confirmRefund = false;
      this.showReceiptDevolution = false;
      
    }
    else if (id === 'minhas-chaves') {
      this.isKeys = true;
      this.isTransfer = false;
      this.isApprovals = false;
      this.isTerms = false;
      this.isAccounts = true;
      this.pixKeys = false;
      this.pixCadastro = false;
      this.isLimits = false;
      this.receivedPendency = false;

      this.refundOnOf = false;
      this.newRefundOnOf = false
      this.confirmRefund = false;
      this.showReceiptDevolution = false;
    }
    else if (id === 'solicitacoes') {
      this.isKeys = false;
      this.isTransfer = false;
      this.isApprovals = true;
      this.isTerms = false;
      this.isApprovalsKeys = true;
      this.isLimits = false;
      this.receivedPendency = false;

      this.refundOnOf = false;
      this.newRefundOnOf = false
      this.confirmRefund = false;
      this.showReceiptDevolution = false;
    }
    else if (id === 'termos') {
      this.isKeys = false;
      this.isTransfer = false;
      this.isApprovals = false;
      this.isLimits = false;
      this.isTerms = true;
      this.isTermApprovals = true;
      this.receivedPendency = false;

      this.refundOnOf = false;
      this.newRefundOnOf = false
      this.confirmRefund = false;
      this.showReceiptDevolution = false;
    }
    else if (id === 'limites') {
      this.isKeys = false;
      this.isTransfer = false;
      this.isApprovals = false;
      this.isTerms = false;
      this.isLimits = true;
      this.isTermApprovals = true;
      this.isAccounts = true;
      this.receivedPendency = false;

      this.refundOnOf = false;
      this.newRefundOnOf = false
      this.confirmRefund = false;
      this.showReceiptDevolution = false;
    }
    else if (id === 'solicitacoes-chaves') {
      this.isKeys = false;
      this.isTransfer = false;
      this.isApprovals = false;
      this.isTerms = false;
      this.isTermApprovals = false;
      this.isSolicitations = true;
      this.isLimits = false;
      this.receivedPendency = false;

      this.refundOnOf = false;
      this.newRefundOnOf = false
      this.confirmRefund = false;
      this.showReceiptDevolution = false;

    } else if (id === 'refund') {
      this.isAccounts = true;
      this.refundOnOf = false;
      this.newRefundOnOf = false
      this.confirmRefund = false;
      this.showReceiptDevolution = false;
      this.isLimits = false;
      this.receivedPendency = false;
    }

  }

  public getAccSelecionada(event) {
    this.accountNumSelecionado = event;
  }

  public changeViewChaves(event) {
    if (event === 'cadastro') {
      this.pixCadastro = true;
      this.pixKeys = false;
      this.pixBeneficiaries = false;
      this.isAccounts = false;
    } else if (event === 'chaves') {
      this.pixCadastro = false;
      this.pixKeys = true;
      this.pixBeneficiaries = false;
      this.isAccounts = false;
    } else if (event === 'accounts') {
      this.pixCadastro = false;
      this.pixKeys = false;
      this.pixBeneficiaries = false;
      this.isAccounts = true;
    }
  }

  public changeViewTransferir(event) {
    if (event === 'transferir') {
      this.isAccounts = true;
    } else if (event === 'beneficiaries') {
      this.pixBeneficiaries = true;
      this.isAccounts = false;
    }
  }

  public changeNavBars(event) {
    if (event === 'transferir') {
      this.isKeys = false;
      this.isTransfer = true;
      this.isApprovals = false;
      this.isAccounts = true;
      this.pixBeneficiaries = false;

    }
  }

  public wasReadInfomationPix(type) {
    this.serviceTerms.getRedInfomationPix(type).subscribe(data => {
      this.showOnboarding = !data['data'].visualizado;

      if (data['data'].visualizado) {
        this.isProof = false;
      }

      this.initPix = true;
    }, error => {
      console.log(error)
    })
  }

  public wasReadInfomationPixKey() {
    this.serviceTerms.getRedInfomationPix('2').subscribe(data => {
      if (data['data'].visualizado) {
        this.readInfomationKeyPix = true;
      }
      this.initPix = true;
    }, error => {
      console.log(error)
    })
  }

  public closeModal(_$event): void {
    this.modalService.dismissAll();
  }

  openModal(content): void {
    this.modalService.open(content, {
      centered: true,
      size: 'lg',
      windowClass: 'modal-xxl',
      backdrop: 'static',
    });
  }

  emitGotoPage($event) {
    switch ($event) {
      case 'trans':
        this.changeNavbarClass('transferir');
        break;
      case 'keys':
        this.changeNavbarClass('minhas-chaves');
        break;
      case 'trans':
        this.changeNavbarClass('minhas-chaves');
        break;
      default:
        break;
    }
  }

  selectConfirmationType(isType) {
    this.isAprovalsSolicitationKeys = isType
    this.keySolicitation = { key: this.solicitationKey, isApprovals: this.isAprovalsSolicitationKeys }
    window.sessionStorage.setItem('key',JSON.stringify(this.keySolicitation));
  }

  isTherePortabilityOrClaim() {
    this.getKeys.getRequestReceivedPending().subscribe((data: any) => {
      for (const key of data['data']) {
        if (this.isPending(key.aprovacoes)) {
          this.solicitationKey = key;
          this.openModal(this.content);
          break;
        }
      }
    }, error => {
      console.log(error);
    })
  }

  isTermsPending() {
    this.notif = false;
    this.serviceTerms.getPixTerms().subscribe(data => {
      let accounts: any;
      accounts = data['data'];
      accounts.map(dta => {
        if (this.isPending(dta.aprovacoes) === true) {
          this.notif = true
        }
      })

    });
  }

  isPending(approveds) {
    let isOtherMaster = false;
    approveds.map(data => {
      if (data.status === 'PENDENTE' && this.userNanme === data.pessoa.email) {
        isOtherMaster = true
      }
    })
    return isOtherMaster
  }

  callLoading(value){
    this.loadingConfirmPayment = value;
  }

  public clicaSaibaMais() {
    this.initPix = true;
    this.showOnboarding = true;
    this.pixComprovante = false;
  }

  public onTemPendenciasChanged(value: boolean): void {
    this.temPendencias = value;
  }

  private getTemPendencias() {
    this.getKeys.temPendencias().subscribe(result => {
      this.temPendenciasChave = result.data.temPendenciasChave;
      this.temPendenciasDoacao = result.data.temPendenciasDoacao;
      this.temPendenciasRequisicao = result.data.temPendenciasRequisicao;

      this.temPendencias = this.temPendenciasChave || this.temPendenciasDoacao || this.temPendenciasRequisicao;
    })
  }

  acceptTerm($event) {
    if($event === 'termos') {
      this.selectedNavBar = $event;
    }
  }

  getPendency() {
   if(this.pendencyLimitsDataShare.getValue() === 'pix') {
    this.receivedPendency = true;
    this.selectedNavBar = 'limites';
    this.isLimits = true;
   }

   if(this.pendencyLimitsDataShare.getValue() === 'limitMaxPix') {
    this.selectedNavBar = 'limites';
    this.isLimits = true;
   }

   this.pendencyLimitsDataShare.clear();
  }
}
