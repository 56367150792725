<fibra-toast-error-message></fibra-toast-error-message>
<div class="main-selector">
    <fibra-company-list></fibra-company-list>
    <div class="main-divisor"></div>
    <fibra-profile-list *ngIf="!loading" funcionalidade="EXT"></fibra-profile-list>
</div>
<div class="content-page" style="background-size: 180em" *fibraHasRole="'EXT.CONS_EXT_SAL'">
    <fibra-breadcrumb height="280" [infoAccount]="routAccont" roule="Extrato" [downloadDoc]="true" (infoReport)="exportScreen($event)"></fibra-breadcrumb>
    <fibra-balance-list *ngIf="!loading"></fibra-balance-list>
    <div *ngIf="!loading" class="detail">
        <div class="detail-title">Detalhamento das movimentações</div>
    </div>

    <fibra-tabs-list *ngIf="!loading" [options]="options" [filter]="true" funcionalidade="EXT"></fibra-tabs-list>

    <div *ngIf="!loading && (selectedTab === 'extract-list-all')" class="scheduledView">
        <img src="../../../../assets/png/icon-pending.png" class="scheduledIcon" />
        <span class="scheduledSpan">Exceto agendamentos</span>
    </div>

    <div *ngIf="!loading" class="movement-sale row">
        <fibra-list class="extract-list" balance="{{ balance }}"></fibra-list>
        <fibra-side-card [options]="optionsSideCard"></fibra-side-card>
    </div>
</div>
<fibra-access-denied-page *fibraHasRole="'!EXT.CONS_EXT_SAL'"></fibra-access-denied-page>


<div style="margin-left: 400px;">
    <section class="body-content report" id="body-report" *ngIf="report" style="position: absolute;">
        <fibra-extract-report id="report" (closeReport)="changeReportView($event)"></fibra-extract-report>
    </section>
</div>

<div *ngIf="loading" class="d-flex justify-content-center position loading-container loadingView">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>