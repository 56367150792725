import { Injectable } from '@angular/core';
import { TokenRepository } from '@src/core/repositories/token/token.repository';
import { Observable, of, Subject, throwError } from 'rxjs';
import { TokenRepositoryMapper } from '@src/data/repository/token-repository/token-repository.mapper';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TokenEntity } from '@src/data/repository/token-repository/token.entity';
import { flatMap, map, catchError, concatMap, delay, retryWhen, finalize } from 'rxjs/operators';
import {
  TokenTypeModel,
  NewTokenRequest,
} from '@src/core/domain/token/tokenType.model';
import { handleError } from '@src/shared/util-common';

// header
import { getInitialHeader, getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';
import { RolesService } from '@src/shared/services/roles.service';
//router
import { ActivatedRoute, Params, Router } from '@angular/router';


@Injectable({
  providedIn: 'root',
})
export class TokenDataRepository extends TokenRepository {
  mapper = new TokenRepositoryMapper();
  
  constructor(private http: HttpClient, private rolesService: RolesService, private router: Router, private route: ActivatedRoute) {
    super();
  }

  getToken(): Observable<TokenTypeModel> {
    const httpOptions = {
      headers: getInitialHeader(),
    };

    let interaction_id = null;

    this.route.queryParams.subscribe((params: Params) => {
      if (params && params.interaction_id) {
        interaction_id = params.interaction_id;
      }
    });
    console.log('>>>>>>>>>>>>>', interaction_id);
    if (interaction_id) {
      this.destroySessionStorageResponse();
      const result = new Subject<TokenTypeModel>();
      console.log('>>>>>>>>>>>>>> entrou if');
      this.http.get<TokenEntity>(`${environment.URLBase}v1/usuarios/sessao`, httpOptions)
        .pipe(retryWhen(errors => errors.pipe(concatMap((error, count) => { if (count < 3 && (error.status === 504 || error.status === 500)) { return of(error); } return throwError(error.error); }), delay(500))))
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.mapper.mapFrom), catchError((error: HttpErrorResponse) => {
          result.error(error);
          return handleError(error);
        }))
        .pipe(map((val) => {
          this.rolesService.setSession(val.data.sessionToken);
          return val;
        })).subscribe(
          () => {
            const _handleError = (error: HttpErrorResponse) => {
              this.rolesService.clearOpenFinanceSession()
              return handleError(error);
            }

            this.http.get<TokenEntity>(`${environment.URLBase}v1/openfinance/sessao/${interaction_id}`, { headers: getHeader() })
              .pipe((catchError(_handleError)))
              .pipe(map(r => {
                this.rolesService.setSession(r.data.token_sessao);
                this.rolesService.setOpenFinanceSession(r.data, r.data.interactionId);
                return this.mapper.mapFrom(r);                
              })).subscribe(
                r => result.next(r),
                e => result.error(e),
                () => result.complete()
              )
            }
        );
      return result;

    } else {
      return this.http.get<TokenEntity>(`${environment.URLBase}v1/usuarios/sessao`, httpOptions)
        .pipe(retryWhen(errors => errors.pipe(concatMap((error, count) => { if (count < 3 && (error.status === 504 || error.status === 500)) { return of(error); } return throwError(error.error); }), delay(500))))
        .pipe(flatMap((item) => [item]))
        .pipe(map(this.mapper.mapFrom), catchError(handleError))
        .pipe(map((val) => {
          this.rolesService.setSession(val.data.sessionToken);

          return val;
        }));
    }
  }

  getNewToken(obj: NewTokenRequest): Observable<TokenTypeModel> {
    const httpOptions = {
      headers: getHeader(),
      params: { ...obj },
    };
    return this.http
      .get<TokenEntity>(
        `${environment.URLBase}v1/contas/selecionar`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError))
      .pipe(map((val) => {
        this.rolesService.setSession(val.data.sessionToken);
        return val;
      }));
  }

  getNewTokenExtract(): Observable<TokenTypeModel> {
    return this.http.get<TokenEntity>(`${environment.URLBase}v1/contas/selecionar/extrato`, { headers: getHeader() })
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError))
      .pipe(map((val) => {
        this.rolesService.setSession(val.data.sessionToken);
        return val;
      }));
  }

  validProblemaCredito() {
    let roles = false;
    this.rolesService.getRoles().find((res: any) => console.log(res)); 
  }


  destroySessionStorageResponse() {
    sessionStorage.getItem('response-limite') ? sessionStorage.removeItem('response-limite') : '';
    sessionStorage.getItem('response-saldo') ? sessionStorage.removeItem('response-saldo') : '';
  }
}
