import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { periods, TabsDataShare, optionWalletConsultas } from '@src/data/repository/data-share-repository/index';
import { optionWallet, optionTypeTransfer, optionTypeReceipt } from '@src/data/repository/data-share-repository';
import { TransactionsDataShare } from '@src/core/usecases/data-share/transactions-data-share.service';
import { DropdownList } from '@src/core/domain/business-models';
import { GetAllAccountTypeUsecase } from '@src/core/usecases/account/get-all-accountTypes.usecase';
import { isArray } from 'util';
import { toTitleCase } from 'codelyzer/util/utils';
import { Observable } from 'rxjs';
import { AccountTypeModel } from '@src/core/domain/account/accountType.model';
import { GetAccountsByFunctionalityUsecase } from '@src/core/usecases/account/get-accounts-by-functionality.usecase';

@Component({
  selector: 'fibra-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Input() inputFilter;
  @Input() default;
  @Input() approval;
  @Input() receipt;
  @Input() isSchedules;
  @Input() approvalTransaction = false;
  @Input() abaTransacionais = false;
  @Input() minDayCalendar: number = null;
  @Input() maxDaysInterval: number = null;
  @Input() public funcionalidade: string = null;
  @Input() isReceiptPix: boolean;

  public filter: FormGroup;
  public filterInput: FormGroup;
  public optionWallet = optionWallet;
  public optionsProfile: DropdownList;
  public optionTypeTransfer = optionTypeTransfer;
  public optionTypeReceipt = optionTypeReceipt;
  public periods;
  public periodDays;
  public dataInicio;
  public dataIn;
  public dataFinal
  public dataFim;
  public period;
  public periodDate;
  public date = new Date();
  public type;
  public account;
  public wallet;
  public ourNumber;
  public yourNumber;
  public draweeName;
  public periodCalendar: any;
  public optionPeriod = [7, 15, 30, 90];
  public dataRequest = 7;
  public today: NgbDate;
  public maxDay: NgbDate;
  public minDay: NgbDate;
  public selected = 'Todas';
  public optionWalletConsultas = optionWalletConsultas;
  public calendarActive = {
    active: false,
    dataStart: null,
    dataEnd: null,
  };
  public isGreaterThan730Days: boolean;
  public isGreaterThanDayConfig: boolean;
  public loading: boolean = false;
  public typeEvent: string;

  @Output() emitFilter: EventEmitter<any>;
  @Output() emitFilterToast: EventEmitter<any> = new EventEmitter();

  constructor(
    public tab: TabsDataShare,
    private formBuild: FormBuilder,
    private getAllAccountTypes: GetAllAccountTypeUsecase,
    private getAccountsByFuncionality: GetAccountsByFunctionalityUsecase,
    private transactionsDataShare: TransactionsDataShare,
    private calendar: NgbCalendar
  ) {
    this.filter = this.formBuild.group({
      wallet: [''],
      account: [''],
      type: [''],
    });
    this.filterInput = this.formBuild.group({
      ourNumber: [''],
      yourNumber: [''],
      draweeName: [''],
    });
    this.optionsProfile = [];
    this.periods = periods;
    this.emitFilter = new EventEmitter();
    this.today = calendar.getToday();
    this.maxDay = calendar.getNext(this.today, 'd', 180);
    this.minDay = calendar.getPrev(this.today, 'd', 730);
  }

  ngOnInit() {
    this.changeMinDayCalendar();
    this.requestAccount();
    this.changePeriod(this.dataRequest);
  }

  public getOptionWallet(): { name: string, value: string }[] {    
    return this.abaTransacionais ? 
      this.optionWallet.filter(wallet => wallet.value == 'arquivos-em-transito') :
      this.optionWallet.filter(wallet => wallet.value != 'arquivos-em-transito');
  }

  public sendOptionsExtract() {
    this.tab.setValue({
      periodDays: this.periodDays,
      periodCalendar: this.periodCalendar,
    });
  }

  public initSchedules() {
    this.isSchedules = true;
    this.changePeriod(7);
  }

  public initAll() {
    this.isSchedules = false;
    this.changePeriod(7);
  }

  public initDiffSchedules() {
      this.isSchedules = undefined;
      this.changePeriod(7);
    }

  private requestAccount(): void {
    this.loading = true;

    let getAccounts: Observable<AccountTypeModel>;
    
    if (this.funcionalidade == null) {
      getAccounts = this.getAllAccountTypes.execute(null);
    } else {
      getAccounts = this.getAccountsByFuncionality.execute({ functionality: this.funcionalidade, filter: true });
    }

    getAccounts
      .pipe(finalize(() => this.loading = false))
      .subscribe(this.requestSuccess, this.requestError);
  }

  private formatAccount = (account): string => {
    const desc = toTitleCase(account.exibicao);
    return `${account.num_conta} - ${desc}`;
  }

  private requestSuccess = (value) => {
    let x = 0;
    if (isArray(value.data)) {
      value.data.map((e, i) => {
        this.optionsProfile.push({
          indice: e.num_indice,
          name: this.formatAccount(e),
          value: e.num_conta,
          modality_account: e.cod_modalidade,
          selected: false,
        });

        x = e.num_indice;
      });
      this.optionsProfile.unshift({
        indice: x++,
        name: 'Todas',
        value: '',
        modality_account: '',
        selected: true,
      });
    } else {
      this.optionsProfile.push({
        indice: value.data.num_indice,
        name: this.formatAccount(value.data),
        value: value.data.num_conta,
        modality_account: value.data.cod_modalidade,
        selected: true,
      });
    }
    if (this.optionsProfile.length === 1) {
      this.selected = this.optionsProfile[0].name;
    }
    this.requestComplete();
  }

  public async sendOptions() {
    if (this.ourNumber || this.yourNumber || this.draweeName) {   
      try {
        this.emitFilter.emit({
          account: await this.account,
          dt_Inicio: await this.dataInicio,
          dt_Final: await this.dataFim,
          codigo_especie: await this.wallet.codigo_especie,
          draweeName: (await this.draweeName) || '',
          yourNumber: (await this.yourNumber) || '',
          ourNumber: (await this.ourNumber) || '',
          type: (await this.type) || '',
          periodCalendar: this.periodCalendar || '',
        });
      } catch (error) {
        this.emitFilter.emit({
          account: await this.account,
          dt_Inicio: await this.dataInicio,
          dt_Final: await this.dataFim,
          draweeName: (await this.draweeName) || '',
          yourNumber: (await this.yourNumber) || '',
          ourNumber: (await this.ourNumber) || '',
          type: (await this.type) || '',
          periodCalendar: this.periodCalendar || '',
        });
      }  
      
      return;
    }
    else if (this.account !== undefined) {      
     try {
      this.emitFilter.emit({
        account: await this.account,
        dt_Inicio: await this.dataInicio,
        dt_Final: await this.dataFim,
        periodCalendar: this.periodCalendar || '',
        codigo_especie: await this.wallet.codigo_especie,
        typeEvent: this.typeEvent
      });
     } catch (error) {
      this.emitFilter.emit({
        account: await this.account,
        dt_Inicio: await this.dataInicio,
        dt_Final: await this.dataFim,
        periodCalendar: this.periodCalendar || '',
        codigo_especie: await (this.wallet && this.wallet.codigo_especie)? this.wallet.codigo_especie : '',
        typeEvent: this.typeEvent
      });
     }
    }
  }

  private async requestComplete() {
    this.account = await this.optionsProfile[0].value;
    this.sendOptions();
  }

  private requestError = (err) => {
    if (err && err.status === 401) {
      console.log(err);
    }
  };

  public changeWallet(option) {
    var codigo_especie = [
      { codigo_especie: '', value: 'todas' },
      { codigo_especie: 'DES', value: 'desconto' },
      { codigo_especie: 'TER', value: 'cobranca-terceiros' },
      { codigo_especie: 'TRA', value: 'arquivos-em-transito' },
      { codigo_especie: 'VIN', value: 'cobranca-vinculada' },
    ].find(carteira => carteira.value == option);

    // this.wallet = option;
    this.wallet = codigo_especie;
    this.filter.patchValue({
      // wallet: option
      codigo_especie,
    });

    this.sendOptions();
    this.sendOptionsExtract();
  }

  public changeType(option) {
    this.type = option;
    this.filter.patchValue({
      type: option,
    });
    this.transactionsDataShare.setValue({
      type: option,
    });
  }
  public changeMinDayCalendar(){
    if(this.minDayCalendar){
      this.minDay =  this.calendar.getPrev(this.today, 'd',this.minDayCalendar);
    }
  }
  public setDraweeName(option) {
    this.draweeName = option;
    this.filterInput.patchValue({
      draweeName: option,
    });
  }

  public setYourNumber(option) {
    this.yourNumber = option;
    this.filterInput.patchValue({
      yourNumber: option,
    });
  }

  public setOurNumber(option) {
    this.ourNumber = option;
    this.filterInput.patchValue({
      ourNumber: option,
    });
  }

  public changeAccount(option) {
    this.account = option;
    this.filter.patchValue({
      account: option,
    });
    this.sendOptions();
    this.sendOptionsExtract();
  }

  public formatDate(isSchedules?) {
    let tab = sessionStorage.getItem('tab')
    if (isSchedules) {
      let initialDate;
      let finalDate;
      this.period = this.periodDays;
      this.dataInicio = this.date.toISOString().slice(0, 10);
      initialDate = new Date(this.date);
      this.periodDate = initialDate.setDate(this.date.getDate() + this.period);
      finalDate = new Date(this.periodDate);
      this.dataFim = finalDate.toISOString().slice(0, 10);
      // this.sendOptions();
      // this.sendOptionsExtract();
      return;
    }
    this.period = this.periodDays;
    this.dataFim = this.date.toISOString().slice(0, 10);
    const auxDate = new Date(this.date);
    this.periodDate = auxDate.setDate(this.date.getDate() - this.period);
    const endDate = new Date(this.periodDate);
    this.dataInicio = endDate.toISOString().slice(0, 10);
  }

  public changePeriod(days: number, evt?) {
    this.typeEvent = evt;
    this.periodDays = days;
    this.dataRequest = days;
    this.periodCalendar = undefined;
    if (this.isSchedules) {
      this.formatDate(this.isSchedules);
      this.sendOptions();
      return;
    } 
    this.formatDate();
    this.sendOptions();
    this.sendOptionsExtract();
  }

  public changePeriodDate(period: { to: NgbDate; from: NgbDate }) {
    this.dataRequest = null;
    if(!period.from || !period.to) 
      return;

    this.dataInicio = `${period.from.year}-${this.adjustDate(
      period.from.month
    )}-${this.adjustDate(period.from.day)}`;

    this.dataFim = `${period.to.year}-${this.adjustDate(
      period.to.month
    )}-${this.adjustDate(period.to.day)}`;
    this.periodCalendar = this.convertPeriodCalendar(period);
    const dateTo = moment(this.dataFim);
    const dateFrom = moment(this.dataInicio);
    const today = moment(moment().format('YYYY-MM-DD'));
    const diffDays = dateTo.diff(dateFrom, 'days');
    this.isGreaterThan730Days = diffDays > 730 && true;
    this.isGreaterThanDayConfig = this.maxDaysInterval != null? diffDays > this.maxDaysInterval : false;
    
    if (this.isGreaterThan730Days) {
      this.emitFilterToast.emit('isGreaterThan730Days');
      return;
    } 
    if(this.isGreaterThanDayConfig){
      this.emitFilterToast.emit('isGreaterThanDayConfig_'+this.maxDaysInterval);
      return
    }
    else if (
      today.diff(dateFrom, 'days') <= 0 &&
      today.diff(dateTo, 'days') < -180
    ) {
      this.emitFilterToast.emit('outOfReach');
      return;
    }
    this.dataIn = moment(this.periodCalendar.dataInicio)
    .locale('pt-br')
    .format('DD MMM/yyyy');
    this.dataFinal = moment(this.periodCalendar.dataFinal)
    .locale('pt-br')
    .format('DD MMM/yyyy');
    this.sendOptions();
    //this.sendOptionsExtract();
  }
  private convertPeriodCalendar(period) {
    return {
      dataInicio: `${period.from.year}-${this.adjustDate(
        period.from.month
      )}-${this.adjustDate(period.from.day)}`,
      dataFinal: `${period.to.year}-${this.adjustDate(
        period.to.month
      )}-${this.adjustDate(period.to.day)}`,
    };
  }

  private adjustDate(date) {
    if (Number(date) < 10) {
      return `0${date}`;
    } else {
      return date;
    }
  }
}
