import { ModalShareDataRepository } from './repository/modal-share-data/modal-share-data.repository';
import { ModalShareDataRepositories } from '@src/core/repositories/modal-share-data/modal-share-data.respository';
import { AcceptTermRepositories } from '@src/core/repositories/accept-term/accept-term.repository';
import { PendencyLimitReducePixRepository } from '@src/core/repositories/dashboard/pendency-limit-reduce-pix.repository';
import { TransactionDetailRepository } from '@src/core/repositories/transaction/transaction.repository';
import { ReceiptPdfRepository } from '@src/core/repositories/receipt/receipt-pdf.repository';
import { TransferApprovalRepository } from '@src/core/repositories/transfer-approval/approval.repository';
import { TransferDataRepository } from './repository/transfer/transfer.repository';
import { TransferRepository } from '@src/core/repositories/transfer/transfer.repository';
import { BeneficiariesDataRepository } from './repository/transfer/beneficiaries/beneficiaries.repository';
import { BankDataRepository } from './repository/transfer/bank/bank.repository';
import { PendencyDataRepository } from './repository/dashboard/pendency/pendency.repository';
import { LoansDateParameterDataShare } from '@src/core/usecases/data-share/loans-date-param';

import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

// Data share
import {
  TabsDataShare,
  TabsOnChange,
  FirstStepDataShare,
  SecondStepDataShare,
  DownloadArchiveDataShare,
  UploadDataShare,
  ChargeArchiveDataShare,
  DuplicateBillDataShare,
  StatusMessageDataShare,
  SelectorDataShare,
  ShowValuesDashboardDataShare,
  InstructionDataShare,
  DetailsListShare,
  AccountDataShare,
  BalanceDataShare,
  FilterQueryDataShare,
  FilterBillDataShare,
  BeneficiaryDataShare,
  SelectBeneficiaryDataShare,
  TransferReceiptDataShare,
  BankDataShare,
  RemoveClassDataShare,
  DebitAccountDataShare,
  CompanySelectDataShare,
  TransferConfirmDataShare,
  TransferPendingDataShare,
  SchedulingDataShare,
  ApprovalReceiptDataShare,
} from '@src/data/repository/data-share-repository';

// Core
import { TokenRepository } from '@src/core/repositories/token/token.repository';
import { TokenDataRepository } from '@src/data/repository/token-repository/token.repository';

// Account
import { AccountTypeRepository } from '@src/core/repositories/account/account-type.repository';
import { AccountTypeDataRepository } from '@src/data/repository/account/accountType.repository';

// Company
import { CompanyTypeRepository } from '@src/core/repositories/company/company-type.repository';
import { CompanyTypeDataRepository } from '@src/data/repository/company/companyType.repository';

// Balance
import { BalancesRepository } from '@src/core/repositories/balance/balances.repository';
import { BalancesDataRepository } from '@src/data/repository/balances-repository/balances.repository';

// Extract
import { ExtractRepository } from '@src/core/repositories/extract/extract.repository';
import { ExtractDataRepository } from '@src/data/repository/extract-repository/extract.repository';

// Loans
import { LoansRepository } from '@src/core/repositories/loans/loans-repository';
import { LoansDataRepository } from '@src/data/repository/loans-repository/loans-repository';


// Query
import { QueryRepository } from '@src/core/repositories/query/query.repository';
import { QueryDataRepository } from '@src/data/repository/query/query-list/query.repository';

// Predicted
import { PredictedRepository, PredictedTotalizadoresRepository } from '@src/core/repositories/predicted/predicted.repository';
import { PredictedWebRepository, getPredictedMovementsTotalizadores } from '@src/data/repository/predicted-repository/predicted.repository';

// Product
import { ProductRepository } from '@src/core/repositories/product/product.repository';
import { ProductDataRepository } from '@src/data/repository/product/product.repository';

// Payment
import { PaymentRepository } from '@src/core/repositories/payment/payment.repository';
import { PaymentDataRepository } from '@src/data/repository/payment/payment.repository';

// Login
import { LoginRepository } from '@src/core/repositories/login/login.repository';
import { LoginDataRepository } from '@src/data/repository/login-repository/login.repository';
import { AccessRepository } from '@src/core/repositories/access/access.repository';
import { AccessDataRepository } from '@src/data/repository/access-repository/access.repository';

// OTP
import { OtpRepository } from '@src/core/repositories/otp/otp.repository';
import { OtpDataRepository } from '@src/data/repository/otp/otp.repository';

// Pix
import { RefundRepository } from '@src/core/repositories/pix/refund.repository';
import { PixGetRefundListRepository } from '@src/data/repository/pix/refund/pix-refund-repository';


// Charge
import { ArchiveRepository } from '@src/data/repository/charge/archive-repository/archive.repository';
import { ArchiveModelRepository } from '@src/core/repositories/charge/archive.repository';
import { AddressTypeModelRepository } from '@src/core/repositories/charge/address-type.repository';
import { AddressDataRepository } from '@src/data/repository/charge/address/address.repository';
import { DocumentCheckModelRepository } from '@src/core/repositories/charge/document-check-type.repository';
import { DocuemntCheckRepository } from '@src/data/repository/charge/document-check/document-check.repository';
import { TitleInformationModelRepository } from '@src/core/repositories/charge/title-information.repository';
import { TitleInformationRepository } from '@src/data/repository/charge/title-information/title-information.repository';
import {
  DownloadBillModelRepository,
  DuplicateBillModelRepository,
} from '@src/core/repositories/charge/duplicate-bill.repository';
import { DuplicateBillRepository } from '@src/data/repository/charge/duplicate-bill/duplicate-bill.repository';
import { InstructionRepository } from '@src/core/repositories/query/instruction.repository';
import { InstructionDataRepository } from './repository/query/query-instruction-list/instruction.repository';

// Login Mobile
import { FilterArchiveDataShare } from '@src/core/usecases/data-share/filter-data-share.service';
import { AccompanimentRepository } from '@src/core/repositories/dashboard/accompaniment.repository';
import { AccompanimentDataRepository } from '@src/data/repository/dashboard/accompaniment.repository';
import { WarrantyRepository } from '@src/core/repositories/dashboard/warranty.repository';
import { WarrantyDataRepository } from '@src/data/repository/dashboard/warranty/warranty.repository';
import { PendencyRepository } from '@src/core/repositories/dashboard/pendency.repository';
import { FrancesinhaRepository, FrancesinhaRepositoryResumo } from '@src/core/repositories/query/francesinha.repository';
import { FrancesinhaDataRepository, FrancesinhaResumoDataRepository } from './repository/query/query-francesinha-list/francesinha.repository';
import { BankRepository } from '@src/core/repositories/transfer/bank.repository';

// Instructions
import { InstructionsTypeRepository } from '@src/core/repositories/instructions/instructions-type.repository';
import { InstructionsTypeDataRepository } from '@src/data/repository/instructions/instructionsType.repository';
import { FavoritesRepository } from '@src/core/repositories/transfer/favorites.repository';
import { PurposesRepository } from '@src/core/repositories/transfer/purposes.repository';
import { PurposesDataRepository } from './repository/transfer/purposes/purposes.repository';
import { TransferApprovalDataRepository } from './repository/approval-repository/approval.repository';

// Approval Matrix

import { RulesRepository } from '@src/core/repositories/approval-matrix/rules.repository';
import { RulesDataRepository } from '@src/data/repository/approval-matrix/approval-matrix.repository';
import { RuleListDataShare } from '@src/core/usecases/data-share/rule-data-share.service';
import { NewRuleMatrixListDataShare } from '@src/core/usecases/data-share/new-rule-data-share.service';
import { TransactionDetailDataRepository } from './repository/transaction/transaction.repository';

// Admin User
import { AdminUserRepository } from '@src/core/repositories/admin-user/admin-user.repository';
import { AdminUserDataRepository } from './repository/admin-user/admin-user.repository';
import { ReadyChangeDataRepository } from './repository/ready-change-repository/ready-change.repository';
import { IncomeReportDataRepository } from './repository/income-report-repository/income-report.repository';
import { DerivativeDataRepository } from './repository/derivative-repository/derivative.repository';
import { InvestimentsDataRepository } from './repository/investiments/investiments.repository';

import { RegistratoDataRepository } from './repository/registrato-repository/registrato.repository';

// Disclaimer
import { DisclaimerRepository } from '@src/core/repositories/disclaimer/disclaimer.repository';
import { DisclaimerDataRepository } from '@src/data/repository/disclaimer/disclaimer.repository';


// Download file
import { FileService } from '@src/data/repository/file/file.service';
import { ChatRepository } from './repository/chat-repository/chat.repository';
import { PosicaoCarteiraRepository } from '@src/core/repositories/query/posicao-carteira.repository';
import { PosicaoCarteiraDataRepository } from './repository/query/query-posicao-carteira/posicao-carteira.repository';
import { ImpersonateRepository } from './repository/impersonate-repository/impersonate.repository';
import { TransferReceiptDataRepository } from './repository/receipt-repository/receipt.repository';
import { OpenBankingRepository } from './repository/openbanking/openbanking.repository';
import { WalletTypeRepository } from '@src/core/repositories/wallet/wallet-type.repository';
import { WalletTypeDataRepository } from './repository/wallet/walletType.repository';
import { PixLimitesRepository } from '@src/core/repositories/pix/pix-limites.repository';
import { PixLimiteDataRepository } from './repository/pix/limites/pix-limite.repository';
import { TransactionLimitRepository } from '@src/core/repositories/transactional-limits/transactional-limits.repository';
import { ApprovalLimitsRepository } from '@src/core/repositories/approval-limits/approval-limits.repository';
import { GetAccountLimitRepositoryData } from './repository/transactional-limit/transactional-limit.repository';
import { GetApprovalLimitsRepository } from './repository/approval-limits/get-approval-limits-repository';
import { GetLimitsRepository } from './repository/limits-disclaimer-repository/limits-disclaimer.repository';
import { LimitsDisclaimerRepositories } from '@src/core/repositories/limits-disclaimer/limits-disclaimer.repository';
import { GetPendencyLimitsApprovalRepository } from './repository/dashboard/get-pendency-limits-approval.repository';
import { PendencyLimitsApprovalResository } from '@src/core/repositories/dashboard/pendency-limits-approval.repository';
import { GetPendencyLimitReducePixRepository } from './repository/dashboard/get-pendency-limit-reduce-pix.repository';
import { GetAcceptTermRepository } from './repository/accept-term-repository/accept-term.repository';
import { EspecieRepository } from '@src/core/repositories/charge/especie.repository';
import { EspecieGetDataRepository } from './repository/charge/especie/especie.repository';
import { DdaTermRepository } from '@src/core/repositories/dda/dda-term.repository';
import { TermImplementationRepository } from './repository/dda/term/term-implementation.repository';
import { DdaHistoryRepository } from '@src/core/repositories/dda/dda-history.repository';
import { DdaHistoryImplementationRepository } from './repository/dda/history/dda-history.implementation.repository';
import { AlteracoesLimitesRepository } from '@src/core/repositories/alteracoeslimites/alteracoeslimites.repository';
import { AlteracoesDataLimitesRepository } from './repository/alteracoeslimites/alteracoeslimites.repository';
import { GetNotificationRepositories } from '@src/core/repositories/notification/get-notification.repository';
import { NotificationRepository } from './repository/notification/notification.repository';
import { DeleteNotificationRepositories } from '@src/core/repositories/notification/delete-notification-pix.repository';
import { PostNotificationRepositories } from '@src/core/repositories/notification/post-notification.repository';
import { PutNotificationRepositories } from '@src/core/repositories/notification/put-notification.repository';


@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: [],
  providers: [
    TabsDataShare,
    TabsOnChange,
    FirstStepDataShare,
    SecondStepDataShare,
    DownloadArchiveDataShare,
    UploadDataShare,
    ChargeArchiveDataShare,
    DuplicateBillDataShare,
    StatusMessageDataShare,
    FilterArchiveDataShare,
    SelectorDataShare,
    ShowValuesDashboardDataShare,
    InstructionDataShare,
    DetailsListShare,
    AccountDataShare,
    BalanceDataShare,
    FilterQueryDataShare,
    FilterBillDataShare,
    BeneficiaryDataShare,
    ApprovalReceiptDataShare,
    SelectBeneficiaryDataShare,
    TransferReceiptDataShare,
    RuleListDataShare,
    NewRuleMatrixListDataShare,
    BankDataShare,
    RemoveClassDataShare,
    DebitAccountDataShare,
    CompanySelectDataShare,
    TransferConfirmDataShare,
    TransferPendingDataShare,
    ReadyChangeDataRepository,
    IncomeReportDataRepository,
    DerivativeDataRepository,
    InvestimentsDataRepository,
    SchedulingDataShare,
    FileService,
    LoansDateParameterDataShare,
    { provide: TokenRepository, useClass: TokenDataRepository },
    { provide: ExtractRepository, useClass: ExtractDataRepository },
    { provide: LoansRepository, useClass: LoansDataRepository },
    { provide: BalancesRepository, useClass: BalancesDataRepository },
    { provide: PredictedRepository, useClass: PredictedWebRepository },
    { provide: PredictedTotalizadoresRepository, useClass: getPredictedMovementsTotalizadores },
    { provide: AddressTypeModelRepository, useClass: AddressDataRepository },
    { provide: AccountTypeRepository, useClass: AccountTypeDataRepository },
    { provide: WalletTypeRepository, useClass: WalletTypeDataRepository },
    { provide: CompanyTypeRepository, useClass: CompanyTypeDataRepository },
    { provide: QueryRepository, useClass: QueryDataRepository },
    { provide: PaymentRepository, useClass: PaymentDataRepository },
    {
      provide: InstructionsTypeRepository,
      useClass: InstructionsTypeDataRepository,
    },
    {
      provide: DocumentCheckModelRepository,
      useClass: DocuemntCheckRepository,
    },
    {
      provide: AccompanimentRepository,
      useClass: AccompanimentDataRepository,
    },
    {
      provide: InstructionRepository,
      useClass: InstructionDataRepository,
    },
    {
      provide: FrancesinhaRepository,
      useClass: FrancesinhaDataRepository,
    },
    {
      provide: FrancesinhaRepositoryResumo,
      useClass: FrancesinhaResumoDataRepository,
    },
    {
      provide: PosicaoCarteiraRepository,
      useClass: PosicaoCarteiraDataRepository,
    },
    { provide: ArchiveModelRepository, useClass: ArchiveRepository },
    {
      provide: TitleInformationModelRepository,
      useClass: TitleInformationRepository,
    },
    {
      provide: DuplicateBillModelRepository,
      useClass: DuplicateBillRepository,
    },
    {
      provide: DownloadBillModelRepository,
      useClass: DuplicateBillRepository,
    },
    {
      provide: WarrantyRepository,
      useClass: WarrantyDataRepository,
    },
    {
      provide: FavoritesRepository,
      useClass: BeneficiariesDataRepository,
    },
    {
      provide: PendencyRepository,
      useClass: PendencyDataRepository,
    },
    {
      provide: PurposesRepository,
      useClass: PurposesDataRepository,
    },
    {
      provide: TransferRepository,
      useClass: TransferDataRepository,
    },
    {
      provide: TransferApprovalRepository,
      useClass: TransferApprovalDataRepository,
    },
    {
      provide: TransactionDetailRepository,
      useClass: TransactionDetailDataRepository,
    },

    {
      provide: LoginRepository,
      useClass: LoginDataRepository,
    },
    {
      provide: AccessRepository,
      useClass: AccessDataRepository,
    },
    {
      provide: ProductRepository,
      useClass: ProductDataRepository,
    },

    {
      provide: OtpRepository,
      useClass: OtpDataRepository,
    },

    {
      provide: TransferReceiptDataRepository,
      useClass: TransferReceiptDataRepository,
    },

    {
      provide: PixLimitesRepository,
      useClass: PixLimiteDataRepository,
    },

    { provide: BankRepository, useClass: BankDataRepository },
    { provide: RulesRepository, useClass: RulesDataRepository },
    { provide: AdminUserRepository, useClass: AdminUserDataRepository },
    { provide: DisclaimerRepository, useClass: DisclaimerDataRepository },
    { provide: RegistratoDataRepository, useClass: RegistratoDataRepository },
    { provide: ChatRepository, useClass: ChatRepository },
    { provide: ImpersonateRepository, useClass: ImpersonateRepository },
    {
      provide: ReceiptPdfRepository,
      useClass: TransferReceiptDataRepository,
    },
    {
      provide: OpenBankingRepository,
      useClass: OpenBankingRepository
    },
    {
      provide: RefundRepository,
      useClass: PixGetRefundListRepository
    },
    {
      provide: TransactionLimitRepository,
      useClass: GetAccountLimitRepositoryData
    },
    {
      provide: ApprovalLimitsRepository,
      useClass: GetApprovalLimitsRepository
    },
    {
      provide: LimitsDisclaimerRepositories,
      useClass: GetLimitsRepository
    },
    {
      provide: PendencyLimitsApprovalResository,
      useClass: GetPendencyLimitsApprovalRepository
    },
    {
      provide: PendencyLimitReducePixRepository,
      useClass: GetPendencyLimitReducePixRepository
    },
	 {
      provide: EspecieRepository,
      useClass: EspecieGetDataRepository,
    },
    {
      provide: DdaTermRepository,
      useClass: TermImplementationRepository,
    },
    {
      provide: DdaHistoryRepository,
      useClass: DdaHistoryImplementationRepository,
    },
    {
      provide: AcceptTermRepositories,
      useClass: GetAcceptTermRepository
    },
    {
      provide: AlteracoesLimitesRepository,
      useClass: AlteracoesDataLimitesRepository
    },
    {
      provide: ModalShareDataRepositories,
      useClass: ModalShareDataRepository
    },
    {
      provide: GetNotificationRepositories,
      useClass: NotificationRepository
    },
    {
      provide: PutNotificationRepositories,
      useClass: NotificationRepository
    },
    {
      provide: DeleteNotificationRepositories,
      useClass: NotificationRepository
    },
    {
      provide: PostNotificationRepositories,
      useClass: NotificationRepository,
    },
    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class DataModule {}
