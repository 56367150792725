import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as $ from 'jquery';
import * as moment from 'moment';
import * as Util from '@src/shared/util-common';
import { AccountTypeDataRepository } from '@src/data/repository/account/accountType.repository';

@Component({
  selector: 'fibra-extract-report',
  templateUrl: './extract-report.component.html',
  styleUrls: ['./extract-report.component.scss']
})
export class ExtractReportComponent implements OnInit {
  @Output() public closeReport = new EventEmitter();
  public contractsArray = [];
  public contractsCheckArray = [];
  public releases;
  public releases1;
  public contractInfoArray = [];
  public parcelasArray = [];
  public dailyPositionArray = [];
  public reportData;
  public date = new Date();
  public day;
  public hours;
  public company;
  public hide = false;
  public readyToLoad = false;
  public listAllContracts = false;
  public informations;
  public dateReferenceToShow;
  public listValuesDailyPosition;
  public listValuesParcelas;
  public iconPath = (cod: string, desc: string) => Util.iconPath(cod, desc);
  public SaldoDisponivel;
  public SaldoBloqueado;
  public SaldoChequeDisponivel;
  public SaldoChequeUtilizado;
  public SaldoChequeCalculado;
  public NumeroConta;
  public NomeUsuario;
  public TipoConta;
  public conta = ''
  public agencia = ''
  public cpfCnpj=''
  public nomeRemet=''

  constructor(private accountRepository: AccountTypeDataRepository) { }

  ngOnInit() {
    this.accountRepository.getAllaccountTypes().subscribe((contasList) => {

      contasList.data.forEach(conta => {
        if (conta.selecionado === true) {
          this.NomeUsuario = localStorage.getItem('username');
          this.NumeroConta = conta.num_conta;
          this.TipoConta = conta.desc_conta;
          this.SaldoDisponivel = conta.saldo.vlr_saldo;
          this.SaldoBloqueado = conta.saldo.vlr_bloqueado;
          this.SaldoChequeDisponivel = conta.saldo.vlr_cheque_disponivel;
          this.SaldoChequeUtilizado = conta.saldo.vlr_cheque_utilizado;
          this.SaldoChequeCalculado = conta.saldo.vlr_saldo_calculado;

          //Inicializa Arrays
          this.contractsArray = null;
          this.dailyPositionArray = null;
          this.contractInfoArray = null;
          this.contractsCheckArray = null;

          this.hide = false;
          this.day = this.date.toLocaleDateString();
          this.hours = this.date.toLocaleTimeString();

          this.company = JSON.parse(sessionStorage.getItem('defaultCompany'));
          if (this.company[0].modality_account.length === 11) {
            this.company[0].modality_account = this.company[0].modality_account.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
          } else {
            this.company[0].modality_account = this.company[0].modality_account.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/, '$1.$2.$3/$4-$5');
          }
          this.informations = sessionStorage.getItem('informations_pdf') !== null ? JSON.parse(sessionStorage.getItem('informations_pdf')) : [];
          this.releases = sessionStorage.getItem('extrato_all_pdf') !== null ? JSON.parse(sessionStorage.getItem('extrato_all_pdf')) : [];

          let contador = 0;

          for (let i = 0; i < this.releases.length; i++) {
            this.releases[i].contador = contador++;
            this.releases[i].contador = contador++;

            this.releases[i].contador2 = contador > 16 ? contador - 16 : 0;
            this.releases[i].contador2 = contador > 16 ? contador - 16 : 0;

            for (let j = 0; j < this.releases[i].lancamentos.length; j++) {
              this.releases[i].lancamentos[j].contador = contador++;
              this.releases[i].lancamentos[j].contador2 = contador > 16 ? contador - 16 : 0;
            }
          }

          this.readyToLoad = true;

          setTimeout(() => this.generatePDF(this.informations.report_name), 500);
        }
      });
    })
  }

  public generatePDF(reportName: string) {

    const data = document.getElementById('comprovante');
    const addFooters = doc => {
      const pageCount = doc.internal.getNumberOfPages()
    
      doc.setFont('helvetica', 'italic')
      doc.setFontSize(8)
      for (var i = 1; i <= pageCount; i++) {
        doc.setPage(i)
        doc.text(String(i) + '/' + String(pageCount), 200, 290)
      }
    }

    $('#comprovante #chequeempresa').css('width', '155px')

    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {

      html2canvas(data).then((canvas) => {
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        
        var doc = new jsPDF('p', 'mm');
        var position = 0;

        const imgData = canvas.toDataURL("image/png")

        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight,undefined,'FAST');
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight,undefined,'FAST');
          heightLeft -= pageHeight;
        }
        addFooters(doc)
        doc.save(reportName+'-'+this.day +' '+ this.hours +'.pdf');   
      });
    } else {
      html2canvas(data).then((canvas) => {
        window.scrollTo(0, 0);
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        
        var doc = new jsPDF('p', 'mm');
        var position = 0;

        const imgData = canvas.toDataURL("image/png")

        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight,undefined,'FAST');
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight,undefined,'FAST');
          heightLeft -= pageHeight;
        }
        addFooters(doc)
        doc.save(reportName+'-'+this.day +' '+ this.hours +'.pdf');   
      });
    }

    $("#ico-fechar").css({ display: "block" });

    this.goBack();
  }

  public goBack() {
    this.closeReport.emit('true');
  }

  formatDate(date: string) {
    moment.locale('pt-br');
    const today = moment().startOf('day');
    const patternDate = moment(date.split('T')[0]);
    const diffDays = today.diff(patternDate, 'days');

    return diffDays === 0
      ? 'Hoje, ' + patternDate.format('LL')
      : diffDays === 1
        ? 'Ontem, ' + patternDate.format('LL')
        : diffDays > 1 && diffDays <= 7
          ? patternDate.format('dddd').charAt(0).toUpperCase() +
          patternDate.format('dddd').slice(1) +
          ', ' +
          patternDate.format('LL')
          : patternDate.format('DD') +
          ' de ' +
          patternDate.format('MMMM') +
          ' de ' +
          patternDate.format('YYYY');
  }

  isNull = (value: any) => {
    return Util.isNull(value);
  };

  public isPix(item): boolean {
    return (<string>item.desc_historico).toUpperCase().indexOf("PIX") > -1;
  }

  public temDescricao(item): boolean {
    return item.compl_det && item.compl_det.desc_nome && item.compl_det.desc_nome.trim() != '';
  }

  public temInstituicao(item): boolean {
    return item.compl_det && item.compl_det.dados_conta && item.compl_det.dados_conta.nome_banco && item.compl_det.dados_conta.nome_banco != '0' && item.compl_det.dados_conta.nome_banco != '00';
  }

  public temComprovante(item): boolean {
    return false;
    //return this.isPix(item) && item.cod_deb_cred == 'D';
  }

  public temChave(item): boolean {
    return item.compl_det && item.compl_det.dados_conta && item.compl_det.dados_conta.chave;
  }

  public formatAccountNumber(accNumber: string): string {
    return `${accNumber.substring(0, accNumber.length - 1)}-${accNumber[accNumber.length - 1]}`;
  }

  public formatCpfCnpj = (value: string) => Util.formatCpfCnpj(value);

  public HasRemetInfo(txt:string, nomeRemet:string, cnpj:string,  agencia:string, conta:string)
  {
    
   if ((txt && nomeRemet && cnpj) && txt.trim() !== "" && nomeRemet.trim() !== "" && cnpj.trim() !== "")
    {
      const splits = txt.split(' ')
      // this.agencia = "AG:"+splits[1] 
      // this.conta = "CC:"+txt// "CC:"+splits[0]
      this.agencia = "AG:"+agencia.padStart(4,'0') 
      this.conta = "CC:"+conta// "CC:"+splits[0]
      this.cpfCnpj = this.FormatcpfCnpj(cnpj) // cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
      this.nomeRemet = nomeRemet

      // if(splits[0].length > 1 &&
      //    splits[1].length > 2)
      //      return true
      // else
      //   return false

      return true
    }
    else
    {
      return false
    }
  }

  public FormatcpfCnpj(v:string){
    
    v=v.replace(/\D/g,"")
    let v2= +(v)
    v = v2.toString();
    
    //Remove tudo o que não é dígito
    
 
    if (v.length <= 11) { //CPF

      

      v  = v.padStart(11,'0')
 
        //Coloca um ponto entre o terceiro e o quarto dígitos
        v=v.replace(/(\d{3})(\d)/,"$1.$2")
 
        //Coloca um ponto entre o terceiro e o quarto dígitos
        //de novo (para o segundo bloco de números)
        v=v.replace(/(\d{3})(\d)/,"$1.$2")
 
        //Coloca um hífen entre o terceiro e o quarto dígitos
        v=v.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
 
    } else { //CNPJ

      v  = v.padStart(14,'0')
        
 
        //Coloca ponto entre o segundo e o terceiro dígitos
        v=v.replace(/^(\d{2})(\d)/,"$1.$2")
 
        //Coloca ponto entre o quinto e o sexto dígitos
        v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
 
        //Coloca uma barra entre o oitavo e o nono dígitos
        v=v.replace(/\.(\d{3})(\d)/,".$1/$2")
 
        //Coloca um hífen depois do bloco de quatro dígitos
        v=v.replace(/(\d{4})(\d)/,"$1-$2")
      
 
    }
 
    return v
 
  }
}

