import { Injectable } from '@angular/core';
import { ExtractRepository } from '@src/core/repositories/extract/extract.repository';
import {
  ExtractModel,
  ExtractRequest,
} from '@src/core/domain/extract/extract.model';
import { Observable } from 'rxjs';
import { ExtractWebRepositoryMapper } from '@src/data/repository/extract-repository/extract-repository-mapper';
import { HttpClient } from '@angular/common/http';
import { ExtractEntity } from '@src/data/repository/extract-repository/extract.entity';
import { flatMap, map, catchError } from 'rxjs/operators';
import { handleError } from '@src/shared/util-common';

// header
import { getHeader } from '@src/core/base/header';

// environment
import { environment } from '@src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExtractDataRepository extends ExtractRepository {
  mapper = new ExtractWebRepositoryMapper();

  constructor(private http: HttpClient) {
    super();
  }

  getAllExtracts(obj: ExtractRequest): Observable<ExtractModel> {
    const httpOptions = {
      headers: getHeader(),
      params: { 
        ...obj
      },
    };
    return this.http
      .get<ExtractEntity>(`${environment.URLBase}v1/extrato/total`, httpOptions)
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getEntriesExtracts(obj: ExtractRequest): Observable<ExtractModel> {
    const httpOptions = {
      headers: getHeader(),
      params: { ...obj },
    };
    return this.http
      .get<ExtractEntity>(
        `${environment.URLBase}v1/extrato/entrada`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getExitExtracts(obj: ExtractRequest): Observable<ExtractModel> {
    const httpOptions = {
      headers: getHeader(),
      params: { ...obj },
    };
    return this.http
      .get<ExtractEntity>(`${environment.URLBase}v1/extrato/saida`, httpOptions)
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getSchedulingExtracts(obj: ExtractRequest): Observable<ExtractModel> {
    const httpOptions = {
      headers: getHeader(),
      params: { ...obj },
    };
    return this.http
      .get<ExtractEntity>(
        `${environment.URLBase}v1/movimentacoesagendadas`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  getBlockedExtracts(obj: ExtractRequest): Observable<ExtractModel> {
    const httpOptions = {
      headers: getHeader(),
      params: { ...obj },
    };
    return this.http
      .get<ExtractEntity>(
        `${environment.URLBase}v1/saldos/bloqueados`,
        httpOptions
      )
      .pipe(flatMap((item) => [item]))
      .pipe(map(this.mapper.mapFrom), catchError(handleError));
  }

  postExportExcelTotal(obj: ExtractRequest): Observable<any> {

    const httpOptions = {
      headers: getHeader(),
      params: { ...obj },
    };
    const params = { ...obj };
    return this.http
      .post<any>(
        `${environment.URLBase}v1/extrato/total/excel?dataInicio=${obj.dataInicio}&dataFinal=${obj.dataFinal}`,
        params,
        {
          headers: getHeader(),
          responseType: 'blob' as 'json'
        }
      )
  }

  postExportExcelSaida(obj: ExtractRequest): Observable<any> {
    const params = { ...obj };
    return this.http
      .post<any>(
        `${environment.URLBase}v1/extrato/saida/excel?dataInicio=${obj.dataInicio}&dataFinal=${obj.dataFinal}`,
        params,
        {
          headers: getHeader(),
          responseType: 'blob' as 'json'
        }
      )
  }

  postExportExcelEntrada(obj: ExtractRequest): Observable<any> {
    const params = { ...obj };
    return this.http
      .post<any>(
        `${environment.URLBase}v1/extrato/entrada/excel?dataInicio=${obj.dataInicio}&dataFinal=${obj.dataFinal}`,
        params,
        {
          headers: getHeader(),
          responseType: 'blob' as 'json'
        }
      )
  }

  postExportExcelAgendadas(obj: ExtractRequest): Observable<any> {
    const params = { ...obj };
    return this.http
      .post<any>(
        `${environment.URLBase}v1/extrato/agendadas/excel?dataInicio=${obj.dataInicio}&dataFinal=${obj.dataFinal}`,
        params,
        {
          headers: getHeader(),
          responseType: 'blob' as 'json'
        }
      )
  }

  public getPDF(params: ExtractRequest): Observable<any> {
    return this.http.post<any>(`${environment.URLBase}v1/extrato/pdf?dataInicio=${params.dataInicio}&dataFinal=${params.dataFinal}&tipo=${params.tipo}&showAmmounts=${params.showAmmouts}&periodShow=${params.periodShow}`, { ...params }, { headers: getHeader(), responseType: 'blob' as 'json' })
  }
}
