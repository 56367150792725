<div class="content-transferir" *ngIf="cadastro">
    <div class="has-content" id="nova-chave">
        <div class="arrow" (click)="goBack()">
            <img class="arrow-img" src="/assets/svg/arrow-voltar-icon.svg" alt="arrow-voltar"> <span class="voltar">
                Voltar</span>
        </div>
        <div class="transferir-nova-chave" *ngIf="!selected">
            <div class="trocar_banco_noinfo" *ngIf="novaChave">
                <div class="initial-letters">
                    {{ initialLetters(beneficiarioFavorito.beneficiarioInfo.nome_beneficiario) }}
                </div>
                <span class="nome-banco">
                    <p>{{ beneficiarioFavorito.beneficiarioInfo.nome_beneficiario }} </p>
                    <p>{{ beneficiarioFavorito.beneficiarioInfo.num_cpf_cnpj | cpfcnpj}}</p>
                </span>
            </div>
            <div class="title">
                <span class="span-title"> Escolha uma nova chave do beneficiário</span>
            </div>
            <div class="accounts-div">
                <div class="account" (click)="selectKeyType('PHONE')">
                    <div class="account-img">
                        <img src="/assets/svg/smartphone-icon.svg" alt="smartphone">
                    </div>
                    <div class="font">
                        <span class="account-font"> Número de celular</span>
                    </div>

                </div>
                <div class="account" (click)="selectKeyType('document')">
                    <div class="account-img">
                        <img src="/assets/svg/document-icon.svg" alt="smartphone">
                    </div>
                    <div class="font">
                        <span class="account-font"> CNPJ/CPF</span>
                    </div>

                </div>
                <div class="account" (click)="selectKeyType('EMAIL')">
                    <div class="account-img">
                        <img src="/assets/svg/email-icon.svg" alt="smartphone">
                    </div>
                    <div class="font">
                        <span class="account-font"> Endereço de e-mail</span>
                    </div>

                </div>
                <div class="account" (click)="selectKeyType('aleatorio')">
                    <div class="account-img">
                        <img src="/assets/svg/aleatorio-icon.svg" alt="smartphone">
                    </div>
                    <div class="font">
                        <span class="account-font"> Chave aleatoria</span>
                    </div>

                </div>
            </div>
            <div class="title">
                <span class="span-title"> Ou agência e conta</span>
            </div>
            <div class="accounts-div">
                <div class="account" (click)="selectedAgenciaConta()">
                    <div class="account-img">
                        <img src="/assets/svg/agencia-conta-icon.svg" alt="agencia">
                    </div>
                    <div class="font">
                        <span class="account-font"> Agência e conta</span>
                    </div>

                </div>
            </div>
        </div>

        <div class="transferir-formulario" *ngIf="selected">
            <div class="steps">
                <div class="step">
                    <div class="title">
                        <div class="number">
                            <img src="/assets/svg/step_1.svg" alt="step1">
                            <span class="number-title"> Digite o código da chave</span>
                        </div>
                    </div>
                    <div class="key-data">
                        <div class="data-div">
                            <span class="data-title" *ngIf="keyType === 'EMAIL'" [hidden]="favorito"> E-mail</span>
                            <span class="data-title" *ngIf="keyType === 'CNPJ/CPF'" [hidden]="favorito"> CNPJ/CPF</span>
                            <span class="data-title" *ngIf="keyType === 'TELEFONE'" [hidden]="favorito"> Número de celular com DDD</span>
                            <span class="data-title" *ngIf="keyType === 'ALEATORIO'" [hidden]="favorito"> Chave Aleatória</span>
                            <form [formGroup]="formGroup" id="reactiveForm">
                                <div class="input-div" *ngIf="keyType === 'EMAIL'">
                                    <input type="text" placeholder="nome@empresa.com.br" class="line-code-bar" name="data" formControlName="emailKey"  [hidden]="favorito">
                                    <div class="label-div" *ngIf="!errorSearchKey">
                                        <div class="info-div" style="margin-right: 80px;" *ngIf="showMiniLoad">
                                            <span [ngClass]="!favorito  ? 'info-title' : 'info-title info-title-favorito'"> Nome</span>
                                            <div class="div" *ngIf="!keyFound && loadingKey">
                                                <div class="spinner-border text-primary" role="status"
                                                    style="height: 1rem !important;width: 1rem !important;color: #4D86BF !important;">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <span class="info-value-load"> Buscando beneficiário...</span>
                                            </div>
                                            <span class="info-value data-hj-suppress" *ngIf="keyFound"> {{key.titular_conta.nome}}</span>
                                        </div>
                                        <div class="info-div" *ngIf="showMiniLoad">
                                            <span [ngClass]="!favorito  ? 'info-title' : 'info-title info-title-favorito'"> CNPJ/CPF</span>
                                            <div class="div" *ngIf="!keyFound && loadingKey">
                                                <div class="spinner-border text-primary" role="status"
                                                    style="height: 1rem !important;width: 1rem !important;color: #4D86BF !important;">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <span class="info-value-load"> Buscando CNPJ/CPF...</span>
                                            </div>
                                            <span class="info-value" *ngIf="keyFound"> {{key.titular_conta.cpf_cnpj_mascara}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="warning-div" *ngIf="errorSearchKey">
                                        <img class="info-img" src="/assets/svg/icon-info-red.svg">
                                        <div class="div">
                                            <span class="warning-font"> Chave pix inexistente. Verifique a chave digitada e
                                                tente novamente</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-div" *ngIf="keyType === 'TELEFONE'">
                                    <input type="text" [textMask]="{ mask: celMask, placeholderChar: '_', guide: false, modelClean: true}" placeholder="+55 11 00000-0000" class="line-code-bar" name="data" formControlName="celKey" [hidden]="favorito">
                                    <div class="label-div" *ngIf="!errorSearchKey">
                                        <div class="info-div" style="margin-right: 80px;" *ngIf="showMiniLoad">
                                            <span [ngClass]="!favorito  ? 'info-title' : 'info-title info-title-favorito'"> Nome</span>
                                            <div class="div" *ngIf="!keyFound && loadingKey">
                                                <div class="spinner-border text-primary" role="status"
                                                    style="height: 1rem !important;width: 1rem !important;color: #4D86BF !important;">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <span class="info-value-load"> Buscando beneficiário...</span>
                                            </div>
                                            <span class="info-value" *ngIf="keyFound"> {{key.titular_conta.nome}}</span>
                                        </div>
                                        <div class="info-div" *ngIf="showMiniLoad">
                                            <span [ngClass]="!favorito  ? 'info-title' : 'info-title info-title-favorito'"> CNPJ/CPF</span>
                                            <div class="div" *ngIf="!keyFound && loadingKey">
                                                <div class="spinner-border text-primary" role="status"
                                                    style="height: 1rem !important;width: 1rem !important;color: #4D86BF !important;">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <span class="info-value-load"> Buscando CNPJ/CPF...</span>
                                            </div>
                                            <span class="info-value" *ngIf="keyFound"> {{key.titular_conta.cpf_cnpj_mascara}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="warning-div" *ngIf="errorSearchKey">
                                        <img class="info-img" src="/assets/svg/icon-info-red.svg">
                                        <div class="div">
                                            <span class="warning-font"> Chave pix inexistente. Verifique a chave digitada e
                                                tente novamente</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-div" *ngIf="keyType === 'CNPJ/CPF'">
                                    <input type="text" maxlength="18" placeholder="00.000.000/0000-00" mascaraCPFCNPJ class="line-code-bar" name="data" formControlName="documentKey" [hidden]="favorito">
                                    <div class="label-div" *ngIf="!errorSearchKey">
                                        <div class="info-div" style="margin-right: 80px;" *ngIf="showMiniLoad">
                                            <span [ngClass]="!favorito  ? 'info-title' : 'info-title info-title-favorito'"> Nome</span>
                                            <div class="div" *ngIf="!keyFound && loadingKey">
                                                <div class="spinner-border text-primary" role="status"
                                                    style="height: 1rem !important;width: 1rem !important;color: #4D86BF !important;">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <span class="info-value-load"> Buscando beneficiário...</span>
                                            </div>
                                            <span class="info-value" *ngIf="keyFound"> {{key.titular_conta.nome}}</span>
                                        </div>
                                        <div class="info-div" *ngIf="showMiniLoad">
                                            <span [ngClass]="!favorito  ? 'info-title' : 'info-title info-title-favorito'"> CNPJ/CPF</span>
                                            <div class="div" *ngIf="!keyFound  && loadingKey">
                                                <div class="spinner-border text-primary" role="status"
                                                    style="height: 1rem !important;width: 1rem !important;color: #4D86BF !important;">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <span class="info-value-load"> Buscando CNPJ/CPF...</span>
                                            </div>
                                            <span class="info-value" *ngIf="keyFound"> {{key.titular_conta.cpf_cnpj_mascara}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="warning-div" *ngIf="errorSearchKey">
                                        <img class="info-img" src="/assets/svg/icon-info-red.svg">
                                        <div class="div">
                                            <span class="warning-font"> Chave pix inexistente. Verifique a chave digitada e
                                                tente novamente</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-div" *ngIf="keyType === 'ALEATORIO'">
                                    <input type="text" maxlength="36" placeholder="00000000-0000-0000-0000-00000000-0000" class="line-code-bar" formControlName="aleatorioKey" name="data" [hidden]="favorito">
                                    <div class="label-div" *ngIf="!errorSearchKey">
                                        <div class="info-div" style="margin-right: 80px;" *ngIf="showMiniLoad">
                                            <span [ngClass]="!favorito  ? 'info-title' : 'info-title info-title-favorito'"> Nome</span>
                                            <div class="div" *ngIf="!keyFound && loadingKey">
                                                <div class="spinner-border text-primary" role="status"
                                                    style="height: 1rem !important;width: 1rem !important;color: #4D86BF !important;">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <span class="info-value-load"> Buscando beneficiário...</span>
                                            </div>

                                            <span class="info-value" *ngIf="keyFound"> {{key.titular_conta.nome}}</span>
                                        </div>
                                        <div class="info-div" *ngIf="showMiniLoad">
                                            <span [ngClass]="!favorito  ? 'info-title' : 'info-title info-title-favorito'"> CNPJ/CPF</span>
                                            <div class="div" *ngIf="!keyFound  && loadingKey">
                                                <div class="spinner-border text-primary" role="status"
                                                    style="height: 1rem !important;width: 1rem !important;color: #4D86BF !important;">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                <span class="info-value-load"> Buscando CNPJ/CPF...</span>
                                            </div>
                                            <span class="info-value" *ngIf="keyFound"> {{key.titular_conta.cpf_cnpj_mascara}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="warning-div" *ngIf="errorSearchKey">
                                        <img class="info-img" src="/assets/svg/icon-info-red.svg">
                                        <div class="div">
                                            <span class="warning-font">
                                                Chave pix inexistente. Verifique a chave digitada e tente novamente
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="check-div" [hidden]="favorito">
                                <input type="checkbox" class="checkbox" id="checkbox1" (click)="setFavorite()" />
                                <span class="check-font">Salvar chave</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="step">
                    <div class="title">
                        <div class="number">
                            <img src="/assets/svg/step_2.svg" alt="step2">
                            <span class="number-title"> Valor e data</span>
                        </div>
                    </div>
                    <div class="data-div">
                        <div class="row ajust-layout">
                            <div class="data">
                                <span class="data-title" style="margin-left: 16px;
                                margin-bottom: 12px;"> Valor a ser transferido</span>
                                <input type="text" maxlength="18" currencyMask
                                    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',',  align: 'left', allowNegative: false, allowZero: false }"
                                    placeholder="R$ 0,00" [(ngModel)]="transferValue" class="line-code-bar"
                                    style="width: 200px;" name="data" (blur)="changeAmmount()">
                            </div>
                            <div class="data">
                                <span class="data-title" style="margin-bottom: 13px;"> Data de transferência</span>
                                <fibra-datepicker [maxDate]="maxDate" placement="top" [minDate]="minDate"
                                    (dateSelected)="changePeriodDate($event)">
                                </fibra-datepicker>
                            </div>
                            <div class="warning-div-sched" *ngIf="errDates">
                                <img class="info-img" src="/assets/svg/icon-info-red.svg" alt="icon info red">
                                <span class="warning-font"> {{errDateType}}  </span>
                            </div>
                        </div>

                        <div class="col-4  marginTop  ">
                            <input type="checkbox" class="checkbox" id="checkbox" value="1" [(ngModel)]="checkRepeat" (click)="repeatTransaction()" />
                            <span class="check-font"> Repetir transferência</span>
                            <div *ngIf="this.checkRepeat" class="row marginElements">
                                <div class="col-4">
                                  <input
                                    class="pix-radio"
                                    checked
                                    type="radio"
                                    name="pix-radio"
                                    value="mensal"
                                    (click)="radioWeekOrMonth('mensal')"
                                  />
                                  <label class="payment-txt-small"> Mensal</label>
                                </div>
                  
                                <div class="col-5">
                                  <input
                                    class="pix-radio"
                                    type="radio"
                                    name="pix-radio"
                                    value="semanal"
                                    (click)="radioWeekOrMonth('semanal')"
                                  />
                                  <label class="payment-txt-small"> Semanal<br/>
                                  </label>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="this.radioWeek && this.checkRepeat" class="row marginTop ajust">
                            <div class="margin-radio" style="max-width: 14.7%;">
                                <input
                                class="pix-radio"
                                type="radio"
                                checked
                                name="semana-radio"
                                (click)="radioWeekSelected('segunda')"
                            />
                                <span class="payment-txt-small"> Segunda-feira</span>
                            </div>

                            <div class="margin-radio">
                                <input
                                class="pix-radio"
                                type="radio"
                                name="semana-radio"
                                (click)="radioWeekSelected('terca')"
                            />
                            <span class="payment-txt-small"> Terça-feira</span>
                            </div>

                            <div class="margin-radio">
                                <input
                                class="pix-radio"
                                type="radio"
                                name="semana-radio"
                                (click)="radioWeekSelected('quarta')"
                            />
                                <span class="payment-txt-small"> Quarta-feira</span>
                            </div>

                            <div class="margin-radio">
                                <input
                                class="pix-radio"
                                type="radio"
                                name="semana-radio"
                                (click)="radioWeekSelected('quinta')"
                            />
                                <span class="payment-txt-small"> Quinta-feira</span>
                            </div>

                            <div class="margin-radio">
                                <input
                                class="pix-radio"
                                type="radio"
                                name="semana-radio"
                                (click)="radioWeekSelected('sexta')"
                            />
                                <span class="payment-txt-small"> Sexta-feira</span>
                            </div>
                            
                            <div class="margin-radio" style="max-width: 11%;">
                                <input
                                class="pix-radio"
                                type="radio"
                                name="semana-radio"
                                (click)="radioWeekSelected('sabado')"
                            />
                                <span class="payment-txt-small"> Sábado</span>
                            </div>
                            
                            <div class="margin-radio" style="max-width: 11%;">
                                <input
                                class="pix-radio"
                                type="radio"
                                name="semana-radio"
                                (click)="radioWeekSelected('domingo')"
                            />
                                <span class="payment-txt-small"> Domingo</span>
                            </div>

                        </div>
                        <div class="col-4  marginTop">
                            <div *ngIf="this.checkRepeat" class="row marginTop" style="width: 700px;">
                                    <div class="number">
                                        <span class="number-title"> Repetir até</span>
                                    </div>
                                        <fibra-datepicker [date]="startDate"  [maxDate]="maxDate" placement="top"
                                            (dateSelected)="changePeriodDateRepeat($event)">
                                        </fibra-datepicker>
                                    <div class="warning-div-sched" *ngIf="errDatesRepeat" style="margin-left: 15px;">
                                        <img class="info-img" src="/assets/svg/icon-info-red.svg" alt="icon info red">
                                        <span class="warning-font"> {{errDateTypeRepeat}}  </span>
                                    </div>
                            </div>
                            <div *ngIf="dataRepetirSelected && checkRepeat && !errDatesRepeat" class="row marginElements" style="width: 700px;">
                               <div class="col-12">
                                    <div *ngIf="resultWeekOrMonth === 'semanal'" class="rest-cred">A transferência será realizada {{resultDayofWeek === 'sabado' || resultDayofWeek === 'domingo' ? 'todo ' : 'toda '}} {{ stringDay }} a partir do dia {{ dataSelected | date :'shortDate' }}
                                        até o dia {{ labelDateRepeat }}, mediante a disponibilidade de saldo na conta.
                                    </div>
                                    <div *ngIf="resultWeekOrMonth === 'mensal'" class="rest-cred"> A transferência será realizada a cada 30 dias a partir do dia {{ dataSelected | date :'shortDate' }}
                                        até o dia {{ labelDateRepeat }}, mediante a disponibilidade de saldo na conta.
                                    </div>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="step">
                    <div class="title">
                        <div class="number">
                            <img src="/assets/svg/step_3.svg" alt="step3">
                            <span class="number-title"> Descrição </span>
                        </div>
                    </div>
                    <div class="descricao-div">
                        <div class="data-div" style="flex-direction: column; padding-left: 40px;">
                            <div class="title-div">
                                <span class="descricao-title"> Descrição</span>
                                <span class="descricao-length" [ngClass]="{ redCounter: char == 0 }"> {{ char }}
                                    caracteres</span>
                            </div>
                            <textarea class="text-area" [(ngModel)]="textArea" id="w3review" name="w3review"
                                maxlength="40" rows="6" cols="50" (keyup)="
                                limitTextarea($event.target.value)"></textarea>
                        </div>
                    </div>
                </div>
                <div class="step">
                    <div class="title">
                        <div class="number">
                            <img src="/assets/svg/step_4.svg" alt="step4">
                            <span class="number-title"> Conta para débito</span>
                        </div>
                    </div>
                    <div class="account-info">
                        <div class="valor-div">
                            <span class="valor-title"> Valor a ser transferido</span>
                            <span class="valor"> R$ {{ transferValue || 0 | number: '1.2-2' }}</span>
                        </div>
                        <div class="accounts-div">
                            <fibra-slide-account-selector-pix [active]="contaSelecionada" [label]="'Selecione a conta a ser debitada'"
                                [data]="accountData" (emitAccount)="setAccountDebit($event)" (emitLimite)="erroLimite($event)">
                            </fibra-slide-account-selector-pix>
                        </div>
                        <div class="limits-button-continue">
                            <div class="text_btn">
                                <span class="limit-disclaimer" *ngIf="!msgCharging && validDisclaimer && mesmaTitularidadeOutraTitularidade == 'MESMA TITULARIDADE'">
                                    <p class="title_limits">Transação Pix para Mesma Titularidade</p>
                                    <p *ngIf="showInfoDisclaimer[0].tipoPeriodo === 'DIURNO'">Das {{ showInfoDisclaimer[0].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[0].horarioTermino | slice:0:5 }}h: até R$ {{ showInfoDisclaimer[0].valorMaximoTotal || 0 | number: "1.2-2" }}</p>
                                    <p *ngIf="showInfoDisclaimer[0].tipoPeriodo === 'NOTURNO'">Das {{ showInfoDisclaimer[0].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[0].horarioTermino | slice:0:5 }}h: até R$ {{ showInfoDisclaimer[0].valorMaximoTotal || 0 | number: "1.2-2" }}</p>
                                    <p *ngIf="showInfoDisclaimer[0].tipoPeriodo === 'DIURNO'">Por transação das {{ showInfoDisclaimer[0].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[0].horarioTermino | slice:0:5 }}h: R$ {{ showInfoDisclaimer[0].valorMaximoTransacao || 0 | number: "1.2-2" }}</p>
                                    <p *ngIf="showInfoDisclaimer[0].tipoPeriodo === 'NOTURNO'">Por transação das {{ showInfoDisclaimer[0].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[0].horarioTermino | slice:0:5 }}h: R$ {{ showInfoDisclaimer[0].valorMaximoTransacao || 0 | number: "1.2-2" }}</p>
                                </span>
                                <span class="limit-disclaimer" *ngIf="(!msgCharging && validDisclaimer && mesmaTitularidadeOutraTitularidade == 'OUTRA TITULARIDADE') && accountCPForCNPJ == 'CNPJ'">
                                    <p class="title_limits">Transação Pix para Outra Titularidade PJ</p>
                                    <p *ngIf="showInfoDisclaimer[1].tipoPeriodo === 'DIURNO'">Das {{ showInfoDisclaimer[1].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[1].horarioTermino | slice:0:5 }}h: até R$ {{ showInfoDisclaimer[1].valorMaximoTotal || 0 | number: "1.2-2" }}</p>
                                    <p *ngIf="showInfoDisclaimer[1].tipoPeriodo === 'NOTURNO'">Das {{ showInfoDisclaimer[1].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[1].horarioTermino | slice:0:5 }}h: até R$ {{ showInfoDisclaimer[1].valorMaximoTotal || 0 | number: "1.2-2" }}</p>
                                    <p *ngIf="showInfoDisclaimer[1].tipoPeriodo === 'DIURNO'">Por transação das {{ showInfoDisclaimer[1].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[1].horarioTermino | slice:0:5 }}h: R$ {{ showInfoDisclaimer[1].valorMaximoTransacao || 0 | number: "1.2-2" }}</p>
                                    <p *ngIf="showInfoDisclaimer[1].tipoPeriodo === 'NOTURNO'">Por transação das {{ showInfoDisclaimer[1].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[1].horarioTermino | slice:0:5 }}h: R$ {{ showInfoDisclaimer[1].valorMaximoTransacao || 0 | number: "1.2-2" }}</p>
                                </span>
                                <span class="limit-disclaimer" *ngIf="(!msgCharging && validDisclaimer && mesmaTitularidadeOutraTitularidade == 'OUTRA TITULARIDADE') && accountCPForCNPJ == 'CPF'">
                                    <p class="title_limits">Transação Pix para Outra Titularidade PF</p>
                                    <p *ngIf="showInfoDisclaimer[2].tipoPeriodo === 'DIURNO'">Das {{ showInfoDisclaimer[2].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[2].horarioTermino | slice:0:5 }}h: até R$ {{ showInfoDisclaimer[2].valorMaximoTotal || 0 | number: "1.2-2" }}</p>
                                    <p *ngIf="showInfoDisclaimer[2].tipoPeriodo === 'NOTURNO'">Das {{ showInfoDisclaimer[2].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[2].horarioTermino | slice:0:5 }}h: até R$ {{ showInfoDisclaimer[2].valorMaximoTotal || 0 | number: "1.2-2" }}</p>
                                    <p *ngIf="showInfoDisclaimer[2].tipoPeriodo === 'DIURNO'">Por transação das {{ showInfoDisclaimer[2].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[2].horarioTermino | slice:0:5 }}h: R$ {{ showInfoDisclaimer[2].valorMaximoTransacao || 0 | number: "1.2-2" }}</p>
                                    <p *ngIf="showInfoDisclaimer[2].tipoPeriodo === 'NOTURNO'">Por transação das {{ showInfoDisclaimer[2].horarioInicio | slice:0:5 }}h às {{ showInfoDisclaimer[2].horarioTermino | slice:0:5 }}h: R$ {{ showInfoDisclaimer[2].valorMaximoTransacao || 0 | number: "1.2-2" }}</p>
                                </span>

                                <span class="limit-disclaimer" *ngIf="!msgCharging && !validDisclaimer && !ocultDisclaimerInit">
                                    <p>Não foi possível exibir os limites. Tente novamente!</p>
                                </span>
                                <span *ngIf="msgCharging">
                                    <p>
                                        Carregando...
                                    </p>
                                    <br />
                                    </span>
                            </div>
                            <div class="button">
                                <button type="button" class="button-continuar" (click)="setTransferInfos()"
                                    [disabled]="validationInfo()">
                                    Continuar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <fibra-toast-error-message [color]="'#ED6C6C'"></fibra-toast-error-message>

</div>

<fibra-modal-cheque-empresa-pix *ngIf="chequeEmpresaPix" (closeEvent)="closeModalPix($event)" [valor]="valorChequeEmpresa" [conta]="this.contaToDebit?.exibicao"></fibra-modal-cheque-empresa-pix>