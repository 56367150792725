import { Router } from '@angular/router';
import { Component, OnInit, Renderer2 } from '@angular/core';
import * as moment from 'moment';
import { PaymentDataShare } from '@src/core/usecases/data-share/payment-data-share.service';
import { DeleteBoletosUsecases } from '@src/core/usecases/payment/delete-boleto.usecase';
import { PostMultipleBoletoUsecases } from '@src/core/usecases/payment/post-multiple-boleto.usecase';
import { ApprovalandRefuseRequest } from '@src/core/domain/transfer-approval/approval.model';
import { TransferApprovalDataRepository } from '@src/data/repository/approval-repository/approval.repository';

@Component({
  selector: 'fibra-page-payment-multiple',
  templateUrl: './payment-multiple.component.html',
  styleUrls: ['./payment-multiple.component.scss'],
})
export class PaymentMultipleComponent implements OnInit {
  public loading: boolean;
  public loadingPage: boolean;
  public loadingDetail: boolean;
  public dataList: Array<any>;
  public sorted: boolean;
  public dataDetail: any;
  public error: any;
  public mock;
  public totalPay = 0;
  public showModalValidation = false;
  public operationNumberArr: any;
  public paymentMultiple: any;
  public apiCallback = 2;
  public toasterTitle: string;
  public toasterMessage: string;
  public showToaster: boolean;
  public shared: any;
  public arrowArr: Array<boolean>;
  public showModalCancel: boolean = false;

  constructor(
    private router: Router,
    private paymentDataShare: PaymentDataShare,
    private deleteBoletos: DeleteBoletosUsecases,
    private transactionApproval: TransferApprovalDataRepository,
    private render: Renderer2
  ) {
    this.error = {
      active: false,
      type: 'error',
      title: 'Ocorreu um erro',
      message: 'Não foi possível listar os status. Tente novamente mais tarde.',
    };
    this.toasterTitle = 'Ocorreu um erro no envio!';
    this.toasterMessage = 'Tente novamente.';
    this.arrowArr = [];
  }

  ngOnInit(): void  {
    this.loadingPage=true
    console.log(this.showModalCancel);
    this.paymentDataShare.dataShared.subscribe(
      res => {
        this.shared = res;
        if (res && res.paymentMultiple && res.paymentMultiple.length > 0) {
          this.paymentMultiple = res.paymentMultiple;
        } else {
          this.router.navigate(['/payment']);
        }

        this.loadingPage=false
      },
      err => {
        this.router.navigate(['/payment']);
        this.loadingPage=false
      }
    );
    this.countTotal(this.paymentMultiple);
  }

  public removeString = (wordToRemove, phrase) => {
    if (phrase.split(wordToRemove)[0] === '') {
      return phrase.split(wordToRemove)[1];
    } else {
      return phrase;
    }
  }

  public onNav = (path) => {
    this.router.navigateByUrl(path);
  }

  public onPay() {
    this.showModalValidation = true;
  }

  public countTotal(value) {
    this.totalPay = 0;
    value.map((e, i) => {
      this.totalPay += e.vlr_pagamento;
      this.arrowArr.push(false);
    });
  }

  public closeModal(event?) {
    this.showModalValidation = event;
  }

  public deleteBoleto(numOperacao) {
    this.loading = true;
    const params = {
      num_operacao: numOperacao
    };
    this.deleteBoletos.execute(params).subscribe(
      this.deleteBoletoSuccess,
      this.deleteBoletoError,
      this.deleteBoletoComplete,
    );
  }

  private deleteBoletoSuccess = res => {
    const data = this.paymentMultiple;
    if (res && res.data && res.data.sucesso) {
      this.paymentMultiple = [];
      data.map(e => {
        if (e.num_operacao !== res.data.num_operacao) {
          this.paymentMultiple.push(e);
        }
      });
      this.countTotal(this.paymentMultiple);
    } else {
      this.showToaster = true;
    }
  }

  private deleteBoletoError = err => {
    this.loading = false;
    this.showToaster = true;
  }

  private deleteBoletoComplete = () => {
    this.loading = false;
  }

  public postBoleto = () => {
      this.showModalValidation = false;
      this.loadingPage = true;
      const lstPagEfetivar = [];
      this.paymentMultiple.map(e => {
        lstPagEfetivar.push(e.num_operacao);
      });
      const params = {
        lista_pagamentos_efetivar: lstPagEfetivar,
      };
      this.loadingPage=false
      this.showModalValidation = true;
      return params;
  }

  public responseApi(value) {
    this.showModalValidation =false
      if (value.status === 'success') {
        this.postBoletoSuccess(value);
        this.showModalValidation =true
        return;
      }
      this.postBoletoError(value);
  }

  public postBoletoSuccess = res => {
    if (res && res.data) {
      const dtTransacao = moment().format('DD/MM/YYYY');
      const hrTransacao = moment().format('HH:mm:ss');
      const multipleReturn = {
        ...res.data,
        dt_transacao: dtTransacao,
        hr_transacao: hrTransacao
      };
      this.paymentDataShare.setValue({
        paymentMultiple: this.paymentMultiple,
        multipleReturn,
      });

      let listrans= []
      res.data.forEach(element => {
        listrans.push(element.num_compromisso)
      });


     
      const approvalReq:ApprovalandRefuseRequest = {
        lista_transacoes:listrans
      }

      
      this.transactionApproval.postApproval(approvalReq).subscribe((apro) =>{
        
      })



      this.onNav('/payment-revision');
    } else {
      this.showToaster = true;
    }
  }

  public postBoletoError = (err) => {
    this.loadingPage = false;

    if (err.status == 500 || err.status == 504) {
      sessionStorage.setItem('erroAprovacao', 'Serviço indisponível. Atenção! Antes de realizar novamente os pagamentos, confira se eles não foram concluídos.');
      this.router.navigate(['transaction-approval']);
    } else {
      this.showToaster = true;
    }
  }

  public closeToaster = () => {
    this.showToaster = false;
  }

  public sortList(value) {
    this.sorted = !this.sorted;
    this.paymentMultiple.sort((a, b) => {
      if (a[value] < b[value]) {
        if (!this.sorted) {
          return -1;
        } else {
          return 1;
        }
      }
      if (a[value] > b[value]) {
        if (!this.sorted) {
          return 1;
        } else {
          return -1;
        }
      }
      return 0;
    });
  }

  public closeArrow = (index) => {
    this.arrowArr[index] = !this.arrowArr[index];
  }

  openModalCancel() {
    this.showModalCancel = true;
  }

  closeAlertModal(modal: HTMLElement){
    this.render.removeClass(modal, "animate__fadeIn");
    this.render.addClass(modal, "animate__fadeOut");
    
    setTimeout(() => {
      this.showModalCancel = false;
    }, 500);
  }
}
