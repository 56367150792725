<div style="background-color: white;">

  <table class="table">
      <thead class="table-header">
        <tr>
          <th style="width: 115px; min-width: 115px" scope="col" (click)="ordenar('seu_numero')">Seu número<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/></th>
          <th style="width: 105px; min-width: 105px" scope="col" (click)="ordenar('data_emissao')">Data da emissão<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/></th>
          <th style="width: 105px; min-width: 105px" scope="col" (click)="ordenar('data_envio')">Data de envio<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/></th>
          <th style="width: 300px; min-width: 300px" scope="col" (click)="ordenar('nome_sacado')">Pagador<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/> </th>
          <th style="width: 132px; min-width: 132px" scope="col" (click)="ordenar('vlr_titulo')">Valor do título<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/></th>
          <th style="width: 120px; min-width: 120px" scope="col" (click)="ordenar('codigo_status_linha')">Status<img src="../../../../assets/svg/duo_arrow.svg" aria-label="Indica possibilidade de ordenar coluna" alt="Icone ordernar coluna"/></th>
          <th style="width: 80px; min-width: 80px; cursor: default" scope="col">Ver título</th>
          <th style="width: 90px; min-width: 90px; text-align: center" scope="col">Motivo da recusa</th>
        </tr>
      </thead>

    <tbody *ngIf="!hide && !loading" class="p-15">
      <ng-container *ngFor="let item of transit">
        <tr [attr.colspan]="1 + 1">
          <td style="width: 115px; min-width: 115px" scope="row">{{ item.seu_numero }}</td>
          <td style="width: 105px; min-width: 105px" scope="row">{{ item.data_emissao | date: 'dd/MM/yyyy' }}</td>
          <td style="width: 105px; min-width: 105px" scope="row">{{ item.data_envio | date: 'dd/MM/yyyy' }}</td>
          <td style="width: 300px; min-width: 300px" class="nowrap">
            <ngb-highlight data-hj-suppress [result]="item.nome_sacado" [term]="filter.value"></ngb-highlight>
          </td>
          <td style="width: 132px; min-width: 132px">
            <ngb-highlight [result]="item.vlr_titulo | currency: 'BRL'" [term]="filter.value"></ngb-highlight>
          </td>
          <td style="width: 120px; min-width: 120px" class="util">
            <div *ngIf="getStatusItem(item) === 'pending' || getStatusItem(item) === 'authorized'" [ngClass]="{ pending: getStatusItem(item) === 'pending', authorized: getStatusItem(item) === 'authorized' }"></div>
            <div *ngIf="getStatusItem(item) === 'refused'">
              <span class="icon-info notification" [appTooltip]="tooltipAll">
                <img width="10" height="10" src="/assets/png/alert.png" alt="logo" style="margin-right: 5px;" />
                <div #tooltipAll class="tooltip-custom-down-balace">
                  <h3 class="warning">Para visualizar o motivo da<br>recusa, clique na seta.</h3>
                  <div class="arrow-top"></div>
                </div>
              </span>
            </div>
            <ngb-highlight [result]="getStatusFormated(item.codigo_status_linha)" [term]="filter.value"></ngb-highlight>
          </td>
          <td style="width: 80px; min-width: 80px;">
            <svg (click)="sendItemList(item)" width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g opacity="0.5">
                <path
                  d="M12.9999 12.3967H14.1987M5.79932 3.99609H10.5993H5.79932ZM5.79932 9.99609H10.5993H5.79932ZM5.79932 6.39549H9.39932H5.79932ZM5.79932 12.3967H9.39932H5.79932ZM12.9999 3.99609H14.1987H12.9999ZM12.9999 9.99609H14.1987H12.9999ZM12.9999 6.39549H14.1987H12.9999Z"
                  stroke="#082A4D" stroke-width="0.8" stroke-miterlimit="10" stroke-linecap="round"
                  stroke-linejoin="round" />
                <path
                  d="M1 1H19M2.8081 1V17.7928L4.6018 16.597L6.421 17.7964L8.2141 16.597L10.0141 17.7928L11.8075 16.597L13.6069 17.7928L15.4012 16.597L17.2 17.7928V1H2.8081ZM13.6069 17.7964V17.7928V17.7964ZM10.0141 17.7964V17.7928V17.7964Z"
                  stroke="#082A4D" stroke-width="0.8" stroke-miterlimit="10" stroke-linecap="round"
                  stroke-linejoin="round" />
              </g>
            </svg>
          </td>
          <td style="width: 90px; min-width: 90px; text-align: center">
            <span class="item-icon" *ngIf="getStatusItem(item) === 'refused'" (click)="toogleShowMore(item)" style="cursor: pointer;">
              <img src="/assets/svg/dropdown.svg" alt="Ver mais" />
            </span>
          </td>
        </tr>
        <tr style="background: #f8f9fa;" *ngIf="item.showMore">
          <td colspan="9" style="border-top: 0;">
            <ul *ngFor="let motivo of item.motivos_recusa">
              <li>{{ replaceUnicode(motivo) }}</li>
            </ul>
          </td>
        </tr>
      </ng-container>
    </tbody>

  </table>

  <div class="d-flex justify-content-center position" *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div class="empty-info" *ngIf="hide">
    <fibra-generic-info type="warn" title="Nenhum resultado encontrado"
      message="Tente uma nova pesquisa inserindo novos parâmetros.">
    </fibra-generic-info>
  </div>

</div>