<fibra-toast-error-message [color]="typToastColor"> </fibra-toast-error-message>
<div class="main-selector" >
  <fibra-company-list></fibra-company-list>
</div>
<div class="content-page" id="body-content" style="background-size: 182em" 
    *fibraHasRole="'
    COB.CONS_POS_CART,
    COB.CONS_ARQ_TRANS,
    COB.CONS_INST_ADIC,
    COB.CONS_FRANC,
    QUALQUER_CONTA.COB.CONS_FRANC,
    QUALQUER_CONTA.COB.CONS_INST_ADIC,
    QUALQUER_CONTA.COB.CONS_POS_CART,
    QUALQUER_CONTA.COB.CONS_ARQ_TRANS'">

  <div *ngIf = "currentView">
  <fibra-breadcrumb *ngIf = "adcInstrucao !== false"
    [infoAccount]="'Cobrança / Consulta / ' + currentView?.option"
    roule="Consulta" 
    arrow="false">
  </fibra-breadcrumb>
  <fibra-breadcrumb *ngIf = "adcInstrucao === false"
    bottom="-130"
    [infoAccount]="'Cobrança / Consulta / ' + currentView?.option"
    roule="Consulta" 
    arrow="false" 
    [downloadDoc]="true" 
    [onlyExcel]="true" 
    (infoReport)="getInfoExport($event)">
  </fibra-breadcrumb>
</div>

  <fibra-listing-tab (emitBack)="backDetail()" (selected)="listingSelect($event)"></fibra-listing-tab>

  <div *ngIf="!titleDetail && !queryDetail" >
    <fibra-tabs-list [hidden]="hideList === 'query-francesinha'" [options]="tabsOptions" (selected)="selectTypeList($event)"
      (itemTab)="selectTypeList($event)" [showTooltipPortfolioPosition]="showTooltipPortfolioPosition" [tabListConsultaPosicaoCarteira]="this.currentView">
    </fibra-tabs-list>
    
    <div class="movement-sale row"  [ngClass]="{
        list: tabsOptions == optionFranc || tabsOptions == optionInstruction
      }">
      <div>
        <fibra-filter-cobranca 
          [default]="true" 
          class="filter" 
          (emitFilter)="filterConfig($event)" 
          [abaTransacionais]="hideList === 'query-transit' || hideList === 'query-posicao-carteira' || hideList === 'query-instruction'"
          [abaSelected]="hideList" 
          [abaSelectedPosicaoCarteira]="typeList" 
          [isFrancesinha]="hideList == 'query-francesinha'"
          [isTransit]="hideList == 'query-transit'"
          [isNotCessao]="hideList == 'query-transit'"
          (emitFilterToast)="verifyCalendar($event)"
          [isAdcInstrucao]="adcInstrucao !== false"
          [inputFilter]="hideList === 'query-posicao-carteira' || hideList === 'query-transit' || hideList === 'query-instruction'"
          [isTransitAprovados]="typeList == 'query-list-authorized' && hideList == 'query-transit'">

          <div *ngIf="controlFormQuery.showButtonInstructions" class="add-instructions-off" >
              <button [hidden]="this.typeList !== 'posicao-carteira-list-paid' && this.typeList !== 'posicao-carteira-list-downloaded'" *fibraHasRole="'COB.ADD_INST'" type="button" class="adcInst-button" (click)="addInstructions()" [disabled]="this.typeList === 'posicao-carteira-list-paid' || this.typeList === 'posicao-carteira-list-downloaded'" >
                Adicionar instruções
              </button>
              <button [hidden]="this.typeList === 'posicao-carteira-list-paid' || this.typeList === 'posicao-carteira-list-downloaded'" *fibraHasRole="'COB.ADD_INST'" type="button" class="leaked-button" (click)="addInstructions()" >
                  Adicionar instruções
                </button>
            </div>

          <div *ngIf="this.controlFormQuery.showOptionsInstructions" class="add-instructions-on">
            <fibra-dropdown [style.width.px]="240" removeShadown="false" customClass="select-option" [options]="optionInstructionsWallet"
              (change)="changeInstruction($event)" placeholder="Selecione a instrução"></fibra-dropdown>

            <span class="label-add-instructions">Adicionar instrução</span>
            <span class="options-add-instructions">
              <form [formGroup]="reactiveForm">
                <span [appTooltip]="tooltipInBatch">
                  <div class="form-check form-check-inline">
                    <input type="radio" class="form-check-input" id="instructionInBatch" name="reactiveRadio" mdbInput formControlName="reactiveRadio"
                      [value]="true" (ngModelChange)="deactivateAdvanceButton()" />
                    <label class="form-check-label" for="instructionInBatch">Em lote</label>
                  </div>
                </span>
                <div class="form-check form-check-inline">
                  <input type="radio" class="form-check-input" id="instructionIndividually" name="reactiveRadio" [ngModel]="controlFormQuery.radioInstructionInBatch"
                    mdbInput [value]="false" formControlName="reactiveRadio" (ngModelChange)="deactivateAdvanceButton()" />
                  <label class="form-check-label" for="instructionIndividually">Individual</label>
                </div>
              </form>
            </span>
            <span class="label-close-instructions" (click)="closeInstructions()">
              Fechar instruções
            </span>
            <div #tooltipInBatch class="tooltip-custom tooltip-custom-batch">
              <p>
                Você pode dar a mesma instrução para todos os títulos que selecionar
              </p>
              <div></div>
            </div>
          </div>
        </fibra-filter-cobranca>

        <div class="row-msg">
          <div [hidden]="controlFormQuery.hideMsgLabel" class="row-grant-allowance">
            <div class="label-msg">{{ controlFormQuery.labelMsg }} </div>
            <div class="buttons" [hidden]="controlFormQuery.hideButtonBottom">
                <button type="button" class="leaked-button button button-come-back" (click)="returnOption()">
                {{ controlFormQuery.titleButtonComeBack }} 
                </button>
                <button type="button" class="secondary-button button button-advance" (click)="advanceOption()" [disabled]="disable || _posicaoCarteiraLoadingRemainingRows">
                  <div class="d-flex justify-content-center" *ngIf="_posicaoCarteiraLoadingRemainingRows">
                    <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Carregando...</span>
                    </div>
                  </div>
                  <span *ngIf="!_posicaoCarteiraLoadingRemainingRows">{{ controlFormQuery.titleButtonAdvance }}</span>
                </button>
            </div>
           </div>
        </div>
        <div class="row-msg">
          <div [hidden]="controlFormQuery.hideMsgLink" class="row-grant-allowance" style="background-color: #73B599;">
            <div class="label-msg" style="font-weight: bold; color: white;">{{ controlFormQuery.labelMsg }} </div>
            <!-- <div class="link" (click)="instruction()">
              {{ controlFormQuery.labelLink }}
            </div> -->
            <div class="link" style="color: white" (click)="returnOption()">
             Ok 
            </div>
          </div>
        </div>
        <div class="row-msg">
          <div [hidden]="controlFormQuery.hideMsgInputButtons" class="row-grant-allowance">
            <div class="msg-side-left">
              <div class="label-msg">{{ controlFormQuery.labelMsg }} </div>
              <input *ngIf="controlFormQuery.serviceInstruction === 'abatimento'" type="text" maxlength="18" (keyup)="getValueChoice($event)" currencyMask [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false }" />
              <input *ngIf="controlFormQuery.serviceInstruction === 'protesto'" type="text" maxlength="5" (keyup)="getValueChoice($event)" onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
              <div [formGroup]="myGroup" *ngIf="controlFormQuery.serviceInstruction === 'alteracao-vencimento'">
                <fibra-datepicker formControlName="expirationChange" [date]="expirationChange" [minDate]="createDefaultDateBatchVencimento" (dateSelected)="selectDate($event)" (keyup)="dataChanged($event)" placement="bottom" placeholder="00/00/0000"></fibra-datepicker>                  
                <ng-container *ngIf="dataInvalidaLote === true">
                    <small class="small text-danger">
                      {{ (dataInvalidaLote === true && isExpirationDateInvalid === true) ? invalidExpirationDateMessage : invalidDateMessage }}
                    </small>
                  </ng-container>
              </div>
              <span class="label-msg" [hidden]="controlFormQuery.hideMsgPostInput">{{ controlFormQuery.labelPostInput }} 
              </span>
            </div>
            <div class="buttons" [hidden]="!controlFormQuery.hideButtonTop">
              <button type="button" class="leaked-button button button-come-back" (click)="returnOption()">
                {{ controlFormQuery.titleButtonComeBack  }}
              </button>
              <button type="button" class="secondary-button button button-advance" (click)="advanceOption()" [disabled]="disableLote || !myGroup">
                {{ controlFormQuery.titleButtonAdvance }}
              </button>
            </div>
          </div>
        </div>
        <div class="row-msg">
          <div [hidden]="controlFormQuery.hideMsgButtons" class="row-grant-allowance">
            <div class="label-msg">{{ controlFormQuery.labelMsg }} </div>
            <div class="buttons" [hidden]="!controlFormQuery.hideButtonTop">
              <button type="button" class="leaked-button button button-come-back"  (click)="returnOption()">
                {{ controlFormQuery.titleButtonComeBack }} 
              </button>
              <button type="button" class="secondary-button button button-advance" (click)="advanceOption()" [disabled]="disableLote" >
                {{ controlFormQuery.titleButtonAdvance }}
              </button>
            </div>
          </div>
        </div>

        <div 
          *ngIf="hideList == 'query-posicao-carteira'"
          [hidden]="!adcInstrucao"
          class="infoInstruction"
        >
          <span>
            • Instruções enviadas após as 16h00 serão recepcionadas no movimento do dia seguinte. <br>
            • Todas as instruções ficarão pendentes para avaliação e aprovação da área Comercial/Cobrança, e consequentemente o retorno das mesmas 
            seguirão após o efetivo processamento da instrução. <br>
            • Os pedidos de baixas, prorrogações e sustação de protesto serão efetivados, após a confirmação de retirada 
            do cartório. <br>
            • As tarifas são cobradas automaticamente por quantidade de históricos, portanto, antes do envio das instruções, favor 
            verificar se as mesmas já não foram enviadas. <br>
            • Atenção: Para títulos vencidos, títulos com agendamento em cartório ou já em cartório, 
            solicitar a sustação de protesto antes de enviar qualquer outra instrução.
          </span>
        </div>

        <div 
          *ngIf="hideList === 'query-transit' && typeList == 'query-list-all' || hideList === 'query-transit' && typeList == 'query-list-authorized'"
          class="infoInstruction"
        >
          <span>
            Títulos com status "Aprovado" serão exibidos apenas no dia da análise.
          </span>
 
        </div>

        <fibra-query-transit-list 
          class="col-lg-8" 
          *ngIf="hideList == 'query-transit'" 
          (emitItem)="showDetail($event)"
          [isTransit]="hideList == 'query-transit'"
        ></fibra-query-transit-list>
        <fibra-query-posicao-carteira 
          *ngIf="hideList == 'query-posicao-carteira'" 
          [isCarteira]="hideList == 'query-posicao-carteira'"
          (emitItem)="showPortfolio($event)" 
          [showCheckboxInstruction]="!controlFormQuery.showButtonInstructions"
          (instructionEvent)="instructionEvent($event)"
          (valueChecked)="verifyChecked($event)"
          (valueChoiceOut)="verifyValueChoiceOut($event)"
          (TotalValuesPeriod)="totalValuesPeriod($event)"
          (qtdChecked)="verifyQtdChecked($event)"
          (checkedAll)="posicaoCarteiracheckedAll($event)"
          (eof)="posicaoCarteiraEOF($event)"
          (loadingRemainingRows)="posicaoCarteiraLoadingRemainingRows($event)"
          (loadedRemainingRows)="posicaoCarteiraLoadedRemainingRows($event)"
          [limparLista]="cleanList == true"
          [valueChoiceQuery]="choice"
          (isDatePosicaoCarteiraValid)="verifyDate($event)"
          (isExpirationDateInvalid)="verifyExpirationDate($event)"
          [isBatchChoose]="isBatch"
          [isBatchAbatimentoChoose]="isBatchAbatimento"
          [isBatchAltVencimentoChoose]="isBatchAltVencimento"
          [readonlyInput]="readonlyInput"
          ></fibra-query-posicao-carteira>

        <fibra-query-instruction-list *ngIf="hideList === 'query-instruction'" [params]="filterOptions"></fibra-query-instruction-list>

        <fibra-query-francesinha-list  *ngIf="hideList === 'query-francesinha'" [params]="filterOptions"></fibra-query-francesinha-list>
      </div>
      <div [style.margin-top.px]="50">
        <fibra-side-card 
          [options]="options" 
          [hidden]="hideCard" 
          class="side-card"
          [isCobranca]="true"
          [isCarteira]="hideList == 'query-posicao-carteira'"
          [valuesPeriod]="valuesPeriod"
        ></fibra-side-card>
      </div>
    </div>
  </div>

  <fibra-query-transit-detail (emitBack)="backDetail()" [titleData]="queryDetail" *ngIf="queryDetail">
  </fibra-query-transit-detail>

  <fibra-query-portfolio-detail (emitBack)="backDetail()" [titleData]="titleDetail" *ngIf="titleDetail">
  </fibra-query-portfolio-detail>

</div>
<ng-content></ng-content>

<fibra-modal-validation
  #content
  *ngIf="showModalValidation"
  [apiCallback]="apiCallback"
  (closeEvent)="closeModal()"  
  [apiPayload]="requestDataApi"
  (responseValidation)="responseApi($event)">
</fibra-modal-validation>


