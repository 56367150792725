<div class="container-fluid">
    <div class="row d-flex justify-content-center transaction" *ngIf="(transacaoRealizada && infoComprovantes.data.status == 1 || infoComprovantes.data.status == 5) && infoComprovantes.data.mensagem !== 'O envio do Pix está em processamento. Aguarde!'">
        <div class="col-7 header py-4 mt-4">
            <p class="ml-2 mt-1 d-flex align-items-center title-header"> <img class="mr-2 "
                    src="/assets/svg/icon-ok.svg" alt="arrow">
                <span>A Devolução Pix foi realizada</span>
            </p>
        </div>
        <div class="col-7 header py-4 mt-4 px-0">
            <p class="d-flex justify-content-between align-items-center my-4 px-4">
                <img class=" " src="/assets/svg/logo-pix.svg" alt="arrow">
                <span class="text-bold">Comprovante de devolução Pix</span>
            </p>
            <div class="separator">

            </div>
            <div class="row mt-5 px-4">
                <div class="col-3">
                    <div>
                        <label for="">Valor</label>
                        <p class="mt-2 value">{{infoComprovantes.data.obj_transf_pix.valorTransferencia | currency:'BRL'}}</p>
                    </div>
                </div>
               
                <div class="col-5">
                    <div>
                        <label for="">Data e hora da devolução</label>
                        <p class="mt-2"><span class="mr-2 value">{{infoComprovantes.data.obj_transf_pix.dataProcessamento | date: 'dd/MM/yyyy'}} {{infoComprovantes.data.obj_transf_pix.dataProcessamento | date: 'HH:mm:ss'}}</span></p>
                    </div>
                </div>
            </div>
            <div class="col-8 mt-5 px-4 ">
                <label class="margin_b" for="">Motivo da devolução</label>
                <p>
                    {{infoComprovantes.data.obj_transf_pix.descricao}}
                </p>
            </div>

            <p class="px-4 my-4 text-bold">Beneficiário</p>
            <div class="row mt-4 px-4 ">
                <div class="col-4">
                    <div>
                        <label for="">Nome</label>
                        <p class="mt-2 value">{{infoComprovantes.data.obj_transf_pix.destino.nome}}</p>
                    </div>
                </div>
                <div class="col-4">
                    <div>
                        <label for="">CPF/CNPJ</label>
                        <p class="mt-2 value">{{ infoComprovantes.data.obj_transf_pix.destino.cpf_cnpj }}</p>
                    </div>
                </div>
                <div class="col-4">
                    <div>
                        <label for="">Instituição</label>
                        <p class="mt-2 value">{{infoComprovantes.data.obj_transf_pix.destino.desc_banco}}</p>
                    </div>
                </div>
            </div> 
            
            <div class="row mt-4 px-4 ">
                <div class="col-3">
                    <div>
                        <label for="">Agência</label>
                        <p class="mt-2 value">{{ agencyFormat(infoComprovantes.data.obj_transf_pix.destino.agencia_conta) }}</p>
                    </div>
                </div>
                <div class="col-3">
                    <div>
                        <label for="">Conta</label>
                        <p class="mt-2 value">{{ accountFormat(infoComprovantes.data.obj_transf_pix.destino.num_conta) | account}}</p>
                    </div>
                </div>
            </div>
            <p class="px-4 my-4 text-bold">Debitado de</p>
            <div class="row mt-4 px-4">
                <div class="col-4">
                    <div>
                        <label for="">Nome</label>
                        <p class="mt-2 value">{{infoComprovantes.data.obj_transf_pix.origem.nome}}</p>
                    </div>
                </div>
                <div class="col-4">
                    <div>
                        <label for="">CPF/CNPJ</label>
                        <p class="mt-2 value">{{htmlShowCpf(infoComprovantes.data.obj_transf_pix.origem.cpf_cnpj)}}</p>
                    </div>
                </div>
            </div>
            <div class="row px-4 my-4">
                <div class="col-4">
                    <div>
                        <label for="">Instituição</label>
                        <p class="mt-2 value">{{infoComprovantes.data.obj_transf_pix.origem.nome_banco}}</p>
                    </div>
                </div>
                <div class="col-3">
                    <div>
                        <label for="">Agência</label>
                        <p class="mt-2 value">{{infoComprovantes.data.obj_transf_pix.origem.agencia_conta}}</p>
                    </div>
                </div>
                <div class="col-3">
                    <div>
                        <label for="">Conta</label>
                        <p class="mt-2 value">{{infoComprovantes.data.obj_transf_pix.origem.num_conta | account}}</p>
                    </div>
                </div>
            </div>
            <div class="separator">

            </div>
            <div class="my-4 px-4">
                <label for="">ID da transação</label>
                <p class="mt-2 value">{{infoComprovantes.data.obj_transf_pix.codTransferencia}}</p>
            </div>
            <div class="separator">

            </div>

            <div class="my-4 px-4">
                <p class="sub-text" style="line-height: 18px">Se precisar de ajuda, entre em contato com a gente.</p>
            </div>

            <div class="my-4 px-4">
                <p class="d-flex align-items-center text-apoio"><img class="mr-2" src="/assets/svg/apoio.svg"
                        alt="arrow">Canais
                    exclusivos
                </p>
                <p class="text-apoio" style="margin-left: 31px">Dúvidas e apoio</p>
            </div>
            <div class="my-4 px-4">
                <p class="value" style="font-size: 12px">Corporate, Agro e PMEs</p>
                <p class="mt-2">(11) 4130-7449, das 9h às 18h, de segunda a sexta-feira, exceto feriados</p>
                <p class="mt-4 email">relacionamento@bancofibra.com.br</p>
            </div>
            <div class="separator">

            </div>
            <div class="d-flex justify-content-between mt-4 px-4">
                <button class="primary px-4" (click)="downloadComprovante()">Baixar comprovante</button>
                <button class="secondary px-4" (click)="goToView('/dashboard')">Fechar</button>
            </div>
        </div>
    </div>
    
    <div *ngIf="transacaoPendente" class="transaction">
        <div class="row d-flex justify-content-center">
            <div class="col-4 header py-4">
                <p class="ml-2 mt-1 d-flex align-items-center title-header"> <img class="mr-2 "
                        src="/assets/svg/pix-pendente.svg" alt="arrow">
                    <span style="color: #D69D10">Transação Pix pendente </span>
                </p>
                <p class="my-3 sub-text-header">Enviada para aprovação do(s) master(s) da sua empresa.</p>
            </div>
        </div>
        <div class="row d-flex justify-content-center p-0 m-0">
            <div class="col-4 mt-4 p-0 m-0">
                <div class="row p-0 m-0">
                    <div class="col-12 p-0 m-0">
                        <button class="primary px-4 w-100" (click)="goBack()">Voltar</button>
                    </div>
                    <div class="col-12 p-0 m-0 mt-4">
                        <button class="secondary px-4 w-100" (click)="goToView('/transaction-approval')">Acompanhar transação</button>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div *ngIf="!transacaoPendente && infoComprovantes.data && infoComprovantes.data.status == 1">
        <div class="row d-flex justify-content-center transaction">
            <div class="col-4 header py-4">
                <p class="ml-2 mt-1 d-flex align-items-center title-header"> <img class="mr-2 "
                        src="/assets/svg/pix-pendente.svg" alt="arrow">
                    <span *ngIf="true" style="color: #D69D10">O envio do Pix está em processamento. </span>
                </p>
                <p *ngIf="true" class="my-3 sub-text-header">Favor confirmar em seu extrato a realização da transação.</p>
            </div>
        </div>
        <div class="row d-flex justify-content-center p-0 m-0">
            <div class="col-4 mt-4 p-0 m-0">
                <div class="row p-0 m-0">
                    <div class="col-12 p-0 m-0 mt-4">
                        <button class="secondary px-4 w-100" (click)="goToView('/dashboard')">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!transacaoPendente && infoComprovantes.data && infoComprovantes.data.status == 8">
        <div class="row d-flex justify-content-center transaction">
            <div class="col-4 header py-4">
                <p class="ml-2 mt-1 d-flex align-items-center title-header"> <img class="mr-2 "
                        src="/assets/svg/pix-pendente.svg" alt="arrow">
                    <span *ngIf="true" style="color: #D69D10">Transação não concluida.</span>
                </p>
                <p *ngIf="true" class="my-3 sub-text-header">O envio do Pix foi reijeitado pelo Banco Central.</p>
            </div>
        </div>
        <div class="row d-flex justify-content-center p-0 m-0">
            <div class="col-4 mt-4 p-0 m-0">
                <div class="row p-0 m-0">
                    <div class="col-12 p-0 m-0 mt-4">
                        <button class="secondary px-4 w-100" (click)="goToView('/dashboard')">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!transacaoPendente && infoComprovantes.data && infoComprovantes.data.status == 10">
        <div class="row d-flex justify-content-center transaction">
            <div class="col-4 header py-4">
                <p class="ml-2 mt-1 d-flex align-items-center title-header"> <img class="mr-2 "
                        src="/assets/svg/pix-pendente.svg" alt="arrow">
                    <span *ngIf="true" style="color: #D69D10">Transação não concluida.</span>
                </p>
                <p *ngIf="true" class="my-3 sub-text-header">Ocorreu um erro no envio do Pix.</p>
            </div>
        </div>
        <div class="row d-flex justify-content-center p-0 m-0">
            <div class="col-4 mt-4 p-0 m-0">
                <div class="row p-0 m-0">
                    <div class="col-12 p-0 m-0 mt-4">
                        <button class="secondary px-4 w-100" (click)="goToView('/dashboard')">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!transacaoPendente && infoComprovantes.data && infoComprovantes.data.status == 9">
        <div class="row d-flex justify-content-center transaction">
            <div class="col-4 header py-4">
                <p class="ml-2 mt-1 d-flex align-items-center title-header"> <img class="mr-2 "
                        src="/assets/svg/pix-pendente.svg" alt="arrow">
                    <span *ngIf="true" style="color: #D69D10">Transação não concluida.</span>
                </p>
                <p *ngIf="true" class="my-3 sub-text-header">O envio do Pix foi rejeitado.</p>
            </div>
        </div>
        <div class="row d-flex justify-content-center p-0 m-0">
            <div class="col-4 mt-4 p-0 m-0">
                <div class="row p-0 m-0">
                    <div class="col-12 p-0 m-0 mt-4">
                        <button class="secondary px-4 w-100" (click)="goToView('/dashboard')">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="transacaoPendente && infoComprovantes.data && (infoComprovantes.data.status === 0 || infoComprovantes.data.status === 14)">
        <div class="row d-flex justify-content-center transaction">
            <div class="col-4 header py-4">
                <p class="ml-2 mt-1 d-flex align-items-center title-header"> <img class="mr-2 "
                        src="/assets/svg/pix-pendente.svg" alt="arrow">
                    <span *ngIf="true" style="color: #D69D10">Transação em processamento.</span>
                </p>
                <p *ngIf="true" class="my-3 sub-text-header">Entre em contato com a nossa área de atendimento.</p>
            </div>
        </div>
        <div class="row d-flex justify-content-center p-0 m-0">
            <div class="col-4 mt-4 p-0 m-0">
                <div class="row p-0 m-0">
                    <div class="col-12 p-0 m-0 mt-4">
                        <button class="secondary px-4 w-100" (click)="goToView('/transaction-approval')">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>